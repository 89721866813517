/**
 * The `AddReviewRating` component is a React component that renders a star rating input field.
 */
import React from 'react';
import {
  TiStarFullOutline,
  TiStarHalfOutline,
  TiStarOutline,
} from 'react-icons/ti';
import ReactStars from 'react-rating-stars-component';

const AddReviewRating = ({ size, count, onChange }) => {
  const ratingChanged = (newRating) => {
    if (onChange) {
      onChange(newRating);
    }
  };

  return (
    <div className="rating_main_star">
      <ReactStars
        size={size ? size : 32}
        count={count ? count : 5}
        color={'#CCD0D1'}
        activeColor={'#FFB703'}
        value={0}
        a11y={true}
        emptyIcon={<TiStarOutline />}
        halfIcon={<TiStarHalfOutline />}
        filledIcon={<TiStarFullOutline />}
        onChange={ratingChanged}
      />
    </div>
  );
};

export default AddReviewRating;
