/**
 * The `BreadcrumbsLabel` component is a React component that renders a breadcrumb navigation bar.
 * @returns The `BreadcrumbsLabel` component is being returned.
 */
import React from 'react';
import * as styles from 'assets/styles';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { routesConfig } from 'helper/config';
import { RightArrow } from 'components/typography';

const BreadcrumbsLabel = ({
  breadcrumbsOne,
  breadcrumbsTwo,
  breadcrumbsThree,
  breadcrumbsOnePath,
  breadcrumbsTwoPath,
  isBack,
}) => {
  return (
    <>
      <styles.BreadcrumbsWrapper>
        <styles.ContainerWrapper>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to={routesConfig.homePath}
              className="flex items-center font-normal"
            >
              <styles.Para16Tag
                color="#414b56"
                className="hover:text-[#219ebc] hover:underline"
              >
                Home
              </styles.Para16Tag>
              <RightArrow />
            </Link>

            {isBack ? (
              <button
                onClick={() => window.history.back()}
                className="flex items-center font-normal"
              >
                <styles.Para16Tag
                  color="#219ebc"
                  className="text-[#219ebc] hover:underline"
                >
                  {breadcrumbsOne}
                </styles.Para16Tag>
                {breadcrumbsTwo ? <RightArrow /> : null}
              </button>
            ) : (
              <Link
                to={breadcrumbsOnePath}
                className="flex items-center font-normal"
              >
                <styles.Para16Tag
                  color="#219ebc"
                  className="text-[#219ebc] hover:underline"
                >
                  {breadcrumbsOne}
                </styles.Para16Tag>
                {breadcrumbsTwo ? <RightArrow /> : null}
              </Link>
            )}

            {breadcrumbsThree ? (
              <Link
                to={breadcrumbsTwoPath}
                className="flex items-center font-normal"
              >
                <styles.Para16Tag
                  color="#219ebc"
                  className="text-[#219ebc] hover:underline"
                >
                  {breadcrumbsTwo}
                </styles.Para16Tag>
                <RightArrow />
              </Link>
            ) : (
              <styles.Para16Tag
                color="#219ebc"
                className="font-normal underline"
              >
                {breadcrumbsTwo}
              </styles.Para16Tag>
            )}
            <styles.Para16Tag color="#219ebc" className="font-normal underline">
              {breadcrumbsThree}
            </styles.Para16Tag>
          </Breadcrumbs>
        </styles.ContainerWrapper>
      </styles.BreadcrumbsWrapper>
    </>
  );
};

export default BreadcrumbsLabel;
