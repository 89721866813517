import { SELECTED_SHIPPING_ADDRESS } from 'redux/constants';

export const selectedShippingAddressAction =
  (selectedData) => async (dispatch) => {
    try {
      dispatch({
        type: SELECTED_SHIPPING_ADDRESS,
        payload: selectedData,
      });
    } catch (error) {
      throw new Error();
    }
  };
