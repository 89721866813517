/**
 * The `LazyLoader` function is a higher-order component that wraps a component in a `Suspense`
 * component to enable lazy loading.
 * @param Component - The Component parameter is the component that you want to lazy load. It can be
 * any valid React component.
 */
import React from 'react';

/**
 * The `LazyLoader` function is a higher-order component that wraps a component in a `React.Suspense`
 * component to enable lazy loading.
 * @param Component - The `Component` parameter is the component that you want to wrap with lazy
 * loading functionality. It can be any valid React component.
 * @returns a higher-order component (HOC) called `WrappedComponent`.
 */
const LazyLoader = (Component) => {
  const WrappedComponent = (props) => (
    <React.Suspense fallback={null}>
      <Component {...props} />
    </React.Suspense>
  );

  WrappedComponent.displayName = `LazyLoader(${
    Component.displayName || Component.name
  })`;

  return WrappedComponent;
};

// Disable prop-types for this component (if needed)
LazyLoader.propTypes = {};

export default LazyLoader;
