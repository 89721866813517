import React from 'react';
import { useManageNotification } from 'hooks';
import { SwitchBtn } from 'components/commonComponents';
import * as styled from 'assets/styles';
import {
  EmailIcon,
  NotificationIcon,
  SMSIcons,
  WhatsAppIcons,
} from 'components/typography';

const ManageNotificationList = () => {
  const { handleChange, email, push, sms, whatsapp } = useManageNotification();

  return (
    <>
      <ul className="divide-y divide-gray-200">
        <li className="flex items-center justify-between py-5 first:pt-0 last:pb-0">
          <div className="flex items-center">
            <WhatsAppIcons />
            <styled.Para16Tag $weight="500" color="#2E3233">
              WhatsApp Notification
            </styled.Para16Tag>
          </div>
          <SwitchBtn
            checked={whatsapp}
            color="#FFFFFF"
            onChange={(isChecked) => handleChange('whatsapp', isChecked)}
          />
        </li>
        <li className="flex items-center justify-between py-5 first:pt-0 last:pb-0">
          <div className="flex items-center">
            <SMSIcons />
            <styled.Para16Tag $weight="500" color="#2E3233">
              SMS Notification
            </styled.Para16Tag>
          </div>
          <SwitchBtn
            checked={sms}
            color="#FFFFFF"
            onChange={(isChecked) => handleChange('sms', isChecked)}
          />
        </li>
        <li className="flex items-center justify-between py-5 first:pt-0 last:pb-0">
          <div className="flex items-center">
            <NotificationIcon stroke={'#000000'} />
            <styled.Para16Tag $weight="500" color="#2E3233">
              Push Notification
            </styled.Para16Tag>
          </div>
          <SwitchBtn
            checked={push}
            color="#FFFFFF"
            onChange={(isChecked) => handleChange('push', isChecked)}
          />
        </li>
        <li className="flex items-center justify-between py-5 first:pt-0 last:pb-0">
          <div className="flex items-center">
            <EmailIcon stroke={'#000000'} />
            <styled.Para16Tag $weight="500" color="#2E3233">
              Email Notification
            </styled.Para16Tag>
          </div>
          <SwitchBtn
            checked={email}
            color="#FFFFFF"
            onChange={(isChecked) => handleChange('email', isChecked)}
          />
        </li>
        {/* {manageNotifications.map((item, index) => (
          <li
            className="flex items-center justify-between py-5 first:pt-0 last:pb-0"
            key={index}
          >
            <div className="flex items-center">
              {item?.icon}
              <styled.Para16Tag $weight="500" color="#2E3233">
                {item?.label}
              </styled.Para16Tag>
            </div>
            <SwitchBtn
              checked={notification[item?.type]}
              color="#FFFFFF"
              onChange={() => handleSwitchChange(item?.type)}
            />
          </li>
        ))} */}
      </ul>
    </>
  );
};

export default ManageNotificationList;
