/* The code you provided is a JavaScript code snippet that defines a Redux reducer function called
`paymentReducer`. */
import {
  GET_SHIPPING_ADDRESS,
  GET_SHIPPING_ADDRESS_SUCCESS,
  MAKE_PAYMENT_SUCCESS,
  PROCESSING_PAYMENT,
} from 'redux/constants';
import { MAKE_PAYMENT_ERROR } from 'redux/constants/paymentConstants';

const initialState = {
  loadingAddress: false,
  shippingAddressInfo: {},
  processingPayment: false,
  success: false,
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHIPPING_ADDRESS:
      return {
        loadingAddress: true,
        ...state,
      };

    case GET_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        loadingAddress: false,
        shippingAddressInfo: action.payload,
      };

    case PROCESSING_PAYMENT:
      return {
        ...state,
        processingPayment: true,
      };
    case MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        success: true,
        processingPayment: false,
      };
    case MAKE_PAYMENT_ERROR:
      return {
        ...state,
        success: false,
        processingPayment: false,
      };
    default:
      return state;
  }
};
