import { USER_PRESCRIPTION, USE_MY_FRAME_PRESCRIPTION } from 'redux/constants';

const initialState = {
  prescription: null,
  useMyFramePrescription: null,
};

export const userPrescriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PRESCRIPTION:
      return {
        ...state,
        prescription: action.payload,
      };

    case USE_MY_FRAME_PRESCRIPTION:
      return {
        ...state,
        useMyFramePrescription: action.payload,
      };

    default:
      return state;
  }
};
