const useToggleRefs = (refs) => {
  const toggleActive = (btn, ref1, ref2) => {
    if (btn === 1) {
      ref1.current.classList.add('inactive');
      ref2.current.classList.remove('inactive');
    } else if (btn === 2) {
      ref2.current.classList.add('inactive');
      ref1.current.classList.remove('inactive');
    }
  };

  return toggleActive;
};

export default useToggleRefs;
