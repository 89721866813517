/* The code is defining a constant variable `STORAGE_KEYS` and exporting it. The purpose of this code
is not clear without additional context. It seems to be related to storing keys in some kind of
storage system, but without ore information, it is difficult to determine its exact purpose. */
export const STORAGE_KEYS = {
  // user mail
  service: 'service',
  // user token
  token: 'token',
  // user id
  user_identify: 'identify',
  // Guest
  guest_token: 'guest_token',
  guestData: 'guestData',
  prescriptionData: 'prescriptionData',
  cartList: 'cartList',
  cartData: 'cartData',
  guestUserBillingDetails: 'guestUserBillingDetails',
  accessories: 'accessories',
  accessoriesData: 'accessoriesData',
  // Promo code
  promocodeDetail: 'promocodeDetail',
};

/* The code is defining another constant variable `SECURITY_KEYS` and exporting it. It seems to be
related to storing security-related keys in some kind of storage system. The keys include
`user_mail`, `token`, and `user_identify`, which likely represent the user's email, token, and
identification respectively. The purpose of these keys and their usage would depend on the specific
implementation and context of the code. */
export const SECURITY_KEYS = {
  // user mail
  user_mail: 'user_mail',
  // user token
  token: 'token',
  // user id
  user_identify: 'user_identify',
};
