/**
 * The PopularBlog component is a React component that displays a list of popular blog posts.
 * @returns The PopularBlog component is returning a section element with the class name
 * "PopularBlog_detail". Inside the section, there is a ContainerWrapper component that contains a
 * Head32Tag component with the text "Popular Blogs". Below that, there is a MainPadding component with
 * top and bottom padding values. Inside the MainPadding component, there is a div element with the
 * class name "popular_Blogs". Inside this
 */
import React from 'react';
import './popularBlog.css';
import * as styles from 'assets/styles';
import { routesConfig } from 'helper/config';
import moment from 'moment';
import { useFetchBlogs } from 'hooks';
import { Loader } from '..';

const PopularBlog = () => {
  const { blogs, loading } = useFetchBlogs('/blogs', { is_popular: true });

  const blogDetail = blogs.map((item, index) => {
    return (
      <div className="popular_blog_detail" key={index}>
        <styles.CardBorder padding={16} radius={12}>
          <img
            loading="lazy"
            className="h-[233px] object-cover rounded-lg mx-auto"
            src={item?.image}
            alt={item?.image}
          />
          <styles.Head24Tag>
            {item?.title?.slice(0, 22) + '...'}
          </styles.Head24Tag>
          <styles.Para16Tag
            className="flex items-center"
            $m_bottom={12}
            $weight={500}
          >
            {moment(item?.timestamp)?.format('MMMM D, YYYY')}{' '}
          </styles.Para16Tag>

          <div className="readmore">
            <styles.Para16Tag color={'#2E3233'} $weight={500}>
              {item?.author}
            </styles.Para16Tag>
            <styles.TextLink to={routesConfig.blogDetail + '/' + item?._id}>
              {'Read More'}
            </styles.TextLink>
          </div>
        </styles.CardBorder>
      </div>
    );
  });

  return (
    <>
      <section className="PopularBlog_detail">
        <styles.ContainerWrapper>
          <div className="flex justify-between items-center flex-wrap gap-3">
            <styles.Head32Tag $weight={600}>Popular Blogs</styles.Head32Tag>
            <styles.LinkWrapper to={routesConfig.seeAllBlogs}>
              See more
            </styles.LinkWrapper>
          </div>
          <styles.MainPadding $p_top={32} $p_bottom={48}>
            <div className="popular_Blogs">
              <div className="grid grid-cols-4 laptop:grid-cols-3 screen992:grid-cols-2 screen553:!grid-cols-1 gap-4">
                {loading ? <Loader /> : blogDetail}
              </div>
            </div>
          </styles.MainPadding>
        </styles.ContainerWrapper>
      </section>
    </>
  );
};

export default PopularBlog;
