/* The code is a React component called `ChangePasswordPopup`. It is a modal popup that allows the user
to change their password. */
import React from 'react';
import * as joy from '@mui/joy';
import * as formik from 'formik';
import * as styles from 'assets/styles';
import * as commonComponents from 'components/commonComponents';
import { Auth } from 'helper/Auth';
import { ApiResetPassword } from 'helper/API';
import { resetPasswordSchema } from 'helper';
import { PasswordIcon } from 'components/typography';
import { useUserProfile } from 'hooks';
import { useSnackbar } from 'context';

const ChangePasswordPopup = ({
  changePasswordPopup,
  setChangePasswordPopup,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { showSnackbar } = useSnackbar();

  const userEmail = Auth?.getUserEmail() || null;

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const { userData } = useUserProfile();

  /**
   * The handleSubmit function is a JavaScript function that handles form submission, dispatches a
   * loginUserAction, resets the form, and resolves or rejects a promise based on the success or
   * failure of the login action.
   * @param values - The `values` parameter is an object that contains the form values submitted by the
   * user. It typically includes properties for each form field, with the field names as keys and the
   * user input as values.
   * @returns The handleSubmit function returns a Promise.
   */
  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const body = {
        email: userEmail,
        newpassword: values?.password,
      };

      // API
      const response = await ApiResetPassword(body);

      if (response?.data?.status === 200) {
        showSnackbar(
          `Hey, ${userData?.userName} Your Password reset successfully!`,
          'soft',
          'primary'
        );

        setChangePasswordPopup(false);
        resetForm();
      } else if (response?.data?.status === 400)
        showSnackbar(response?.data?.message, 'soft', 'danger');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <joy.Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={changePasswordPopup}
        onClose={() => setChangePasswordPopup(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <joy.Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <commonComponents.ModalCloseButton />

          <styles.Para20Tag className="pb-6" $weight="600" color="#2E3233">
            Change Password
          </styles.Para20Tag>

          <formik.Formik
            initialValues={initialValues}
            validationSchema={resetPasswordSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <formik.Form>
                <div className="flex flex-col gap-8">
                  <div>
                    <styles.FormInputLabel>Password</styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <PasswordIcon stroke={'#000000'} />
                      <formik.Field
                        type="password"
                        placeholder="Enter your password"
                        name="password"
                      />
                    </styles.FormInputOuter>
                    {touched.password && errors.password && (
                      <commonComponents.FormErrorMessage
                        errors={errors.password}
                      />
                    )}
                  </div>
                  <div>
                    <styles.FormInputLabel>
                      Confirm Password
                    </styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <PasswordIcon stroke={'#000000'} />
                      <formik.Field
                        type="password"
                        placeholder="Enter your confirm password"
                        name="confirmPassword"
                      />
                    </styles.FormInputOuter>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <commonComponents.FormErrorMessage
                        errors={errors.confirmPassword}
                      />
                    )}
                  </div>
                  <styles.ButtonWrapper width="100%" type="submit">
                    {isLoading ? 'Loading...' : 'Verify'}
                  </styles.ButtonWrapper>
                </div>
              </formik.Form>
            )}
          </formik.Formik>
        </joy.Sheet>
      </joy.Modal>
    </>
  );
};

export default ChangePasswordPopup;
