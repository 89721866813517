/**
 * The above code is a JavaScript module that contains various action functions for user authentication
 * and user data management, such as creating a user, logging in a user, verifying OTP, getting a
 * single user, and updating a user.
 * @param userData - The `userData` parameter is an object containing user data such as username,
 * email, password, and mobile number. It is used in the `createUserAction` and `loginUserAction`
 * functions to create a new user or log in an existing user.
 * @param navigate - The `navigate` parameter is a function that is used to navigate to a different
 * page or route in the application. It is typically provided by a navigation library such as React
 * Router or React Navigation.
 * @returns The code is returning multiple action functions that dispatch actions to create, login,
 * verify OTP, get single user, and update user. These action functions are asynchronous and return
 * promises.
 */
import { message, routesConfig } from 'helper/config';
import { UserAPI, UserAuthAPI } from 'helper/API';
import * as APIHeader from 'helper/API';
import { Auth } from 'helper/Auth';
import * as userConstants from 'redux/constants';
import { STORAGE_KEYS } from 'helper/APP';
import { sessionStorageHandler } from 'utils';
import { API } from 'utils/axios';

/**
 * The `createUserAction` function is an asynchronous action that dispatches a request to create a user
 * with the provided user data, and returns a promise that resolves with the response or rejects with
 * an error.
 * @param userData - An object containing user data such as username, email, password, and mobile
 * number.
 * @returns The `createUserAction` function returns a Promise.
 */
export const createUserAction =
  (userData, navigate, state = undefined, isNavigate, showSnackbar) =>
  async (dispatch) => {
    try {
      dispatch({ type: userConstants.USER_REQUEST });

      // AXIOS BODY
      const body = {
        username: userData?.username,
        email: userData?.email,
        password: userData?.password,
        role_id: Auth?.roleUser(),
        mobile: userData?.mobile,
      };

      // API
      const response = await UserAuthAPI.post(
        '/signup',
        body,
        APIHeader.APIHeaderCors
      );

      dispatch({
        type: userConstants.CREATE_USER_SUCCESS,
        payload: response?.data?.message,
      });

      if (response?.data?.status === 200) {
        Auth.removeGuest();

        showSnackbar(response?.data?.message, 'soft', 'success');

        if (isNavigate) {
          navigate(routesConfig?.login, { state: state });
        }
      } else if (response?.data?.status === 409) {
        showSnackbar(response?.data?.message, 'soft', 'danger');
      } else if (response?.data?.res?.status === 400) {
        showSnackbar(response?.data?.res?.message, 'soft', 'danger');
      } else return;

      return response;
    } catch (error) {
      dispatch({
        type: userConstants.USER_FAILURE,
        payload: error?.message,
      });

      if (error) showSnackbar(message?.ERROR_TRY_AGAIN, 'soft', 'danger');
      else if (error?.message === 'Network Error')
        showSnackbar(message?.NETWORK_ERROR, 'soft', 'danger');
      else return;

      throw error;
    }
  };

/**
 * This function is an action creator that handles the login process for a user in a JavaScript
 * application.
 * @param userData - The `userData` parameter is an object that contains the user's data, specifically
 * their email.
 * @returns The loginUserAction function returns a Promise.
 */
export const loginUserAction =
  (userData, state, navigate, isNavigate, showSnackbar) => async (dispatch) => {
    try {
      dispatch({ type: userConstants.USER_REQUEST });

      // AXIOS BODY
      const body = {
        email: userData?.email,
        password: userData?.password,
      };

      // API
      const response = await UserAuthAPI.post(
        '/user-signin',
        body,
        APIHeader.APIHeaderCors
      );

      dispatch({
        type: userConstants.LOGIN_USER_SUCCESS,
        payload: response?.data?.user,
      });

      if (response?.data?.token) {
        Auth.removeGuest();

        Auth?.setUserEmail(userData?.email);
        Auth?.setUserId(response?.data?.user?._id);
        Auth?.setToken(response?.data?.token);

        // showSnackbar(message.LOGIN_SUCCESS, 'soft', 'success');
        showSnackbar(
          `Welcome back, ${response?.data?.user?.username}!`,
          'soft',
          'primary'
        );

        if (isNavigate) {
          if (state) {
            navigate(state?.redirect);
          } else {
            navigate(routesConfig?.homePath);
          }
        }
      } else if (response?.data?.status === 401)
        showSnackbar(
          message?.LOGIN_USER_INVALID_EMAIL_OR_PASSWORD,
          'soft',
          'warning'
        );
      else if (response?.data?.status === 403)
        showSnackbar(
          message?.LOGIN_USER_INVALID_EMAIL_OR_PASSWORD,
          'soft',
          'warning'
        );
      else return;

      return response;
    } catch (error) {
      dispatch({
        type: userConstants.USER_FAILURE,
        payload: error?.message,
      });

      if (error) showSnackbar(message?.ERROR_TRY_AGAIN, 'soft', 'danger');
      else if (error?.message === 'Network Error')
        showSnackbar(message?.NETWORK_ERROR, 'soft', 'danger');
      else return;

      throw error;
    }
  };

export const guestUserAction =
  (userData, showSnackbar) => async (dispatch, getState) => {
    try {
      dispatch({ type: userConstants.USER_REQUEST });

      // API
      const response = await UserAuthAPI.post(
        '/guestsignin',
        userData,
        APIHeader.APIHeaderCors
      );

      if (response?.data?.status) {
        showSnackbar(response?.data?.message, 'soft', 'danger');
      }

      dispatch({
        type: userConstants.GUEST_USER_SUCCESS,
        payload: response?.data?.user,
      });

      sessionStorageHandler.setItem(
        STORAGE_KEYS.guestData,
        JSON.stringify(getState().userAuth.guestUser)
      );

      if (response?.data?.token) {
        Auth?.setGuestToken(response?.data?.token);
      }

      return response;
    } catch (error) {
      dispatch({
        type: userConstants.USER_FAILURE,
        payload: error?.message,
      });

      if (error) showSnackbar(message?.ERROR_TRY_AGAIN, 'soft', 'danger');
      else if (error?.message === 'Network Error')
        showSnackbar(message?.NETWORK_ERROR, 'soft', 'danger');
      else return;

      throw error;
    }
  };

export const getSingleUserAction =
  (showSnackbar, handleUserLogout) => async (dispatch) => {
    try {
      dispatch({ type: userConstants.USER_REQUEST });

      const id = Auth?.getUserId() || null;

      const headers = await APIHeader.getHeaders();

      // API
      const response = await API.get(`/users/${id}`, {
        headers,
      });
      if (response?.data?.status === 401) {
        handleUserLogout();
      }

      dispatch({
        type: userConstants.GET_SINGLE_USER_SUCCESS,
        payload: response?.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: userConstants.USER_FAILURE,
        payload: error?.message,
      });

      if (error) showSnackbar(message?.ERROR_TRY_AGAIN, 'soft', 'danger');
      else if (error?.message === 'Network Error')
        showSnackbar(message?.NETWORK_ERROR, 'soft', 'danger');
      else return;

      throw error;
    }
  };

export const updateUserAction =
  (updateUser, userId, setProfileImage, handleClose, showSnackbar) =>
  async (dispatch) => {
    try {
      dispatch({ type: userConstants.USER_REQUEST });

      const headers = await APIHeader.getFormDataHeaders();

      // API
      const response = await UserAPI.put(`/${userId}`, updateUser, {
        headers,
      });

      dispatch({
        type: userConstants.UPDATE_USER_SUCCESS,
        payload: response?.data?.user,
      });

      if (response?.data?.message?.status === 200) {
        showSnackbar(message.PROFILE_UPDATE_SUCCESS, 'soft', 'success');

        setProfileImage(response?.data?.user?.profile_picture);
        handleClose();
      } else if (response?.data?.status === 400)
        showSnackbar(response?.data?.message, 'soft', 'danger');
      else return;

      return response;
    } catch (error) {
      dispatch({
        type: userConstants.USER_FAILURE,
        payload: error?.message,
      });

      if (error) showSnackbar(message?.ERROR_TRY_AGAIN, 'soft', 'danger');
      else if (error?.message === 'Network Error')
        showSnackbar(message?.NETWORK_ERROR, 'soft', 'danger');
      else return;

      throw error;
    }
  };

export const resetUserData = () => async (dispatch) => {
  try {
    dispatch({ type: userConstants.USER_RESET });
  } catch (error) {
    if (error) throw error;
  }
};
