import React from 'react';
import { ProductCard, ProductCardSkeleton } from '.';
import { ProductFrameListWrapper } from 'assets/styles';
import { Pagination } from 'swiper/modules';
import { SwiperSlide, Swiper } from 'swiper/react';

const ProductFrameList = ({ products, isLoading }) => {
  return (
    <>
      <ProductFrameListWrapper>
        <div className="grid grid-cols-3 gap-6 xl:grid-cols-2 mobile:min-w-max mobile:hidden">
          {isLoading
            ? new Array(6)
                .fill(null)
                .map((_, index) => <ProductCardSkeleton key={index} />)
            : products
                ?.slice(-6)
                ?.reverse()
                ?.map((product) => {
                  return (
                    <ProductCard
                      key={product?.product_frame?._id}
                      product={product}
                    />
                  );
                })}
        </div>
        <Swiper
          loop={true}
          slidesPerView={1}
          spaceBetween={16}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            576: {
              slidesPerView: 1,
            },
            720: {
              slidesPerView: 2,
            },
          }}
          modules={[Pagination]}
          className="useYourFrameSlider !hidden mobile:!block"
        >
          {isLoading
            ? new Array(6)
                .fill(null)
                .map((_, index) => <ProductCardSkeleton key={index} />)
            : products
                ?.slice(-6)
                ?.reverse()
                ?.map((product) => (
                  <SwiperSlide key={product?.product_frame?._id}>
                    <ProductCard product={product} />
                  </SwiperSlide>
                ))}
        </Swiper>
      </ProductFrameListWrapper>
    </>
  );
};

export default ProductFrameList;
