/* The Auth class is used to authorize a user and save a token string in cookies. */
// For get item use @returns {type} || no need to returns then use @param {type}
import CryptoJS from 'crypto-js';
import {
  setEncryptCookie,
  getCookie,
  setCookie,
  sessionStorageHandler,
} from 'utils';
import { SECURITY_KEYS, STORAGE_KEYS } from './APP';
import Cookies from 'js-cookie';

/* The Auth class provides methods for setting the authentication email in local storage and returning
a specific role ID. */
export class Auth {
  /**
   * The function sets the user's email as a cookie with a specified storage key and security key.
   * @param email - The email parameter is the email address of the user that needs to be set.
   */
  static setUserEmail(email) {
    setEncryptCookie(STORAGE_KEYS.service, email, SECURITY_KEYS?.user_mail);
  }

  /**
   * The function `getUserEmail()` retrieves and decrypts a cookie containing an email address using
   * AES encryption.
   * @returns the decrypted email value obtained from the cookie.
   */
  static getUserEmail() {
    const getCookies = getCookie(STORAGE_KEYS.service);

    const email =
      getCookies &&
      CryptoJS.AES.decrypt(getCookies, SECURITY_KEYS?.user_mail).toString(
        CryptoJS.enc.Utf8
      );

    return email;
  }

  /**
   * The function sets a token in a cookie with a specified name and security options.
   * @param token - The token parameter is the value that you want to set as the token. It could be a
   * string or any other data type that represents the token value.
   */
  static setToken(token) {
    setCookie(STORAGE_KEYS.token, token);
  }

  static setGuestToken(token) {
    sessionStorageHandler.setItem(STORAGE_KEYS.guest_token, token);
  }

  /**
   * The function `getToken()` retrieves and decrypts a token stored in a cookie using AES encryption.
   * @returns the decrypted token value.
   */
  static getToken() {
    const token = getCookie(STORAGE_KEYS.token);

    return token;
  }

  static getGuestToken() {
    const token = sessionStorageHandler.getItem(STORAGE_KEYS.guest_token);

    return token;
  }

  static removeGuest() {
    sessionStorageHandler.removeItem(STORAGE_KEYS.guest_token);
    sessionStorageHandler.removeItem(STORAGE_KEYS.guestData);
    sessionStorageHandler.removeItem(STORAGE_KEYS.cartData);
    sessionStorageHandler.removeItem(STORAGE_KEYS.prescriptionData);
    sessionStorageHandler.removeItem(STORAGE_KEYS.guestUserBillingDetails);
    sessionStorageHandler.removeItem(STORAGE_KEYS.cartList);
  }

  /**
   * The function sets the user ID as a cookie with a specified storage key and security key.
   * @param id - The id parameter is the user identification value that you want to set for the user.
   */
  static setUserId(id) {
    setEncryptCookie(
      STORAGE_KEYS.user_identify,
      id,
      SECURITY_KEYS?.user_identify
    );
  }

  /**
   * The function `getUserId` retrieves and decrypts a user ID stored in a cookie using AES encryption.
   * @returns the decrypted user ID.
   */
  static getUserId() {
    const isUserId = getCookie(STORAGE_KEYS.user_identify);

    const id =
      isUserId &&
      CryptoJS.AES.decrypt(isUserId, SECURITY_KEYS?.user_identify).toString(
        CryptoJS.enc.Utf8
      );

    return id;
  }

  /**
   * The function "roleUser" returns a specific role ID.
   * @returns the value of the variable `roleId`, which is the string "64b5200bcde1b39872064686".
   */
  static roleUser() {
    const roleId = process.env.REACT_APP_USER_ROLE_ID;
    return roleId;
  }

  /**
   * The function `userLogout` removes certain cookies related to user authentication and
   * identification.
   * @returns Nothing is being returned. The return statement is used to exit the function early, but
   * it does not specify a value to be returned.
   */
  static userLogout() {
    // Get all existing cookies
    const cookies = Cookies.get();

    // Loop through cookies and remove each one
    Object.keys(cookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });

    localStorage.clear();
    sessionStorageHandler.clear();
  }

  /**
   * The function `userClear()` clears all existing cookies after a timeout of 1 day.
   * @returns Nothing is being returned. The return statement is used to exit the function early, but
   * since there is no value being returned, it is not necessary in this case.
   */
  static userClear() {
    // Get all existing cookies
    const cookies = Cookies.get();

    // Loop through cookies and remove each one
    Object.keys(cookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });

    localStorage.clear();
    sessionStorageHandler.clear();
  }

  static isLogin() {
    if (this.getUserId()) {
      return true;
    }
    return false;
  }
}
