/**
 * The `usePathCheck` function checks if the current pathname matches any of the routes specified in
 * the `routesConfig` object.
 * @returns The function `usePathCheck` returns a boolean value indicating whether the current pathname
 * matches any of the routes defined in the `routesConfig` object.
 */
import { routesConfig } from 'helper/config';
import { useLocation } from 'react-router-dom';

const usePathCheck = () => {
  const { pathname } = useLocation();

  let routes = [
    routesConfig?.login,
    routesConfig?.createAccount,
    routesConfig?.forgot,
    routesConfig?.verifyEmail,
    routesConfig?.resetPassword,
  ];

  /* The line `const includesPath = routes.map((route) => [route, route + '/']).flat();` is creating an
  array of paths by mapping over the `routes` array and appending a forward slash (`/`) to each
  route. The `flat()` method is then used to flatten the resulting array of arrays into a single
  array. */
  const includesPath = routes.map((route) => [route, route + '/']).flat();

  return includesPath.includes(pathname);
};

export default usePathCheck;
