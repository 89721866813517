/**
 * This is a React component that renders a checkbox with a custom style.
 * @returns The CheckBox component is being returned.
 */
import React from 'react';

import * as styled from 'assets/styles';

const CheckBox = ({ id, name, className, onChange }) => {
  return (
    <>
      <styled.CheckboxContainer className={className}>
        <styled.HiddenCheckbox
          type="checkbox"
          id={id}
          name={name}
          onChange={onChange}
        />
        <styled.StyledCheckbox>
          <styled.CheckboxIcon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </styled.CheckboxIcon>
        </styled.StyledCheckbox>
      </styled.CheckboxContainer>
    </>
  );
};

export default CheckBox;
