/* This code is creating an authentication context and provider using React's context API. */
import React, { useContext } from 'react';
import { Auth } from 'helper/Auth';
import { SELECTED_COLOR_IMAGES } from 'helper/APP';

// Define the initial state for your data
const initialState = {
  lensSliderImages: [], // This is where your array will be stored
};

// Create a context
const AppContext = React.createContext();

// Define a reducer function to handle state updates
const reducer = (state, action) => {
  switch (action.type) {
    case SELECTED_COLOR_IMAGES:
      return {
        ...state,
        lensSliderImages: action.payload,
      };
    default:
      return state;
  }
};

// Create a provider component
export const AppProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = React.useState(false);

  // (1280px / 1200px / 1024px ) to 720px Tablet view
  const [isTablet, setIsTablet] = React.useState(window.innerWidth > 1280);
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth <= 1280 && window.innerWidth > 720
  );

  // Shipping cost for "use your frame"
  const shippingCost = 20;

  /**
   * The function `handleResize` determines whether the window size corresponds to a tablet or mobile
   * device and updates the corresponding state variables.
   */
  const handleResize = () => {
    const tablet = window.innerWidth > 1280;
    const mobile = window.innerWidth <= 1280 && window.innerWidth > 720;
    setIsTablet(tablet);
    setIsMobile(mobile);
  };

  /* The line `const [state, dispatch] = React.useReducer(reducer, initialState);` is using the
  `useReducer` hook from React to create a state and a dispatch function. */
  const [state, dispatch] = React.useReducer(reducer, initialState);

  /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
  code, it is used to check if there is a token stored in the cookie and set the `authenticated`
  state to `true` if a token is found. */
  React.useEffect(() => {
    const token = Auth?.getToken(); // Retrieve token from cookie
    if (token) setAuthenticated(true);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Define a function to update the data array
  const lensSliderImagesArray = (lensSliderImaArray) => {
    dispatch({ type: SELECTED_COLOR_IMAGES, payload: lensSliderImaArray });
  };

  const contextState = {
    authenticated,
    setAuthenticated,
    state,
    lensSliderImagesArray,
    isTablet,
    setIsTablet,
    isMobile,
    setIsMobile,
    shippingCost,
  };

  /* The `return` statement is returning a JSX element that represents the provider component. */
  return (
    <AppContext.Provider value={contextState}>{children}</AppContext.Provider>
  );
};

/**
 * The useApp function returns the value of the AppContext from the React context.
 * @returns The `useApp` function is returning the value of `React.useContext(AppContext)`.
 */
export const useApp = () => {
  return useContext(AppContext);
};
