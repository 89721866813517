/* The above code is a React component written in JavaScript. It is a form for entering a lens
prescription manually. It includes input fields for the left and right eye's spherical power (SPH),
cylindrical power (CYL), and axis. It also includes a checkbox for selecting a 2 PD number option.
When the "Continue" button is clicked, the form data is sent to the server and added to the user's
cart. */
import React from 'react';
import * as styled from 'assets/styles';
import { routesConfig } from 'helper/config';
import { Link } from 'react-router-dom';
import {
  CheckBoxCustom,
  UserPrescriptionTable,
} from 'components/commonComponents';
import { usePrescriptionContext, useSnackbar } from 'context';
import { BtnRightArrow } from 'components/typography';
import { IoClose } from 'react-icons/io5';
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import { Para16Tag } from 'assets/styles';

const LensePrescriptionManually = ({
  handelClick,
  handleBackButtonClick,
  closeButton,
}) => {
  const [open, setOpen] = React.useState(false);
  const [licensed, setLicensed] = React.useState(false);

  const { showSnackbar } = useSnackbar();

  const {
    checked,
    leftEyeAxisSwitch,
    setLeftEyeAxisSwitch,
    rightEyeAxisSwitch,
    setRightEyeAxisSwitch,
    leftEyeAttributes,
    setLeftEyeAttributes,
    rightEyeAttributes,
    setRightEyeAttributes,
    errors,
    setErrors,
    isProgressives,
    isProgressive,
    handleCheckboxChange,
  } = usePrescriptionContext();

  const onContinueClick = async () => {
    if (licensed) {
      try {
        if (
          isProgressives &&
          (!leftEyeAttributes?.power || !rightEyeAttributes?.power)
        ) {
          setOpen(true);
          return;
        }

        if (!errors.isValid) setOpen(true);
        if (errors.isValid) handelClick?.(3);
      } catch (error) {
        if (error)
          showSnackbar(error?.message?.ERROR_TRY_AGAIN, 'soft', 'danger');
        else if (error?.message === 'Network Error')
          showSnackbar(error?.message?.NETWORK_ERROR, 'soft', 'danger');
        else throw error;
      }
    } else {
      showSnackbar(
        'Check the box before proceeding to the next page.',
        'soft',
        'danger'
      );
    }
  };

  return (
    <>
      <div>
        <div className="hidden screen1280:block">
          <div className="flex items-center gap-3 pb-[14px] mb-[14px] border-b-4">
            <button onClick={closeButton}>
              <IoClose size={20} color="#868A8B" />
            </button>
            <styled.Para16Tag>Enter Prescription Manually</styled.Para16Tag>
          </div>
        </div>
        <div>
          <styled.Head32Tag
            className="screen1280:hidden"
            color={'#2E3233'}
            $weight={600}
          >
            Enter Prescription Manually
          </styled.Head32Tag>
          <styled.Para16Tag className="py-4 screen1280:pt-0" $weight={600}>
            Have a Saved Prescription?{' '}
            <span className="text-[#FB8500]">
              {' '}
              <Link to={routesConfig?.addNewPrescription}>
                Go to Add Prescription
              </Link>{' '}
            </span>
          </styled.Para16Tag>
        </div>
        <UserPrescriptionTable
          checked={checked}
          leftEyeAxisSwitch={leftEyeAxisSwitch}
          setLeftEyeAxisSwitch={setLeftEyeAxisSwitch}
          rightEyeAxisSwitch={rightEyeAxisSwitch}
          setRightEyeAxisSwitch={setRightEyeAxisSwitch}
          leftEyeAttributes={leftEyeAttributes}
          setLeftEyeAttributes={setLeftEyeAttributes}
          rightEyeAttributes={rightEyeAttributes}
          setRightEyeAttributes={setRightEyeAttributes}
          handleCheckboxChange={handleCheckboxChange}
          errors={errors}
          setErrors={setErrors}
          isProgressive={isProgressive}
        />

        <label className="cursor-pointer flex items-baseline gap-[10px] py-4">
          <CheckBoxCustom
            id="licensed"
            name="licensed"
            checked={licensed}
            onChange={() => setLicensed(!licensed)}
          />
          <Para16Tag className="italic !font-bold">
            By selecting this box, the customer affirms that they are 18 years
            and older and that their eye prescription is correct, was obtained
            from a licensed optometrist/ophthalmologist and valid under state
            law. Optics Haven assumes no liability for any inaccuracies in the
            prescription provided on this site.
          </Para16Tag>
        </label>

        <Para16Tag color="red">
          Note: To avoid delay in processing your orders, Residents of
          California and Nevada should upload their prescription as we cannot
          ship self reported prescriptions to their state
        </Para16Tag>

        <div className="flex items-center mt-10 justify-center screen1280:hidden">
          <styled.ButtonWrapper onClick={onContinueClick}>
            Continue
            <BtnRightArrow />
          </styled.ButtonWrapper>
        </div>
      </div>
      <div className="hidden screen1280:block py-4">
        <div className="flex justify-between gap-4">
          <styled.ButtonWrapper
            onClick={handleBackButtonClick}
            $bg_color={'#FFF'}
            $bColor={'#DEE2E4'}
            color={'#5F6364'}
            $hover_color={'#2E3233'}
            $hover_bg={'#FFF'}
            $hover_border={'#DEE2E4'}
            width={'100%'}
            $border_color={'#DEE2E4'}
          >
            Back
          </styled.ButtonWrapper>
          <styled.ButtonWrapper onClick={onContinueClick} width={'100%'}>
            Continue
          </styled.ButtonWrapper>
        </div>
      </div>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <div className="max-w-[450px]">
            {errors?.right?.sph && (
              <styled.Para16Tag className="mb-2">
                {errors?.right?.sph || errors?.left?.sph}
              </styled.Para16Tag>
            )}
            {errors?.right?.cyl && (
              <styled.Para16Tag className="mb-2">
                {errors?.right?.cyl}
              </styled.Para16Tag>
            )}
            {errors?.right?.axis && (
              <styled.Para16Tag className="mb-2">
                {errors?.right?.axis}
              </styled.Para16Tag>
            )}
            {errors?.left?.sph && (
              <styled.Para16Tag className="mb-2">
                {errors?.left?.sph}
              </styled.Para16Tag>
            )}
            {errors?.left?.cyl && (
              <styled.Para16Tag className="mb-2">
                {errors?.left?.cyl}
              </styled.Para16Tag>
            )}
            {errors?.left?.axis && (
              <styled.Para16Tag className="mb-2">
                {errors?.left?.axis}
              </styled.Para16Tag>
            )}
            {isProgressives &&
            (!leftEyeAttributes?.power || !rightEyeAttributes?.power) ? (
              <styled.Para16Tag className="mb-2">
                You selected Progressive as primary use of your glasses, please
                input your ADD for the reading portion of your glasses
              </styled.Para16Tag>
            ) : null}
          </div>
          <styled.ButtonWrapper onClick={() => setOpen(false)} className="mt-3">
            Got it, thanks!
          </styled.ButtonWrapper>
        </Sheet>
      </Modal>
    </>
  );
};

export default LensePrescriptionManually;
