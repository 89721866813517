import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { useApp } from 'context';

const BannerSkeleton = () => {
  const { isTablet, isMobile } = useApp();

  return (
    <>
      <div className="overflow-x-hidden">
        <Box
          sx={{
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Skeleton
            sx={{ bgcolor: '#bfbfbf' }}
            variant="rectangular"
            width={'100%'}
            height={isTablet ? 536 : isMobile ? 398 : 229}
          />
        </Box>
      </div>
    </>
  );
};

export default BannerSkeleton;
