/* This code is a React component called `ResetPasswordForm`. It is a form that allows users to reset
their password. */
import React from 'react';
import * as styles from 'assets/styles';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { resetPasswordSchema } from 'helper';
import { ApiResetPassword } from 'helper/API';
import { Auth } from 'helper/Auth';
import { routesConfig } from 'helper/config';
import styled from 'pages/desktopView/Auth/Auth.module.css';
import { FormErrorMessage } from '..';
import { PasswordIcon } from 'components/typography';
import { useSnackbar } from 'context';

const ResetPasswordForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const userEmail = Auth?.getUserEmail() || null;

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  /**
   * The handleSubmit function is a JavaScript function that handles form submission, dispatches a
   * loginUserAction, resets the form, and resolves or rejects a promise based on the success or
   * failure of the login action.
   * @param values - The `values` parameter is an object that contains the form values submitted by the
   * user. It typically includes properties for each form field, with the field names as keys and the
   * user input as values.
   * @returns The handleSubmit function returns a Promise.
   */
  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const body = {
        email: userEmail,
        newpassword: values?.password,
      };

      // API
      const response = await ApiResetPassword(body);
      //

      if (response?.data?.status === 200) {
        showSnackbar('Password reset successfully', 'soft', 'success');
        navigate(routesConfig?.login);
        resetForm();
        Auth?.setUserEmail(null);
      } else if (response?.data?.status === 400)
        showSnackbar(response?.data?.message, 'soft', 'danger');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styled.login_right_inner_outer_info}>
        <div className={styled.login_text_main_title_top_div_inner}>
          <div className={styled.login_text_main_title}>
            <styles.Head48Tag className="text-center screen1280:text-left">
              Reset Password
            </styles.Head48Tag>
          </div>
          <styles.Para16Tag
            className="text-center screen1280:text-left"
            $weight={500}
            color={'#2E3233'}
          >
            {'Your new password must be different from previous used password.'}
          </styles.Para16Tag>
        </div>
        <div className="max-w-lg w-full mx-auto screen1280:max-w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={resetPasswordSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className={styled.form_input_list_email_pass_other_data}>
                  <div className={styled.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>Password</styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <PasswordIcon stroke={'#000000'} />
                      <Field
                        type="password"
                        placeholder="Enter your password"
                        name="password"
                      />
                    </styles.FormInputOuter>
                    {touched.password && errors.password && (
                      <FormErrorMessage errors={errors.password} />
                    )}
                  </div>
                </div>
                <div className={styled.form_input_list_email_pass_other_data}>
                  <div className={styled.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>
                      Confirm Password
                    </styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <PasswordIcon stroke={'#000000'} />
                      <Field
                        type="password"
                        placeholder="Enter your confirm password"
                        name="confirmPassword"
                      />
                    </styles.FormInputOuter>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <FormErrorMessage errors={errors.confirmPassword} />
                    )}
                  </div>
                </div>
                <styles.ButtonWrapper width="100%" type="submit">
                  {isLoading ? 'Loading...' : 'Verify'}
                </styles.ButtonWrapper>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
