import { CommonApi } from 'helper/API';
import { APIHeaderCors } from 'helper/API';
import { ApiGetCommon } from 'helper/API';
import { ErrorToast } from 'helper/Toast';
import {
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_REQUEST,
  LOADING_FILTER_DATA,
  SET_FILTER_DATA,
  SET_SELECTED_PRODUCT,
} from 'redux/constants';
import { API } from 'utils/axios';

export const getAllProducts =
  (
    category,
    frameTypes,
    frameShapes,
    frameColors,
    frameSizes,
    frameMaterial,
    genders,
    sortBy
  ) =>
  async (dispatch) => {
    try {
      const headers = await APIHeaderCors;
      dispatch({ type: GET_ALL_PRODUCTS_REQUEST });

      const body = {
        in_stock: true,
        is_active: true,
      };

      if (category?.length) body.category_id = category?.[0];
      if (frameTypes?.length) body.frame_type_id = frameTypes;
      if (frameShapes?.length) body.frame_shape_id = frameShapes;
      if (frameColors?.length) body.color_id = frameColors;
      if (frameSizes?.length) body.frame_size_id = frameSizes;
      if (frameMaterial?.length) body.frame_material_id = frameMaterial;
      if (genders?.length) body.gender = genders;
      if (sortBy?.length) body['sort'] = sortBy?.[0];

      const response = await CommonApi.post(
        '/product-frames-detail',
        body,
        headers
      );

      dispatch({
        type: GET_ALL_PRODUCTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error) ErrorToast(error?.message?.ERROR_TRY_AGAIN);
      else if (error?.message === 'Network Error')
        ErrorToast(error?.message?.NETWORK_ERROR);
      else throw error;
    }
  };

export const getProductFilters = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_FILTER_DATA });
    const { data: frameTypeResponse } = await ApiGetCommon('/frame-type');
    const { data: frameShapeResponse } = await ApiGetCommon('/frame-shapes');
    const { data: frameSizeResponse } = await ApiGetCommon('/frame-size');
    const { data: frameColorResponse } = await ApiGetCommon('/base-color');

    dispatch({
      type: SET_FILTER_DATA,
      payload: {
        type: frameTypeResponse,
        shape: frameShapeResponse,
        size: frameSizeResponse,
        color: frameColorResponse,
      },
    });
  } catch (error) {
    if (error) ErrorToast(error?.message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(error?.message?.NETWORK_ERROR);
    else throw error;
  }
};

// export const fetchFilteredProducts =
//   ({ selectedOptions: filters, selectedCategoryID: categoryID }) =>
//   async (dispatch) => {
//     try {
//       const headers = await getHeaders();
//       dispatch({ type: GET_ALL_PRODUCTS_REQUEST });

//       const map = {
//         type: 'frame_type_id',
//         shape: 'frame_shape_id',
//         size: 'frame_size_id',
//         color: 'base_color_code',
//         gender: 'gender',
//       };
//       let body = {
//         in_stock: true,
//       };
//       Object.keys(filters).map((key) => {
//         if (filters[key]?.length > 0) {
//           const tempObj = { [map[key]]: filters[key] };
//           body = { ...body, ...tempObj };
//         }
//       });

//       const response = await CommonApi.post('/products', body, headers);

//       const filteredProductList = response.data.filter((x) => {
//         if (x?.category_id?._id === categoryID) return x;
//       });

//       dispatch({
//         type: GET_ALL_PRODUCTS_SUCCESS,
//         payload: filteredProductList,
//       });

//       if (response?.data?.message?.status === 200) {
//         showSnackbar(response?.data?.message?.ADD_SUCCESS_REVIEW);
//       }
//     } catch (error) {

//       if (error) ErrorToast(error?.message?.ERROR_TRY_AGAIN);
//       else if (error?.message === 'Network Error')
//         ErrorToast(error?.message?.NETWORK_ERROR);
//       else throw error;
//     }
//   };

// export const getProductFilters = () => async (dispatch) => {
//   try {
//     const filterPayload = {
//       frameShapes: [],
//       frameColors: [],
//     };

//     const { data: frameShapeResponse } = await CommonApi.get(
//       "/frame-shapes",
//       APIAuthorizationHeader
//     );

//     filterPayload["frameShapes"] = frameShapeResponse;

//     dispatch({
//       type: SET_PRODUCT_FILTERS,
//       payload: filterPayload,
//     });
//   } catch (error) {

//     if (error) ErrorToast(error?.message?.ERROR_TRY_AGAIN);
//     else if (error?.message === "Network Error")
//       ErrorToast(error?.message?.NETWORK_ERROR);
//     else throw error;
//   }
// };

export const getProductDetails = (id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/common/product-frames-detail/${id}`);

    dispatch({ type: SET_SELECTED_PRODUCT, payload: data[0] });
  } catch (error) {
    throw new Error();
  }
};
