/**
 * The `Logout` component is a React component that renders a button with a logout icon and handles
 * user logout when clicked.
 * @returns The Logout component is returning a button element that triggers the handleUserLogout
 * function when clicked. The button includes the LogOutIcon component and the text "Logout".
 */
import React from 'react';
import { LogOutIcon } from 'components/typography';
import { useAuthentication } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from 'helper/config';

const Logout = () => {
  const { handleUserLogout } = useAuthentication();
  const navigate = useNavigate();

  return (
    <>
      <button
        className="flex items-center text-[#FA5252]"
        onClick={() => {
          handleUserLogout();
          navigate(routesConfig.homePath);
        }}
      >
        <LogOutIcon />
        Logout
      </button>
    </>
  );
};

export default Logout;
