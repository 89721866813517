import { ApiPostCommon } from 'helper/API';
import { useState, useEffect } from 'react';

const useFetchBlogs = (endpoint, requestBody) => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const response = await ApiPostCommon(endpoint, requestBody);
      setLoading(false);
      if (response?.data) {
        setBlogs(response.data);
      }
    } catch (error) {
      setLoading(false);
      throw new Error();
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return { blogs, loading };
};

export default useFetchBlogs;
