/* The code you provided is a JavaScript code that uses the styled-components library to create a
global style for your application. */
import { createGlobalStyle } from 'styled-components';

export const GlobalStyled = createGlobalStyle`
    * {
        scroll-behavior: smooth;
        font-family: Poppins;
        font-style: normal;
    }   

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    button {
        cursor: pointer;
    }

    input::placeholder,textarea::placeholder {
        color: #868e96;
        font-weight: 500;
    }

    li.MuiBreadcrumbs-separator {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        width: 2px;
        background: #219ebc;
        border-radius: 50px
    }

    ::-webkit-scrollbar {
        width: 2px;
    }
    
    .specs_categories_head_title_inner_main {
        margin-bottom: 40px;
    }

    select:focus-visible {
        outline-color: #219ebc;
    }

    input ,textarea, .form_input_main_gender .MuiSelect-select,textarea {
        outline: none;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.28px;
        width: 100%;
    }
    
    input {
        background-color: transparent;
        height: 100%;
    }

    textarea {
        background-color: #f4f8f9;
        display: flex;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #eaeef0;
        padding: 14px 16px;
        height: 165px;
    }

    .form_input_flex_div {
        margin-bottom: 32px;
    }

    div#mui-component-select-gender {
        padding-bottom: 0;
        padding-right: 8px;
    }

    //* Swiper Css:
    .mySwiper span.swiper-pagination-bullet.swiper-pagination-bullet-active{
        width: 24px;
        border-radius: 6px;
        background: #FFFFFF;
    }
    
    .mySwiper span.swiper-pagination-bullet {
        margin: 0 5px;
        width: 6px;
        height: 6px;
        background: rgb(255 255 255 / 50%);
    }

    .mySwiper .swiper-button-prev:after,
    .mySwiper .swiper-button-next:after {
        font-size: 12px;
        color: #000;
        background: #ffffff;
        height: 1.75rem;
        width: 1.75rem;
        font-weight: 900;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mySwiper .swiper-button-prev,.mySwiper .swiper-button-next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;
    }
    
    .mySwiper .swiper-button-prev {
        left: 1.25rem;
    }

    .mySwiper .swiper-button-next {
        right: 1.25rem;
    }

    /* Antd Design */
    .ant-drawer-content-wrapper {
        box-shadow: none !important;
    }

    .swiper-pagination-bullet-active {
    background: #219EBC;
}
`;
