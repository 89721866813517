import React from 'react';
import Avatar from '@mui/joy/Avatar';
import * as typography from 'components/typography';
import * as styled from 'assets/styles';

const UserProfile = (props) => {
  return (
    <>
      <div className="flex items-end gap-4 flex-wrap screen575:justify-center">
        {props.profileImage ? (
          <Avatar
            alt="Profile"
            src={props.profileImage}
            sx={{
              '--Avatar-size': '118px',
            }}
          />
        ) : (
          <Avatar
            src={'/broken-image.jpg'}
            sx={{
              '--Avatar-size': '118px',
            }}
          />
        )}
        <div>
          <styled.FormInputLabel>User Name</styled.FormInputLabel>
          <styled.FormInputOuter>
            <typography.ProfileIcon />
            <input
              type="text"
              placeholder="Enter your user name"
              name="username"
              value={props.userData?.userName}
              onChange={props.handleChangeUserData}
              readOnly
            />
          </styled.FormInputOuter>
        </div>
        <button onClick={props.handleOpen} className="text-[#219EBC] underline">
          Edit Profile
        </button>
      </div>
    </>
  );
};

export default UserProfile;
