import React from 'react';
import './technicalInformationTab.css';
import { useSelector } from 'react-redux';
import { MainPadding, Para16Tag, Para20Tag } from 'assets/styles';
import { Divider } from '@mui/joy';
import { technicalInfo } from 'assets/images';

const TechnicalInformation = ({ product }) => {
  const { frameSize } = useSelector((state) => state?.frameSize);

  return (
    <>
      <MainPadding $p_top={40} $p_bottom={40}>
        <div className="tecnical_information_tab_frame-parent2">
          <div className="tecnical_information_tab_frame-parent3 capitalize">
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Brand Name
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag $weight={'700'}>
                {process.env.REACT_APP_PUBLIC_NAME}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Product Type
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag $weight={'700'}>
                {product?.product_frame?.category_id?.type}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Frame Type
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag $weight={'700'}>
                {product?.product_frame?.frame_type_id?.frame_type}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Frame Shape
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag $weight={'700'}>
                {product?.product_frame?.frame_shape_id?.frame_shape}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Frame Size
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag $weight={'700'}>{frameSize?.size}</Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Frame Width
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag
                className="lowercase"
                $weight={'700'}
              >{`${frameSize?.width} mm`}</Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Frame Dimensions
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag $weight={'700'}>{frameSize?.dimensions}</Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Frame Color
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag $weight={'700'}>{frameSize?.colorName}</Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Temple Length
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag
                className="lowercase"
                $weight={'700'}
              >{`${frameSize?.weight} mm`}</Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Lens Width
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag
                className="lowercase"
                $weight={'700'}
              >{`${frameSize?.lensWidth} mm`}</Para16Tag>
            </div>
          </div>
          <div className="tecnical_information_tab_frame-item" />
          <div className="tecnical_information_tab_frame-parent3 tecnical_information_tab_border_right">
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Model No
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag $weight={'700'}>
                {product?.product_frame?.model_no}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Age Group
              </Para16Tag>
              <div className="tecnical_information_tab_div21">:</div>
              <Para16Tag $weight={'700'}>
                {product?.product_frame?.age_range}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Frame Material
              </Para16Tag>
              <div className="tecnical_information_tab_div32">:</div>
              <Para16Tag $weight={'700'}>
                {product?.product_frame?.frame_material_id?.frame_material}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Collection
              </Para16Tag>
              <div className="tecnical_information_tab_div32">:</div>
              <Para16Tag $weight={'700'}>
                {product?.product_frame?.collection_name}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Product Warranty
              </Para16Tag>
              <div className="tecnical_information_tab_div32">:</div>
              <Para16Tag $weight={'700'}>
                {product?.product_frame?.product_warranty}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Gender
              </Para16Tag>
              <div className="tecnical_information_tab_div32">:</div>
              <Para16Tag $weight={'700'}>
                {product?.product_frame?.gender?.map((gender, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && ', '}
                      {gender.charAt(0).toUpperCase() + gender.slice(1)}
                    </span>
                  );
                })}
              </Para16Tag>
            </div>
            <div className="tecnical_information_tab_brand-name-group ">
              <Para16Tag $weight={'500'} color={'#2E3233'}>
                Bridge
              </Para16Tag>
              <div className="tecnical_information_tab_div32">:</div>
              <Para16Tag
                className="lowercase"
                $weight={'700'}
              >{`${frameSize?.height} mm`}</Para16Tag>
            </div>
          </div>
        </div>
        <div className="mt-5" />
        <Divider />
        <div className="mt-5 flex items-center flex-wrap gap-4">
          {/* <img className="w-[476px] h-[309px]" src={technicalInfo} alt="" /> */}
          <img
            className="max-w-[210px] object-contain"
            src={technicalInfo}
            alt=""
          />
          <div>
            <Para20Tag className="mb-2">
              What’s included in Every Optics Haven Purchase
            </Para20Tag>
            <ul className="list-disc list-inside">
              <li>Free shipping on all Optics Haven Orders</li>
              <li>Free Returns or Exchange within 14 days of purchase</li>
              <li>A lens cloth and Frame Case</li>
            </ul>
          </div>
        </div>
      </MainPadding>
    </>
  );
};

export default TechnicalInformation;
