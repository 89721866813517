/* The code you provided is a Redux reducer function. It defines the behavior of the
`frameMaterialReducer` when different actions are dispatched. */
import {
  LIST_ALL_USER_NOTIFY_REQUEST,
  LIST_ALL_USER_NOTIFY_SUCCESS,
  LIST_ALL_USER_NOTIFY_FAILURE,
  UPDATE_USER_NOTIFY,
} from 'redux/constants';

const initialState = {
  userNotifyList: [],
  loading: false,
  error: null,
};

export const userNotifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ALL_USER_NOTIFY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_USER_NOTIFY_SUCCESS:
      return {
        ...state,
        userNotifyList: action?.payload,
        loading: false,
      };

    case LIST_ALL_USER_NOTIFY_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case UPDATE_USER_NOTIFY: {
      const updatedNotify = action.payload.data;
      return {
        ...state,
        userNotifyList: state.userNotifyList.map((notify) => {
          if (notify.id === updatedNotify._id) {
            return {
              ...notify,
              is_read: updatedNotify?.is_read,
            };
          }
          return notify;
        }),
      };
    }

    default:
      return state;
  }
};
