import { ErrorToast } from 'helper/Toast';
import { API } from 'utils/axios';
import * as constants from 'redux/constants';
import { Auth } from 'helper/Auth';

export const wishListAction =
  (frameDetailId, sizeId, showSnackbar) => async (dispatch) => {
    try {
      dispatch({ type: constants.ADD_WISHLIST_REQUEST });

      const body = {
        user_id: Auth.getUserId(),
        product_frame_detail_id: frameDetailId,
        product_size_id: sizeId,
      };

      const response = await API.post('/user-wishlist', body);

      dispatch({
        type: constants.ADD_WISHLIST_SUCCESS,
        payload: response.data.wishlistItem,
      });

      if (response.data.message) {
        showSnackbar('Item added in Wishlist!', 'soft', 'success');
      }
    } catch (error) {
      dispatch({
        type: constants.ADD_WISHLIST_FAILURE,
        payload: error?.message,
      });

      if (error) ErrorToast(error?.message?.ERROR_TRY_AGAIN);
      else if (error?.message === 'Network Error')
        ErrorToast(error?.message?.NETWORK_ERROR);
      else throw error;
    }
  };
