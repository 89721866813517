/* The code you provided is a JavaScript module that exports a reducer function for managing the state
of a category in a Redux store. */
import {
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_SUCCESS,
  SET_SELECTED_CATEGORY_ID,
  SET_SELECTED_CATEGORY_TYPE,
  RESET_SELECTED_CATEGORY_ID,
  RESET_SELECTED_CATEGORY_TYPE,
  GET_ALL_CATEGORIES_SUCCESS_ADULT,
  GET_ALL_CATEGORIES_SUCCESS_KIDS,
} from 'redux/constants';

const initialState = {
  loading: false,
  categoryList: [],
  categoryListAdult: [],
  categoryListKids: [],
  selectedCategoryId: null,
  selectedCategoryType: null,
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoryList: action.payload,
        loading: false,
      };

    case GET_ALL_CATEGORIES_SUCCESS_ADULT:
      return {
        ...state,
        categoryListAdult: action.payload,
        loading: false,
      };

    case GET_ALL_CATEGORIES_SUCCESS_KIDS:
      return {
        ...state,
        categoryListKids: action.payload,
        loading: false,
      };

    case SET_SELECTED_CATEGORY_ID:
      return {
        ...state,
        selectedCategoryId: action.payload,
      };

    case SET_SELECTED_CATEGORY_TYPE:
      return {
        ...state,
        selectedCategoryType: action.payload,
      };

    case RESET_SELECTED_CATEGORY_ID:
      return {
        ...state,
        selectedCategoryId: action.payload,
      };

    case RESET_SELECTED_CATEGORY_TYPE:
      return {
        ...state,
        selectedCategoryType: action.payload,
      };

    default:
      return state;
  }
};
