/* The code is setting up the Redux store for a JavaScript application. */
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as reducer_1 from './reducer';
import { STORAGE_KEYS } from 'helper/APP';
import { sessionStorageHandler } from 'utils';

/* The `combineReducers` function is used to combine multiple reducers into a single reducer function.
In this code, it is combining several reducers related to different parts of the application's state
into one reducer object. */
const reducer = combineReducers({
  // User Reducer
  userAuth: reducer_1.userReducer,

  // Review Reducer
  reviews: reducer_1.reviewReducer,
  category: reducer_1.categoryReducer,
  products: reducer_1.productReducer,
  lensSelection: reducer_1.lensInfoReducer,
  cart: reducer_1.cartReducer,
  order: reducer_1.orderReducer,
  payment: reducer_1.paymentReducer,

  // Addresses Reducer
  selectedShippingAddress: reducer_1.selectedShippingAddressReducer,

  // Product Reducer

  // Prescription Reducer
  userPrescription: reducer_1.userPrescriptionReducer,

  // Frame Material
  frameMaterial: reducer_1.frameMaterialReducer,

  // Frame Material
  frameType: reducer_1.frameTypeReducer,

  // User Notify
  userNotify: reducer_1.userNotifyReducer,

  // Frame Size
  frameSize: reducer_1.frameSizeReducer,

  useMyFrame: reducer_1.useMyFrameReducer,

  wishList: reducer_1.wishListReducer,
});

// OUR Initial State
const initialState = {
  userAuth: {
    guestUser: sessionStorageHandler.getItem(STORAGE_KEYS.guestData)
      ? JSON.parse(sessionStorageHandler.getItem(STORAGE_KEYS.guestData))
      : null,
  },
};

/* The line `const middleware = [thunk];` is creating an array called `middleware` and assigning it the
value `[thunk]`. */
const middleware = [thunk];

/* The code is creating the main Redux store using the `createStore` function from the Redux library. */
const store = createStore(
  reducer,
  initialState,
  /* `composeWithDevTools(applyMiddleware(...middleware))` is a function that enhances the Redux store
  with additional debugging capabilities. */
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
