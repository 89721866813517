/**
 * The above code is a React component that renders a back button with an icon and text.
 * @returns The component `Back` is being returned.
 */
import React from 'react';
import { ContainerWrapper, Para16Tag } from 'assets/styles';
import { AuthLoginBackIcon } from 'components/typography';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from 'helper/config';

const Back = ({ text, color }) => {
  const navigate = useNavigate();

  /**
   * The handleBack function is used to navigate back to the previous page in the browser history.
   */
  const handleBack = () => {
    if (window.history.back()) {
      window.history.back();
    } else {
      navigate(routesConfig.homePath);
    }
  };

  return (
    <ContainerWrapper>
      <button onClick={handleBack} className="flex items-center py-2">
        <AuthLoginBackIcon />
        <Para16Tag color={color ? color : '#2E3233'} $weight={500}>
          {text}
        </Para16Tag>
      </button>
    </ContainerWrapper>
  );
};

export default Back;
