import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message, routesConfig } from 'helper/config';
import { Auth } from 'helper/Auth';
import { ApiGetByIdAuthorization, ApiPut } from 'helper/API';
import { useSnackbar } from 'context';

const useEditPrescription = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [initFormikValues, setInitFormikValues] = useState({
    rightEyeAxis: '',
    rightEyeCYL: '',
    rightEyePD: '',
    rightEyeSPH: '',
    leftEyeAxis: '',
    leftEyeCYL: '',
    leftEyePD: '',
    leftEyeSPH: '',
  });
  const { showSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();
  const prescription_id = location?.state?.prescription_id;
  const dispatch = useDispatch();

  useEffect(() => {
    const getSinglePrescription = async () => {
      try {
        setIsLoading(true);
        const response = await ApiGetByIdAuthorization(
          '/user-prescriptions',
          prescription_id
        );
        setInitFormikValues({
          rightEyeAxis: response?.data?.right_eye_attributes?.axis,
          rightEyeCYL: response?.data?.right_eye_attributes?.cyl,
          rightEyePD: response?.data?.right_eye_attributes?.sph,
          rightEyeSPH: response?.data?.right_eye_attributes?.pd,
          leftEyeAxis: response?.data?.left_eye_attributes?.axis,
          leftEyeCYL: response?.data?.left_eye_attributes?.cyl,
          leftEyePD: response?.data?.left_eye_attributes?.sph,
          leftEyeSPH: response?.data?.left_eye_attributes?.pd,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    if (prescription_id) {
      getSinglePrescription();
    }
  }, [dispatch, prescription_id]);

  const handleSubmit = async (values, { resetForm }) => {
    const initialValues = {
      user_id: Auth?.getUserId(),
      right_eye_attributes: {
        axis: values?.rightEyeAxis,
        cyl: values?.rightEyeCYL,
        sph: values?.rightEyePD,
        pd: values?.rightEyeSPH,
      },
      left_eye_attributes: {
        axis: values?.leftEyeAxis,
        cyl: values?.leftEyeCYL,
        sph: values?.leftEyePD,
        pd: values?.leftEyeSPH,
      },
    };

    setIsLoading(true);
    let body = initialValues;

    try {
      const response = await ApiPut(
        '/user-prescriptions',
        prescription_id,
        body
      );
      resetForm();
      setIsLoading(false);

      if (response?.data?.message?.status === 200) {
        navigate(routesConfig?.prescription);
        showSnackbar(message?.UPDATE_PRESCRIPTION_SUCCESS, 'soft', 'success');
      } else {
        showSnackbar(message?.PRESCRIPTION_FAILURE_UPDATE, 'soft', 'danger');
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return { isLoading, initFormikValues, handleSubmit };
};

export default useEditPrescription;
