import { useEffect, useState } from 'react';
import * as constants from 'redux/constants';
import { useDispatch, useSelector } from 'react-redux';

const useLensSelection = (
  setFinished,
  setLensSelectionFlowData,
  flowData,
  setSelectedPrescriptionType
) => {
  const [totalAvailableOptions, setTotalAvailableOptions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedLensOption, setSelectedLensOption] = useState({});
  const [selectedLensSteps, setSelectedLensSteps] = useState([]);
  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const dispatch = useDispatch();

  const {
    lensData,
    selectedLensData,
    colorStep,
    loading = true,
  } = useSelector((store) => store?.lensSelection);

  const prepareFormSteps = (selectedLensIndex) => {
    if (selectedLensIndex !== undefined && lensData !== undefined) {
      return (
        lensData && [
          lensData?.[selectedLensIndex]?.title,
          ...lensData[selectedLensIndex].steps.map((x) => x?.title),
        ]
      );
    }
  };

  const readersGrid = totalAvailableOptions?.[0]?.title === '+0.25';

  let isColor = false;

  const handleBackButtonClick = () => {
    setCurrentStep((prev) => {
      const nextStep = prev - 1;
      if (nextStep === 0) {
        setTotalAvailableOptions(lensData);
      } else {
        setTotalAvailableOptions(() => {
          return selectedLensOption?.steps[nextStep - 1]?.options;
        });
      }

      setSelectedLensSteps((steps) => {
        return steps?.map((x, index) => {
          if (index === prev) {
            return selectedLensOption?.steps[prev - 1]?.title;
          }
          return x;
        });
      });
      setBackButtonClicked(true);
      const stepsDataAtCurrentStep = flowData;
      dispatch({
        type: constants.REDUCE_SELECTED_LENS_INFO_PRICE,
        payload:
          typeof flowData[prev]?.price === 'string'
            ? parseInt(flowData[prev]?.price?.split('$')[1])
            : flowData[prev]?.price,
      });
      delete stepsDataAtCurrentStep[prev];
      setLensSelectionFlowData(stepsDataAtCurrentStep);

      if (
        Object?.values(stepsDataAtCurrentStep)?.find(
          (item) => item?.identifier === 'lense_type_id'
        )
      ) {
        if (
          Object?.values(stepsDataAtCurrentStep)?.find(
            (item) => item?.title === 'Blue-light Filtering'
          )
        ) {
          isColor = false;
          // if (!selectedLensOption?.steps?.find((i) => i?.title === 'Colors')) {
          //   setSelectedLensSteps((prev) => {
          //     const data = [...prev];
          //     data.splice(currentStep + 1, 0, 'Colors');
          //     return data;
          //   });
          //   dispatch({
          //     type: constants.ADD_COLOR_STEP,
          //     payload: {
          //       lens_id: selectedLensData?.lense_id,
          //       index: currentStep,
          //     },
          //   });
          // }
        } else {
          // setSelectedLensSteps((prev) => prev?.filter((x) => x !== 'Colors'));
          dispatch({
            type: constants.REMOVE_COLOR_STEP,
            payload: selectedLensData?.lense_id,
          });
        }
      }
      return nextStep;
    });
  };

  const changeSelectionFromOptions = (optionInfo, optionIndex) => {
    if (optionInfo?.identifier === 'lense_type_id') {
      if (optionInfo?.title === 'Light Responsive') {
        isColor = true;
        if (!selectedLensOption?.steps?.find((i) => i?.title === 'Colors')) {
          setSelectedLensSteps((prev) => {
            const data = [...prev];
            data.splice(currentStep + 1, 0, 'Colors');
            return data;
          });
          dispatch({
            type: constants.ADD_COLOR_STEP,
            payload: {
              lens_id: selectedLensData?.lense_id,
              index: currentStep,
            },
          });
        }
      } else {
        setSelectedLensSteps((prev) => prev?.filter((x) => x !== 'Colors'));
        dispatch({
          type: constants.REMOVE_COLOR_STEP,
          payload: selectedLensData?.lense_id,
        });
      }
    }

    dispatch({
      type: constants.SET_SELECTED_LENS_INFO,
      payload: [
        { [optionInfo?.identifier]: optionInfo.id },
        {
          price:
            typeof optionInfo?.price === 'string'
              ? parseInt(optionInfo?.price?.split('$')[1])
              : optionInfo?.price,
        },
      ],
    });

    if (currentStep === 0) {
      setSelectedLensOption(optionInfo);
      setSelectedLensSteps(prepareFormSteps(optionIndex));
      setSelectedPrescriptionType(optionInfo?.title);
    } else {
      setSelectedLensSteps((steps) => {
        return steps?.map((x, index) => {
          if (index === currentStep) {
            return optionInfo?.title;
          }
          return x;
        });
      });
    }

    setLensSelectionFlowData((prev) => {
      return { ...prev, [currentStep]: optionInfo };
    });

    if (currentStep + 1 === selectedLensSteps?.length) {
      setFinished(true);
      return;
    }

    setCurrentStep((prev) => {
      const nextStep = prev + 1;
      if (prev === 0) {
        setTotalAvailableOptions(() => {
          return optionInfo?.steps[prev]?.options;
        });
      } else {
        if (isColor) {
          setTotalAvailableOptions(colorStep?.options);
        } else {
          setTotalAvailableOptions(() => {
            return selectedLensOption.steps[prev]?.options;
          });
        }
      }
      return nextStep;
    });
  };

  useEffect(() => {
    if (backButtonClicked) {
      if (currentStep === 0) {
        setSelectedLensOption(() => {
          return flowData[currentStep];
        });
      }
      setBackButtonClicked(false);
    }
  }, [backButtonClicked, currentStep]);

  useEffect(() => {
    if (flowData[0]?.steps?.length + 1 === Object?.keys(flowData)?.length) {
      setTotalAvailableOptions(
        flowData[0]?.steps?.[Object?.keys(flowData)?.length - 2]?.options
      );
      setSelectedLensOption(flowData[0]);
      setSelectedLensSteps(() => {
        return Object.keys(flowData)?.map(
          (keyNumber) => flowData[keyNumber]?.title
        );
      });
      setCurrentStep(flowData[0]?.steps?.length);
    } else {
      setTotalAvailableOptions(lensData);
      setSelectedLensOption(lensData[0]);
      setSelectedLensSteps(prepareFormSteps(0));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(selectedLensOption)?.length === 0) return;
    const currentlySelectedLensFromLensData = lensData?.filter(
      (x) => x?.id === selectedLensData?.lense_id
    )[0];
    if (
      selectedLensOption?.steps?.length !==
      currentlySelectedLensFromLensData?.steps
    ) {
      setSelectedLensOption((prev) => {
        return { ...prev, steps: currentlySelectedLensFromLensData?.steps };
      });
      setLensSelectionFlowData((prev) => {
        return { ...prev, 0: currentlySelectedLensFromLensData };
      });
    }
  }, [lensData]);

  return {
    totalAvailableOptions,
    currentStep,
    selectedLensOption,
    selectedLensSteps,
    handleBackButtonClick,
    changeSelectionFromOptions,
    readersGrid,
    loading,
  };
};

export default useLensSelection;
