import { CommonApi } from 'helper/API';
import { APIHeaderCors } from 'helper/API';
import { ErrorToast } from 'helper/Toast';
import { message } from 'helper/config';
import {
  LIST_ALL_FRAME_MATERIALS_SUCCESS,
  LIST_ALL_FRAME_MATERIALS_REQUEST,
  LIST_ALL_FRAME_MATERIALS_FAILURE,
} from '../constants/frameMaterial';

export const listAllFrameMaterial = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_ALL_FRAME_MATERIALS_REQUEST });

    // API
    const response = await CommonApi.get('/frame-material', APIHeaderCors);
    //

    const frameMaterialList = [];
    response?.data?.map((x) => {
      frameMaterialList.push({
        id: x._id,
        frame_material: x.frame_material,
      });
    });

    dispatch({
      type: LIST_ALL_FRAME_MATERIALS_SUCCESS,
      payload: frameMaterialList,
    });
  } catch (error) {
    dispatch({
      type: LIST_ALL_FRAME_MATERIALS_FAILURE,
      payload: error?.message,
    });

    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else return;

    throw new Error();
  }
};
