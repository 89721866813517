import { useDispatch } from 'react-redux';
import { wishListAction } from 'redux/actions';
import { Auth } from 'helper/Auth';
import { routesConfig } from 'helper/config';
import useNavigateWithState from './useNavigateWithState';
import { useNavigate } from 'react-router-dom';
import useQueryFetch from './useQueryFetch';
import { useSnackbar } from 'context';

const useWishList = () => {
  const { showSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateWithState = useNavigateWithState();
  const { fetchData } = useQueryFetch();

  const handleWishList = (frameDetailId, sizeId) => {
    try {
      if (Auth.isLogin()) {
        dispatch(wishListAction(frameDetailId, sizeId, showSnackbar));

        fetchData();
        navigate(routesConfig.wishList);
      } else {
        navigateWithState(routesConfig.wishList, {
          redirect: routesConfig.wishList,
        });
      }
    } catch (error) {
      throw new Error();
    }
  };

  return {
    handleWishList,
  };
};

export default useWishList;
