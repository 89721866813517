/* The above code is a React component called `PrescriptionCard`. It is responsible for rendering a
list of prescription data in a table format. */
import React from 'react';
import * as styles from 'assets/styles';
import * as typography from 'components/typography';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { ApiDelete, ApiGetByIdAuthorization } from 'helper/API';
import { message, routesConfig } from 'helper/config';
import { Link } from 'react-router-dom';
import { Loader, NoDataText } from '.';
import { Auth } from 'helper/Auth';
import { useSnackbar } from 'context';

const PrescriptionCard = ({ isAccount, title }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [allPrescription, setAllPrescription] = React.useState([]);

  const { showSnackbar } = useSnackbar();

  /**
   * The function createData creates an object with properties for name, calories, and fat.
   * @param name - The name parameter is a string that represents the name of the data.
   * @param calories - The "calories" parameter is used to specify the number of calories in the data
   * object.
   * @param fat - The "fat" parameter represents the amount of fat in the data being created.
   * @returns The function `createData` returns an object with properties `name`, `calories`, and
   * `fat`.
   */
  const createData = (name, calories, fat) => {
    return { name, calories, fat };
  };

  React.useEffect(() => {
    /* The `setDatForTable` function is a helper function that takes an array of prescription
      data and maps over each item in the array. For each item, it creates an array of rows
      using the `createData` function and returns an object with properties `_id`, `user_id`,
      `firstname`, `lastname`, `rows`, `dob`, and `created_at`. This function is used to
      transform the prescription data received from the API into a format that can be used to
      render the table rows in the component. */
    const setDatForTable = async (data) => {
      return data?.map((item) => {
        let rows = [];
        rows.push(
          createData(
            'Axis (0-180)',
            item?.right_eye_attributes?.axis,
            item?.left_eye_attributes?.axis,
            '--'
          )
        );
        rows.push(
          createData(
            'Cylindrical Power (cyl)',
            item?.right_eye_attributes?.cyl,
            item?.left_eye_attributes?.cyl,
            '--'
          )
        );
        rows.push(
          createData(
            'PD',
            item?.right_eye_attributes?.pd,
            item?.left_eye_attributes?.pd,
            '--'
          )
        );
        rows.push(
          createData(
            'Spherical Power (sph)',
            item?.right_eye_attributes?.sph,
            item?.left_eye_attributes?.sph,
            '--'
          )
        );

        return {
          _id: item?._id,
          user_id: item?.user_id,
          firstname: item?.firstname,
          lastname: item?.lastname,
          rows,
          dob: item?.dob,
          created_at: item?.created_at,
        };
      });
    };

    const getAllPrescription = async () => {
      setIsLoading(true);

      try {
        setIsLoading(false);

        // Axios Params
        const user_id = Auth?.getUserId();

        // API
        const response = await ApiGetByIdAuthorization(
          '/user-prescriptions/user',
          user_id
        );

        setAllPrescription(
          await setDatForTable(
            response.data.filter((item) => !item.is_image_prescription)
          )
        );
      } catch (error) {
        setIsLoading(false);

        throw new Error();
      }
    };

    getAllPrescription();
  }, []);

  const handleDelete = async (prescription_id) => {
    try {
      const response = await ApiDelete('/user-prescriptions', prescription_id);
      if (response?.data?.message?.status === 200) {
        showSnackbar(message?.DELETE_PRESCRIPTION_SUCCESS, 'soft', 'success');

        // Remove the deleted user prescription from the list
        setAllPrescription((prevPrescription) =>
          prevPrescription.filter(
            (prescription) => prescription?._id !== prescription_id
          )
        );
      } else {
        showSnackbar(message?.PRESCRIPTION_FAILURE_DELETE, 'soft', 'danger');
      }
    } catch (error) {
      throw new Error();
    }
  };

  const prescriptionList =
    allPrescription?.length &&
    allPrescription?.map((item, index) => {
      // Check if isAccount is true and index is less than 2
      const shouldRenderItem = isAccount ? index < 2 : true;

      return shouldRenderItem ? (
        <div className="mb-5">
          <div key={index} className="flex items-end mb-4 justify-end">
            <Link
              to={routesConfig?.editPrescription}
              state={{ prescription_id: item?._id }}
            >
              <typography.EditIcons />
            </Link>
            <button onClick={() => handleDelete(item?._id)}>
              <typography.DeleteIcons />
            </button>
          </div>
          <styles.TableWrapper>
            <Table aria-label="simple table">
              <styles.StyledTableHead>
                <TableRow>
                  <TableCell>Power</TableCell>
                  <TableCell align="center">Right</TableCell>
                  <TableCell align="center">Left</TableCell>
                </TableRow>
              </styles.StyledTableHead>
              <TableBody>
                {item?.rows?.map((row, index) => (
                  <TableRow key={index} sx={{ border_bottom: 'none' }}>
                    <TableCell component="th" scope="row">
                      {row?.name}
                    </TableCell>
                    <TableCell align="center">
                      {row?.calories ? row?.calories : '-'}
                    </TableCell>
                    <TableCell align="center">
                      {row?.fat ? row?.fat : '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </styles.TableWrapper>
        </div>
      ) : null;
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : isAccount &&
        allPrescription.length === 0 ? null : allPrescription?.length > // If isAccount is true and allPrescription is empty, return null
        0 ? (
        <div>
          {isAccount && allPrescription?.length === 0 ? null : (
            <styles.Para20Tag className="mb-4">{title}</styles.Para20Tag>
          )}

          {prescriptionList}
        </div>
      ) : (
        <NoDataText text={'No prescription yet!!'} />
      )}
    </>
  );
};

export default PrescriptionCard;
