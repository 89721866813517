import { CommonApi, getHeaders } from 'helper/API';
import { ErrorToast } from 'helper/Toast';
import { message } from 'helper/config';
import {
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_SUCCESS_ADULT,
  GET_ALL_CATEGORIES_SUCCESS_KIDS,
} from '../constants/categoryConstants';

export const getAllCategories = () => async (dispatch) => {
  try {
    const headers = await getHeaders();

    dispatch({ type: GET_ALL_CATEGORIES_REQUEST });

    const response = await CommonApi.get('/categories?is_active=true', {
      headers,
    });

    dispatch({
      type: GET_ALL_CATEGORIES_SUCCESS,
      payload: response?.data.map((x) => {
        return { title: x.type, id: x._id, image: x.image };
      }),
    });
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

export const getAllCategoriesAdult = () => async (dispatch) => {
  try {
    const headers = await getHeaders();

    dispatch({ type: GET_ALL_CATEGORIES_REQUEST });

    const response = await CommonApi.get('/categories?isAdult=true', {
      headers,
    });

    dispatch({
      type: GET_ALL_CATEGORIES_SUCCESS_ADULT,
      payload: response?.data.map((x) => {
        return { title: x.type, id: x._id, image: x.image };
      }),
    });
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

export const getAllCategoriesKids = () => async (dispatch) => {
  try {
    const headers = await getHeaders();

    dispatch({ type: GET_ALL_CATEGORIES_REQUEST });

    const response = await CommonApi.get('/categories?iskid=true', {
      headers,
    });

    dispatch({
      type: GET_ALL_CATEGORIES_SUCCESS_KIDS,
      payload: response?.data.map((x) => {
        return { title: x.type, id: x._id, image: x.image };
      }),
    });
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};
