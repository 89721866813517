import React from 'react';
import { Sheet, Modal } from '@mui/joy';
import * as styles from 'assets/styles';
import ModalClose from '@mui/joy/ModalClose';

const CartRemoveModel = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 500,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <div className="max-w-[46.25rem]">
          {/* <styles.Head32Tag className="mb-3" color={'#2e3233'} $weight={600}>
            Remove Item From Cart?
          </styles.Head32Tag> */}
          <styles.Para16Tag color={'#2E3233'}>
            If you&apos;ve reconsidered this item, no problem. You can either
            remove it from your cart or transfer it to your wish list, and then
            continue with your shopping
          </styles.Para16Tag>
        </div>
        <div className="flex items-center mt-8 gap-5 flex-wrap">
          <styles.ButtonWrapper onClick={props.handleRemoveItemFun}>
            Yes, remove
          </styles.ButtonWrapper>
          {!props.isAccessories ? (
            <styles.ButtonWrapper
              onClick={() => {
                props.handleWishList(
                  props.wishListData.product_frame_detail_id,
                  props.wishListData.product_size_id
                );

                // When user move product to wishlist from cart then it remove from the cart no need to show it in cart
                props.handleRemoveItemFun();
              }}
              $border_color={'#DEE2E4'}
              color={'#5F6364'}
              $bg_color={'#FFF'}
              $weight={600}
              $hover_color={'#2E3233'}
              $hover_bg={'FFF'}
              $hover_border={'#DEE2E4'}
            >
              Move to wishlist
            </styles.ButtonWrapper>
          ) : (
            ''
          )}
        </div>
      </Sheet>
    </Modal>
  );
};

export default CartRemoveModel;
