/* The code snippet defines a JavaScript object called `routesConfig` which contains various paths for
different pages or functionalities of a website. */

const homePath = '/';
const id = homePath + ':id';

/* The code snippet defines a JavaScript object called `routesConfig` which contains various paths for
different pages or functionalities of a website. Each property of the object represents a specific
route or path, and its value is a concatenation of the `homePath` constant and a specific route
name. */
// SEO configuration
export const routesConfig = {
  // homepage
  homePath: homePath,

  // authentication configuration
  login: homePath + 'login',
  createAccount: homePath + 'create-account',
  forgot: homePath + 'forgot',
  verifyEmail: homePath + 'verify-email',
  resetPassword: homePath + 'reset-password',

  // product
  productList: homePath + 'frames',
  productDetails: homePath + 'frames',
  productDetailsId: homePath + 'frames' + id,

  search: homePath + 'search',

  // dashboard
  account: homePath + 'account',

  // order
  order: homePath + 'order',
  orderDetails: homePath + 'order',
  orderDetailsId: homePath + 'order' + id,

  // Voucher Balance
  checkVoucherBalance: homePath + 'check-voucher-balance',

  // Store Credit
  checkStoreCredit: homePath + 'check-store-credit',

  // manage notification
  manageNotification: homePath + 'manage-notification',

  // shipping address
  shippingAddress: homePath + 'shipping-address',
  addNewShipping: homePath + 'add-shipping-address',
  editShipping: homePath + 'edit-shipping-address',

  // shipping cart
  shippingCart: homePath + 'shipping-cart',

  // prescription details
  prescription: homePath + 'prescription',
  addNewPrescription: homePath + 'add-prescription',
  editPrescription: homePath + 'edit-prescription',

  // Checkout
  checkOut: homePath + 'checkout',

  // payment success
  paymentSuccess: homePath + 'payment-info',

  // useMyFrame
  useMyFrame: homePath + 'use-your-frame',

  // Wish List
  wishList: homePath + 'wishlist',

  // blog
  blog: homePath + 'blog',
  blogDetail: homePath + 'blog',
  blogDetailId: homePath + 'blog' + id,

  // policy configuration
  returnPolicy: homePath + 'shipping-return-policy',

  // Buying Guide
  buyingGuide: homePath + 'buying-guide',

  // refer Earn
  referEarn: homePath + 'refer-earn',
  referralProgram: homePath + 'referral-program',

  // about-us
  aboutUs: homePath + 'about-us',

  // faqs
  faqs: homePath + 'faqs',

  //coupons
  coupons: homePath + 'optics-haven-coupons',

  //frame size
  frameSize: homePath + 'frame-size-guide',

  //frame size
  visionInsurance: homePath + 'vision-insurance',

  // Measure My PD
  measureMyPD: homePath + 'measure-my-pd',

  // Find My Size
  findMySize: homePath + 'find-my-size',

  // Accessibility
  accessibility: homePath + 'accessibility',

  // California Transparency Act
  californiaTransparencyAct: homePath + 'california-transparency-act',

  // Accessories
  accessories: homePath + 'accessories',
  accessoriesId: homePath + 'accessories' + id,

  limitTheUseOfMySensitivePersonalInformation:
    homePath + 'limit-the-use-of-my-personal-information',

  doNotShareMyPersonalInformation:
    homePath + 'do-not-share-my-personal-information',

  termsOfUseForOPTICSHAVEN: homePath + 'terms-of-use',

  seeAllBlogs: homePath + 'blogs',

  privacyPolicy: homePath + 'privacy-policy',

  noticeOfPrivacyPractices: homePath + 'notice-of-privacy-practices',

  cookiePolicy: homePath + 'cookie-policy',

  trackOrder: homePath + 'track-order',

  useYourFrameSuccess: homePath + 'success',

  // error
  error: '*',
};
