/* The above code is importing various components from different libraries in JavaScript. It is
importing components from the "@mui/material" library, such as Accordion, AccordionDetails,
AccordionSummary, Step, StepConnector, StepContent, StepLabel, Stepper, Table, TableCell,
TableContainer, TableHead, and TableRow. It is also importing the Link component from the
"react-router-dom" library and the createGlobalStyle and styled components from the
"styled-components" library. */
import * as material from '@mui/material';
import { Field } from 'formik';
import { device } from 'helper/config';
import { Link } from 'react-router-dom';
import { keyframes, styled } from 'styled-components';
import { Swiper } from 'swiper/react';

// Dashboard styles
export const DashboardHead = styled.h2`
  color: #2e3233;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -0.8px;
  padding-bottom: 1.5rem;

  ${device.maxW_1280} {
    font-size: ${({ font }) => (font ? `${font}px` : '18px')};
  }
`;

// Input styles
export const FormInputLabel = styled.label`
  color: #2e3233;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  margin-bottom: 8px;
  display: flex;
`;

// DashboardDetailsBorder
export const DashboardDetailsBorder = styled.div`
  border-radius: 18px;
  border: 1px solid #eaeef0;
  padding: ${({ padding }) => (padding ? padding : null)};
  width: 100%;
  margin-bottom: ${({ $m_bottom }) => ($m_bottom ? `${$m_bottom}px` : null)};

  ${device.maxW_720} {
    padding: 1rem;
  }
`;

// Icon span tag
export const IconSpan = styled.span`
  display: flex;
  margin-right: ${({ $margin }) => ($margin ? $margin : '12px')};
  margin-left: ${({ $m_left }) => ($m_left ? $m_left : '0')};
  align-items: center;

  ${device.maxW_1280} {
    margin-right: 6px;
  }
`;

// Checkbox
// Checkbox container
export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  display: flex;
  align-items: center;
`;

// Checkbox Icon
export const CheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  display: flex;
`;

// Hide checkbox visually but remain accessible to screen readers.
export const HiddenCheckbox = styled(Field)`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

// Hide checkbox visually but remain accessible to screen readers.
export const HiddenCheckboxCustom = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

// Styled Checkbox
export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({ checked }) => (checked ? '#219ebc' : '#FFF')};
  filter: drop-shadow(0px 0px 0px #e9ecef)
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  border: 1px solid #e9ecef;
  border-radius: 3px;
  transition: all 150ms;

  &&:hover {
    border: 1px solid #ced4da;
  }

  ${HiddenCheckbox}:checked + & {
    background: #219ebc;
    box-shadow: 0 0 0 3px #219ebc4d;
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #ced4da;
  }

  ${CheckboxIcon} {
    // visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

// Breadcrumbs
export const BreadcrumbsWrapper = styled.div`
  background: #f2fafd;
  padding: 12px 0 10px;
`;

// Color Box Div
export const ColorBoxDiv = styled.div`
  display: flex;
  padding: ${({ padding }) => (padding ? `${padding}px` : '6px 12px')};
  border-radius: 7px;
  background: ${({ $bg_color }) => ($bg_color ? $bg_color : null)};
  width: ${({ width }) => (width ? width : 'fit-content')};
  height: ${({ height }) => (height ? height : null)};
  justify-content: center;
  align-items: center;
`;

// DashboardDropdown
export const DashboardDropdown = styled.div`
  border-radius: 8px;
  background: rgba(33, 158, 188, 0.05);
  padding: 20px;
`;

// DashboardDropdownBtn
export const DashboardDropdownBtn = styled.button`
  display: flex;
  align-items: center;
  color: #219ebc;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
`;

// Form TextArea
export const FormTextArea = styled.textarea`
  padding: 16px;
  height: 165px;
  border-radius: 12px;
  width: 100%;
  border: 1px solid ${({ border }) => (border ? border : '#eaeef0')};
  background: ${({ $bg_color }) => ($bg_color ? $bg_color : '#fff')};
`;

// ButtonWrapper
export const ButtonWrapper = styled.button`
  font-size: ${({ size }) => (size ? `${size}px` : '16px')};
  font-weight: ${({ $weight }) => ($weight ? $weight : 500)};
  line-height: 20px;
  letter-spacing: -0.32px;
  background-color: ${({ $bg_color }) => ($bg_color ? $bg_color : '#219EBC')};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '14px')};
  padding: 18px 32px;
  border: 1px solid
    ${({ $border_color }) => ($border_color ? $border_color : '#219ebc')};
  color: ${({ color }) => (color ? color : '#FFF')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => (width ? width : null)};
  margin: ${({ $margin }) => ($margin ? $margin : null)};
  height: fit-content;

  &:hover {
    background: ${({ $hover_bg }) => ($hover_bg ? $hover_bg : '#178AA6')};
    color: ${({ $hover_color }) => ($hover_color ? $hover_color : '#FFF')};
    border: 1px solid
      ${({ $hover_border }) => ($hover_border ? $hover_border : 'transparent')};
  }

  ${device.maxW_720} {
    padding: 10px 12px 10px 6px;
    font-size: 14px;
    width: ${({ width }) => (width ? width : '100%')};
  }
`;

// LinkWrapper
export const LinkWrapper = styled(Link)`
  font-size: ${({ size }) => (size ? `${size}px` : '16px')};
  font-weight: ${({ $weight }) => ($weight ? $weight : 500)};
  line-height: 20px;
  letter-spacing: -0.32px;
  background-color: ${({ $bg_color }) => ($bg_color ? $bg_color : '#219EBC')};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '14px')};
  padding: 18px 32px;
  border: 1px solid ${({ $bColor }) => ($bColor ? $bColor : '#219ebc')};
  color: ${({ color }) => (color ? color : '#FFF')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: ${({ width }) => (width ? width : 'null')};

  &:hover {
    background: ${({ $hover_bg }) => ($hover_bg ? $hover_bg : '#178AA6')};
    color: ${({ $hover_color }) => ($hover_color ? $hover_color : '#FFF')};
    border: 1px solid
      ${({ $hover_border }) => ($hover_border ? $hover_border : 'transparent')};
  }

  ${device.maxW_720} {
    padding: 10px 12px 10px 6px;
    font-size: 14px;
    width: ${({ width }) => (width ? width : '100%')};
  }
`;

// span Dots
export const SpanDots = styled.span`
  background-color: ${({ $bg_color }) => ($bg_color ? $bg_color : null)};
  width: ${({ width }) => (width ? width : '4px')};
  height: ${({ height }) => (height ? height : '4px')};
  border-radius: ${({ radius }) => (radius ? radius : '4px')};
  margin: 0 8px;
  display: flex;
`;

// Booking Flow Grid Layout
export const BookingGridLayout = styled.div`
  display: grid;
  gap: ${({ gap }) => (gap ? `${gap}rem` : '1.25rem')};
  grid-template-columns: ${({ grid_cols }) =>
    `repeat(${grid_cols}, minmax(0, 1fr))`};
`;

// Booking Flow Col Span Main Style
export const BookingColspanGridLayoutMain = styled.div`
  grid-column: ${({ span_grid }) => `span ${span_grid} / span ${span_grid}`};
`;

// Booking Flow Col Span Side Style
export const BookingColspanGridLayoutSide = styled.div`
  grid-column: ${({ span_grid }) => `span ${span_grid} / span ${span_grid}`};
`;

// StyledAccordion
export const StyledAccordion = styled(material.Accordion)`
  background: transparent !important;
  box-shadow: none !important;
  padding-bottom: ${({ $p_bottom }) =>
    $p_bottom ? $p_bottom : '16px'} !important ;
  margin-bottom: ${({ $m_bottom }) =>
    $m_bottom ? $m_bottom : '0px'} !important;
  border: 1px solid ${({ border }) => (border ? border : '0')} !important;
  border-radius: ${({ radius }) => (radius ? `${radius}px` : null)} !important;
  overflow: ${({ overflow }) => (overflow ? overflow : 'none')} !important;
  min-height: fit-content;

  &:before {
    display: none !important;
  }
`;

// StyledAccordionSummary
export const StyledAccordionSummary = styled(material.AccordionSummary)`
  border: 1px solid #eaeef0 !important;
  padding: 20px !important;
  background: #f4f8f9 !important;

  .MuiAccordionSummary-content,
  .Mui-expanded {
    margin: 0 !important;
  }

  ${device.maxW_720} {
    padding: 10px !important;
  }
`;

// StyledAccordionDetails
export const StyledAccordionDetails = styled(material.AccordionDetails)`
  box-shadow: none !important;
  padding: 20px !important;

  ${device.maxW_720} {
    padding: 10px !important;
  }
`;

// CardBorder
export const CardBorder = styled.div`
  border: 1px solid #dee2e4;
  padding: ${({ padding }) => (padding ? `${padding}px` : '20px')};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : null)};

  ${device.maxW_720} {
    padding: 10px !important;
  }
`;

// Table Container
export const StyledTableContainer = styled(material.TableContainer)`
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
`;

// Table
export const StyledTable = styled(material.Table)`
  margin: auto !important;
`;

// Table head
export const StyledTableHead = styled(material.TableHead)`
  background-color: ${({ $bg_color }) =>
    $bg_color ? $bg_color : null} !important;
  overflow: ${({ auto }) => (auto ? auto : null)} !important;
`;

// Table Row
export const StyledTableRow = styled(material.TableRow)`
  border: 1px solid #eaeef0 !important;
  background-color: ${({ $bg_color }) =>
    $bg_color ? $bg_color : null} !important;
  overflow: auto !important;
  border-radius: ${({ radius }) => (radius ? `${radius}px` : null)} !important;
`;

// table Cell
export const StyledTableCell = styled(material.TableCell)`
  color: ${({ color }) => (color ? color : '#2e3233')} !important;
  font-size: 18px !important;
  // width: 22.5rem !important;
  text-align: center !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.32px !important;
  border-right: 1px solid
    ${({ border_right }) => (border_right ? border_right : '#eaeef0')} !important;
  border-bottom: 1px solid
    ${({ border_bottom }) => (border_bottom ? border_bottom : '#eaeef0')} !important;
  border-top: 1px solid
    ${({ border_top }) => (border_top ? border_top : '#eaeef0')} !important;
  border-left: 1px solid
    ${({ border_left }) => (border_left ? border_left : '#eaeef0')} !important;
  padding: 20px !important;

  ${device.maxW_720} {
    font-size: ${({ font }) => (font ? `${font}px` : '14px !important')};
    padding: 10px !important;
  }
`;

// login-for-booking stepper
export const StyledStepper = styled(material.Stepper)`
  margin-bottom: 0;
`;

// Styled Step
export const StyledStep = styled(material.Step)`
  border-radius: 16px;
  margin-bottom: 12px;
  border: none;
`;

// Style Step
export const StyleStep = styled(material.Step)`
  padding: 48px;
`;

// Styled Step Label
export const StyledStepLabel = styled(material.StepLabel)`
  padding: 16px !important;

  &.custom-active {
    background: #219ebc;
    border-radius: 16px 16px 0 0;
  }
`;

// Styled Connector
export const StyledConnector = styled(material.StepConnector)`
  /* Set the connector line to none */
  display: none;
`;

// Styled Step Content
export const StyledStepContent = styled(material.StepContent)`
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-left: none !important;
`;

// Hide radio button visually but remain accessible to screen readers.
// export const StyledRadioButton = styled.input.attrs({ type: 'radio' })`
//   $margin: -1px;
//   overflow: hidden;
//   padding: 0;
//   width: 20px;
//   height: 20px;
//   white-space: nowrap;
//   border: 1px solid #dee2e4;
// `;

// ripple Animation
export const rippleAnimation = keyframes`
  to {
    border-color: #219EBC;
  }
`;

// Radio Button Container
export const RadioButtonContainer = styled.div`
  margin: 0;
  display: flex;
`;

// Radio Input
export const RadioInput = styled.input.attrs({ type: 'radio' })`
  display: none;

  &:checked + label:before {
    border-color: #219ebc;
    animation: ${rippleAnimation} 0.2s linear forwards;
  }

  &:checked + label:after {
    transform: scale(1);
  }
`;

// Radio Label
export const RadioLabel = styled.label`
  display: inline-block;
  height: 20px;
  position: relative;
  padding: 0 10px;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: bottom;
  margin-right: 12px;

  &:before,
  &:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transition: all 0.3s ease;
    transition-property: transform, border-color;
  }

  &:before {
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #dee2e4;
  }

  &:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    transform: scale(0);
    background: #219ebc;
  }
`;

// Loader
export const spinAnimation = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

// LoaderMain
export const LoaderMain = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0;
`;

// Spinner
export const Spinner = styled.div`
  width: 56px;
  height: 56px;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #dbdcef #0000;
  animation: ${spinAnimation} 1s infinite linear;

  &::before,
  &::after {
    content: '';
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
  }

  &::before {
    border-color: #219ebc #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
  }

  &::after {
    margin: 8.9px;
  }
`;

// Styled Images Wrapper
export const StyledImagesWrapper = styled.img`
  margin-bottom: 14px;
  border-radius: ${({ border }) => (border ? border : '')};
  width: 100%;
`;

// Form Input Outer
export const FormInputOuter = styled.div`
  background-color: ${({ bg }) => (bg ? bg : '#f4f8f9')};
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #eaeef0;
  padding: 14px 16px;
`;

// DashBoard Grid
export const DashBoardGrid = styled.div`
  display: grid;
  gap: ${({ gap }) => (gap ? `${gap}rem` : '1.25rem')};
  grid-template-columns: ${({ grid_cols }) =>
    `repeat(${grid_cols}, minmax(0, 1fr))`};
`;

// DashBoard Grid Span
export const DashBoardGridSpan = styled.div`
  grid-column: ${({ span_grid }) => `span ${span_grid} / span ${span_grid}`};
`;

// Form Grid
export const FormGrid = styled.div`
  display: grid;
  gap: ${({ gap }) => (gap ? `${gap}rem` : '1.25rem')};
  grid-template-columns: ${({ grid_cols }) =>
    `repeat(${grid_cols}, minmax(0, 1fr))`};

  ${device.maxW_576} {
    gap: 1rem;
    grid-template-columns: ${({ grid_cols_576 }) =>
      `repeat(${grid_cols_576 ? grid_cols_576 : '1'}, minmax(0, 1fr))`};
  }
`;

export const ORText = styled.p`
  position: relative;
  text-align: center;
  color: #a7abac;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;

  &:before {
    position: absolute;
    content: '';
    border-radius: 61px;
    background: linear-gradient(
      90deg,
      rgb(70 206 239 / 20%) 0%,
      rgba(33, 158, 188, 0) 100%
    );
    width: 9.34rem;
    height: 2px;
    top: 50%;
    left: 2rem;
    rotate: 0deg;
  }

  &:after {
    position: absolute;
    content: '';
    border-radius: 61px;
    background: linear-gradient(
      90deg,
      rgb(70 206 239 / 20%) 0%,
      rgba(33, 158, 188, 0) 100%
    );
    width: 9.34rem;
    height: 2px;
    top: 50%;
    rotate: 180deg;
    transform: translateX(2rem);
    right: 0;
  }
`;

// Lens & Prescription main div
export const LensPreWrapper = styled.div`
  padding: 48px;
  border-top: ${({ border }) => (!border ? '1px solid #eaeef0' : 'none')};

  ${device.maxW_720} {
    padding: 0;
  }
`;

// ----------------------------------------------------------------
// Shipping Cart Styles
export const ShippingCartFrameChild = styled.img`
  align-self: stretch;
  border-radius: 20px;
  max-height: 100%;
  // max-width: 23.75rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  // height: 17.6875rem;
  max-width: 18.75rem;
`;

export const ShippingCartColorParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.38rem;
`;

export const ShippingCartFrameParent1 = styled.div`
  display: flex;
  gap: 5.06rem;
`;

export const ShippingCartPrescriptionTypeParent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.38rem;
`;

export const ShippingCartFrameDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;
export const ShippingCartFrameItem = styled.div`
  position: relative;
  border-radius: 20px;
  background-color: #eaeef0;
  height: 0.06rem;
`;

export const ShippingCartRemoveParent = styled.div`
  flex: 1;
  display: flex;
  gap: 1.5rem;
`;

export const ShippingCartFrameParent2 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;
export const ShippingCartFrameGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.88rem;
`;
export const ShippingCartFrameParentRoot = styled.div`
  width: 100%;
  position: relative;
  border-radius: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  padding: 1.88rem;
  box-sizing: border-box;
  gap: 4.19rem;
  text-align: left;
  font-size: 1.13rem;
  color: #2e3233;
  font-family: Poppins;
`;
// ----------------------------------------------------------------

// Select Wrapper
export const SelectWrapper = styled.select`
  background-color: ${({ bg }) => (bg ? bg : '#f4f8f9')};
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 1rem 0.5rem;
  width: ${({ width }) => (width ? width : '100%')};
  height: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  border: 1px solid
    ${({ bordercolor }) => (bordercolor ? bordercolor : '#eaeef0')};
  color: ${({ color }) => (color ? color : '#2e3233')};

  ${device.maxW_1280} {
    padding: 0.5rem 0;
  }
`;

// SelectWrapperField For formik select filed
export const SelectWrapperField = styled(Field)`
  background-color: #f4f8f9;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #eaeef0;
  padding: 1rem 0.5rem;
  width: ${({ width }) => (width ? width : '100%')};
  height: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
`;

// Color Div for product
export const ColorWrapper = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '32px')};
  height: ${({ height }) => (height ? `${height}px` : '32px')};
  border-radius: 50%;
  background: url(${({ $color_img }) => $color_img}) no-repeat;
  cursor: pointer;
`;

// TableWrapper Blue Header
export const TableWrapper = styled.div`
  border-radius: 18px;
  border: 1px solid #eaeef0;

  tr > * {
    border-bottom: none !important;
  }

  th,
  td {
    color: #2e3233;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.32px;
  }

  table thead tr th:not(:last-child) {
    border-right: 1px solid #eaeef0 !important;
  }

  table thead tr th {
    background: #219ebc;
    color: #fff;
  }

  table thead tr > th:first-child {
    border-radius: 18px 0 0;
  }

  table thead tr > th:last-child {
    border-radius: 0 18px 0 0;
  }

  table tbody tr th,
  td:not(:last-child) {
    border-right: 1px solid #eaeef0 !important;
    color: #2e3233;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.36px;
  }

  table thead tr th {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.36px;
  }

  ${device.maxW_720} {
    th,
    td {
      font-size: 16px !important;
      padding: 8px;
    }
  }
`;

export const SwiperWrapper = styled(Swiper)`
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 24px;
    border-radius: 6px;
    background: #219ebc;
  }

  .swiper-pagination-bullet {
    margin: 0 5px;
    width: 6px;
    height: 6px;
    background: gray;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 12px;
    color: #000;
    background: #fff;
    height: 1.75rem;
    width: 1.75rem;
    font-weight: 900;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
  }

  .swiper-button-prev {
    left: 1.25rem;
  }

  .swiper-button-next {
    right: 1.25rem;
  }
`;
