/**
 * The NoDataText component is a React component that displays a given text in a centered paragraph
 * tag.
 * @returns The NoDataText component is returning a Paragraph tag with the provided text as its
 * content.
 */
import React from 'react';
import { Para16Tag } from 'assets/styles';

const NoDataText = ({ text }) => {
  return (
    <>
      <Para16Tag className="text-center py-4">{text}</Para16Tag>
    </>
  );
};

export default NoDataText;
