/* This code is defining a reducer function called `lensInfoReducer` for managing the state related to
lens information in a Redux store. */
import * as constants from 'redux/constants';
import { GET_LENS_DATA } from 'redux/constants/lensInfoConstants';

const initialState = {
  loading: false,
  lensData: [],
  selectedLensData: {},
  item_price: 0,
  colorStep: {},
  lense: {},
};

export const lensInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_LENS_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_LENS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        lensData: action.payload,
      };
    case constants.SET_SELECTED_LENS_INFO:
      return {
        ...state,
        loading: false,
        selectedLensData: { ...state.selectedLensData, ...action.payload?.[0] },
        item_price: (state.item_price += action.payload?.[1]?.price),
      };
    case constants.REDUCE_SELECTED_LENS_INFO_PRICE:
      return {
        ...state,
        loading: false,
        item_price: state.item_price - action.payload,
      };

    case constants.LENS_COLOR_OPTIONS: {
      return {
        ...state,
        loading: false,
        colorStep: action.payload,
      };
    }

    case constants.ADD_COLOR_STEP: {
      const { lensData, colorStep } = state;
      const { lens_id, index } = action.payload;
      //   x.steps.splice(index - 1, 0, colorStep)  [...x.steps, colorStep]

      return {
        ...state,
        loading: false,
        lensData: lensData.map((x) => {
          if (x.id === lens_id) {
            const steps = [...x.steps];
            // steps[index]=colorStep
            steps.splice(index, 0, colorStep);

            return {
              ...x,
              steps,
            };
          }
          return x;
        }),
      };
    }

    case constants.REMOVE_COLOR_STEP: {
      const { lensData } = state;
      return {
        ...state,
        loading: false,
        lensData: lensData.map((x) => {
          if (x.id === action.payload) {
            return {
              ...x,
              steps: x.steps.filter((x) => x.title !== 'Colors'),
            };
          }
          return x;
        }),
      };
    }
    case GET_LENS_DATA: {
      return {
        ...state,
        lense: action.payload,
      };
    }
    default:
      return state;
  }
};
