import { FRAME_SIZE_FAILURE, FRAME_SIZE_SUCCESS } from 'redux/constants';

export const setFrameSizeAction = (frameSize) => async (dispatch) => {
  try {
    dispatch({
      type: FRAME_SIZE_SUCCESS,
      payload: frameSize,
    });
  } catch (error) {
    dispatch({
      type: FRAME_SIZE_FAILURE,
      payload: null,
    });
    throw new Error();
  }
};
