/* The code is importing the `Yup` library and assigning it to the variable `Yup`. `Yup` is a
JavaScript schema validation library that allows you to define validation rules for objects. */
import * as Yup from 'yup';
import * as config from './config';

/* The code is defining a schema for validating user signup data. It uses the `Yup` library to create a
validation schema object. The schema specifies the validation rules for each field in the signup
form. */
export const signUpUserSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, config.message?.YUP_TOO_SHORT)
    .max(70, config.message?.YUP_TOO_LONG)
    .required(config.message?.YUP_REQUIRED),
  email: Yup.string()
    .email(config.message?.YUP_EMAIL)
    .required(config.message?.YUP_REQUIRED),
  mobile: Yup.string()
    .matches(config.mobileRegex, config.message?.YUP_INVALID_NUMBER)
    .required(config.message?.YUP_REQUIRED),
  password: Yup.string()
    .min(8, config.message?.YUP_PASSWORD_MIN)
    .max(50, config.message?.YUP_PASSWORD_MAX)
    .required(config.message?.YUP_REQUIRED),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], config.message?.YUP_PASSWORD_NOT_MATCH)
    .required(config.message?.YUP_REQUIRED),
  agreeToPrivacyPolicy: Yup.boolean()
    .oneOf([true], config.message?.YUP_MUST_AGREE_TO_PRIVACY_POLICY)
    .required(config.message?.YUP_REQUIRED),
});

/* The code is defining a validation schema for validating user sign-in data. It uses the `Yup` library
to create a validation schema object. The schema specifies the validation rules for the `email`
field in the sign-in form. */
export const signInUserSchema = Yup.object().shape({
  email: Yup.string()
    .email(config.message?.YUP_EMAIL)
    .required(config.message?.EMAIL_REQUIRED),
  password: Yup.string()
    .min(8, config.message?.YUP_PASSWORD_MIN)
    .max(50, config.message?.YUP_PASSWORD_MAX)
    .required(config.message?.YUP_REQUIRED),
});

/* The code is defining a validation schema for the forgot password form. It uses the `Yup` library to
create a validation schema object. The schema specifies the validation rules for the `email` field
in the forgot password form. */
export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(config.message?.YUP_EMAIL)
    .required(config.message?.EMAIL_REQUIRED),
});

/* The code is defining a validation schema for verifying an email address. It uses the `Yup` library
to create a validation schema object. The schema specifies the validation rules for the `email` and
`validationCode` fields in the verification form. */
export const verifyEmailSchema = Yup.object().shape({
  otp: Yup.string().required(config.message?.YUP_REQUIRED),
});

/* The code is defining a validation schema for resetting a password. It uses the `Yup` library to
create a validation schema object. The schema specifies the validation rules for the `password` and
`confirmPassword` fields in the reset password form. */
export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, config.message?.YUP_PASSWORD_MIN)
    .max(50, config.message?.YUP_PASSWORD_MAX)
    .required(config.message?.YUP_REQUIRED),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], config.message?.YUP_PASSWORD_NOT_MATCH)
    .required(config.message?.YUP_REQUIRED),
});

/* The code is defining a validation schema for a product review. It uses the `Yup` library to create a
validation schema object. The schema specifies the validation rules for the `comment` field in the
product review form. The `comment` field is required, meaning that the user must provide a review
message in order to submit the form. If the `comment` field is empty or does not meet the validation
criteria, an error message will be displayed. */
export const productReviewSchema = Yup.object().shape({
  comment: Yup.string().required(config.message?.REVIEW_COMMENT),
});

/* The code is defining a validation schema for the terms and conditions checkbox. It uses the `Yup`
library to create a validation schema object. The schema specifies the validation rule for the
`agreeToPrivacyPolicy` field, which is a boolean field representing whether the user has agreed to
the privacy policy. The validation rule states that the value of `agreeToPrivacyPolicy` must be
`true`, otherwise an error message will be displayed, which is specified in the
`message?.YUP_MUST_AGREE_TO_PRIVACY_POLICY` variable. */
export const termsCondition = Yup.object().shape({
  agreeToPrivacyPolicy: Yup.boolean().oneOf(
    [true],
    config.message?.YUP_MUST_AGREE_TO_PRIVACY_POLICY
  ),
});

/* The code is defining a validation schema for adding a new shipping address. It uses the `Yup`
library to create a validation schema object. The schema specifies the validation rules for each
field in the shipping address form. */
export const shippingAddressSchema = Yup.object().shape({
  address: Yup.string().required(config.message?.ADDRESS_REQUIRED),
  state: Yup.string().required(config.message?.SELECT_STATE),
  city: Yup.string().required(config.message?.SELECT_CITY),
  zipcode: Yup.string()
    .matches(config.zipCodeRegex, 'Invalid ZIP code')
    .required(config.message?.ZIP_CODE),
  is_default_address: Yup.boolean(),
  email: Yup.string()
    .email(config.message?.YUP_EMAIL)
    .required(config.message?.YUP_REQUIRED),
  mobile_no: Yup.string()
    .matches(config.mobileRegex, config.message?.YUP_INVALID_NUMBER)
    .required(config.message?.YUP_REQUIRED),
  firstname: Yup.string().required(config.message?.FIRST_NAME),
  lastname: Yup.string().required(config.message?.LAST_NAME),
  address_type: Yup.string(),
});

/* The code is defining a validation schema for adding a prescription. It uses the `Yup` library to
create a validation schema object. The schema specifies the validation rules for each field in the
prescription form. */
export const prescriptionSchema = Yup.object().shape({
  rightEyeAxis: Yup.string()
    .matches(config.axisRegex, 'Right Eye axis only numbers')
    .required(config.message?.RIGHT_EYE_AXIS),
  rightEyeCYL: Yup.string()
    .matches(config.axisRegex, 'Right Eye CYL only numbers')
    .required(config.message?.RIGHT_EYE_CYL),
  rightEyePD: Yup.string()
    .matches(config.axisRegex, 'Right Eye PD only numbers')
    .required(config.message?.RIGHT_EYE_PD),
  rightEyeSPH: Yup.string()
    .matches(config.axisRegex, 'Right Eye SHP only numbers')
    .required(config.message?.RIGHT_EYE_SPH),

  leftEyeAxis: Yup.string()
    .matches(config.axisRegex, 'Left Eye axis only numbers')
    .required(config.message?.LEFT_EYE_AXIS),
  leftEyeCYL: Yup.string()
    .matches(config.axisRegex, 'Left Eye CYL only numbers')
    .required(config.message?.LEFT_EYE_CYL),
  leftEyePD: Yup.string()
    .matches(config.axisRegex, 'Left Eye PD contain only numbers')
    .required(config.message?.LEFT_EYE_PD),
  leftEyeSPH: Yup.string()
    .matches(config.axisRegex, 'Left Eye SHP only numbers')
    .required(config.message?.LEFT_EYE_SPH),
});

/* The code is defining a validation schema for a guest login/signup form. It uses the `Yup` library to
create a validation schema object. The schema specifies the validation rules for each field in the
form. */
export const stepperGuestLoginSignupSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .required('Please enter your full name')
    .matches(
      config.fullNameRegex,
      'Please enter both your first and last name'
    ),
  emailGuest: Yup.string()
    .email(config.message?.YUP_EMAIL)
    .required(config.message?.YUP_REQUIRED),
  phoneNumber: Yup.string()
    .matches(config.mobileRegex, config.message?.YUP_INVALID_NUMBER)
    .required(config.message?.YUP_REQUIRED),
});

/* The code is defining a validation schema for the email field in a login/signup form. It uses the
`Yup` library to create a validation schema object. The schema specifies the validation rules for
the `email` field. The `email` field must be a string and must be a valid email address format. It
is also required, meaning that the user must provide an email address in order to submit the form.
If the `email` field is empty or does not meet the validation criteria, an error message will be
displayed, which is specified in the `message?.YUP_REQUIRED` and `message?.YUP_EMAIL` variables. */
export const stepperLoginSignupSchema = Yup.object().shape({
  email: Yup.string()
    .email(config.message?.YUP_EMAIL)
    .required(config.message?.EMAIL_REQUIRED),
  password: Yup.string()
    .min(8, config.message?.YUP_PASSWORD_MIN)
    .max(50, config.message?.YUP_PASSWORD_MAX)
    .required(config.message?.YUP_REQUIRED),
});

/* This code snippet is defining a validation schema for tracking an order. It uses the `Yup` library
to create a validation schema object. The schema specifies the validation rules for the
`trackingNumber` field in the tracking order form. */
export const trackingOrderSchema = Yup.object().shape({
  trackingNumber: Yup.string()
    .required(config.message?.YUP_REQUIRED)
    .test('len', 'Invalid tracking number', (val) => val.length >= 12)
    .test('len', 'Invalid tracking number', (val) => val.length <= 12),
  // status: Yup.string()
});
