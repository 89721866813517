import { useEffect, useState } from 'react';
import useFetchBlogs from './useFetchBlogs';

const useRandomBlog = (url, options) => {
  const [randomItem, setRandomItem] = useState();

  const { blogs, loading } = useFetchBlogs(url, options);

  useEffect(() => {
    if (blogs.length > 0) {
      const randomIndex = Math.floor(Math.random() * blogs.length);
      setRandomItem(blogs[randomIndex]);
    }
  }, [blogs]);

  return { randomItem, loading };
};

export default useRandomBlog;
