/* The code is defining a React functional component called `UserPresentationOptions`. This component
takes two props, `handelClick` and `selectedId`. */
import React from 'react';
import { ButtonWrapper, Para16Tag, Para22Tag } from 'assets/styles';
import { lensePresentationData } from 'data';
import styles from './UserPrescriptionOptions.module.css';
import { IoClose } from 'react-icons/io5';
import { usePrescriptionContext } from 'context';

const UserPrescriptionOptions = ({
  handelClick,
  selectedId,
  handleBackButtonClick,
  closeButton,
}) => {
  const { setSelectedPrescriptionOption } = usePrescriptionContext();

  const handleClose = () => {
    if (closeButton) closeButton();
    else window.history.back();
  };

  const handleClickOptions = (id) => {
    handelClick(id);
    setSelectedPrescriptionOption(id);
  };

  return (
    <>
      <div>
        <div className="hidden screen1280:block">
          <div className="flex items-center gap-3 pb-[14px] mb-[14px] border-b-4">
            <button onClick={handleClose}>
              <IoClose size={20} color="#868A8B" />
            </button>
            <Para16Tag>Add your Prescription</Para16Tag>
          </div>
        </div>

        {lensePresentationData.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className={`${styles.lense_prescription_manually_main} ${
                  item.id === selectedId
                    ? styles.lense_prescription_manually_active_add
                    : ''
                }`}
                onClick={() => handleClickOptions(item?.id)}
              >
                <img
                  className="screen1280:w-14"
                  loading="lazy"
                  src={item?.images}
                  alt="img"
                />
                <div
                  className={styles.lense_prescription_manually_main_text_inner}
                >
                  <Para22Tag color={'#2E3233'}>{item?.title}</Para22Tag>
                  <Para16Tag color={'#2E3233'}>{item?.description}</Para16Tag>
                </div>
              </div>
            </>
          );
        })}
      </div>

      <div className="hidden screen1280:block mt-4">
        <ButtonWrapper
          onClick={handleBackButtonClick}
          $bg_color={'#FFF'}
          $bColor={'#DEE2E4'}
          color={'#5F6364'}
          $hover_color={'#2E3233'}
          $hover_bg={'#FFF'}
          $hover_border={'#DEE2E4'}
          width={'100%'}
          $border_color={'#DEE2E4'}
        >
          Back
        </ButtonWrapper>
      </div>
    </>
  );
};

export default UserPrescriptionOptions;
