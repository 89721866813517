const hostName = window.location.hostname;
const port = window.location.port;
let isLocalApi = port >= 3000;

const STRIPE_LIVE = process.env.REACT_APP_LIVE_STRIPE_PUBLISHABLE_KEY;

const STRIPE_TEST = process.env.REACT_APP_TEST_STRIPE_PUBLISHABLE_KEY;

export const STRIPE_CONFIG =
  hostName === 'localhost' && isLocalApi ? STRIPE_TEST : STRIPE_LIVE;
