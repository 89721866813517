const sessionStorageHandler = {
  setItem(key, value) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      throw new Error('Failed to set item in sessionStorage');
    }
  },

  getItem(key) {
    try {
      const storedValue = sessionStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : null;
    } catch (error) {
      throw new Error('Failed to get item from sessionStorage');
    }
  },

  removeItem(key) {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      throw new Error('Failed to remove item from sessionStorage');
    }
  },

  clear() {
    try {
      sessionStorage.clear();
    } catch (error) {
      throw new Error('Failed to clear sessionStorage');
    }
  },
};

export default sessionStorageHandler;
