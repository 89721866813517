import * as constants from 'redux/constants';

const initialState = {
  wishListItem: [],
  loading: false,
  error: null,
};

export const wishListReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ADD_WISHLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.ADD_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        wishListItem: action.payload,
      };
    case constants.ADD_WISHLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
