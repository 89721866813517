import React from 'react';
import { ButtonWrapper, Head32Tag, Para16Tag } from 'assets/styles';
import UserUploadImg from './UserUploadImg';
import { ApiPostDocsPrescription } from 'helper/API';
import { message, routesConfig } from 'helper/config';
import '../../lensePrescription/lenseUploadPrescription.css';
import { usePrescriptionContext, useSnackbar } from 'context';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { UseMyFramePrescriptionAction } from 'redux/actions';
import { Auth } from 'helper/Auth';
import { CheckBoxCustom } from 'components/commonComponents';

const UserUploadPrescription = ({ isUseMyFrame, complete }) => {
  const [firstUpload, setFirstUpload] = React.useState(false);

  const {
    camOn,
    setCamOn,
    prescriptionImages,
    setPrescriptionImages,
    camera,
    handleImageChange,
  } = usePrescriptionContext();

  const { showSnackbar } = useSnackbar();

  // const camera = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const toBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader?.result);
  //     reader.onerror = reject;
  //   });

  // const handleImageChange = async (e) =>
  //   setPrescriptionImages(await toBase64(e.target.files[0]));

  const handleSubmit = async () => {
    if (firstUpload) {
      try {
        if (isUseMyFrame) {
          const userID = Auth?.getUserId();

          const body = {
            image_prescription: prescriptionImages,
            is_image_prescription: true,
            user_id: userID,
          };

          dispatch(UseMyFramePrescriptionAction(body));

          complete();

          showSnackbar(
            message?.PRESCRIPTION_SUBMITTED_SUCCESS,
            'soft',
            'success'
          );
        } else {
          const { status } = await ApiPostDocsPrescription(prescriptionImages);

          if (status === 200) {
            showSnackbar(
              message?.PRESCRIPTION_SUBMITTED_SUCCESS,
              'soft',
              'success'
            );
            navigate(routesConfig.prescription);
          }
        }
      } catch (error) {
        throw new error();
      }
    } else {
      showSnackbar(
        'Check the box before proceeding to the next page.',
        'soft',
        'danger'
      );
    }
  };

  return (
    <>
      <div>
        <Head32Tag className="pb-10" color={'#2E3233'} $weight={600}>
          Upload Prescription
        </Head32Tag>

        <UserUploadImg
          camOn={camOn}
          setCamOn={setCamOn}
          prescriptionImages={prescriptionImages}
          setPrescriptionImages={setPrescriptionImages}
          camera={camera}
          takePhoto={camera?.current?.takePhoto}
          handleImageChange={handleImageChange}
        />

        <label className="cursor-pointer flex items-baseline gap-[10px] py-4">
          <CheckBoxCustom
            id="firstUpload"
            name="firstUpload"
            checked={firstUpload}
            onChange={() => setFirstUpload(!firstUpload)}
          />
          <Para16Tag className="italic !font-bold">
            By selecting this box, the customer affirms that they are 18 years
            and older and that their eye prescription is correct, was obtained
            from a licensed optometrist/ophthalmologist and valid under state
            law. Optics Haven assumes no liability for any inaccuracies in the
            prescription provided on this site.
          </Para16Tag>
        </label>
        <Para16Tag color="red">
          Note: To avoid delay in processing your orders, Residents of
          California and Nevada should upload their prescription as we cannot
          ship self reported prescriptions to their state
        </Para16Tag>
      </div>

      <div className="flex items-center mt-10 justify-center">
        <ButtonWrapper onClick={handleSubmit}>
          Submit Prescription
        </ButtonWrapper>
      </div>
    </>
  );
};

export default UserUploadPrescription;
