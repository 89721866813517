/**
 * The `ReviewRating` component is a React component that displays a star rating using the `ReactStars`
 * library.
 * @returns The ReviewRating component is being returned.
 */
import React from 'react';
import ReactStars from 'react-rating-stars-component';
import {
  TiStarFullOutline,
  TiStarHalfOutline,
  TiStarOutline,
} from 'react-icons/ti';

const ReviewRating = ({ size, value, isHalf, count }) => {
  const ratingChanged = (newRating) => {};

  return (
    <div className="rating_main_star">
      <ReactStars
        size={size ? size : 32}
        count={count ? count : 5}
        color={'#CCD0D1'}
        activeColor={'#FFB703'}
        value={value}
        a11y={true}
        edit={false}
        isHalf={isHalf ? isHalf : true}
        emptyIcon={<TiStarOutline />}
        halfIcon={<TiStarHalfOutline />}
        filledIcon={<TiStarFullOutline />}
        onChange={ratingChanged}
      />
    </div>
  );
};

export default ReviewRating;
