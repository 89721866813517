/**
 * The `NewArrivals` component is a React component that renders an image wrapped in a link.
 * @returns The NewArrivals component is returning a JSX element.
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { routesConfig } from 'helper/config';
import { new_arrivals1, new_arrivals2 } from 'assets/images';
import { ImgFadeInOut } from '.';

const NewArrivals = () => {
  const image = [new_arrivals1, new_arrivals2];

  return (
    <>
      <Link to={routesConfig?.productList}>
        <ImgFadeInOut image={image} />
        {/* <FullBanner image={image} /> */}
      </Link>
    </>
  );
};

export default NewArrivals;
