/* The code block you provided is defining a set of constants for user-related actions in a JavaScript
application. Each constant represents a specific action that can be dispatched in the application. */

//------------------------------------------------------------------//
export const USER_REQUEST = 'USER_REQUEST';
export const USER_FAILURE = 'USER_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_RESET = 'USER_RESET';

// USER CONSTANTS //
//------------------------------------------------------------------//
// CREATE USER
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// LOGIN USER
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GUEST USER
export const GUEST_USER_REQUEST = 'GUEST_USER_REQUEST';
export const GUEST_USER_SUCCESS = 'GUEST_USER_SUCCESS';
export const GUEST_USER_FAILURE = 'GUEST_USER_FAILURE';
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// SIGN IN GOOGLE
export const GOOGLE_SIGN_IN_REQUEST = 'GOOGLE_SIGN_IN_REQUEST';
export const GOOGLE_SIGN_IN_SUCCESS = 'GOOGLE_SIGN_IN_SUCCESS';
export const GOOGLE_SIGN_IN_FAILURE = 'GOOGLE_SIGN_IN_FAILURE';
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// OTP VERIFY USER
export const OTP_VERIFY_USER_REQUEST = 'OTP_VERIFY_USER_REQUEST';
export const OTP_VERIFY_USER_SUCCESS = 'OTP_VERIFY_USER_SUCCESS';
export const OTP_VERIFY_USER_FAILURE = 'OTP_VERIFY_USER_FAILURE';
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// RESET USER PASSWORD
export const RESET_USER_PASS_REQUEST = 'RESET_USER_PASS_REQUEST';
export const RESET_USER_PASS_SUCCESS = 'RESET_USER_PASS_SUCCESS';
export const RESET_USER_PASS_FAILURE = 'RESET_USER_PASS_FAILURE';
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// GET SINGLE USER
export const GET_SINGLE_USER_REQUEST = 'GET_SINGLE_USER_REQUEST';
export const GET_SINGLE_USER_SUCCESS = 'GET_SINGLE_USER_SUCCESS';
export const GET_SINGLE_USER_FAILURE = 'GET_SINGLE_USER_FAILURE';
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// UPDATE USER
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
//------------------------------------------------------------------//
