/* The code you provided is a JavaScript reducer function. It is used to manage the state of a review
feature in a Redux store. */
import * as constants from 'redux/constants';

const initialState = {
  reviewList: [],
  avg: '',
  loading: false,
  error: null,
  like_dislike: false,
};

export const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ADD_REVIEW_SUCCESS:
      return {
        ...state,
        reviewList: [...state.reviewList, action.payload],
      };

    case constants.LIST_ALL_REVIEW_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case constants.LIST_ALL_REVIEW_SUCCESS: {
      const { reviewAvg, reviewData } = action.payload;

      return {
        ...state,
        reviewList: reviewData || [],
        avg: reviewAvg,
        loading: false,
      };
    }

    case constants.POST_REVIEW_LIKE_DISLIKE_REQUEST: {
      const { like_dislike } = action.payload;

      return {
        ...state,
        loading: false,
        like_dislike: like_dislike,
      };
    }

    case constants.LIST_ALL_REVIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }

    default:
      return state;
  }
};
