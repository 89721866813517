import React from 'react';
import { ColorWrapper, Para16Tag } from 'assets/styles';
import styles from './FramesColor.module.css';

const FramesColor = (props) => {
  return (
    <div className={styles.product_sidebar_inner_frame_size}>
      <label className={styles.product_sidebar_add_label_inner_main}>
        <input
          type="checkbox"
          className={styles.input}
          value={props.item.color_name}
          onChange={() => props.HandleFrameColorChange(props.item._id)}
          checked={props?.frameColor?.includes(props.item._id)}
        />

        <div className={styles.product_sidebar_color_name_num_add_div}>
          <ColorWrapper
            className={styles.product_latest_diff_color_inner}
            width={24}
            height={24}
            $color_img={props.item.image}
          />
          <div className={styles.product_latest_diff_color_name_inner}>
            <Para16Tag>{props.item.color_name}</Para16Tag>
          </div>
        </div>
      </label>
    </div>
  );
};

export default FramesColor;
