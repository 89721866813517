/* This code is responsible for rendering the React application to the DOM. */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store from 'redux/store';
import { LazyLoader } from 'components/commonComponents';
import './assets/styles/indexCss';
import { BrowserRouter as Router } from 'react-router-dom';

if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window;
  if (typeof devToolsExtension === 'function') {
    devToolsExtension();
  }
}

/* The above code is dynamically importing various components using React.lazy and LazyLoader. These
components are used in different pages of a website. The LazyLoader function is used to lazily load
the components, meaning they will be loaded only when they are actually needed. This helps in
improving the performance of the website by reducing the initial load time. */
const App = LazyLoader(React.lazy(() => import('App')));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
// serviceWorker.unregister();
