/**
 * The FullBanner component is a React component that renders an image wrapped in a link.
 * @returns The FullBanner component is returning a Link component from react-router-dom, which wraps
 * an ImgWrapper component. The ImgWrapper component is an img element styled using styled-components.
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { routesConfig } from 'helper/config';
import { styled } from 'styled-components';

const FullBanner = ({ image, path }) => {
  return (
    <>
      <Link to={path ? path : routesConfig?.productList}>
        <ImgWrapper loading="lazy" src={image} alt={image} />
      </Link>
    </>
  );
};

const ImgWrapper = styled.img`
  width: 100%;
`;

export default FullBanner;
