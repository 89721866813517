import { CommonApi } from 'helper/API';
import { useQuery } from 'react-query';

const useProductsQuery = (queryKey, body) => {
  const { data, isLoading } = useQuery(
    queryKey,
    async () => {
      const response = await CommonApi.post('/product-frames-detail', body);
      return response.data;
    },
    {
      staleTime: 30000,
    }
  );

  return { data, isLoading };
};

export default useProductsQuery;
