/* The code is a React component called `BannerSlider` that uses the Swiper library to create a
slideshow banner. */
import React from 'react';
import 'assets/styles/swiperCss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { FullBanner } from 'components/commonComponents';
import styled from 'styled-components';

const BannerSlider = ({ images, nav }) => {
  return (
    <>
      <SwiperSlider
        style={{
          '--swiper-navigation-color': '#adb1b5',
          '--swiper-pagination-color': '#fff',
        }}
        loop={true}
        spaceBetween={0}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={nav ? nav : false}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {images.map((value, index) => (
          <SwiperSlide key={`${value}-${index}`}>
            <FullBanner image={value} />
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </>
  );
};

const SwiperSlider = styled(Swiper)`
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 24px;
    border-radius: 6px;
    background: #ffffff;
  }

  span.swiper-pagination-bullet {
    margin: 0 5px;
    width: 6px;
    height: 6px;
    background: rgb(255 255 255 / 50%);
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 12px;
    color: #000;
    background: #ffffff;
    height: 1.75rem;
    width: 1.75rem;
    font-weight: 900;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
  }

  .swiper-button-prev {
    left: 1.25rem;
  }

  .swiper-button-next {
    right: 1.25rem;
  }
`;

export default BannerSlider;
