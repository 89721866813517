/* The code is defining a reducer function called `frameSizeReducer` that handles actions related to
frame size. */
import * as constants from 'redux/constants';

const initialState = {
  frameSize: null,
  loading: false,
  error: null,
};

export const frameSizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FRAME_SIZE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case constants.FRAME_SIZE_SUCCESS:
      return {
        ...state,
        frameSize: action?.payload,
        loading: false,
      };

    case constants.FRAME_SIZE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case constants.FRAME_SIZE_REMOVE: {
      return {
        ...state,
        frameSize: null,
        loading: false,
      };
    }

    default:
      return state;
  }
};
