import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getProductDetails } from 'redux/actions';

const useProductDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const { selectedProduct } = useSelector((store) => store.products);
  let selectedColor = location?.state?.selectedColor;

  const colorAndSize = useMemo(() => {
    const result = [];

    selectedProduct?.frames?.forEach((item) => {
      const colorName = item?.color_id?.color_name;
      if (colorName && !result.some((color) => color.name === colorName)) {
        result.push({
          id: item?.color_id?._id,
          frame_id: item?._id,
          name: colorName,
          colorImage: item?.color_id?.image,
          thumbnailImage: item?.thumbnail_picture,
          images: item?.images,
          tryOnPicture: item?.try_on_picture,
          frameSize: item?.frame_sizes?.map((sizeItem) => sizeItem),
        });
      }
    });

    return result;
  }, [selectedProduct]);

  selectedColor = selectedColor ? selectedColor : colorAndSize?.[0]?.id;

  const activeColorIndex = colorAndSize?.findIndex(
    (x) => x?.id === selectedColor
  );

  // Memoize the getProductDetails action creator
  const getProductDetailsAction = useMemo(() => {
    return (productId) => dispatch(getProductDetails(productId));
  }, [dispatch]);

  useEffect(() => {
    // Check if selectedProduct is not available before dispatching the action
    // if (!selectedProduct) {
    getProductDetailsAction(id);
    // }
  }, [id]);

  return {
    selectedProduct,
    selectedColor,
    colorAndSize,
    activeColorIndex,
    getProductDetailsAction,
    id,
  };
};

export default useProductDetails;
