import axios from 'axios';
import * as lensInfoConstants from '../constants/lensInfoConstants';
import { BASEURL } from 'helper/API';
import { getHeadersCors } from 'helper/API';
import { ErrorToast } from 'helper/Toast';
import { message } from 'helper/config';

export const getLensInfo =
  (isKidsType = false, showSnackbar) =>
  async (dispatch) => {
    try {
      const headers = await getHeadersCors();
      dispatch({ type: lensInfoConstants.GET_LENS_INFO_REQUEST });

      const { data: lensesResponseData } = await axios.get(
        `${BASEURL}/common/lenses`,
        headers
      );

      let processedLensesTypeData = {
          title: 'Type of Lens',
          options: [],
        },
        processedSingleVisionData = {
          title: 'Type of Single Vision',
          options: [],
        },
        processedMaterialTypeData = {
          title: 'Type of Lens Material',
          options: [],
        },
        processedStrengthOptions = {
          title: 'Select a readers strength',
          options: [
            {
              value: '+0.25',
              title: '+0.25',
              identifier: 'strength_id',
              id: '232',
            },
            {
              value: '+0.5',
              title: '+0.5',
              identifier: 'strength_id',
              id: '3243',
            },
            {
              value: '+0.75',
              title: ' +0.75',
              identifier: 'strength_id',
              id: '3435',
            },
            {
              value: '+1.0',
              title: '+1.0',
              identifier: 'strength_id',
              id: '234532',
            },
            {
              value: '+1.25',
              title: ' +1.25',
              identifier: 'strength_id',
              id: '22345332',
            },
            {
              value: '+1.5',
              title: '+1.5',
              identifier: 'strength_id',
              id: '232342',
            },
            {
              value: '+1.75',
              title: ' +1.75',
              identifier: 'strength_id',
              id: '23223424',
            },
            {
              value: '+2.0',
              title: '+2.0',
              identifier: 'strength_id',
              id: '2322342',
            },
            {
              value: '+2.25',
              title: '+2.25',
              identifier: 'strength_id',
              id: '2322342',
            },
            {
              value: '+2.5',
              title: '+2.5',
              identifier: 'strength_id',
              id: '232342',
            },
            {
              value: '+2.75',
              title: ' +2.75',
              identifier: 'strength_id',
              id: '232234',
            },
          ],
        },
        colorOptions = {};

      //   lenses type response data
      const { data: lensesTypeResponseData } = await axios.get(
        `${BASEURL}/common/lense-type`,
        headers
      );

      lensesTypeResponseData.forEach((x) => {
        const { colors } = x;
        if (colors.length > 0) {
          const colorStepInfo = { title: 'Colors', options: [] };
          colors.forEach(({ color_id: color }) => {
            colorStepInfo.options.push({
              id: color?._id,
              identifier: 'color_id',
              title: color?.color_name,
              image: color?.image,
            });
          });
          colorOptions = colorStepInfo;
        }
        processedLensesTypeData.options.push({
          id: x?._id,
          title: x?.lenses_type,
          details: x?.description,
          price: x?.price,
          identifier: 'lense_type_id',
        });
      });

      // singleVision response data
      const { data: singleVisionResponseData } = await axios.get(
        `${BASEURL}/common/single-vision-type`,
        headers
      );

      singleVisionResponseData.forEach((x) => {
        processedSingleVisionData?.options.push({
          id: x?._id,
          title: x?.single_vision_type,
          details: x?.description,
          price: x?.price,
          identifier: 'singlevision_id',
        });
      });

      // material response data
      const { data: materialTypeResponseData } = await axios.get(
        `${BASEURL}/common/lense-material-type`,
        headers
      );

      materialTypeResponseData?.map((x) => {
        return processedMaterialTypeData.options.push({
          id: x?._id,
          title: x?.lens_material_type,
          details: x?.description,
          price: x?.price,
          identifier: 'material_type_id',
        });
      });

      const allOptions = [
        processedSingleVisionData,
        processedLensesTypeData,
        processedMaterialTypeData,
      ];

      const progressiveOptions = [
        processedLensesTypeData,
        processedMaterialTypeData,
      ];
      const readersOptions = [
        processedStrengthOptions,
        processedLensesTypeData,
        processedMaterialTypeData,
      ];

      const processedLensData = lensesResponseData?.map((x) => {
        const resultObj = {
          id: x?._id,
          images: x?.image,
          title: x?.prescription_type,
          price: x?.price,
          details: x?.description,
          steps: [],
          identifier: 'lense_id',
        };
        if (resultObj.title === 'Readers') {
          resultObj.steps.push(...readersOptions);
        } else if (resultObj.title === 'Progressives') {
          resultObj.steps.push(...progressiveOptions);
        } else {
          resultObj.steps.push(...allOptions);
        }
        return resultObj;
      });

      dispatch({
        type: lensInfoConstants.GET_LENS_INFO_SUCCESS,
        payload: isKidsType
          ? processedLensData.filter(
              (data) =>
                data.title === 'Single Vision Lenses' ||
                data.title === 'Non-Prescription'
            )
          : processedLensData,
      });
      const payload = {
        lensesTypeResponseData,
        lensesResponseData,
        singleVisionResponseData,
        materialTypeResponseData,
      };

      dispatch({
        type: lensInfoConstants.GET_LENS_DATA,
        payload: payload,
      });

      dispatch({
        type: lensInfoConstants.LENS_COLOR_OPTIONS,
        payload: colorOptions,
      });

      if (lensesResponseData?.message?.status === 200) {
        showSnackbar(message?.ADD_SUCCESS_REVIEW, 'soft', 'success');
      }
    } catch (error) {
      if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
      else if (error?.message === 'Network Error')
        ErrorToast(message?.NETWORK_ERROR);
      else throw error;
    }
  };
