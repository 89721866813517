import React from 'react';
import useFrameData from './useFrameData';
import { URLSearchParams } from 'helper/config';

const useFilterParams = (
  searchParams,
  setSearchParams,
  sortBy,
  setSortBy,
  categoryId,
  setCategoryId
) => {
  const { frameTypeList, frameShapeList, frameSizeList, frameColorList } =
    useFrameData();

  const initialFrameTypes = searchParams?.getAll(URLSearchParams.frameType);
  const [frameType, setFrameType] = React.useState(
    initialFrameTypes?.length ? initialFrameTypes : []
  );

  const initialFrameShape = searchParams?.getAll(URLSearchParams.frameShape);
  const [frameShape, setFrameShape] = React.useState(
    initialFrameShape?.length ? initialFrameShape : []
  );

  const initialColor = searchParams?.getAll(URLSearchParams.frameColor);
  const [frameColor, setFrameColor] = React.useState(
    initialColor?.length ? initialColor : []
  );

  const initialSize = searchParams?.getAll(URLSearchParams.frameSize);
  const [frameSize, setFrameSize] = React.useState(
    initialSize?.length ? initialSize : []
  );

  const initialFrameMaterial = searchParams?.getAll(
    URLSearchParams.frameMaterial
  );
  const [frameMaterial, setFrameMaterial] = React.useState(
    initialFrameMaterial?.length ? initialFrameMaterial : []
  );

  const initialGender = searchParams?.getAll(URLSearchParams.gender);
  const [gender, setGender] = React.useState(
    initialGender?.length ? initialGender : []
  );

  //? Desktop Devices
  const handleArrayChange = (id, setState) => {
    setState((prevCompatible) => {
      if (prevCompatible.includes(id)) {
        return prevCompatible.filter((item) => item !== id);
      } else {
        return [...prevCompatible, id];
      }
    });
  };

  const HandleFrameTypeChange = (id) => handleArrayChange(id, setFrameType);
  const HandleFrameShapeChange = (id) => handleArrayChange(id, setFrameShape);
  const HandleFrameColorChange = (id) => handleArrayChange(id, setFrameColor);
  const HandleFrameSizeChange = (id) => handleArrayChange(id, setFrameSize);

  // Tab means tablet/mobile view
  const handleArrayChangeTab = (id, setState) => {
    // setState((prevCompatible) => {
    //   if (prevCompatible.includes(id)) {
    //     return prevCompatible.filter((item) => item !== id);
    //   }
    //   else {
    //     return [...prevCompatible, id];
    //   }
    // });
    setState(id);
  };

  // Tab means tablet/mobile view
  const HandleFrameTypeChangeTab = (id) =>
    handleArrayChangeTab(id, setFrameType);
  const HandleFrameShapeChangeTab = (id) =>
    handleArrayChangeTab(id, setFrameShape);
  const HandleFrameColorChangeTab = (id) =>
    handleArrayChangeTab(id, setFrameColor);
  const HandleFrameSizeChangeTab = (id) =>
    handleArrayChangeTab(id, setFrameSize);
  const HandleGenderChangeTab = (id) => handleArrayChangeTab(id, setGender);

  const HandleGenderChange = (e) => {
    const value = e.target.value;
    setGender((prevCompatible) => {
      if (prevCompatible.includes(value)) {
        return prevCompatible.filter((item) => item !== value);
      } else {
        return [...prevCompatible, value];
      }
    });
  };

  const clearAllFilter = () => {
    setFrameType([]);
    setFrameShape([]);
    setFrameColor([]);
    setFrameSize([]);
    setGender([]);
  };

  /* The above code is using the `React.useEffect` hook to update the `searchParams` state whenever any
  of the dependencies (`frameType`, `frameShape`, `frameColor`, `frameSize`, `gender`) change. It
  creates a `params` object with the values of these dependencies and then sets the `searchParams`
  state to this object. */
  React.useEffect(() => {
    let params = {
      categoryId,
      frameType,
      frameShape,
      frameColor,
      frameSize,
      frameMaterial,
      gender,
      sortBy,
    };
    setSearchParams(params);
  }, [
    categoryId,
    frameType,
    frameShape,
    frameColor,
    frameSize,
    frameMaterial,
    gender,
    sortBy,
  ]);

  React.useEffect(() => {
    const initialCategory = searchParams?.getAll(URLSearchParams.categoryId);
    const initialFrameTypes = searchParams?.getAll(URLSearchParams.frameType);
    const initialFrameShape = searchParams?.getAll(URLSearchParams.frameShape);
    const initialColor = searchParams?.getAll(URLSearchParams.frameColor);
    const initialSize = searchParams?.getAll(URLSearchParams.frameSize);
    const initialFrameMaterial = searchParams?.getAll(
      URLSearchParams.frameMaterial
    );
    const initialGender = searchParams?.getAll(URLSearchParams.gender);
    const initialSortBy = searchParams?.getAll(URLSearchParams.sortBy);

    setCategoryId(initialCategory?.length ? initialCategory : []);
    setFrameType(initialFrameTypes?.length ? initialFrameTypes : []);
    setFrameShape(initialFrameShape?.length ? initialFrameShape : []);
    setFrameColor(initialColor?.length ? initialColor : []);
    setFrameSize(initialSize?.length ? initialSize : []);
    setFrameMaterial(initialFrameMaterial?.length ? initialFrameMaterial : []);
    setGender(initialGender?.length ? initialGender : []);
    setSortBy(
      initialSortBy?.length ? initialSortBy : [URLSearchParams.new_arrival]
    );
  }, [searchParams]);

  return {
    frameTypeList,
    frameType,
    setFrameType,
    HandleFrameTypeChange,
    HandleFrameTypeChangeTab,
    frameShapeList,
    frameShape,
    setFrameShape,
    HandleFrameShapeChange,
    HandleFrameShapeChangeTab,
    frameColorList,
    frameColor,
    setFrameColor,
    HandleFrameColorChange,
    HandleFrameColorChangeTab,
    frameSizeList,
    frameSize,
    setFrameSize,
    HandleFrameSizeChange,
    HandleFrameSizeChangeTab,
    gender,
    setGender,
    HandleGenderChange,
    HandleGenderChangeTab,
    clearAllFilter,
  };
};

export default useFilterParams;
