/**
 * The `LensStepperCard` component is a React component that renders a card with lens information and
 * an optional selected icon.
 * @returns The LensStepperCard component is returning a JSX element.
 */
import React from 'react';
import './lensStepperCard.css';
import { Para16Tag, Para18Tag, Para20Tag } from 'assets/styles';
import { SelectVisionDone } from 'components/typography';

const LensStepperCard = ({ item, selected, changeSelected, index }) => {
  return (
    <>
      <div
        className="single_vision_stepper_main_outer rounded-lg single_vision_stepper_main mb-4"
        style={{
          background: selected ? 'linear-gradient(0deg, #F4FAFB, #F4FAFB)' : '',
          border: selected ? '1px solid #219EBC4D' : '1px solid #eaeef0',
        }}
        onClick={() => changeSelected(item, index)}
      >
        <div className="single_vision_stepper_inner gap-4">
          {item?.images ? (
            <img
              className="w-9 h-7 object-contain mobile:hidden"
              loading="lazy"
              src={item?.images}
              alt=""
            />
          ) : null}
          <div className="single_vision_lenses_head_text_ para_div">
            <div className="single_vision_lenses_head_text_div">
              <Para20Tag color={'#2E3233'} $weight={600}>
                {item?.title}
              </Para20Tag>
              {item?.image ? (
                <img className="lens_color_code" src={item?.image} alt="" />
              ) : item?.identifier !== 'strength_id' ? (
                <Para18Tag color={'#219EBC'} $weight={600}>
                  ({item?.price === 0 ? 'free' : `$${item?.price}`})
                </Para18Tag>
              ) : (
                ''
              )}
            </div>
            {item?.colorCode ? (
              ' '
            ) : (
              <div className="single_vision_lenses_para_text_div">
                <Para16Tag color={'#2E3233'}>{item?.details}</Para16Tag>
              </div>
            )}
          </div>
        </div>
        {item?.colorCode ? '' : selected ? <SelectVisionDone /> : ''}
      </div>
    </>
  );
};

export default LensStepperCard;
