/* The code is creating a React context called `PrescriptionContext` and exporting a
`PrescriptionProvider` component and a custom hook called `usePrescriptionContext`. */
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const PrescriptionContext = React.createContext();

export const PrescriptionProvider = ({ children }) => {
  // Enter Manually Prescription State
  const [checked, setChecked] = React.useState(false);
  const [leftEyeAxisSwitch, setLeftEyeAxisSwitch] = React.useState(true);
  const [rightEyeAxisSwitch, setRightEyeAxisSwitch] = React.useState(true);
  const [leftEyeAttributes, setLeftEyeAttributes] = React.useState({
    axis: '',
    cyl: 0.0,
    sph: 0.0,
    pd: '',
    power: '',
  });
  const [rightEyeAttributes, setRightEyeAttributes] = React.useState({
    axis: '',
    cyl: 0.0,
    sph: 0.0,
    pd: 63,
    power: '',
  });
  const [errors, setErrors] = React.useState({
    right: {
      axis: '',
      cyl: '',
      sph: '',
      pd: '',
      power: '',
    },
    left: {
      axis: '',
      cyl: '',
      sph: '',
      pd: '',
      power: '',
    },
    isValid: false,
  });
  const [selectedPrescriptionOption, setSelectedPrescriptionOption] =
    React.useState(0);

  // Selected Lens Type
  const [selectedLensType, setSelectedLensType] = React.useState('');
  const [finishLensSelection, setFinishLensSelection] = React.useState(false);
  const [lensSelectionFlowData, setLensSelectionFlowData] = React.useState({});
  const [selectedPrescriptionType, setSelectedPrescriptionType] =
    React.useState('');

  // Upload Prescription State
  const [camOn, setCamOn] = React.useState(false);
  const [prescriptionImages, setPrescriptionImages] = React.useState(null);

  const camera = useRef(null);

  const resetPd = () => {
    setChecked(!checked);
    setLeftEyeAttributes((pre) => {
      return {
        ...pre,
        pd: '',
      };
    });
    setRightEyeAttributes((pre) => {
      return {
        ...pre,
        pd: 63,
      };
    });
    setErrors({
      right: {
        axis: '',
        cyl: '',
        sph: '',
        pd: '',
        power: '',
      },
      left: {
        axis: '',
        cyl: '',
        sph: '',
        pd: '',
        power: '',
      },
      isValid: false,
    });
  };

  const reset = () => {
    resetPd();
    setLeftEyeAttributes({
      axis: '',
      cyl: 0.0,
      sph: 0.0,
      pd: '',
    });
    setRightEyeAttributes({
      axis: '',
      cyl: 0.0,
      sph: 0.0,
      pd: 63,
    });
    setErrors({
      right: {
        axis: '',
        cyl: '',
        sph: '',
        pd: '',
        power: '',
      },
      left: {
        axis: '',
        cyl: '',
        sph: '',
        pd: '',
        power: '',
      },
      isValid: false,
    });
    setFinishLensSelection(false);
    setLensSelectionFlowData({});
    setSelectedPrescriptionType('');
    setSelectedLensType('');
    setCamOn(false);
    setPrescriptionImages(null);
    setChecked(false);
    setLeftEyeAxisSwitch(true);
    setRightEyeAxisSwitch(true);
    setSelectedPrescriptionOption(0);
  };

  const setError = (eye, attribute, message, isValid) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [eye]: {
        ...prevErrors[eye],
        [attribute]: message,
      },
      isValid,
    }));
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleImageChange = async (e) =>
    setPrescriptionImages(await toBase64(e.target.files[0]));

  React.useEffect(() => {
    const checkCylinderValues = () => {
      const isCylinderValid =
        rightEyeAttributes.cyl === 0 ||
        leftEyeAttributes.cyl === 0 ||
        rightEyeAttributes.cyl === '0' ||
        leftEyeAttributes.cyl === '0' ||
        (rightEyeAttributes.cyl > 0 && leftEyeAttributes.cyl > 0) ||
        (rightEyeAttributes.cyl < 0 && leftEyeAttributes.cyl < 0);

      if (isCylinderValid) {
        setError('left', 'cyl', '', true);
        setError('right', 'cyl', '', true);
      } else {
        setError(
          'left',
          'cyl',
          'Please check your prescription. Cylinder values should both be + or both be - numbers',
          false
        );
        setError(
          'right',
          'cyl',
          'Please check your prescription. Cylinder values should both be + or both be - numbers',
          false
        );
      }
    };

    const checkAxisValue = (eyeAttributes) => {
      if (
        eyeAttributes.cyl &&
        eyeAttributes.cyl !== '0' &&
        !eyeAttributes.axis
      ) {
        setError(
          eyeAttributes.eye,
          'axis',
          'Please check your prescription. This value should not be less than 1 or exceed 180',
          false
        );
      } else {
        setError(eyeAttributes.eye, 'axis', '', true);
      }
    };

    checkCylinderValues();
    checkAxisValue({ eye: 'right', ...rightEyeAttributes });
    checkAxisValue({ eye: 'left', ...leftEyeAttributes });
  }, [leftEyeAttributes, rightEyeAttributes, setErrors]);

  useEffect(() => {
    setSelectedLensType(selectedPrescriptionType);
  }, [selectedPrescriptionType, setSelectedLensType]);

  useEffect(() => {
    if (!checked) {
      setLeftEyeAttributes((pre) => {
        return {
          ...pre,
          pd: '',
        };
      });
    } else {
      setRightEyeAttributes((pre) => {
        return {
          ...pre,
          pd: '',
        };
      });
    }
  }, [checked]);

  const isProgressives = selectedLensType === 'Progressives';

  const lensSelection = useSelector((store) => store.lensSelection);

  const { selectedLensData } = lensSelection;
  const isProgressive =
    lensSelection?.lensData?.[1]?.id === selectedLensData?.lense_id
      ? true
      : false;

  const handleCheckboxChange = () => {
    setChecked(!checked);
    resetPd();
  };

  /* The `state` object is a collection of all the state variables and their corresponding setter
 functions that are used in the PrescriptionProvider component. It is created to provide a single
 object that can be passed as the value to the PrescriptionContext.Provider component. This allows
 all the state variables and setter functions to be accessed and used by any component that consumes
 the PrescriptionContext. */
  const state = {
    camOn,
    setCamOn,
    prescriptionImages,
    setPrescriptionImages,
    checked,
    setChecked,
    leftEyeAxisSwitch,
    setLeftEyeAxisSwitch,
    rightEyeAxisSwitch,
    setRightEyeAxisSwitch,
    leftEyeAttributes,
    setLeftEyeAttributes,
    rightEyeAttributes,
    setRightEyeAttributes,
    errors,
    setErrors,
    selectedLensType,
    setSelectedLensType,
    finishLensSelection,
    setFinishLensSelection,
    lensSelectionFlowData,
    setLensSelectionFlowData,
    selectedPrescriptionType,
    setSelectedPrescriptionType,
    resetPd,
    reset,
    isProgressives,
    isProgressive,
    handleCheckboxChange,
    camera,
    handleImageChange,
    selectedPrescriptionOption,
    setSelectedPrescriptionOption,
  };

  return (
    <PrescriptionContext.Provider value={state}>
      {children}
    </PrescriptionContext.Provider>
  );
};

export const usePrescriptionContext = () => {
  return useContext(PrescriptionContext);
};
