import React from 'react';
import { useParams } from 'react-router-dom';
import * as styled from 'assets/styles';
import * as typography from 'components/typography';
import moment from 'moment';
import { ApiGetById } from 'helper/API';

const useOrderDetails = () => {
  // const [isOpen, setIsOpen] = React.useState(false);
  // const [reviewValue, setReviewValue] = React.useState(0);
  const [orderDetail, setOrderDetail] = React.useState(null);
  const [orderStatus, setOrderStatus] = React.useState(-0.5);

  const { id } = useParams();

  // const toggleSubMenu = () => setIsOpen(!isOpen);

  function ColorlibStepIcon({ active, completed, className }) {
    return (
      <styled.ColorlibStepIconRoot
        ownerState={{ active, completed }}
        className={className}
      >
        {completed ? (
          <div className="complete_stepper_span_border">
            <typography.CompleteOrder />
          </div>
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </styled.ColorlibStepIconRoot>
    );
  }

  const stepsData = [
    {
      id: 1,
      name: 'Order Created',
      date: moment(orderDetail?.created_at).format('DD MMMM YYYY'),
    },
    {
      id: 2,
      name: 'Preparing for Dispatch',
    },
    {
      id: 3,
      name: 'On the Way',
      title:
        orderDetail?.order_status === 'shipped' ||
        orderDetail?.order_status === 'delivered'
          ? 'Tracking Number'
          : '',
      tracking_no:
        orderDetail?.order_status === 'shipped' ||
        orderDetail?.order_status === 'delivered'
          ? orderDetail?.tracking_no
          : '',
    },
    {
      id: 4,
      name: 'Delivered',
      date: '',
    },
  ];

  const getOrderDetails = async (id) => {
    try {
      const response = await ApiGetById('/order', id);
      const orderItems = response?.data?.items?.reverse();
      setOrderDetail({ ...response?.data, items: orderItems });

      if (response?.data?.order_status === 'confirmed') setOrderStatus(0.5);
      else if (response?.data?.order_status === 'processing')
        setOrderStatus(1.5);
      else if (response?.data?.order_status === 'shipped') setOrderStatus(2.5);
      else if (response?.data?.order_status === 'delivered')
        setOrderStatus(3.5);
      else setOrderStatus(-0.5);
    } catch (error) {
      throw new Error();
    }
  };

  React.useEffect(() => {
    if (id) {
      getOrderDetails(id);
    }
  }, [id]);

  return {
    orderDetail,
    stepsData,
    orderStatus,
    ColorlibStepIcon,
  };
};

export default useOrderDetails;
