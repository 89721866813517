import React from 'react';
import { BreadcrumbsLabel } from '.';
import { useLocation } from 'react-router-dom';

const ProductListBreadcrumbs = (props) => {
  const location = useLocation();

  return (
    <>
      <BreadcrumbsLabel
        breadcrumbsOne={props.breadcrumbsOne?.title || 'All Glasses'}
        breadcrumbsOnePath={location?.pathname + location?.search}
        // breadcrumbsTwo={'Vincent Chase Rectangle'}
      />
    </>
  );
};

export default ProductListBreadcrumbs;
