/* The above code is a React component written in JavaScript. It is a login form that allows users to
enter their email address and password to log in. The form includes validation using Formik and Yup,
and it dispatches actions to authenticate the user using Redux. It also includes options to sign in
with Google and Facebook. The form is styledModule using CSS modules. */
import React, { useRef } from 'react';
import * as styles from 'assets/styles';
import * as typography from 'components/typography';
import { useApp, useSnackbar } from 'context';
import { Field, Form, Formik } from 'formik';
import { signInUserSchema } from 'helper';
import { message, routesConfig } from 'helper/config';
import { useAuthentication } from 'hooks';
import styledModule from 'pages/desktopView/Auth/Auth.module.css';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { loginUserAction } from 'redux/actions';
import { FormErrorMessage } from '..';
import { Auth } from 'helper/Auth';
import { GoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { BASEURL, APIHeaderCors } from 'helper/API';
import { useScript, appleAuthHelpers } from 'react-apple-signin-auth';
import { FcGoogle } from 'react-icons/fc';
import styled from 'styled-components';

const LoginForm = () => {
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const { setAuthenticated, isMobile } = useApp();
  const { showSnackbar } = useSnackbar();
  const { loading } = useAuthentication();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const initialValues = {
    email: '',
    password: '',
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      let isNavigate = true;
      const response = await dispatch(
        loginUserAction(values, state, navigate, isNavigate, showSnackbar)
      );

      if (response?.data?.token) {
        setAuthenticated(true);
        resetForm();
      }
    } catch (error) {
      resetForm();
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    let USER_CREDENTIAL = null;

    try {
      if (response.credential != null) {
        USER_CREDENTIAL = jwtDecode(response.credential);
        const google_login_response = await axios.post(
          `${BASEURL}/auth/user-google-signin`,
          {
            email: USER_CREDENTIAL?.email,
            userData: {
              username: `${USER_CREDENTIAL?.given_name} ${USER_CREDENTIAL?.family_name}`,
              firstname: USER_CREDENTIAL?.given_name,
              lastname: USER_CREDENTIAL?.family_name,
              email: USER_CREDENTIAL?.email,
              profile_picture: USER_CREDENTIAL?.picture,
            },
          },
          APIHeaderCors
        );
        Auth?.setUserEmail(google_login_response?.data?.user?.email);
        Auth?.setUserId(google_login_response?.data?.user?._id);
        Auth?.setToken(google_login_response?.data?.token);

        if (google_login_response?.data?.token) {
          Auth.removeGuest();
          setAuthenticated(true);
          showSnackbar(message.LOGIN_SUCCESS, 'soft', 'success');
          navigate('/');
        } else showSnackbar('Google sign-in failed!', 'soft', 'danger');
      } else {
        showSnackbar('Error while google-sign-in!', 'soft', 'danger');
        navigate('/login');
      }
    } catch (error) {
      if (error?.message === 'Network Error')
        showSnackbar(message?.NETWORK_ERROR, 'soft', 'danger');
      else if (error) showSnackbar(message?.ERROR_TRY_AGAIN, 'soft', 'danger');
      else showSnackbar('Google sign-in failed!', 'soft', 'danger');
    }
  };

  const handleAppleLoginSuccess = async (response) => {
    try {
      const apple_login_response = await axios.post(
        `${BASEURL}/auth/user-apple-signin`,
        response,
        APIHeaderCors
      );
      Auth?.setUserEmail(apple_login_response?.data?.user?.email);
      Auth?.setUserId(apple_login_response?.data?.user?._id);
      Auth?.setToken(apple_login_response?.data?.token);

      if (apple_login_response?.data?.token) {
        Auth.removeGuest();
        setAuthenticated(true);
        showSnackbar(message.LOGIN_SUCCESS, 'soft', 'success');
        navigate('/');
      } else if (apple_login_response?.data?.status === 400)
        showSnackbar(apple_login_response?.data?.message, 'soft', 'danger');
      else showSnackbar('Apple sign-in failed!', 'soft', 'danger');
    } catch (error) {
      if (error?.message === 'Network Error')
        showSnackbar(message?.NETWORK_ERROR, 'soft', 'danger');
      else if (error) showSnackbar(message?.ERROR_TRY_AGAIN, 'soft', 'danger');
      else showSnackbar('Apple sign-in failed!', 'soft', 'danger');
    }
  };

  const handleGoogleLoginFailure = (error) => {
    showSnackbar('Google sign-in failed!', 'soft', 'danger');
  };

  const handleAppleLoginFailure = (error) => {
    showSnackbar('Apple sign-in failed!', 'soft', 'danger');
  };

  const googleLoginButtonRef = useRef(null);

  const handleCustomGoogleLoginClick = () => {
    if (googleLoginButtonRef.current) {
      googleLoginButtonRef.current.click();
    }
  };

  return (
    <>
      <div className={styledModule.login_right_inner_outer_info}>
        <div className={styledModule.login_text_main_title_top_div_inner}>
          <div className={styledModule.login_text_main_title}>
            <styles.Head48Tag className="text-center screen1280:text-left">
              Login
            </styles.Head48Tag>
          </div>
          <styles.Para16Tag
            className="text-center screen1280:text-left"
            $weight={500}
            color={'#2E3233'}
          >
            {'Enter your information here'}
          </styles.Para16Tag>
        </div>
        <div className="max-w-lg w-full mx-auto screen1280:max-w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={signInUserSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div
                  className={styledModule.form_input_list_email_pass_other_data}
                >
                  <div className={styledModule.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>Email Address</styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <typography.EmailIcon stroke={'#000000'} />
                      <Field
                        type="email"
                        placeholder="Enter your email address"
                        name="email"
                      />
                    </styles.FormInputOuter>
                    {touched.email && errors.email && (
                      <FormErrorMessage errors={errors.email} />
                    )}
                  </div>
                  <div className={styledModule.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>Password</styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <typography.AuthLoginPassword stroke={'#000000'} />
                      <Field
                        type="password"
                        placeholder="Enter password"
                        name="password"
                      />
                    </styles.FormInputOuter>
                    {touched.password && errors.password && (
                      <FormErrorMessage errors={errors.password} />
                    )}
                  </div>
                  <div className="text-end">
                    <Link
                      className={
                        styledModule.form_input_forget_text_main_pass_inner_link_tag
                      }
                      to={routesConfig?.forgot}
                    >
                      {'Forgot Password?'}
                    </Link>
                  </div>
                </div>
                <styles.ButtonWrapper width="100%" type="submit">
                  {loading ? message?.IS_LOADING : 'Login'}
                </styles.ButtonWrapper>
              </Form>
            )}
          </Formik>

          <div className={styledModule.form_data_or_text_div_line_main}>
            <span
              className={styledModule.form_line_para_or_main_div_add_after}
            />
            <p className={styledModule.form_line_para_or_main_div_add}>OR</p>
            <span
              className={styledModule.form_line_para_or_main_div_add_before}
            />
          </div>

          <div className="flex justify-center items-center gap-4 flex-col w-full">
            <div className="relative w-full">
              <button className={styledModule.form_google_apple_ios_btn_inner}>
                <span className="mr-1">
                  <FcGoogle />
                </span>
                <div className="form_sign_google_para_main">
                  <styles.Para16Tag font={16} $weight={600}>
                    {'Sign in with Google'}
                  </styles.Para16Tag>
                </div>
              </button>
              <GoogleBtn>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_AUTH_KEY}
                  buttonText="Login with Google"
                  onSuccess={handleGoogleLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                  auto_select={false}
                  size="large"
                  width={isMobile ? '900px' : '300px'}
                  logo_alignment={'center'}
                />
              </GoogleBtn>
            </div>
            <AppleSignin
              className="apple-auth-btn w-full !rounded-[12px]"
              authOptions={{
                clientId: process.env.REACT_APP_APPLE_SIGNIN_CLIENTID,
                scope: 'email name',
                redirectURI: process.env.REACT_APP_PUBLIC_URL,
                nonce: 'Optics-haven-apple-sign-in',
                usePopup: true,
              }}
              buttonExtraChildren={'Sign in with Apple'}
              onSuccess={handleAppleLoginSuccess}
              onError={handleAppleLoginFailure}
            />
          </div>
          <div className={styledModule.form_continue_text_title_main_inner_}>
            <styles.Para16Tag color={'#A7ABAC'} center={'center'}>
              {'Don’t have a account?'}
            </styles.Para16Tag>
            <span className={styledModule.form_continue_text_title_guest_inner}>
              <Link
                className={
                  styledModule.form_input_forget_text_main_pass_inner_link_tag
                }
                to={routesConfig?.createAccount}
                state={state}
              >
                {'Create Account'}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const GoogleBtn = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
`;

export default LoginForm;
