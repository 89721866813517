import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import { BsInfoCircle } from 'react-icons/bs';
import { MdDone } from 'react-icons/md';
import { IoWarningOutline } from 'react-icons/io5';

const CustomSnackbar = ({ open, message, onClose, variant, color }) => {
  return (
    <Snackbar
      variant={variant}
      autoHideDuration={5000}
      color={color}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      startDecorator={
        color === 'success' ? (
          <MdDone size={16} />
        ) : color === 'warning' ? (
          <IoWarningOutline size={16} />
        ) : (
          <BsInfoCircle size={16} />
        )
      }
      endDecorator={
        <Button onClick={onClose} size="sm" variant="soft" color={color}>
          Dismiss
        </Button>
      }
      sx={{ maxWidth: 560 }}
    >
      {message}
    </Snackbar>
  );
};

CustomSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
};

CustomSnackbar.defaultProps = {
  variant: 'soft',
  color: 'success',
};

export default CustomSnackbar;
