/* The above code is a React component called `VerifyEmailForm`. It is a form that allows users to
verify their email address by entering a verification code. */
import React from 'react';
import Timer from 'otp-timer';
import * as styles from 'assets/styles';
import * as typography from 'components/typography';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { verifyEmailSchema } from 'helper';
import { ApiForgotPassword, ApiVerifyEmailCode } from 'helper/API';
import { Auth } from 'helper/Auth';
import { message, routesConfig } from 'helper/config';
import styled from 'pages/desktopView/Auth/Auth.module.css';
import { FormErrorMessage } from '..';
import { useSnackbar } from 'context';

const VerifyEmailForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const userEmail = Auth?.getUserEmail() || null;

  const { showSnackbar } = useSnackbar();

  const initialValues = {
    otp: '',
  };

  /**
   * The `hiddenEmail` function takes an email address as input and returns a masked version of the
   * email address with the username partially hidden.
   * @param email - The `email` parameter is a string that represents an email address.
   * @returns The function `hiddenEmail` returns a masked email address where only the first two
   * characters of the username are visible, and the rest are replaced with asterisks. The masked email
   * address is formed by concatenating the masked username with the original domain.
   */
  const hiddenEmail = (email) => {
    const [username, domain] = email.split('@');
    const maskedUsername =
      username.substring(0, 2) + '*'.repeat(username.length - 2);

    return `${maskedUsername}@${domain}`;
  };

  const originalEmail = Auth?.getUserEmail();

  const maskedEmail = hiddenEmail(originalEmail);

  /**
   * The handleSubmit function is a JavaScript function that handles form submission, dispatches a
   * loginUserAction, resets the form, and resolves or rejects a promise based on the success or
   * failure of the login action.
   * @param values - The `values` parameter is an object that contains the form values submitted by the
   * user. It typically includes properties for each form field, with the field names as keys and the
   * user input as values.
   * @returns The handleSubmit function returns a Promise.
   */
  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const body = { email: userEmail, otp: values?.otp };

      // API
      const response = await ApiVerifyEmailCode(body);

      if (response?.data?.status === 200) {
        showSnackbar('Email verified successfully', 'soft', 'success');
        navigate(routesConfig?.resetPassword);
        resetForm();
      } else if (response?.data?.status === 400)
        showSnackbar(response?.data?.message, 'soft', 'danger');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * The function `resendOtp` is an asynchronous function that sends a validation code to the user's
   * email address for password reset.
   */
  const resendOtp = async () => {
    const userEmail = Auth?.getUserEmail() || null;
    try {
      // API
      const response = await ApiForgotPassword({ email: userEmail });

      if (response?.data?.status === 200) {
        initialValues.otp = '';
        showSnackbar(message.RESEND_OTP_SUCCESS, 'soft', 'success');
      } else if (response?.data?.status === 400)
        showSnackbar(response?.data?.message, 'soft', 'danger');
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styled.login_right_inner_outer_info}>
        <div className={styled.login_text_main_title_top_div_inner}>
          <div className={styled.login_text_main_title}>
            <styles.Head48Tag className="text-center screen1280:text-left">
              Verify Email
            </styles.Head48Tag>
          </div>
          <styles.Para16Tag
            className="text-center screen1280:text-left"
            $weight={500}
            color={'#2E3233'}
          >
            {'Please enter the Verification code sent to '}
            <span className="text-[#2e3233] font-black">{maskedEmail}</span>
          </styles.Para16Tag>
        </div>
        <div className="max-w-lg w-full mx-auto screen1280:max-w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={verifyEmailSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className={styled.form_input_list_email_pass_other_data}>
                  <div className="form_input_flex_div_mx_bottom">
                    <div className="form_inner_input_div_main">
                      <styles.FormInputLabel>
                        Email Address
                      </styles.FormInputLabel>
                      <styles.FormInputOuter>
                        <typography.EmailIcon stroke={'#000000'} />
                        <Field
                          type="email"
                          placeholder="Enter your email address"
                          name="email"
                          value={userEmail}
                          disabled={true}
                        />
                      </styles.FormInputOuter>
                    </div>
                  </div>
                </div>
                <div className={styled.form_input_list_email_pass_other_data}>
                  <div className={styled.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>
                      Validation Code
                    </styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <typography.AuthLoginPassword stroke={'#000000'} />
                      <Field
                        type="text"
                        placeholder="Enter your code"
                        name="otp"
                      />
                    </styles.FormInputOuter>
                    {touched.otp && errors.otp && (
                      <FormErrorMessage errors={errors.otp} />
                    )}
                  </div>
                </div>
                <div className={styled.timer_btn_value}>
                  <Timer
                    seconds={0}
                    minutes={2}
                    resend={resendOtp}
                    text={
                      <span className={styled.otp_verify_code_num_inner_second}>
                        Resend code in
                      </span>
                    }
                    ButtonText={
                      <span className="bg-transparent">
                        <styles.Para16Tag $weight={500} color="#219EBC">
                          Resend OTP
                        </styles.Para16Tag>
                      </span>
                    }
                  />
                </div>
                <styles.ButtonWrapper width="100%" type="submit">
                  {isLoading ? 'Loading...' : 'Verify'}
                </styles.ButtonWrapper>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default VerifyEmailForm;
