import React from 'react';
import { ButtonWrapper, Head32Tag, Para16Tag } from 'assets/styles';
import UserPrescriptionTable from './UserPrescriptionTable';
import { ApiPostUserPrescription } from 'helper/API';
import { message, routesConfig } from 'helper/config';
import { Auth } from 'helper/Auth';
import { usePrescriptionContext, useSnackbar } from 'context';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UseMyFramePrescriptionAction } from 'redux/actions';
import { CheckBoxCustom } from 'components/commonComponents';

const UserPrescriptionManually = ({ isUseMyFrame, complete }) => {
  const [first, setFirst] = React.useState(false);

  const {
    checked,
    setChecked,
    leftEyeAxisSwitch,
    setLeftEyeAxisSwitch,
    rightEyeAxisSwitch,
    setRightEyeAxisSwitch,
    leftEyeAttributes,
    setLeftEyeAttributes,
    rightEyeAttributes,
    setRightEyeAttributes,
    errors,
    setErrors,
    isProgressives,
    isProgressive,
    handleCheckboxChange,
  } = usePrescriptionContext();

  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const userID = Auth?.getUserId();

    if (first) {
      try {
        if (
          isProgressives &&
          (!leftEyeAttributes?.power || !rightEyeAttributes?.power)
        ) {
          showSnackbar(
            'You selected Progressive as primary use of your glasses, please input your ADD for the reading portion of your glasses',
            'soft',
            'danger'
          );
          return;
        }

        if (errors.isValid) {
          if (isUseMyFrame) {
            const body = {
              user_id: userID,
              left_eye_attributes: {
                axis: 1,
                ...leftEyeAttributes,
              },
              right_eye_attributes: {
                axis: 1,
                ...rightEyeAttributes,
              },
            };

            dispatch(UseMyFramePrescriptionAction(body));

            complete();
          } else {
            const { status } = await ApiPostUserPrescription(
              userID,
              leftEyeAttributes,
              rightEyeAttributes
            );

            if (status === 200) {
              showSnackbar(
                message?.PRESCRIPTION_SUBMITTED_SUCCESS,
                'soft',
                'success'
              );

              setChecked(false);
              setLeftEyeAxisSwitch(true);
              setRightEyeAxisSwitch(true);
              setLeftEyeAttributes({
                axis: '',
                cyl: 0.0,
                sph: 0.0,
                pd: 63,
                power: 0.75,
              });
              setRightEyeAttributes({
                axis: '',
                cyl: 0.0,
                sph: 0.0,
                pd: 63,
                power: 0.75,
              });

              if (!isUseMyFrame) navigate(routesConfig.prescription);
            }
          }
        }
      } catch (error) {
        if (error)
          showSnackbar(error?.message?.ERROR_TRY_AGAIN, 'soft', 'danger');
        else if (error?.message === 'Network Error')
          showSnackbar(error?.message?.NETWORK_ERROR, 'soft', 'danger');
        else throw error;
      }
    } else {
      showSnackbar(
        'Check the box before proceeding to the next page.',
        'soft',
        'danger'
      );
    }
  };

  return (
    <>
      <div>
        <Head32Tag className="pb-10" color={'#2E3233'} $weight={600}>
          Enter Prescription Manually
        </Head32Tag>

        {/* Table */}
        <UserPrescriptionTable
          checked={checked}
          leftEyeAxisSwitch={leftEyeAxisSwitch}
          setLeftEyeAxisSwitch={setLeftEyeAxisSwitch}
          rightEyeAxisSwitch={rightEyeAxisSwitch}
          setRightEyeAxisSwitch={setRightEyeAxisSwitch}
          leftEyeAttributes={leftEyeAttributes}
          setLeftEyeAttributes={setLeftEyeAttributes}
          rightEyeAttributes={rightEyeAttributes}
          setRightEyeAttributes={setRightEyeAttributes}
          handleCheckboxChange={handleCheckboxChange}
          errors={errors}
          setErrors={setErrors}
          isProgressive={isProgressive}
        />

        <label className="cursor-pointer flex items-baseline gap-[10px] py-4">
          <CheckBoxCustom
            id="first"
            name="first"
            checked={first}
            onChange={() => setFirst(!first)}
          />
          <Para16Tag className="italic !font-bold">
            By selecting this box, the customer affirms that they are 18 years
            and older and that their eye prescription is correct, was obtained
            from a licensed optometrist/ophthalmologist and valid under state
            law. Optics Haven assumes no liability for any inaccuracies in the
            prescription provided on this site.
          </Para16Tag>
        </label>

        <Para16Tag color="red">
          Note: To avoid delay in processing your orders, Residents of
          California and Nevada should upload their prescription as we cannot
          ship self reported prescriptions to their state
        </Para16Tag>
      </div>
      <div className="flex items-center justify-center mt-4">
        <ButtonWrapper onClick={handleSubmit}>
          Submit Prescription
        </ButtonWrapper>
      </div>
    </>
  );
};

export default UserPrescriptionManually;
