import axios from 'axios';
import { BASEURL, getHeaders } from 'helper/API';
import { ErrorToast } from 'helper/Toast';
import { CREATE_ORDER_REQUEST, CREATE_ORDER_SUCCESS } from 'redux/constants';
import { routesConfig } from 'helper/config';
import { sessionStorageHandler } from 'utils';
import { STORAGE_KEYS } from 'helper/APP';

export const createOrder =
  (body, navigate, isNavigate, isUseYourFrame, showSnackbar) =>
  async (dispatch) => {
    try {
      const headers = await getHeaders();
      dispatch({ type: CREATE_ORDER_REQUEST });

      const { data } = await axios.post(`${BASEURL}/order`, body, { headers });

      dispatch({ type: CREATE_ORDER_SUCCESS, payload: data?.orderData?.order });

      if (data?.paymentRes?.message?.status === 200) {
        sessionStorageHandler.removeItem(STORAGE_KEYS.promocodeDetail);
        showSnackbar(data.paymentRes?.message?.message, 'soft', 'success');
        if (isNavigate)
          navigate(routesConfig?.paymentSuccess, {
            state: { paymentSuccess: true },
          });
        if (isUseYourFrame) {
          navigate(routesConfig.useYourFrameSuccess, {
            state: {
              paymentSuccess: true,
              orderDetailId: data?.orderData?.order?._id,
            },
          });
        }
      }

      return data;
    } catch (error) {
      sessionStorageHandler.removeItem(STORAGE_KEYS.promocodeDetail);
      if (error) ErrorToast(error.message?.ERROR_TRY_AGAIN);
      else if (error?.message === 'Network Error')
        ErrorToast(error.message?.NETWORK_ERROR);
      else throw error;
    }
  };
