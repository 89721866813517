import { useState, useEffect } from 'react';
import useCart from './useCart';
import { Auth } from 'helper/Auth';
import { useSnackbar } from 'context';
import { sessionStorageHandler } from 'utils';
import { STORAGE_KEYS } from 'helper/APP';

const usePromoCode = (initialFinalAmount, apiPromoCodeCheck) => {
  const [promoCode, setPromoCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [promoCodeDetail, setPromoCodeDetail] = useState(null);
  const [finalTotalAmount, setFinalTotalAmount] = useState(initialFinalAmount);

  const { cartItemsList } = useCart();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    setFinalTotalAmount(initialFinalAmount);
  }, [initialFinalAmount]);

  const applyPromoCode = async () => {
    let items = [];
    cartItemsList.length &&
      cartItemsList.forEach((element) => {
        const newItem = {
          prescription_data: element?.prescription_data,
          product_frame_detail_id: element?.product_frame_detail_id,
          quantity: element?.quantity,
          lenses: {
            lense_id: element?.lenses?.lense_id?._id,
            material_type_id: element?.lenses?.material_type_id?._id,
            singlevision_id: element?.lenses?.singlevision_id?._id,
            lense_type_id: element?.lenses?.lense_type_id?._id,
          },
          item_price: element?.mainPrice,
          frame_size_id: element?.frame_size_id,
          product_frame_id: element?.id,
        };
        items.push(newItem);
      });
    try {
      const body = {
        promo_code: promoCode,
        cartPayableAmt: initialFinalAmount,
        cart: {
          user_id: Auth.getUserId(),
          items,
        },
      };

      const response = await apiPromoCodeCheck(body, showSnackbar);

      if (response?.data?.message?.status === 200) {
        const promoCode = response?.data?.promoCode;
        const referral = response?.data?.referral;

        const code = {
          _id: response?.data?.promoCode?._id || response?.data?.referral?._id,
          discount_per:
            response?.data?.promoCode?.discount_per ||
            response?.data?.referral?.refer_discount,
        };
        const discount_price = initialFinalAmount * (code?.discount_per / 100);

        setPromoCodeDetail(promoCode || referral);
        sessionStorageHandler.setItem(
          STORAGE_KEYS.promocodeDetail,
          promoCode || referral
        );
        setDiscountAmount(discount_price);
        const finalAmountAfterDiscount = initialFinalAmount - discount_price;
        setFinalTotalAmount(finalAmountAfterDiscount);

        return response?.data?.message?.message;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    promoCode,
    setPromoCode,
    discountAmount,
    promoCodeDetail,
    finalTotalAmount,
    applyPromoCode,
  };
};

export default usePromoCode;
