import React, { useState } from 'react';
import { Auth } from 'helper/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { getLensInfo, removeItemFromCart } from 'redux/actions';
import { STORAGE_KEYS } from 'helper/APP';
import useQueryFetch from './useQueryFetch';
import { useSnackbar } from 'context';
import { sessionStorageHandler } from 'utils';

const useCart = () => {
  const [removeIndex, setRemoveIndex] = React.useState(null);
  const [removeAccessoriesIndex, setAccessoriesRemoveIndex] =
    React.useState(null);
  const [promoCodeData, setPromoCodeData] = React.useState(null);
  const [selectedItemID, setSelectedItemID] = React.useState(null);
  const [cartItemsList, setCartItemsList] = useState([]);
  const [accessoriesList, setAccessoriesList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isAccessories, setIsAccessories] = useState(false);
  const [wishListData, setWishListData] = useState({
    product_frame_detail_id: '',
    product_size_id: '',
  });

  const { showSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const lense = useSelector((store) => store.lensSelection.lense);

  const userId = Auth.getUserId();

  const { data, fetchData, isLoading } = useQueryFetch(
    'cart',
    `/carts/user/${userId}`
  );

  const accessoryData = data?.[0]?.items.filter((item) => item.accessory_id);

  const handleRemoveItemFun = () => {
    handleRemoveItem();
    handleClose();
  };

  // modal open close
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIsAccessories(false);
  };

  React.useEffect(() => {
    if (Auth.isLogin()) {
      const itemList = [];
      data?.[0]?.items?.forEach(
        ({
          product_frame_detail_id: product_frame,
          product_frame_id: product,
          frame_size_id,
          lenses,
          _id: itemID,
          quantity,
          prescription_data,
          item_price: mainPrice,
        }) => {
          if (product) {
            const frameIndex = product_frame?.frame_sizes.findIndex(
              (x) => x?.frame_size_id?._id === frame_size_id
            );
            const {
              product_frame_id: id,
              thumbnail_picture: image,
              color_id,
              frame_sizes,
              description: details,
            } = product_frame;
            const { product_name: title, price } = product;
            const {
              lense_type_id,
              material_type_id,
              singlevision_id,
              lense_id,
            } = lenses;
            itemList.push({
              id,
              image,
              title,
              details,
              price,
              subPrice: 20,
              color: color_id?.color_name,
              quantity,
              mainPrice,
              itemID,
              lens_id: lense_id?.prescription_type,
              lens_id_name: lense_id?.prescription_type,
              lens_id_price: lense_id?.price,
              lense_type_id: lense_type_id?._id,
              lense_type: lense_type_id?.lenses_type,
              lense_type_price: lense_type_id?.price,
              lense_material_id: material_type_id?._id,
              lense_material: material_type_id?.lens_material_type,
              lense_material_price: material_type_id?.price,
              singlevision_id: singlevision_id?.single_vision_type,
              singlevision_id_price: singlevision_id?.price,
              singlevision_type: singlevision_id?.single_vision_type,
              lenses,
              prescription_data: prescription_data,
              model_no: product.model_no,
              product_frame_detail_id: product_frame._id,
              size: frame_sizes?.[frameIndex]?.frame_size_id?.frame_size ?? '',
              product_sku_no: frame_sizes?.[frameIndex]?.sku_no,
              product_size_id: frame_sizes?.[frameIndex]?._id ?? '',
            });
          }
        }
      );
      if (itemList.length) {
        setCartItemsList(itemList);
      }
    }

    if (!Auth.isLogin()) {
      // ? FRAME
      const cartList = JSON.parse(
        sessionStorageHandler.getItem(STORAGE_KEYS.cartList) || '[]'
      );
      if (cartList?.length) {
        const itemList = [];
        cartList?.forEach(
          ({
            product_frame_detail_id: product_frame,
            product_frame_id: { product_frame: product },
            frame_size_id,
            lenses,
            _id: itemID,
            quantity,
            prescription_data,
            item_price: mainPrice,
          }) => {
            if (product) {
              const frameIndex = product_frame?.frame_sizes.findIndex(
                (x) => x?.frame_size_id?._id === frame_size_id
              );
              const {
                product_frame_id: id,
                thumbnail_picture: image,
                color_id,
                frame_sizes,
                description: details,
              } = product_frame;
              const { product_name: title, price } = product;
              const {
                lense_type_id,
                material_type_id,
                singlevision_id,
                lense_id,
              } = lenses;
              const lenseData = lense?.lensesResponseData?.filter(
                (lenseItem) => lenseItem?._id === lense_id
              )?.[0];
              const lense_type = lense?.lensesTypeResponseData?.filter(
                (lenseItem) => lenseItem?._id === lense_type_id
              )?.[0];
              const material_type = lense?.materialTypeResponseData?.filter(
                (lenseItem) => lenseItem?._id === material_type_id
              )?.[0];
              const singlevision = lense?.singleVisionResponseData?.filter(
                (lenseItem) => lenseItem?._id === singlevision_id
              )?.[0];
              itemList.push({
                id,
                image,
                title,
                details,
                price,
                subPrice: 20,
                color: color_id?.color_name,
                size:
                  frame_sizes?.[frameIndex]?.frame_size_id?.frame_size ?? '',
                product_sku_no: frame_sizes?.[frameIndex]?.sku_no,
                product_size_id: frame_sizes?.[frameIndex]?._id ?? '',
                quantity,
                mainPrice,
                itemID,
                lens_id: lenseData?.prescription_type,
                lens_id_name: lense_id?.prescription_type,
                lens_id_price: lenseData?.price,
                lense_type_id: lense_type_id?._id,
                lense_type: lense_type?.lenses_type,
                lense_type_price: lense_type?.price,
                lense_material_id: material_type_id?._id,
                lense_material: material_type?.lens_material_type,
                lense_material_price: material_type?.price,
                singlevision_id: singlevision?.single_vision_type,
                singlevision_id_price: singlevision?.price,
                singlevision_type: singlevision_id?.single_vision_type,
                lenses: {
                  lense_type_id: lense_type,
                  material_type_id: material_type,
                  singlevision_id: singlevision,
                  lense_id: lenseData,
                },
                prescription_data: prescription_data,
                model_no: product.model_no,
                product_frame_detail_id: product_frame._id,
              });
            }
          }
        );
        if (itemList.length) {
          setCartItemsList(itemList);
        }
      }
    }
  }, [data, lense]);

  React.useEffect(() => {
    if (!Auth.isLogin()) {
      // ? ACCESSORIES
      const accessoriesList = JSON.parse(
        sessionStorageHandler.getItem(STORAGE_KEYS.accessories) || '[]'
      );
      if (accessoriesList.length) {
        let itemList = [];
        accessoriesList.forEach((item) => {
          itemList.push({
            _id: item._id,
            name: item.name,
            price: item.price,
            image: item.image,
            colors: item.colors,
            quantity: item.quantity,
          });
        });

        if (itemList.length) {
          setAccessoriesList(itemList);
        }
      }
    }
  }, []);

  const removeItemFromLocalStorage = () => {
    // Remove Frame
    const cartList = JSON.parse(
      sessionStorageHandler.getItem(STORAGE_KEYS.cartList) || '[]'
    );
    const newData = cartList.filter((_, index) => index !== removeIndex);
    setCartItemsList((prev) => {
      return prev.filter((_, index) => index !== removeIndex);
    });
    sessionStorageHandler.setItem(
      STORAGE_KEYS.cartList,
      JSON.stringify(newData)
    );

    // Remove Accessories
    const getAccessoriesList = JSON.parse(
      sessionStorageHandler.getItem(STORAGE_KEYS.accessories) || '[]'
    );
    if (getAccessoriesList?.length) {
      const accessoriesNewData = getAccessoriesList.filter(
        (_, index) => index !== removeAccessoriesIndex
      );
      setAccessoriesList((prev) => {
        return prev.filter((_, index) => index !== removeAccessoriesIndex);
      });
      sessionStorageHandler.setItem(
        STORAGE_KEYS.accessories,
        JSON.stringify(accessoriesNewData)
      );
    }
  };

  const handleRemoveItem = async () => {
    if (Auth.getToken()) {
      setCartItemsList((prev) =>
        prev.filter((item) => item.id === selectedItemID)
      );

      // call delete API
      await dispatch(removeItemFromCart(userId, selectedItemID, showSnackbar));
      fetchData();
    } else {
      removeItemFromLocalStorage();
    }
  };

  React.useEffect(() => {
    dispatch(getLensInfo(null, showSnackbar));
  }, [dispatch]);

  const accessoriesMap = Auth?.isLogin() ? accessoryData : accessoriesList;

  // Calculate accessory and frame Total price
  const totalMainPrice = cartItemsList.reduce(
    (total, current) => total + (current?.mainPrice || 0),
    0
  );
  const totalAccessoriesPrice = accessoriesList.reduce(
    (total, current) => total + (current?.price || 0),
    0
  );
  const totalPrice = totalMainPrice + totalAccessoriesPrice;

  const finalAmount = Auth.isLogin() ? data?.[0]?.final_amount : totalPrice;
  const totalItems = Auth.getToken()
    ? data?.[0]?.items?.length && data?.[0]?.items?.length
    : cartItemsList?.length + accessoriesMap?.length;

  return {
    data,
    cartItemsList,
    accessoriesList,
    finalAmount,
    handleRemoveItem,
    accessoriesMap,
    accessoryData,
    setSelectedItemID,
    setRemoveIndex,
    setAccessoriesRemoveIndex,
    totalItems,
    handleOpen,
    wishListData,
    setWishListData,
    setIsAccessories,
    open,
    isAccessories,
    handleRemoveItemFun,
    handleClose,
    isLoading,
    promoCodeData,
    setPromoCodeData,
  };
};

export default useCart;
