import { CommonApi } from 'helper/API';
import { useQuery } from 'react-query';

const useFrameData = () => {
  const { data: frameTypeList } = useQuery(
    'frameTypes',
    () => CommonApi.get('/frame-type'),
    {
      staleTime: 30000,
    }
  );

  const { data: frameShapeList } = useQuery(
    'frameShapes',
    () => CommonApi.get('/frame-shapes'),
    {
      staleTime: 30000,
    }
  );

  const { data: frameColorList } = useQuery(
    'frameColors',
    () => CommonApi.get('/color/?is_frame_color=true'),
    {
      staleTime: 30000,
    }
  );

  const { data: frameSizeList } = useQuery(
    'frameSizes',
    () => CommonApi.get('/frame-size'),
    {
      staleTime: 30000,
    }
  );

  return {
    frameTypeList: frameTypeList?.data || [],
    frameShapeList: frameShapeList?.data || [],
    frameColorList: frameColorList?.data || [],
    frameSizeList: frameSizeList?.data || [],
  };
};

export default useFrameData;
