/* This code is importing the `axios` library and creating an instance of it called `UserAuthAPI`. */
import axios from 'axios';
import { API_CONFIG } from './API.config';

export const BASEURL = API_CONFIG;

export const UserAuthAPI = axios.create({
  baseURL: `${BASEURL}/auth`,
});

export const CommonApi = axios.create({
  baseURL: `${BASEURL}/common`,
});

export const UserAPI = axios.create({
  baseURL: `${BASEURL}/users`,
});

export const ReviewAPI = axios.create({
  baseURL: `${BASEURL}/reviews`,
});

export const CartAPI = axios.create({
  baseURL: `${BASEURL}/carts`,
});

export const ManageNotificationAPI = axios.create({
  baseURL: `${BASEURL}/notification-settings`,
});

export const CategoryAPI = axios.create({
  baseURL: `${BASEURL}/categories`,
});

export const paymentAPI = axios.create({
  baseURL: `${BASEURL}/payments`,
});

export const productFrameAPI = axios.create({
  baseURL: `${BASEURL}/product-frames-detail`,
});

export const useMyFrameAPI = axios.create({
  baseURL: `${BASEURL}/use-my-frame`,
});

export const recentViewedAPI = axios.create({
  baseURL: `${BASEURL}/recent-activities`,
});

export const UserNotifyAPI = axios.create({
  baseURL: `${BASEURL}/user-notify`,
});

export const AdminAPI = axios.create({
  baseURL: `${BASEURL}`,
});

export const TransportServiceAPI = axios.create({
  baseURL: `${BASEURL}/transport-service`,
});

export const PromoCodeAPI = axios.create({
  baseURL: `${BASEURL}/promo-code`,
});
