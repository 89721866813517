/* These lines of code are defining constants in JavaScript using the `export const` syntax. Each
constant represents a specific action or value in the application. */
export const GET_LENS_INFO_REQUEST = 'GET_LENS_INFO_REQUEST';
export const SET_SELECTED_LENS_INFO = 'SET_SELECTED_LENS_INFO';
export const GET_LENS_INFO_SUCCESS = 'GET_LENS_INFO_SUCCESS';
export const GET_LENS_DATA = 'GET_LENS_DATA';
export const LENS_COLOR_OPTIONS = 'LENS_COLOR_OPTIONS';
export const ADD_COLOR_STEP = 'ADD_COLOR_STEP';
export const REMOVE_COLOR_STEP = 'REMOVE_COLOR_STEP';
export const REDUCE_SELECTED_LENS_INFO_PRICE =
  'REDUCE_SELECTED_LENS_INFO_PRICE';
