const localStorageHandler = {
  setItem(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      throw new Error();
    }
  },

  getItem(key) {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : null;
    } catch (error) {
      throw new Error();
    }
  },

  removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      throw new Error();
    }
  },

  clearItem() {
    try {
      localStorage.clear();
    } catch (error) {
      throw new Error();
    }
  },
};

export default localStorageHandler;
