/**
 * The ReviewCard component is a React component that displays a review with user information, rating,
 * description, and like/dislike buttons.
 * @returns The ReviewCard component is returning a JSX element.
 */
import React, { useState } from 'react';
import { Para16Tag } from 'assets/styles';
import * as typography from 'components/typography';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { postReviewLikeDislike } from 'redux/actions';
import { Auth } from 'helper/Auth';
import { ReviewRating } from '.';

const ReviewCard = ({ review }) => {
  const [like, setLike] = useState(review?.liked_users?.length);
  const [dislike, setDislike] = useState(review?.disliked_users?.length);
  const [likeActive, setLikeActive] = useState(false);
  const [dislikeActive, setDislikeActive] = useState(false);
  // const [likeDislike, setLikeDislike] = React.useState({
  //   is_like: 0,
  //   is_dislike: 0,
  // });

  const user_id = Auth?.getUserId();

  const name = review?.user_id?.username?.charAt(0)?.toUpperCase();
  const username =
    review?.user_id?.username?.charAt(0)?.toUpperCase() +
    review?.user_id?.username?.slice(1);
  const reviewDate = moment(review?.created_at).format('D MMM, YYYY');

  const dispatch = useDispatch();

  const handleSubmit = (review_id, action) => {
    try {
      // if (action === 'like') {
      //   // setLikeDislike((prev) => ({
      //   //   ...prev,
      //   //   is_like: likeDislike.is_like + 1,
      //   // }));
      //   handleLike();
      // } else if (action === 'dislike') {
      //   handleDislike();
      // }

      // API
      dispatch(postReviewLikeDislike(review_id, action));
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  };

  const handleLike = () => {
    if (dislikeActive) {
      setLikeActive(true);
      setDislikeActive(false);
      setLike(like + 1);
      setDislike(dislike - 1);

      handleSubmit(review?._id, 'dislike');
    } else {
      setLikeActive(!likeActive);
      setLike(likeActive ? like - 1 : like + 1);

      handleSubmit(review?._id, 'like');
    }
  };

  const handleDislike = () => {
    if (likeActive) {
      setDislikeActive(true);
      setLikeActive(false);
      setDislike(dislike + 1);
      setLike(like - 1);

      handleSubmit(review?._id, 'like');
    } else {
      setDislikeActive(!dislikeActive);
      setDislike(dislikeActive ? dislike - 1 : dislike + 1);

      handleSubmit(review?._id, 'dislike');
    }
  };

  return (
    <>
      <div className="mb-6">
        <div className="review7">
          <div className="review_tab_frame-parent34">
            <div className="frame-parent35">
              <div className="review_tab_m-wrapper5">
                <Para16Tag color={'#219EBC'} $weight={600}>
                  {name}
                </Para16Tag>
              </div>
              <div className="mira-shah-parent5">
                <Para16Tag $weight={600}>{username}</Para16Tag>
                <p className="review_tab_mira_shah_add_date_num">
                  {reviewDate}
                </p>
              </div>
            </div>
            <div className="review_tab_num_add_inner">
              <ReviewRating
                name="read-only"
                value={review?.rating}
                readOnly
                max={5}
                size={24}
              />
            </div>
          </div>
          <Para16Tag color={'#2E3233'}>{review?.comment}</Para16Tag>
          <div className="review_tab_frame-parent36">
            <div className="review_tab_frame_parent37">
              <div className="review_tab_add_number_list_num_inner">
                <button
                  // onClick={() => handleSubmit(review?._id, 'like')}
                  onClick={() => {
                    handleLike();
                    handleSubmit(review?._id, 'like');
                  }}
                  className="review_tab_done_btn_add flex items-center gap-2"
                >
                  <typography.ReviewTabDone
                    color={
                      review?.liked_users?.some(
                        (x) => x?.user_id === user_id
                      ) && '#219EBC'
                    }
                  />
                  <Para16Tag color={'#2E3233'} $weight={500}>
                    {/* {review?.liked_users?.length} */}
                    {like}
                  </Para16Tag>
                </button>
              </div>
              <div className="review_tab_add_number_list_num_inner">
                <button
                  // onClick={() => handleSubmit(review?._id, 'dislike')}
                  onClick={() => {
                    handleDislike();
                    handleSubmit(review?._id, 'dislike');
                  }}
                  className="review_tab_done_btn_add flex items-center gap-2"
                >
                  <typography.ReviewTabDoneDown
                    color={
                      review?.disliked_users?.some(
                        (x) => x?.user_id === user_id
                      ) && '#219EBC'
                    }
                  />
                  <Para16Tag color={'#2E3233'} $weight={500}>
                    {/* {review?.disliked_users?.length} */}
                    {dislike}
                  </Para16Tag>
                </button>
              </div>
            </div>
            <button>
              <typography.ProductDetailsSendIcons />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewCard;
