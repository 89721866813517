/* The code you provided is a Redux reducer function. It defines the behavior of the
`frameMaterialReducer` when different actions are dispatched. */
import {
  LIST_ALL_FRAME_TYPES_REQUEST,
  LIST_ALL_FRAME_TYPES_SUCCESS,
  LIST_ALL_FRAME_TYPES_FAILURE,
} from 'redux/constants';

const initialState = {
  frameTypeList: [],
  loading: false,
  error: null,
};

export const frameTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ALL_FRAME_TYPES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_FRAME_TYPES_SUCCESS:
      return {
        ...state,
        frameTypeList: action?.payload,
        loading: false,
      };

    case LIST_ALL_FRAME_TYPES_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};
