/* The code is a React component called `ImgFadeInOut`. It is a slideshow component that fades in and
out between a series of images. */
import React from 'react';
import { Link } from 'react-router-dom';
import { routesConfig } from 'helper/config';

const ImgFadeInOut = ({ image }) => {
  const [i, setI] = React.useState(0);

  const imgContainerRef = React.useRef(null);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const prevShowing = i;
      const currentShowing = prevShowing === image.length - 1 ? 0 : i + 1;

      const images = imgContainerRef.current.children;
      images[prevShowing].style.display = 'none';
      images[currentShowing].style.display = 'block';

      setI(currentShowing);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [i, image]);

  return (
    <>
      <div ref={imgContainerRef}>
        {image.map((item, index) => (
          <Link
            key={index}
            to={routesConfig?.productList}
            style={{ display: index === 0 ? 'block' : 'none' }}
          >
            <img className="w-full" src={item} alt={image} />
          </Link>
        ))}
      </div>
    </>
  );
};

export default ImgFadeInOut;
//
