/**
 * This JavaScript code exports several functions for making API calls to retrieve and manipulate
 * product data.
 * @returns The functions in this code are returning the response from the API calls made using the
 * `CommonApi` and `ReviewAPI` objects. If the API call is successful, the response data is returned.
 * If there is an error, the error object is returned.
 */
import axios from 'axios';
import { ErrorToast } from '../Toast';
import { APIHeaderCors, getHeaders } from './API.header';
import * as APIBase from './API.base';
import { Auth } from 'helper/Auth';
import { message } from 'helper/config';

const BasePath = '/';

// GET /api/ Common Api with no Authentication Header
export const ApiGetCommon = async (type, body) => {
  try {
    // API
    const response = await APIBase.CommonApi.get(type, body, APIHeaderCors);
    return response;
  } catch (error) {
    throw new Error();
  }
};

// POST /api/ Common Api with no Authentication Header
export const ApiPostCommon = async (type, body) => {
  try {
    // API
    const response = await APIBase.CommonApi.post(type, body, APIHeaderCors);

    return response;
  } catch (error) {
    throw new Error();
  }
};

/**
 * The function `makeAuthenticatedRequest` makes an authenticated HTTP request using the provided
 * method, type, id, and optional body.
 * @param method - The HTTP method to be used for the request (e.g., GET, POST, PUT, DELETE).
 * @param type - The `type` parameter in the `makeAuthenticatedRequest` function represents the type of
 * resource you want to make a request to. It could be a string value like "users", "posts", or any
 * other resource type in your API.
 * @param id - The `id` parameter is used to specify the identifier of the resource you want to make a
 * request to. It is typically used in the URL to identify a specific resource.
 * @param [body=null] - The `body` parameter is an optional parameter that represents the data to be
 * sent in the request body. It is used for methods like POST or PUT where you need to send data to the
 * server. If no `body` is provided, it defaults to `null`.
 * @returns The function `makeAuthenticatedRequest` returns a promise that resolves to the response
 * object from the axios request.
 */
const makeAuthenticatedRequest = async (method, type, id, body = null) => {
  try {
    const headers = await getHeaders();

    // API URL
    const url = APIBase.BASEURL + type + BasePath + id;

    // API
    const response = await axios({
      method,
      url,
      headers,
      body,
    });

    return response;
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;

    throw new Error();
  }
};

// API - Get with Authorization Header
export const ApiGetByIdAuthorization = async (type, id) => {
  return makeAuthenticatedRequest('get', type, id);
};

// API POST with Authentication Header
export const ApiPost = async (type, body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.post(APIBase.BASEURL + type, body, {
      headers,
    });

    return response;
  } catch (error) {
    throw new Error();
  }
};

// API Get with Authentication Header
export const ApiGet = async (type) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.get(APIBase.BASEURL + type, {
      headers,
    });

    return response;
  } catch (error) {
    throw new Error();
  }
};

// API Update || PUT
export const ApiPut = async (type, id, body) => {
  // return makeAuthenticatedRequest("put", type, id, body);

  try {
    const headers = await getHeaders();

    // API
    const response = await axios.put(
      APIBase.BASEURL + type + BasePath + id,
      body,
      {
        headers,
      }
    );

    return response;
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

// API Delete By Id
export const ApiDelete = async (type, id) => {
  return makeAuthenticatedRequest('delete', type, id);
};

// API Post - "application/json"
export const ApiPostJSON = async (type, body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.post(APIBase.BASEURL + type, body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

// API Forgot Password
export const ApiForgotPassword = async (body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await APIBase.UserAuthAPI.post('/forgot-password', body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

// API Verify Reset Otp
export const ApiVerifyEmailCode = async (body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await APIBase.UserAuthAPI.post('/verify-reset-otp', body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

// API Verify Reset Otp
export const ApiResetPassword = async (body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await APIBase.UserAuthAPI.post('/reset-password', body, {
      headers,
    });
    return response;
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

// Get Recommended Products By category
export const ApiRecommendedProducts = async (category) => {
  try {
    const body = {
      in_stock: true,
      is_active: true,
    };
    // API
    const response = await APIBase.CommonApi.post(
      `/product-frames-detail/?q=${category}`,
      body
    );
    return response;
  } catch (error) {
    throw new Error();
  }
};

// API POST with Authentication Header
export const addRecentlyViewedProduct = async (body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await APIBase.recentViewedAPI.post('/', body, {
      headers,
    });

    return response;
  } catch (error) {
    throw new Error();
  }
};

// API get by user id
export const ApiGetByUserId = async (type, id) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.get(APIBase.BASEURL + type + '/' + id, {
      headers,
    });
    return response;
  } catch (error) {
    throw new Error();
  }
};

// API get by id
export const ApiGetById = async (type, id) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.get(APIBase.BASEURL + type + '/' + id, {
      headers,
    });
    return response;
  } catch (error) {
    throw new Error();
  }
};

// API delete by user id
export const ApiDeleteByUserId = async (type, userId) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await axios.delete(APIBase.BASEURL + type + '/' + userId, {
      headers,
    });
    return response;
  } catch (error) {
    throw new Error();
  }
};

export const ApiPostUserPrescription = async (
  userID,
  leftEyeAttributes,
  rightEyeAttributes
) => {
  try {
    const body = {
      user_id: userID,
      left_eye_attributes: {
        axis: 1,
        ...leftEyeAttributes,
      },
      right_eye_attributes: {
        axis: 1,
        ...rightEyeAttributes,
      },
    };

    const headers = await getHeaders();

    const response = await axios.post(
      `${APIBase.BASEURL}/user-prescriptions`,
      body,
      {
        headers,
      }
    );

    const {
      data: {
        message: { status },
      },
    } = response;

    return { status, response };
  } catch (error) {
    throw new Error();
  }
};

export const ApiPostDocsPrescription = async (prescriptionImages) => {
  try {
    const userID = Auth?.getUserId();

    const headers = await getHeaders();

    const body = {
      image_prescription: prescriptionImages,
      is_image_prescription: true,
      user_id: userID,
    };

    const response = await axios.post(
      `${APIBase.BASEURL}/user-prescriptions`,
      body,
      {
        headers,
      }
    );

    const {
      data: {
        message: { status },
      },
    } = response;

    return { status, response };
  } catch (error) {
    throw new Error();
  }
};

// API Create Shipment
export const ApiCreateShipment = async (body) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await APIBase.TransportServiceAPI.post(
      '/user/create-shipment-label',
      body,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    if (error) ErrorToast(message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(message?.NETWORK_ERROR);
    else throw error;
  }
};

// API POST with Authentication Header
export const ApiPromoCodeCheck = async (body, showSnackbar) => {
  try {
    const headers = await getHeaders();

    // API
    const response = await APIBase.PromoCodeAPI.post('/check-promocode', body, {
      headers,
    });

    return response;
  } catch (error) {
    if (error?.response?.data?.message?.message === 'Promo code is invalid.')
      showSnackbar('Invalid code.', 'soft', 'danger');
    else {
      showSnackbar(error?.response?.data?.message?.message, 'soft', 'danger');
    }
  }
};
