/* The code is defining a Redux reducer function called `cartReducer`. This reducer handles actions
related to a shopping cart. */
import {
  ADD_PRODUCT_TO_CART_SUCCESS,
  CLEAR_CART_DATA,
  GET_CART_PRODUCTS,
  GET_CART_PRODUCTS_REQUEST,
  REMOVE_ITEM_FROM_CART,
} from 'redux/constants';

const initialState = {
  productList: {},
  loading: false,
  totalNumberOfItems: 0,
  totalCartPrice: 0,
  cartDataArr: [],
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case GET_CART_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CART_PRODUCTS: {
      // let totalCartPrice = 0;
      // action.payload?.items.forEach((element) => {
      //   totalCartPrice += parseInt(element.item_price);
      // });
      // return {
      //   ...state,
      //   loading: false,
      //   cartDataArr: action.payload,
      //   productList: action.payload,
      //   totalNumberOfItems: action.payload?.items?.length,
      //   totalCartPrice,
      //   cartId: action.payload?._id,
      // };
      // let totalCartPrice = 0;
      // action.payload?.items.forEach((element) => {
      //   totalCartPrice += parseInt(element.item_price);
      // });
      return {
        ...state,
        loading: false,
        cartDataArr: action.payload,
        // productList: {},
        // totalNumberOfItems: action.payload?.items?.length,
        // totalCartPrice,
        // cartId: action.payload?._id,
      };
    }

    case REMOVE_ITEM_FROM_CART: {
      // const itemToBeRemoved = state.productList.items.find(
      //   (x) => x._id === action.payload
      // );

      return {
        ...state,
        // productList: {
        //   ...state.productList,
        //   items: state.productList?.items.filter(
        //     (x) => x._id !== action.payload
        //   ),
        // },
        cartDataArr: state.cartDataArr?.items?.filter(
          (x) => x._id !== action?.payload
        ),
        // totalNumberOfItems: state.totalNumberOfItems - 1,
        // totalCartPrice: state.totalCartPrice - itemToBeRemoved.item_price,
      };
    }

    case CLEAR_CART_DATA:
      return {
        ...state,
        cartDataArr: [],
        productList: {
          ...state.productList,
          items: [],
        },
        totalNumberOfItems: 0,
      };

    default:
      return state;
  }
};
