/* The code you provided is a Redux reducer function. It defines the behavior of the
`frameMaterialReducer` when different actions are dispatched. */
import { UPLOAD_MY_FRAME_IMAGES } from 'redux/constants';
import { UPLOAD_MY_FRAME_IMAGES_SUCCESS } from 'redux/constants/useMyFrame';

const initialState = {
  loading: false,
  error: null,
  frameDetails: null,
};

export const useMyFrameReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_MY_FRAME_IMAGES: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPLOAD_MY_FRAME_IMAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        frameDetails: action.payload,
      };
    }

    default:
      return state;
  }
};
