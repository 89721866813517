import React from 'react';
import * as styled from 'assets/styles';
import * as typography from 'components/typography';
import * as joy from '@mui/joy';
import * as material from '@mui/material';
import { message } from 'helper/config';
import Avatar from '@mui/joy/Avatar';
import { ModalCloseButton } from '.';

const EditProfilePopup = (props) => {
  return (
    <>
      <joy.Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <joy.Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalCloseButton />
          <form>
            <div className="w-fit">
              <input
                className="hidden"
                type="file"
                name="update_image"
                id="update_image"
                accept="image/png, image/jpeg"
                onChange={props.handleChangeImage}
              />
              <div className="relative">
                {props.profileImage ? (
                  <Avatar
                    alt="Profile"
                    src={props.profileImage}
                    sx={{
                      '--Avatar-size': '118px',
                    }}
                  />
                ) : (
                  <Avatar
                    src={'/broken-image.jpg'}
                    sx={{
                      '--Avatar-size': '118px',
                    }}
                  />
                )}
                <label
                  className="absolute right-0 bottom-0 cursor-pointer"
                  htmlFor="update_image"
                >
                  <typography.EditProfileIcon />
                </label>
              </div>
            </div>
            <div>
              <styled.Para20Tag
                className="pt-8 pb-6"
                $weight="600"
                color="#2E3233"
              >
                Contact Details
              </styled.Para20Tag>
              <div>
                <styled.FormGrid grid_cols={2} gap={1} grid_cols_576={2}>
                  <div>
                    <styled.FormInputLabel>User Name</styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <typography.ProfileIcon />
                      <input
                        type="text"
                        placeholder="Enter your user name"
                        name="userName"
                        value={props.userData?.userName}
                        onChange={props.handleChangeUserData}
                      />
                    </styled.FormInputOuter>
                  </div>
                  <div>
                    <styled.FormInputLabel>First Name</styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <typography.ProfileIcon />
                      <input
                        type="text"
                        placeholder="Enter your first name"
                        name="firstname"
                        value={
                          props.userData.firstname
                            ? props.userData.firstname
                            : ''
                        }
                        onChange={props.handleChangeUserData}
                      />
                    </styled.FormInputOuter>
                  </div>
                  <div>
                    <styled.FormInputLabel>Last Name</styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <typography.ProfileIcon />
                      <input
                        type="text"
                        placeholder="Enter your last name"
                        name="lastname"
                        value={props.userData.lastname}
                        onChange={props.handleChangeUserData}
                      />
                    </styled.FormInputOuter>
                  </div>
                  <div>
                    <styled.FormInputLabel>Email Address</styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <typography.EmailIcon stroke={'#000000'} />
                      <input
                        type="email"
                        placeholder="Enter your email address"
                        name="email"
                        value={props.userData.email}
                        onChange={props.handleChangeUserData}
                        readOnly
                      />
                    </styled.FormInputOuter>
                  </div>
                  <div>
                    <styled.FormInputLabel>Gender</styled.FormInputLabel>
                    <material.FormControl fullWidth>
                      <material.Select
                        name="gender"
                        displayEmpty
                        value={props.gender}
                        onChange={(e) => props.setGender(e.target.value)}
                        input={
                          <material.InputBase style={styled.dropDownStyles} />
                        }
                        IconComponent={typography.DropDownBottomIcon}
                        inputProps={{
                          'aria-label': 'Without label',
                        }}
                      >
                        <material.MenuItem value={''} disabled>
                          Select Gender
                        </material.MenuItem>
                        <material.MenuItem value={'male'}>
                          Male
                        </material.MenuItem>
                        <material.MenuItem value={'female'}>
                          Female
                        </material.MenuItem>
                      </material.Select>
                    </material.FormControl>
                  </div>
                </styled.FormGrid>
                {props?.userData?.is_google_login === true ||
                props?.userData?.is_apple_login === true ? (
                  <></>
                ) : (
                  <button
                    type="button"
                    onClick={() => props.setChangePasswordPopup(true)}
                    className="mt-4 flex items-center"
                  >
                    <styled.Para20Tag color={'#2E3233'}>
                      Change Password{' '}
                    </styled.Para20Tag>
                    <typography.BtnRightArrow stroke={'#2E3233'} />
                  </button>
                )}

                <styled.ButtonWrapper
                  className="mt-6"
                  type="submit"
                  width="100%"
                  onClick={(e) => props.handleUpdateUser(e)}
                >
                  {props.loading ? message?.IS_LOADING : 'Save'}
                </styled.ButtonWrapper>
              </div>
            </div>
          </form>
        </joy.Sheet>
      </joy.Modal>
    </>
  );
};

export default EditProfilePopup;
