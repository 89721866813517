/* The code is defining a React component called `Loader`. */
import React from 'react';
import { LoaderMain, Spinner } from 'assets/styles';

const Loader = () => {
  return (
    <>
      <LoaderMain>
        <Spinner />
      </LoaderMain>
    </>
  );
};

export default Loader;
