import React from 'react';
import { Para16Tag } from 'assets/styles';

const EmptyOrder = ({ images, title }) => {
  return (
    <div className="py-p69 flex flex-col justify-center items-center">
      <img loading="lazy" src={images} alt="img" />
      <Para16Tag className="mt-6 capitalize" color={'#5F6364'} $weight={500}>
        {title}
      </Para16Tag>
    </div>
  );
};

export default EmptyOrder;
