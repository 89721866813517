import React from 'react';
import Helmet from 'react-helmet';

const MetaData = ({ title }) => {
  return (
    <Helmet
    //  encodeSpecialCharacters={true}
    // titleTemplate="opticshaven.com - %s"
    // defaultTitle="Optics Haven | Your premium eyewear store"
    >
      {/* <meta charSet="utf-8" />
            <html lang="en" /> */}
      <title lang="en">{title}</title>
      {/* <base target="_blank" href="https://www.opticshaven.com" />
            <meta name="description" content="Optics Haven" />
            <meta property="og:type" content="Optics Haven" />
            <link rel="canonical" href="https://www.opticshaven.com" /> */}
    </Helmet>
  );
};

export default MetaData;
