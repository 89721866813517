/* These lines of code are defining constants in JavaScript using the `export const` syntax. */
export const GET_ALL_CATEGORIES_REQUEST = 'GET_ALL_CATEGORIES_REQUEST';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_ALL_CATEGORIES_SUCCESS_ADULT =
  'GET_ALL_CATEGORIES_SUCCESS_ADULT';
export const GET_ALL_CATEGORIES_SUCCESS_KIDS =
  'GET_ALL_CATEGORIES_SUCCESS_KIDS';
export const SET_SELECTED_CATEGORY_ID = 'SET_SELECTED_CATEGORY_ID';
export const SET_SELECTED_CATEGORY_TYPE = 'SET_SELECTED_CATEGORY_TYPE';
export const RESET_SELECTED_CATEGORY_ID = 'RESET_SELECTED_CATEGORY_ID';
export const RESET_SELECTED_CATEGORY_TYPE = 'RESET_SELECTED_CATEGORY_TYPE';
