import {
  DEVELOPMENT_HOSTNAME,
  hostName,
  isLocalApi,
  PRODUCTION_HOSTNAME,
} from 'utils/config';

// API's
const PRODUCTION_API = 'https://opticshaven.online';
const DEVELOPMENT_API = 'https://apidev.opticshaven.online';
// const LOCAL_API = 'http://localhost:3000';
let API_CONFIG = '';

if (hostName === 'localhost' && isLocalApi) {
  API_CONFIG = PRODUCTION_API;
} else if (hostName === PRODUCTION_HOSTNAME) {
  API_CONFIG = PRODUCTION_API;
} else if (hostName === DEVELOPMENT_HOSTNAME) {
  API_CONFIG = DEVELOPMENT_API;
}

export { API_CONFIG };
