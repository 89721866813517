/* The code is defining a React functional component called `CheckBoxCustom`. */
import React from 'react';
import * as styled from 'assets/styles';

const CheckBoxCustom = ({
  id,
  name,
  className,
  checked,
  onChange,
  ...props
}) => {
  return (
    <>
      <styled.CheckboxContainer className={className}>
        <styled.HiddenCheckboxCustom
          id={id}
          name={name}
          checked={checked}
          onChange={onChange} // Pass the onChange handler to update Formik state
          {...props}
        />
        <styled.StyledCheckbox checked={checked}>
          <styled.CheckboxIcon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </styled.CheckboxIcon>
        </styled.StyledCheckbox>
      </styled.CheckboxContainer>
    </>
  );
};

export default CheckBoxCustom;
