/* The code is importing the `device` object from the `helper/config` module and the `styled` function
from the `styled-components` library. */
import styled from 'styled-components';
import { device } from 'helper/config';
import { Link } from 'react-router-dom';

// Container wrapper
export const ContainerWrapper = styled.div`
  // max-width: 100%;
  width: -webkit-fill-available;
  // margin-left: auto;
  // margin-right: auto;
  margin-left: 20px;
  margin-right: 20px;

  ${device.maxW_720} {
    margin-left: 10px;
    margin-right: 10px;
  }

  // ${device.minW_768} {
  //   max-width: 768px;
  // }

  // ${device.minW_1024} {
  //   max-width: 1024px;
  // }

  // ${device.minW_1280} {
  //   max-width: 1280px;
  // }

  // ${device.maxW_1280} {
  //   margin: 0 20px;
  //   max-width: -webkit-fill-available;
  // }

  // ${device.minW_1440} {
  //   max-width: 1440px;
  // }

  // ${device.minW_1536} {
  //   max-width: 1536px;
  //   margin: auto;
  // }

  // ${device.tablet} {
  //   margin: 0 20px;
  //   max-width: -webkit-fill-available;
  // }

  @media only screen and (min-width: 1430px) {
    max-width: 1290px;
    margin: auto;
  }

  @media only screen and (min-width: 1600px) {
    max-width: 1440px;
    margin: auto;
  }

  @media only screen and (min-width: 1800px) {
    max-width: 1630px;
    margin: auto;
  }
`;

// Para10Tag
export const Para10Tag = styled.p`
  color: #a7abac;
  font-size: 10px;
  font-weight: 400;
`;

// Para12Tag
export const Para12Tag = styled.p`
  font-size: 12px;
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
  line-height: 16px;
  letter-spacing: -0.24px;
  color: ${({ color }) => (color ? color : '#414B56')};
`;

// // Para14Tag
// export const Para14Tag = styled.p`
//   font-family: Poppins;
//   font-size: 16px;
//   font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
//   line-height: 20px;
//   letter-spacing: -0.28px;
//   color: ${({ color }) => (color ? color : '#414B56')};
//   $margin-bottom: ${({ $m_bottom }) => ($m_bottom ? `${$m_bottom}px` : null)};
// `;

// Para16Tag
export const Para16Tag = styled.p`
  font-size: 16px;
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: ${({ center }) => center};
  color: ${({ color }) => (color ? color : '#2E3233')};
  margin-bottom: ${({ $m_bottom }) => ($m_bottom ? `${$m_bottom}px` : null)};

  ${device.maxW_720} {
    font-size: ${({ font }) => (font ? `${font}px` : '14px')};
  }
`;

// Para18Tag
export const Para18Tag = styled.p`
  font-size: 18px;
  color: ${({ color }) => (color ? color : '#2E3233')};
  font-weight: ${({ $weight }) => ($weight ? $weight : '600')};
  line-height: 24px;
  letter-spacing: -0.36px;

  ${device.maxW_720} {
    font-size: ${({ fontS720 }) => (fontS720 ? `${fontS720}px` : '15px')};
  }
`;

// Para20Tag
export const Para20Tag = styled.p`
  font-size: 20px;
  color: ${({ color }) => (color ? color : '#212529')};
  font-weight: ${({ $weight }) => ($weight ? $weight : '500')};
  line-height: 24px;
  letter-spacing: -0.4px;
  margin-bottom: ${({ $m_bottom }) => ($m_bottom ? `${$m_bottom}px` : null)};

  ${device.maxW_720} {
    font-size: 14px;
  }
`;

// Para22Tag
export const Para22Tag = styled.p`
  font-size: 22px;
  color: ${({ color }) => (color ? color : '#0E1213')};
  font-weight: ${({ $weight }) => ($weight ? $weight : '600')};
  line-height: 28px;
  letter-spacing: -0.44px;

  ${device.maxW_1280} {
    font-size: ${({ font }) => (font ? `${font}px` : '18px')};
  }

  ${device.maxW_720} {
    font-size: ${({ fontS720 }) => (fontS720 ? `${fontS720}px` : '16px')};
  }
`;

// Head24Tag
export const Head24Tag = styled.h5`
  color: ${({ color }) => (color ? color : '#2e3233')};
  font-size: 24px;
  font-weight: ${({ $weight }) => ($weight ? $weight : '600')};
  line-height: 32px;
  letter-spacing: -0.48px;

  ${device.maxW_720} {
    font-size: ${({ font }) => (font ? `${font}px` : '18px')};
  }
`;

// Head32Tag
export const Head32Tag = styled.h4`
  font-size: 32px;
  color: ${({ color }) => (color ? color : '#212529')};
  font-weight: ${({ $weight }) => ($weight ? $weight : '500')};
  line-height: 40px;
  letter-spacing: -0.64px;
  margin-bottom: ${({ $m_bottom }) => ($m_bottom ? `${$m_bottom}px` : null)};
  text-transform: ${({ text }) => (text ? text : '')};

  ${device.maxW_720} {
    font-size: 20px;
    line-height: normal;
  }
`;

// Head40Tag
export const Head40Tag = styled.h4`
  font-size: 40px;
  color: ${({ color }) => (color ? color : '#2E3233')};
  font-weight: ${({ $weight }) => ($weight ? $weight : '600')};
  line-height: normal;
  letter-spacing: -0.8px;
  margin-bottom: ${({ $m_bottom }) => ($m_bottom ? `${$m_bottom}px` : null)};

  ${device.maxW_720} {
    font-size: 26px;
    line-height: normal;
  }
`;

// Head48Tag
export const Head48Tag = styled.h2`
  color: ${({ color }) => (color ? color : '#2E3233')};
  font-size: 48px;
  font-weight: ${({ $weight }) => ($weight ? $weight : '600')};
  line-height: 64px;
  letter-spacing: -0.96px;

  ${device.maxW_1280} {
    font-size: 24px;
    line-height: normal;
  }
`;

// Head40Tag
export const Head50Tag = styled.h4`
  font-size: 50px;
  color: ${({ color }) => (color ? color : '#FFFFFF')};
  font-weight: ${({ $weight }) => ($weight ? $weight : '700')};
  line-height: normal;
  letter-spacing: -1px;
  margin-bottom: ${({ $m_bottom }) => ($m_bottom ? `${$m_bottom}px` : null)};
`;

// Head54Tag
export const Head54Tag = styled.h4`
  font-size: 54px;
  color: ${({ color }) => (color ? color : '#2E3233')};
  font-weight: ${({ $weight }) => ($weight ? $weight : '600')};
  line-height: 72px;
  letter-spacing: -1.08px;

  ${device.maxW_1280} {
    font-size: 24px;
    line-height: normal;
  }
`;

// Text Link
export const TextLink = styled(Link)`
  color: ${({ color }) => (color ? color : '#219ebc')};
  font-size: ${({ size }) => (size ? `${size}px` : '1rem')};
  font-weight: ${({ $weight }) => ($weight ? $weight : 600)};
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? textDecoration : null};
`;

// Border Divider
export const BorderDivider = styled.div`
  margin-bottom: ${({ $m_bottom }) => ($m_bottom ? `${$m_bottom}px` : null)};
  margin-top: ${({ m_top }) => (m_top ? `${m_top}px` : null)};
  border-bottom: 1px solid
    ${({ bordercolor }) => (bordercolor ? bordercolor : '#eaeef0')};
  display: flex;
`;

// modal close button
export const ModalCloseButtonWrapper = styled.button`
  border-radius: 100%;
  background: #f4f8f9;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Main padding
export const MainPadding = styled.div`
  padding-top: ${({ $p_top }) => ($p_top ? `${$p_top}px` : null)};
  padding-bottom: ${({ $p_bottom }) => ($p_bottom ? `${$p_bottom}px` : null)};

  ${device.maxW_720} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const ProductFrameListWrapper = styled.div`
  ${device.maxW_720} {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
