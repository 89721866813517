import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Card, CardContent, Divider } from '@mui/joy';
import { useMediaQuery } from '@mui/material';

const ProductCardSkeleton = () => {
  const matches = useMediaQuery('(max-width:720px)');

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          borderRadius: '18px',
        }}
        width={'100%'}
        height={'100%'}
      >
        <Skeleton
          sx={{ borderRadius: '18px' }}
          variant="rectangular"
          height={matches ? '200px' : '269px'}
        />
        <div className="flex items-center justify-between gap-4">
          <div>
            <Skeleton
              variant="rectangular"
              width={200}
              height="1em"
              sx={{ mb: 1 }}
            />
            <Skeleton variant="rectangular" width={140} height="1em" />
          </div>
          <Skeleton variant="rounded" width={24} height={24} />
        </div>
        <Divider />
        <CardContent
          orientation="horizontal"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div>
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={100} />
          </div>
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <Skeleton variant="circular" width={22} height={22} />
            <Skeleton variant="circular" width={22} height={22} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

// const ProductBox = styled(Card)`
//   // width: ${({ $img_width }) => ($img_width ? `${$img_width}px` : '528px')};
//   // height: 269px;

//   ${device.maxW_1280} {
//     // width: 482px;
//   }

//   ${device.maxW_800} {
//     // height: 200px;
//   }

//   ${device.maxW_720} {
//     // width: ${({ img_width }) => (img_width ? img_width : '335px')};
//   }

//   // ${device.maxW_576} {
//   //   width: 232px;
//   // }
// `;

export default ProductCardSkeleton;
