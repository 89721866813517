/* This code is defining a React component called `CustomModalCloseButton`. */
import React from 'react';
import { ModalCloseButtonWrapper } from 'assets/styles';
import { CgClose } from 'react-icons/cg';
import styles from './CustomModalCloseButton.module.css'; // Import css modules stylesheet as styles

const CustomModalCloseButton = () => {
  return (
    <>
      <div className={styles.modal_close_btn_main_top}>
        <div className={styles.modal_close_btn_main}>
          <ModalCloseButtonWrapper>
            <CgClose />
          </ModalCloseButtonWrapper>
        </div>
      </div>
    </>
  );
};

export default CustomModalCloseButton;
