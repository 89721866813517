/* The code is a React component called `HeaderLogo`. */
import React from 'react';
import { Logo } from 'components/typography';
import { Link } from 'react-router-dom';

const HeaderLogo = () => {
  return (
    <>
      <div className="relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
        <Link className="h_logo" to={'/'}>
          <Logo />
        </Link>
      </div>
    </>
  );
};

export default HeaderLogo;
