/* The code is defining a reducer function called `orderReducer` that handles actions related to
creating an order. */
import { CREATE_ORDER_REQUEST, CREATE_ORDER_SUCCESS } from 'redux/constants';

const initialState = {
  orderDetails: {},
  loading: false,
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER_REQUEST:
      return {
        loading: true,
        ...state,
      };

    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orderDetails: action.payload,
      };

    default:
      return state;
  }
};
