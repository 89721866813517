import { SELECTED_SHIPPING_ADDRESS } from 'redux/constants';

const initialState = {
  selectedShippingData: null,
};

export const selectedShippingAddressReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SELECTED_SHIPPING_ADDRESS:
      return {
        ...state,
        selectedShippingData: action.payload,
      };
    default:
      return state;
  }
};
