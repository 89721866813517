/* The above code is a React component called "AddEditShippingAddress". It is a form that allows users
to add or edit a shipping address. */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'helper/Auth';
import { ApiPostJSON } from 'helper/API';
import { message, routesConfig } from 'helper/config';
import { Field, Form, Formik } from 'formik';
import { shippingAddressSchema } from 'helper';
import * as _ from '.';
import * as styles from 'assets/styles';
import { City, State } from 'country-state-city';
import { useSnackbar } from 'context';

const AddEditShippingAddress = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const initialValues = {
    address: '',
    state: '',
    city: '',
    zipcode: '',
    is_default_address: false, // Set the default value to false
    email: '',
    mobile_no: '',
    lastname: '',
    firstname: '',
    address_type: 'home', // Set the default value to 'home'
  };

  const handleAddShippingAddress = async (values, { resetForm }) => {
    setIsLoading(true);

    // Axios Body
    let body = {
      user_id: Auth?.getUserId(),
      address: values.address,
      state: values.state,
      city: values.city,
      zipcode: values.zipcode,
      is_default_address: values.is_default_address, // Set the default value to false
      email: values.email,
      mobile_no: values.mobile_no,
      lastname: values.lastname,
      firstname: values.firstname,
      address_type: values.address_type,
    };

    try {
      setIsLoading(false);

      // API
      const response = await ApiPostJSON('/user-billing-details', body);

      resetForm();

      if (response?.data?.message?.status === 200) {
        showSnackbar(message?.ADD_SUCCESS_SHIPPING_ADDRESS, 'soft', 'success');

        navigate(routesConfig?.shippingAddress);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={shippingAddressSchema}
        onSubmit={handleAddShippingAddress}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <styles.FormGrid grid_cols={2} gap={2}>
              <div>
                <styles.FormInputLabel>First Name</styles.FormInputLabel>
                <styles.FormInputOuter>
                  <Field
                    type="text"
                    placeholder="Enter your first name"
                    name="firstname"
                  />
                </styles.FormInputOuter>
                {touched.firstname && errors.firstname && (
                  <_.FormErrorMessage errors={errors.firstname} />
                )}
              </div>
              <div>
                <styles.FormInputLabel>Last Name</styles.FormInputLabel>
                <styles.FormInputOuter>
                  <Field
                    type="text"
                    placeholder="Enter your last name"
                    name="lastname"
                  />
                </styles.FormInputOuter>
                {touched.lastname && errors.lastname && (
                  <_.FormErrorMessage errors={errors.lastname} />
                )}
              </div>
              <div>
                <styles.FormInputLabel>Mobile Number</styles.FormInputLabel>
                <styles.FormInputOuter>
                  <Field
                    type="text"
                    placeholder="Enter your mobile number"
                    name="mobile_no"
                  />
                </styles.FormInputOuter>
                {touched.mobile_no && errors.mobile_no && (
                  <_.FormErrorMessage errors={errors.mobile_no} />
                )}
              </div>
              <div>
                <styles.FormInputLabel>Email Address</styles.FormInputLabel>
                <styles.FormInputOuter>
                  <Field
                    type="email"
                    placeholder="Enter your email address"
                    name="email"
                  />
                </styles.FormInputOuter>
                {touched.email && errors.email && (
                  <_.FormErrorMessage errors={errors.email} />
                )}
              </div>
            </styles.FormGrid>
            <styles.BorderDivider $m_bottom={20} m_top={20} />
            <styles.FormGrid grid_cols={2} gap={2}>
              <div>
                <styles.FormInputLabel>Address </styles.FormInputLabel>
                <styles.FormInputOuter>
                  <Field
                    type="text"
                    placeholder="Enter your address one"
                    name="address"
                  />
                </styles.FormInputOuter>
                {touched.address && errors.address && (
                  <_.FormErrorMessage errors={errors.address} />
                )}
              </div>
              <div>
                <styles.FormInputLabel>Zip Code</styles.FormInputLabel>
                <styles.FormInputOuter>
                  <Field
                    type="text"
                    placeholder="Enter your zip code"
                    name="zipcode"
                  />
                </styles.FormInputOuter>
                {touched.zipcode && errors.zipcode && (
                  <_.FormErrorMessage errors={errors.zipcode} />
                )}
              </div>
              <div>
                <styles.FormInputLabel>State</styles.FormInputLabel>
                <div className="form_input_main_inner">
                  <styles.SelectWrapperField
                    as="select"
                    name="state"
                    value={values.state}
                    onChange={(e) => {
                      setFieldValue('state', e.target.value);
                      setFieldValue('city', ''); // Reset city when state changes
                    }}
                  >
                    <option value="">Select a State</option>
                    {/* Map your state options here based on country */}
                    {State?.getStatesOfCountry('US').map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </styles.SelectWrapperField>
                </div>
                {touched.state && errors.state && (
                  <_.FormErrorMessage errors={errors.state} />
                )}
              </div>
              <div>
                <styles.FormInputLabel>City</styles.FormInputLabel>
                <div className="form_input_main_inner">
                  <styles.SelectWrapperField
                    as="select"
                    name="city"
                    value={values.city}
                    onChange={(e) => setFieldValue('city', e.target.value)}
                  >
                    <option value="">Select a City</option>
                    {/* Map your city options here based on state */}
                    {values.state &&
                      City.getCitiesOfState(
                        'US', // Pass country instead of state
                        values.state
                      ).map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                  </styles.SelectWrapperField>
                </div>
                {touched.city && errors.city && (
                  <_.FormErrorMessage errors={errors.city} />
                )}
              </div>
              <div>
                <styles.FormInputLabel>Address Type</styles.FormInputLabel>
                <div className="flex items-center gap-3">
                  <label className="flex items-center cursor-pointer">
                    <_.RadioButton
                      radio_id="home_radio2"
                      isSelected={'home'}
                      radio_name="address_type"
                      value="home"
                      onChange={(e) =>
                        setFieldValue('address_type', e.target.value)
                      }
                    />
                    <styles.Para16Tag color={'#2E3233'}>
                      {'Home'}
                    </styles.Para16Tag>
                  </label>
                  <label className="flex items-center cursor-pointer">
                    <_.RadioButton
                      radio_id="work_radio"
                      isSelected={values?.address_type === 'work'}
                      radio_name="address_type"
                      value="work"
                      onChange={(e) =>
                        setFieldValue('address_type', e.target.value)
                      }
                    />
                    <styles.Para16Tag color={'#2E3233'}>
                      {'Work'}
                    </styles.Para16Tag>
                  </label>
                  {touched.address_type && errors.address_type && (
                    <_.FormErrorMessage errors={errors.address_type} />
                  )}
                </div>
              </div>
              <label className="w-fit cursor-pointer flex items-start flex-col">
                <div className="cursor-pointer flex">
                  <div className="cursor-pointer flex mr-m10">
                    <_.CheckBox
                      id="is_default_address"
                      name="is_default_address"
                    />
                  </div>
                  <styles.Para16Tag color={'#A7ABAC'}>
                    {'Default Address '}
                  </styles.Para16Tag>
                </div>
                {touched.is_default_address && errors.is_default_address && (
                  <_.FormErrorMessage errors={errors.is_default_address} />
                )}
              </label>
            </styles.FormGrid>
            <div className="flex items-center justify-end mt-4">
              <styles.LinkWrapper
                to={routesConfig?.shippingAddress}
                className="mr-6 screen1280:!hidden"
                $bg_color={'#FFF'}
                $bColor={'#DEE2E4'}
                color={'#5F6364'}
                $hover_color={'#2E3233'}
                $hover_bg={'#FFF'}
                $hover_border={'#DEE2E4'}
              >
                Cancel
              </styles.LinkWrapper>
              <styles.ButtonWrapper type="submit" className="screen1280:w-full">
                {isLoading ? message?.IS_LOADING : 'Save & Proceed'}
              </styles.ButtonWrapper>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddEditShippingAddress;
