/* The code is a React component called `ForgotForm` that represents a form for the "Forgot Password"
functionality. */
import React from 'react';
import * as typography from 'components/typography';
import * as styles from 'assets/styles';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { forgotPasswordSchema } from 'helper';
import { ApiForgotPassword } from 'helper/API';
import { Auth } from 'helper/Auth';
import { routesConfig } from 'helper/config';
import styled from 'pages/desktopView/Auth/Auth.module.css';
import { FormErrorMessage } from '..';
import { useSnackbar } from 'context';

const ForgotForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const initialValues = {
    email: '',
  };

  /**
   * The handleSubmit function is a JavaScript function that handles form submission, dispatches a
   * loginUserAction, resets the form, and resolves or rejects a promise based on the success or
   * failure of the login action.
   * @param values - The `values` parameter is an object that contains the form values submitted by the
   * user. It typically includes properties for each form field, with the field names as keys and the
   * user input as values.
   * @returns The handleSubmit function returns a Promise.
   */
  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      // API
      const response = await ApiForgotPassword(values);

      if (response?.data?.status === 200) {
        Auth?.setUserEmail(values?.email);
        showSnackbar(
          'Validation code sent successfully.. expired in 2 minutes..',
          'soft',
          'success'
        );
        navigate(routesConfig?.verifyEmail);
        resetForm();
      } else if (response?.data?.status === 400)
        showSnackbar(response?.data?.message, 'soft', 'danger');
      else {
        showSnackbar('Please check your email!', 'soft', 'danger');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styled.login_right_inner_outer_info}>
        <div className={styled.login_text_main_title_top_div_inner}>
          <div className={styled.login_text_main_title}>
            <styles.Head48Tag className="text-center screen1280:text-left">
              Forgot Password
            </styles.Head48Tag>
          </div>
          <styles.Para16Tag
            className="text-center screen1280:text-left"
            $weight={500}
            color={'#2E3233'}
          >
            {'No worries,we’ll send you reset instruction'}
          </styles.Para16Tag>
        </div>
        <div className="max-w-lg w-full mx-auto screen1280:max-w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={forgotPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className={styled.form_input_list_email_pass_other_data}>
                  <div className={styled.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>Email Address</styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <typography.EmailIcon stroke={'#000000'} />
                      <Field
                        type="text"
                        placeholder="Enter your email address"
                        name="email"
                      />
                    </styles.FormInputOuter>
                    {touched.email && errors.email && (
                      <FormErrorMessage errors={errors.email} />
                    )}
                  </div>
                </div>
                <styles.ButtonWrapper width="100%" type="submit">
                  {isLoading ? 'Loading...' : 'Forgot Password'}
                </styles.ButtonWrapper>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ForgotForm;
