export const URLSearchParams = {
  gender: 'gender',
  frameType: 'frameType',
  frameShape: 'frameShape',
  frameColor: 'frameColor',
  frameSize: 'frameSize',
  frameMaterial: 'frameMaterial',
  new_arrival: 'new_arrival',
  categoryId: 'categoryId',
  sortBy: 'sortBy',
  male: 'male',
  female: 'female',
  boys: 'boys',
  girls: 'girls',
};
