/* The code block you provided defines a set of device sizes and device settings for responsive media
queries in JavaScript. */

// Devices Size
const deviceSize = {
  // mobileS1: "315px",
  // mobileS2: "429px",

  // mobileL1: "430px",
  // mobileL2: "539px",

  // mobileM1: "540px",
  // mobileM2: "767px",

  screen_576: '576px',
  screen_640: '640px',
  screen_720: '720px',
  screen_768: '768px',
  screen_800: '800px',
  screen_992: '992px',
  screen_1024: '1024px',
  screen_1200: '1200px',
  screen_1280: '1280px',
  screen_1440: '1440px',
  screen_1536: '1536px',

  // laptop1: "1000px",
  // laptop2: "1199px",

  // laptop3: "1200px",
  // laptop4: "1270px",

  // desktop: "1279px",
};

/* The `device` object is defining different device sizes based on the values provided in the
`deviceSize` object. Each property in the `device` object represents a specific device size range,
and the value of each property is a string that represents the corresponding media query. */
export const device = {
  // mobileSS: `${deviceSize?.mobileS1} <= width <= ${deviceSize?.mobileS2})`,
  // mobileLL: `${deviceSize?.mobileL1} <= width <= ${deviceSize?.mobileL2})`,
  // mobileMM: `${deviceSize?.mobileM1} <= width <= ${deviceSize?.mobileM2})`,
  tablet: `@media only screen and (min-width: ${deviceSize.screen_720}) and (max-width: ${deviceSize.screen_1280})`,
  // laptop: `${deviceSize?.laptop1} <= width <= ${deviceSize?.laptop2})`,
  // laptopLL: `${deviceSize?.laptop3} <= width <= ${deviceSize?.laptop4})`,

  // Min-Width
  minW_640: `@media (min-width: ${deviceSize.screen_640})`,
  minW_768: `@media (min-width: ${deviceSize.screen_768})`,
  minW_1024: `@media (min-width: ${deviceSize.screen_1024})`,
  minW_1280: `@media (min-width: ${deviceSize.screen_1280})`,
  minW_1440: `@media (min-width: ${deviceSize.screen_1440})`,
  minW_1536: `@media (min-width: ${deviceSize.screen_1536})`,

  // Max-Width
  maxW_1280: `@media (max-width: ${deviceSize.screen_1280})`,
  maxW_720: `@media (max-width: ${deviceSize.screen_720})`,
  maxW_576: `@media (max-width: ${deviceSize.screen_576})`,
  maxW_800: `@media (max-width: ${deviceSize.screen_800})`,
  maxW_992: `@media (max-width: ${deviceSize.screen_992})`,
};

// @media (min-width: 320px) and (max-width: 429px)
