import React from 'react';
import * as styled from 'assets/styles';
import * as formik from 'formik';
import * as config from 'helper/config';
import { prescriptionSchema } from 'helper';
import { useEditPrescription } from 'hooks';
import { FormErrorMessage } from '.';

const EditPrescriptionForm = () => {
  const { isLoading, initFormikValues, handleSubmit } = useEditPrescription();

  return (
    <>
      <formik.Formik
        initialValues={initFormikValues}
        validationSchema={prescriptionSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <formik.Form>
            <styled.FormGrid grid_cols={2} gap={2}>
              <div>
                <styled.Para16Tag $weight="600" className="mb-5">
                  Right Eye Prescription
                </styled.Para16Tag>
                <div className="flex flex-col gap-4">
                  <div>
                    <styled.FormInputLabel>Axis (0-180)</styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <formik.Field
                        type="text"
                        placeholder="Enter your Right Eye Axis (0-180)"
                        name="rightEyeAxis"
                      />
                    </styled.FormInputOuter>
                    {touched.rightEyeAxis && errors.rightEyeAxis && (
                      <FormErrorMessage errors={errors.rightEyeAxis} />
                    )}
                  </div>
                  <div>
                    <styled.FormInputLabel>
                      Cylindrical Power (cyl)
                    </styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <formik.Field
                        type="text"
                        placeholder="Enter your Right Eye Cylindrical Power (cyl)"
                        name="rightEyeCYL"
                      />
                    </styled.FormInputOuter>
                    {touched.rightEyeCYL && errors.rightEyeCYL && (
                      <FormErrorMessage errors={errors.rightEyeCYL} />
                    )}
                  </div>
                  <div>
                    <styled.FormInputLabel>PD</styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <formik.Field
                        type="text"
                        placeholder="Enter your Right Eye PD"
                        name="rightEyePD"
                      />
                    </styled.FormInputOuter>
                    {touched.rightEyePD && errors.rightEyePD && (
                      <FormErrorMessage errors={errors.rightEyePD} />
                    )}
                  </div>
                  <div>
                    <styled.FormInputLabel>
                      Spherical Power (sph)
                    </styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <formik.Field
                        type="text"
                        placeholder="Enter your Right Eye Spherical Power (sph)"
                        name="rightEyeSPH"
                      />
                    </styled.FormInputOuter>
                    {touched.rightEyeSPH && errors.rightEyeSPH && (
                      <FormErrorMessage errors={errors.rightEyeSPH} />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <styled.Para16Tag $weight="600" className="mb-5">
                  Left Eye Prescription
                </styled.Para16Tag>
                <div className="flex flex-col gap-4">
                  <div>
                    <styled.FormInputLabel>Axis (0-180)</styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <formik.Field
                        type="text"
                        placeholder="Enter your Left Eye Axis (0-180)"
                        name="leftEyeAxis"
                      />
                    </styled.FormInputOuter>
                    {touched.leftEyeAxis && errors.leftEyeAxis && (
                      <FormErrorMessage errors={errors.leftEyeAxis} />
                    )}
                  </div>
                  <div>
                    <styled.FormInputLabel>
                      Cylindrical Power (cyl)
                    </styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <formik.Field
                        type="text"
                        placeholder="Enter your Left Eye Cylindrical Power (cyl)"
                        name="leftEyeCYL"
                      />
                    </styled.FormInputOuter>
                    {touched.leftEyeCYL && errors.leftEyeCYL && (
                      <FormErrorMessage errors={errors.leftEyeCYL} />
                    )}
                  </div>
                  <div>
                    <styled.FormInputLabel>PD</styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <formik.Field
                        type="text"
                        placeholder="Enter your Left Eye PD"
                        name="leftEyePD"
                      />
                    </styled.FormInputOuter>
                    {touched.leftEyePD && errors.leftEyePD && (
                      <FormErrorMessage errors={errors.leftEyePD} />
                    )}
                  </div>
                  <div>
                    <styled.FormInputLabel>
                      Spherical Power (sph)
                    </styled.FormInputLabel>
                    <styled.FormInputOuter>
                      <formik.Field
                        type="text"
                        placeholder="Enter your Left Eye Spherical Power (sph)"
                        name="leftEyeSPH"
                      />
                    </styled.FormInputOuter>
                    {touched.leftEyeSPH && errors.leftEyeSPH && (
                      <FormErrorMessage errors={errors.leftEyeSPH} />
                    )}
                  </div>
                </div>
              </div>
            </styled.FormGrid>
            <div className="flex items-center justify-end mt-4">
              <styled.LinkWrapper
                to={config.routesConfig?.prescription}
                className="mr-6"
                $bg_color={'#FFF'}
                $bColor={'#DEE2E4'}
                color={'#5F6364'}
                $hover_color={'#2E3233'}
                $hover_bg={'#FFF'}
                $hover_border={'#DEE2E4'}
              >
                Cancel
              </styled.LinkWrapper>
              <styled.ButtonWrapper type="submit">
                {isLoading ? config.message?.IS_LOADING : 'Save & Proceed'}
              </styled.ButtonWrapper>
            </div>
          </formik.Form>
        )}
      </formik.Formik>
    </>
  );
};

export default EditPrescriptionForm;
