import React from 'react';
import { frame_info_open_images_browser } from 'assets/images';
import * as styles from 'assets/styles';
import { Camera } from 'react-camera-pro';
import '../../lensePrescription/lenseUploadPrescription.css';
import { MdOutlineCameraswitch } from 'react-icons/md';

const UserUploadImg = (props) => {
  // const [image, setImage] = React.useState(null);
  const [numberOfCameras, setNumberOfCameras] = React.useState(0);

  return (
    <div className="frame_information_left_images_open_browse_main">
      {props.camOn === false && (
        <div className="frame_info_image_inner_open">
          <input
            className="hidden"
            type="file"
            name="upload_image"
            id="upload_image"
            accept="image/png, image/jpeg"
            multiple
            onChange={props.handleImageChange}
          />
          <div className="frame_info_images_outer_inner_three_images_show">
            <label htmlFor="upload_image">
              <img
                loading="lazy"
                src={frame_info_open_images_browser}
                alt="img"
                className="frame_info_image_inner_open_inner cursor-pointer"
              />
            </label>
          </div>
        </div>
      )}
      {!props.prescriptionImages ? (
        <>
          {props.camOn ? (
            <>
              <div
                className="frame_info_text_files_name_browse"
                style={{
                  marginTop: '50px',
                  textAlign: 'center',
                }}
              >
                <Camera
                  ref={props.camera}
                  numberOfCamerasCallback={setNumberOfCameras}
                  className="video-cam"
                />
                <div className="flex items-center justify-center gap-4 mt-3">
                  <styles.ButtonWrapper
                    onClick={() => {
                      props.setCamOn(false);
                      props.setPrescriptionImages(props?.takePhoto());
                      // setImage(props.camera.current.takePhoto())
                    }}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Take photo
                  </styles.ButtonWrapper>
                  <styles.ButtonWrapper
                    hidden={numberOfCameras <= 1}
                    onClick={() => {
                      props.camera.current.switchCamera();
                    }}
                  >
                    <MdOutlineCameraswitch size={24} />
                  </styles.ButtonWrapper>
                  <styles.ButtonWrapper
                    onClick={() => props.setCamOn(false)}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Cancel
                  </styles.ButtonWrapper>
                </div>
                {/* <img src={image} alt='' /> */}
                {/* <img src={props.prescriptionImages} alt='' /> */}
              </div>
            </>
          ) : (
            <>
              <div className="frame_info_text_files_name_browse">
                <styles.Para12Tag $weight={600}>
                  Drop your Files here Or
                  <span className="text-[#FB8500]"> Browse</span>
                </styles.Para12Tag>
                <styles.Para10Tag>
                  PNG, JPG, DOC or PDF (Max. 25MB)
                </styles.Para10Tag>
              </div>
              OR
              <div className="flex items-center justify-center">
                <styles.ButtonWrapper
                  onClick={() => props.setCamOn(true)}
                  style={{
                    marginTop: '10px',
                    textAlign: 'center',
                  }}
                >
                  Take photo
                </styles.ButtonWrapper>
              </div>
            </>
          )}
        </>
      ) : (
        <>{props.prescriptionImages && `1 File Selected`}</>
      )}
    </div>
  );
};

export default UserUploadImg;
