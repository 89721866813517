/**
 * The SwitchBtn component is a toggle switch button in React that allows the user to turn on or off a
 * certain feature.
 * @returns The SwitchBtn component is returning a JSX element.
 */
import React from 'react';
import { Switch, switchClasses } from '@mui/joy';

const SwitchBtn = ({ checked, onChange }) => {
  return (
    <>
      <Switch
        checked={checked}
        onChange={(event) => onChange(event.target.checked)}
        sx={{
          '--Switch-thumbShadow': '0 3px 7px 0 rgba(0 0 0 / 0.12)',
          '--Switch-thumbSize': '26px',
          '--Switch-trackWidth': '60px',
          '--Switch-trackHeight': '30px',
          '--Switch-trackBackground': '#E9ECEF',
          [`& .${switchClasses.thumb}`]: {
            transition: 'width 0.2s, left 0.2s',
          },
          '&:hover': {
            '--Switch-trackBackground': '#DEE2E6',
          },
          '&:active': {
            '--Switch-thumbWidth': '30px',
          },
          [`&.${switchClasses.checked}`]: {
            '--Switch-trackBackground': '#2DAAC8',
            '&:hover': {
              '--Switch-trackBackground': '#219EBC',
            },
          },
        }}
      />
    </>
  );
};

export default SwitchBtn;
