import axios from 'axios';
import { BASEURL } from 'helper/API';
import { ErrorToast } from 'helper/Toast';
import { getHeaders } from 'helper/API';
import { API } from 'utils/axios';
import { Auth } from 'helper/Auth';
import { routesConfig, message } from 'helper/config';
import * as constants from 'redux/constants';

export const addItemToCart =
  (body, navigate, showSnackbar) => async (dispatch) => {
    try {
      const { data } = await API.post(`/carts`, body);

      const {
        message: { status },
      } = data;
      if (status === 200) {
        dispatch({ type: constants.ADD_PRODUCT_TO_CART_SUCCESS });
        showSnackbar(message.CART_ADD_SUCCESS, 'soft', 'success');
        navigate(routesConfig.shippingCart);
      }
    } catch (error) {
      if (error) ErrorToast(error?.message?.ERROR_TRY_AGAIN);
      else if (error?.message === 'Network Error')
        ErrorToast(error?.message?.NETWORK_ERROR);
      else throw error;
    }
  };

export const getCartData = () => async (dispatch) => {
  try {
    dispatch({ type: constants.GET_CART_PRODUCTS });

    const userId = Auth.getUserId();

    const response = await API.get(`/carts/user/${userId}`);

    if (response?.message?.status === 401) {
      Auth.userLogout();
      dispatch({
        type: constants.USER_LOGOUT,
      });
    }

    dispatch({
      type: constants.GET_CART_PRODUCTS,
      payload: response?.data,
    });
  } catch (error) {
    if (error) ErrorToast(error?.message?.ERROR_TRY_AGAIN);
    else if (error?.message === 'Network Error')
      ErrorToast(error?.message?.NETWORK_ERROR);
    else throw error;
  }
};

export const removeItemFromCart =
  (userID, itemId, showSnackbar) => async (dispatch) => {
    try {
      const headers = await getHeaders();

      await axios.delete(`${BASEURL}/carts/usercart/${userID}/${itemId}`, {
        headers,
      });

      dispatch({
        type: constants.REMOVE_ITEM_FROM_CART,
        payload: itemId,
      });

      showSnackbar('Remove Item for cart successfully', 'soft', 'warning');
    } catch (error) {
      if (error) {
        throw error;
      }
    }
  };
