import Cookies from 'js-cookie';
import { encrypt } from 'helper/encrypt';

const COOKIE_OPTIONS = { sameSite: 'strict', secure: true };

// Function to set a Encrypt cookie value
export const setEncryptCookie = (key, value, securityKey) => {
  try {
    let data = encrypt(value, securityKey);
    Cookies.set(key, data, { expires: 1, secure: true });
  } catch (error) {
    throw new Error();
  }
};

// Function to set a cookie value
export const setCookie = (key, value, expires = 1) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + expires);

  Cookies.set(key, value, {
    expires: expirationDate,
    ...COOKIE_OPTIONS,
  });
};

// Function to get a cookie value
export const getCookie = (key) => Cookies.get(key);

// Function to remove a cookie
export const removeCookie = (key) => Cookies.remove(key, COOKIE_OPTIONS);
