/* The code is defining a React functional component called `BillDetails`. It is importing various
dependencies and components from different files and libraries. */
import React from 'react';
import * as styled from 'assets/styles';
import './billDetails.css';
import { message, routesConfig } from 'helper/config';
import { useCart, usePromoCode } from 'hooks';
import { ApiPromoCodeCheck } from 'helper/API/API.request';
import { Auth } from 'helper/Auth';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'context';

const BillDetails = () => {
  const { finalAmount } = useCart();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    promoCode,
    setPromoCode,
    discountAmount,
    promoCodeDetail,
    finalTotalAmount,
    applyPromoCode,
  } = usePromoCode(finalAmount, ApiPromoCodeCheck);

  const handleSubmitApply = async () => {
    try {
      if (promoCode) {
        const message = await applyPromoCode();

        if (message) {
          showSnackbar(message, 'soft', 'success');
          setPromoCode('');
        }
      }
    } catch (error) {
      if (error)
        showSnackbar(error?.response?.data?.message?.message, 'soft', 'danger');
      else if (error?.message === 'Network Error')
        showSnackbar(message?.NETWORK_ERROR, 'soft', 'danger');
      else showSnackbar(message?.ERROR_TRY_AGAIN, 'soft', 'danger');
    }
  };

  const handleNavigate = () => {
    navigate(routesConfig.checkOut, {
      state: {
        fromCart: true,
        discountAmount,
        finalTotalAmount,
        promoCodeDetail,
      },
    });
  };

  return (
    <>
      <div className="bill-details-parent screen1280:mt-4">
        <styled.Head24Tag>Bill Details</styled.Head24Tag>
        <div className="shipping_cart_frame-parent54">
          <div className="shipping_cart_frame-parent55">
            <div className="shipping_cart_frame-parent56">
              <div className="shopping-cart-2-parent">
                <styled.Para18Tag color="#2E3233" $weight="400">
                  Total Price
                </styled.Para18Tag>
                <styled.Para18Tag $weight="600">
                  ${finalAmount}
                </styled.Para18Tag>
              </div>
              <div className="shopping-cart-2-parent">
                <styled.Para18Tag color="#2E3233" $weight="400">
                  Total Offer Discount
                </styled.Para18Tag>
                <styled.Para18Tag color="#2E3233">
                  - ${discountAmount}
                </styled.Para18Tag>
              </div>
            </div>

            <styled.BorderDivider className="w-full" />
            <div className="shipping_cart_frame-parent56">
              <div className="shopping-cart-2-parent">
                <styled.Para20Tag color="#2E3233">
                  Total Payable
                </styled.Para20Tag>
                <styled.Para20Tag color="#2E3233" $weight="600">
                  ${finalTotalAmount}
                </styled.Para20Tag>
              </div>
            </div>
          </div>
          {Auth?.isLogin() ? (
            <div className="w-full tablet:w-fit flex mobile:flex-wrap gap-3">
              <styled.FormInputOuter
                bg={'#FFF'}
                className="w-full tablet:w-fit"
              >
                <input
                  type="text"
                  placeholder="Enter Referral or Coupon code"
                  name=""
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
              </styled.FormInputOuter>
              <styled.ButtonWrapper
                type="button"
                onClick={() => handleSubmitApply()}
                className="ml-auto"
              >
                Apply
              </styled.ButtonWrapper>
            </div>
          ) : (
            <styled.Para16Tag color="red">
              Note: To use coupon code sign in or create account
            </styled.Para16Tag>
          )}

          <div className="w-full screen1280:hidden">
            <styled.ButtonWrapper onClick={handleNavigate} width={'100%'}>
              Proceed to Checkout
            </styled.ButtonWrapper>
            <styled.LinkWrapper
              to={routesConfig.productList}
              width={'100%'}
              style={{
                marginTop: '10px',
              }}
              $bg_color={'#FFF'}
              $bColor={'#DEE2E4'}
              color={'#5F6364'}
              $hover_color={'#2E3233'}
              $hover_bg={'#FFF'}
              $hover_border={'#DEE2E4'}
            >
              Continue Shopping
            </styled.LinkWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillDetails;
