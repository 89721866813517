import React from 'react';
import { BreadcrumbsLabel } from '.';

const ProductDetailsBreadcrumbs = (props) => {
  return (
    <>
      <BreadcrumbsLabel
        breadcrumbsOne={props.breadcrumbsOne}
        isBack={true}
        breadcrumbsTwo={props.breadcrumbsTwo}
        breadcrumbsTwoPath={'/'}
        breadcrumbsThree={props.breadcrumbsThree}
      />
    </>
  );
};

export default ProductDetailsBreadcrumbs;
