/**
 * The `encrypt` function in JavaScript uses the AES encryption algorithm to encrypt data using a
 * provided key.
 * @param data - The `data` parameter is the information that you want to encrypt. It can be any type
 * of data, such as a string, number, object, or array.
 * @param key - The `key` parameter is a string that is used as the encryption key. It is used to
 * encrypt the `data` parameter using the AES encryption algorithm.
 * @returns The `encrypt` function returns the encrypted data as a string.
 */
import CryptoJS from 'crypto-js';

export const encrypt = (data, key) => {
  try {
    let encryptData = CryptoJS.AES.encrypt(data, key).toString();
    return encryptData;
  } catch (error) {
    throw new Error();
  }
};
