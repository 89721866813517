/* The above code is defining constants for various actions related to user management, user addresses,
and user reviews in a JavaScript application. Each constant represents a specific action and is used
to dispatch actions and handle the corresponding state changes in the application. */

//------------------------------------------------------------------//
// REVIEW CONSTANTS //
//------------------------------------------------------------------//
// ADD REVIEW
export const ADD_REVIEW_REQUEST = 'ADD_REVIEW_REQUEST';
export const ADD_REVIEW_SUCCESS = 'ADD_REVIEW_SUCCESS';
export const ADD_REVIEW_FAILURE = 'ADD_REVIEW_FAILURE';
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// LIST ALL REVIEW
export const LIST_ALL_REVIEW_REQUEST = 'LIST_ALL_REVIEW_REQUEST';
export const LIST_ALL_REVIEW_SUCCESS = 'LIST_ALL_REVIEW_SUCCESS';
export const LIST_ALL_REVIEW_FAILURE = 'LIST_ALL_REVIEW_FAILURE';
//------------------------------------------------------------------//

//------------------------------------------------------------------//
// POST_REVIEW_LIKE_DISLIKE_REQUEST
export const POST_REVIEW_LIKE_DISLIKE_REQUEST =
  'POST_REVIEW_LIKE_DISLIKE_REQUEST';
//------------------------------------------------------------------//
