/* The code you provided is a JavaScript module that defines a userReducer function and exports it. The
userReducer function is a reducer function that handles different actions related to user data and
updates the state accordingly. */
import * as constants from 'redux/constants';

const initialState = {
  user: null,
  userVerify: null,
  loading: false,
  error: null,
  guestUser: {},
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.USER_REQUEST:
    case constants.GOOGLE_SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CREATE_USER_SUCCESS:
    case constants.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action?.payload,
        loading: false,
      };

    // GOOGLE
    case constants.GOOGLE_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action?.payload,
        loading: false,
      };

    case constants.GET_SINGLE_USER_SUCCESS:
    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action?.payload,
        loading: false,
      };

    case constants.GUEST_USER_SUCCESS:
      return {
        ...state,
        guestUser: action?.payload,
        loading: false,
      };

    case constants.OTP_VERIFY_USER_SUCCESS:
      return {
        ...state,
        userVerify: action?.payload,
        loading: false,
      };

    case constants.USER_RESET:
      return {
        user: null,
        userVerify: null,
        loading: false,
        error: null,
        guestUser: null,
      };

    case constants.USER_FAILURE:
    case constants.GOOGLE_SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case constants.USER_LOGOUT:
      return {
        ...state,
        loading: false,
        user: null,
      };

    default:
      return state;
  }
};
