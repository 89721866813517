import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import Paper from '@mui/material/Paper';
import MobileStepper from '@mui/material/MobileStepper';

export const TabMobBtnWrapper = styled.button`
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  font-weight: ${({ $weight }) => ($weight ? $weight : 500)};
  line-height: 20px;
  letter-spacing: -0.32px;
  background-color: ${({ $bg_color }) =>
    $bg_color ? $bg_color : 'rgba(33, 158, 188, 0.05)'};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '35px')};
  padding: 7px 16px;
  color: ${({ color }) => (color ? color : '#219EBC')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => (width ? width : null)};
  margin: ${({ $margin }) => ($margin ? $margin : null)};
  height: fit-content;
`;

export const TabMobLinkWrapper = styled(Link)`
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  font-weight: ${({ $weight }) => ($weight ? $weight : 500)};
  line-height: 20px;
  letter-spacing: -0.32px;
  background-color: ${({ $bg_color }) =>
    $bg_color ? $bg_color : 'rgba(33, 158, 188, 0.05)'};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '35px')};
  padding: 7px 16px;
  color: ${({ color }) => (color ? color : '#219EBC')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => (width ? width : null)};
  margin: ${({ $margin }) => ($margin ? $margin : null)};
  height: fit-content;
`;

export const AntdBottomDrawer = styled(Drawer)`
  border-radius: ${({ $b_radius }) =>
    $b_radius ? '20px 20px 0 0' : $b_radius};
  position: relative;
`;

export const PaperWrapper = styled(Paper)`
  .MuiBottomNavigation-root {
    background-color: #219ebc;
  }

  .MuiButtonBase-root.Mui-selected,
  button {
    color: #fff;
    font-size: 16px;
  }

  button {
    min-width: fit-content;
    max-width: unset;
    display: flex;
    gap: 8px;
    flex-direction: row-reverse;
    font-size: 16px;
  }
`;

export const MobileStepperWrapper = styled(MobileStepper)`
  padding: 0 !important;

  .MuiLinearProgress-root {
    background-color: #eaeef0;
    width: 100%;
  }

  .MuiLinearProgress-bar {
    background-color: #219ebc;
  }
`;
