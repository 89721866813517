import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useOutsideAlerter = (ref, onClick) => {
  const location = useLocation();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick?.();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    onClick?.();
  }, [location]);
};

export default useOutsideAlerter;
