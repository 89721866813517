/* The code you provided is importing components and styles from the Material-UI library and defining
custom styles for a stepper component. */
import * as material from '@mui/material';

// Order status Stepper QontoConnector
export const QontoConnector = material.styled(material.StepConnector)(
  ({ theme }) => ({
    [`&.${material.stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${material.stepConnectorClasses.active}`]: {
      [`& .${material.stepConnectorClasses.line}`]: {
        borderColor: '#219EBC',
      },
    },
    [`&.${material.stepConnectorClasses.completed}`]: {
      [`& .${material.stepConnectorClasses.line}`]: {
        borderColor: '#219EBC',
      },
    },
    [`& .${material.stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      border_topWidth: 2,
      // borderRadius: 1,
    },
  })
);

// Order status Stepper QontoStepIconRoot
export const ColorlibStepIconRoot = material.styled('div')(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#219EBC',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#219EBC',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  })
);

/* The `modelStyle` constant is an object that defines the CSS styles for a model. It includes
properties such as `position`, `top`, `left`, `transform`, `bg_color`, `background`, `border`, `p`,
and `borderRadius`. These styles are used to position and style the model element on the page. */
export const modelStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bg_color: 'background.paper',
  background: '#FFF',
  border: 'none',
  p: 5,
  borderRadius: 5,
};

/* The `dropDownStyles` constant is an object that defines the CSS styles for a dropdown component. It
includes properties such as `padding`, `width`, `border`, `borderRadius`, `backgroundColor`, and
`height`. These styles are used to customize the appearance of the dropdown component, such as its
size, border, and background color. */
export const dropDownStyles = {
  padding: '14px 16px',
  width: '100%',
  border: '1px solid #eaeef0',
  borderRadius: '10px',
  backgroundColor: '#f4f8f9',
  height: '100%',
  outline: 'none',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.28px',
  cursor: 'pointer',
  fontFamily: 'Poppins',
};
