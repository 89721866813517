import { useState, useEffect } from 'react';
import { ApiRecommendedProducts } from 'helper/API';
import { routesConfig } from 'helper/config';
import { useNavigate } from 'react-router-dom';

const useRecommendedProducts = (product) => {
  const [isLoading, setIsLoading] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({
    type: '',
    description: '',
  });

  const navigate = useNavigate();
  const productId = product?.product_frame?._id;
  const category = product?.product_frame?.category_id?.type;

  const handleNavigate = () => {
    navigate(routesConfig?.productList, {
      state: { product: recommendedProducts },
    });
  };

  useEffect(() => {
    if (category) {
      const getRecommendedProducts = async () => {
        setIsLoading(true);
        try {
          const response = await ApiRecommendedProducts(category);

          const filterData = response?.data?.filter((product) => {
            if (product?.product_frame?._id !== productId) {
              return product;
            }
          });
          setRecommendedProducts(filterData);
          setCategoryDetails({
            type: response?.data?.[0]?.product_frame?.category_id?.type,
            description:
              response?.data?.[0]?.product_frame?.category_id?.description,
          });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      getRecommendedProducts();
    }
  }, [category, navigate, productId]);

  return {
    isLoading,
    recommendedProducts,
    categoryDetails,
    handleNavigate,
  };
};

export default useRecommendedProducts;
