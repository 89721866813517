import { useNavigate } from 'react-router-dom';
import { routesConfig } from 'helper/config';
import { Auth } from 'helper/Auth';

const useNavigateWithState = () => {
  const navigate = useNavigate();

  const navigateWithState = (redirectPath, additionalState = {}) => {
    navigate(Auth.isLogin() ? redirectPath : routesConfig.login, {
      state: {
        ...additionalState,
      },
    });
  };

  return navigateWithState;
};

export default useNavigateWithState;
