/* The code you provided is a Redux reducer function. It defines the behavior of the
`frameMaterialReducer` when different actions are dispatched. */
import {
  LIST_ALL_FRAME_MATERIALS_SUCCESS,
  LIST_ALL_FRAME_MATERIALS_REQUEST,
  LIST_ALL_FRAME_MATERIALS_FAILURE,
} from 'redux/constants';

const initialState = {
  frameMaterialList: [],
  loading: false,
  error: null,
};

export const frameMaterialReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ALL_FRAME_MATERIALS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_ALL_FRAME_MATERIALS_SUCCESS:
      return {
        ...state,
        frameMaterialList: action?.payload,
        loading: false,
      };

    case LIST_ALL_FRAME_MATERIALS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};
