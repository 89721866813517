/**
 * The NeedHelp component is a React functional component that renders a div with a paragraph and a
 * button for contacting the support team.
 * @returns The NeedHelp component is returning a JSX element.
 */
import React from 'react';
import './needHelp.css';
import { ORText, Para16Tag } from 'assets/styles';
import { Link } from 'react-router-dom';

const NeedHelp = () => {
  return (
    <div className="need_help_div_main">
      <ORText>OR</ORText>
      <div className="need_help_div_main_support_text">
        <Para16Tag color="#2E3233" $weight="500">
          Contact support at{' '}
          <Link className="text-[#219EBC]" to="mailto:contact@opticshaven.com">
            contact@opticshaven.com{' '}
          </Link>{' '}
          or{' '}
          <Link className="text-[#219EBC]" to="tel:+18882799806">
            8882799806{' '}
          </Link>{' '}
          if you require any support with your order
        </Para16Tag>
      </div>
    </div>
  );
};

export default NeedHelp;
