/* The code is importing necessary modules and creating an instance of the Axios library. */
import axios from 'axios';
import { BASEURL, getHeaders } from 'helper/API';
import { Auth } from 'helper/Auth';

export const API = axios.create({
  headers: getHeaders().then((headers) => {
    return headers;
  }),
  baseURL: BASEURL,
});

API.interceptors.request.use(async (config) => {
  config.headers = await getHeaders();
  return config;
});

API.interceptors.response.use((response) => {
  if (response?.data?.status === 401) {
    Auth.userLogout();
  }
  return response;
});
