/* The above code is a React component written in JavaScript. It is a form component for creating a
user account. */
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import * as typography from 'components/typography';
import * as styles from 'assets/styles';
import { useDispatch } from 'react-redux';
import { createUserAction } from 'redux/actions';
import { Field, Form, Formik } from 'formik';
import { signUpUserSchema } from 'helper';
import { message, routesConfig } from 'helper/config';
import styled from 'pages/desktopView/Auth/Auth.module.css';
import { useAuthentication } from 'hooks';
import { CheckBox, FormErrorMessage } from '..';
import { useSnackbar } from 'context';

const CreateAccountForm = () => {
  /* The `initialValues` object is used to set the initial values of the form fields in the CreateAccount
  component. Each property in the object corresponds to a form field, such as username, email,
  password, mobile, and confirmPassword. The initial values are set to empty strings, indicating that
  the fields are initially empty when the form is rendered. */
  const initialValues = {
    username: '',
    email: '',
    password: '',
    mobile: '',
    confirmPassword: '',
    agreeToPrivacyPolicy: false,
  };

  const { showSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { loading } = useAuthentication();

  /**
   * The handleSubmit function is an asynchronous function that handles form submission, dispatches a
   * createUserAction, and resets the form.
   * @param values - The `values` parameter is an object that contains the form values submitted by the
   * user. It could include fields such as name, email, password, etc.
   * @returns The `handleSubmit` function is returning a Promise.
   */
  const handleSubmit = (values, { resetForm }) => {
    return new Promise((resolve, reject) => {
      try {
        let isNavigate = true;
        dispatch(
          createUserAction(values, navigate, state, isNavigate, showSnackbar)
        );
        resetForm();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <>
      <div className={styled.login_right_inner_outer_info}>
        <div className={styled.login_text_main_title_top_div_inner}>
          <div className={styled.login_text_main_title}>
            <styles.Head48Tag className="text-center screen1280:text-left">
              Let’s Create Account
            </styles.Head48Tag>{' '}
          </div>
        </div>
        <div className="max-w-lg w-full mx-auto screen1280:max-w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={signUpUserSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className={styled.form_input_list_email_pass_other_data}>
                  <div className={styled.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>Name</styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <typography.ProfileIcon />
                      <Field
                        type="name"
                        placeholder="Enter your name"
                        name="username"
                      />
                    </styles.FormInputOuter>
                    {touched.username && errors.username && (
                      <FormErrorMessage errors={errors.username} />
                    )}
                  </div>
                  <div className={styled.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>Email</styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <typography.EmailIcon stroke={'#000000'} />
                      <Field
                        type="email"
                        placeholder="Enter your email address"
                        name="email"
                      />
                    </styles.FormInputOuter>
                    {touched.email && errors.email && (
                      <FormErrorMessage errors={errors.email} />
                    )}
                  </div>
                  <div className={styled.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>Mobile Number</styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <typography.PhoneIcon stroke={'#000000'} />
                      <Field
                        type="text"
                        placeholder="Enter your mobile number"
                        name="mobile"
                      />
                    </styles.FormInputOuter>
                    {touched.mobile && errors.mobile && (
                      <FormErrorMessage errors={errors.mobile} />
                    )}
                  </div>
                  <div className={styled.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>Password</styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <typography.AuthLoginPassword stroke={'#000000'} />
                      <Field
                        type="password"
                        placeholder="Enter password"
                        name="password"
                      />
                    </styles.FormInputOuter>
                    {touched.password && errors.password && (
                      <FormErrorMessage errors={errors.password} />
                    )}
                  </div>
                  <div className={styled.form_input_flex_div_mx_bottom}>
                    <styles.FormInputLabel>
                      Confirm Password
                    </styles.FormInputLabel>
                    <styles.FormInputOuter>
                      <typography.AuthLoginPassword stroke={'#000000'} />
                      <Field
                        type="password"
                        placeholder="Enter confirm password"
                        name="confirmPassword"
                      />
                    </styles.FormInputOuter>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <FormErrorMessage errors={errors.confirmPassword} />
                    )}
                  </div>
                </div>
                <div className={styled.form_get_update_text_data_title}>
                  <label className="mb-8 flex flex-col cursor-pointer items-start">
                    <div className="flex cursor-pointer">
                      <div
                        className="flex cursor-pointer"
                        style={{
                          marginRight: '10px',
                        }}
                      >
                        <CheckBox
                          id="agreeToPrivacyPolicy"
                          name="agreeToPrivacyPolicy"
                        />
                      </div>
                      <div className="flex">
                        <styles.Para16Tag color={'#A7ABAC'}>
                          {'By creating this account, you agree to our '}
                          <Link
                            to={routesConfig?.returnPolicy}
                            className={
                              styled.create_account_para_text_inner_main
                            }
                          >
                            Privacy Policy
                          </Link>
                        </styles.Para16Tag>
                      </div>
                    </div>
                    {touched.agreeToPrivacyPolicy &&
                      errors.agreeToPrivacyPolicy && (
                        <FormErrorMessage
                          errors={errors.agreeToPrivacyPolicy}
                        />
                      )}
                  </label>
                </div>
                <styles.ButtonWrapper type="submit" width="100%">
                  {loading ? message?.IS_LOADING : 'Create Account'}
                </styles.ButtonWrapper>
                <div className={styled.form_continue_text_title_main_inner_}>
                  <styles.Para16Tag color={'#A7ABAC'} center={'center'}>
                    {'Have an account?'}
                  </styles.Para16Tag>
                  <span className={styled.form_continue_text_title_guest_inner}>
                    <Link
                      className={
                        styled.form_continue_text_title_guest_inner_login_link
                      }
                      to={routesConfig?.login}
                    >
                      {'Login'}
                    </Link>
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateAccountForm;
