import React, { useState } from 'react';
import * as commonComponents from 'components/commonComponents';

const useAddPrescription = () => {
  const [selectedId, setSelectedId] = useState(null); // Selected Lens type ID
  const [currentComponent, setCurrentComponent] = useState(null); // Track which component to show

  const handleClick = (itemId) => {
    setSelectedId(itemId);
    const componentMap = {
      1: 'manually',
      2: 'uploadPrescription',
    };
    setCurrentComponent(componentMap[itemId]);
  };

  const handleBackButtonClick = () => {
    setCurrentComponent(null);
  };

  const selectedComponent = (() => {
    switch (currentComponent) {
      case 'manually':
        return <commonComponents.UserPrescriptionManually />;
      case 'uploadPrescription':
        return <commonComponents.UserUploadPrescription />;
      default:
        return (
          <commonComponents.UserPrescriptionOptions
            selectedId={selectedId}
            handelClick={handleClick}
          />
        );
    }
  })();

  return {
    selectedComponent,
    handleBackButtonClick,
    currentComponent,
  };
};

export default useAddPrescription;
