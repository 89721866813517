import { USER_PRESCRIPTION, USE_MY_FRAME_PRESCRIPTION } from 'redux/constants';

export const userPrescriptionAction =
  (userPrescription) => async (dispatch) => {
    try {
      dispatch({
        type: USER_PRESCRIPTION,
        payload: userPrescription,
      });
    } catch (error) {
      throw new Error();
    }
  };

export const UseMyFramePrescriptionAction = (body) => async (dispatch) => {
  try {
    dispatch({
      type: USE_MY_FRAME_PRESCRIPTION,
      payload: body,
    });
  } catch (error) {
    throw new Error();
  }
};
