/* The code is a React component called `ShippingAddressCard`. It is responsible for rendering a list of
shipping addresses. */
import React from 'react';
import { ApiDelete, ApiGetByIdAuthorization } from 'helper/API';
import { Auth } from 'helper/Auth';
import { message, routesConfig } from 'helper/config';
import * as styled from 'assets/styles';
import * as typography from 'components/typography';
import { Link } from 'react-router-dom';
import styles from './ShippingAddressCard.module.css';
import { Loader, NoDataText } from '..';
import { useQuery } from 'react-query';
import { useSnackbar } from 'context';

const ShippingAddressCard = ({ isAccount, title }) => {
  const { data, refetch, isLoading } = useQuery('shippingAddress', async () => {
    const user_id = await Auth?.getUserId();
    const response = await ApiGetByIdAuthorization(
      '/user-billing-details/user',
      user_id
    );
    return response;
  });

  const { showSnackbar } = useSnackbar();

  const handleDelete = async (address_id) => {
    try {
      // API
      const response = await ApiDelete('/user-billing-details', address_id);

      if (response?.data?.message?.status === 200) {
        showSnackbar(
          message?.DELETE_SUCCESS_SHIPPING_ADDRESS,
          'soft',
          'warning'
        );

        refetch();
      } else showSnackbar(message?.SHIPPING_ADDRESS_REMOVE, 'soft', 'danger');
    } catch (error) {
      if (error)
        showSnackbar(message?.SHIPPING_ADDRESS_REMOVE, 'soft', 'danger');
    }
  };

  const shippingAddressList =
    data?.data?.length &&
    data?.data?.map((item, index) => {
      // Check if isAccount is true and index is less than 2
      const shouldRenderItem = isAccount ? index < 2 : true;

      return shouldRenderItem ? (
        <div key={index} className="mb-5 last:mb-0">
          <styled.DashboardDetailsBorder padding={'20px'}>
            <div className={styles.shipping_address_li_inner}>
              <div>
                <div className="flex gap-3">
                  <styled.Para16Tag
                    className="mb-2"
                    $weight={'600'}
                    color={'#2E3233'}
                  >
                    {`${item?.firstname} ${item?.lastname}`}
                  </styled.Para16Tag>
                  <styled.ColorBoxDiv
                    padding={'0 0'}
                    height={'20px'}
                    width={'50px'}
                    $bg_color="#fb85000f"
                  >
                    <styled.Para12Tag className="capitalize" color="#FB8500">
                      {' '}
                      {item?.address_type}{' '}
                    </styled.Para12Tag>
                  </styled.ColorBoxDiv>
                </div>
                <styled.Para16Tag>{item?.address}</styled.Para16Tag>
                <div className={styles.shipping_address_flex_contact}>
                  <styled.Para16Tag>Phone No.</styled.Para16Tag>
                  <styled.Para16Tag color={'#2E3233'} $weight={'500'}>
                    {item?.mobile_no}
                  </styled.Para16Tag>
                </div>
              </div>
              <div className="flex items-start">
                <Link to={routesConfig?.editShipping} state={{ id: item?._id }}>
                  <typography.EditIcons />
                </Link>
                <button onClick={() => handleDelete(item?._id)}>
                  <typography.DeleteIcons />
                </button>
              </div>
            </div>
          </styled.DashboardDetailsBorder>
        </div>
      ) : null;
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : isAccount && data?.data?.length === 0 ? null : data?.data?.length > // If isAccount is true and shippingAddressData is empty, return null
        0 ? (
        <>
          <div>
            {isAccount && data?.data?.length === 0 ? null : (
              <styled.Para20Tag className="mb-4">{title}</styled.Para20Tag>
            )}
            {shippingAddressList}
          </div>
        </>
      ) : (
        <NoDataText text={'No address yet!!'} />
      )}
    </>
  );
};

export default ShippingAddressCard;
