import { Head32Tag, MainPadding, Para16Tag } from 'assets/styles';
import React from 'react';

const ProductDetailsTab = ({ description, productName }) => {
  return (
    <>
      <MainPadding $p_top={40} $p_bottom={40}>
        <Head32Tag className="mb-3">{`About ${productName}`}</Head32Tag>
        <Para16Tag>{description}</Para16Tag>
      </MainPadding>
    </>
  );
};

export default ProductDetailsTab;
