/* The above code is a React component called `UserPrescriptionTable`. It is a table component that
displays the user's prescription information. It receives props such as `leftEyeAttributes`,
`rightEyeAttributes`, `setLeftEyeAttributes`, `setRightEyeAttributes`, `leftEyeAxisSwitch`,
`setLeftEyeAxisSwitch`, `rightEyeAxisSwitch`, `setRightEyeAxisSwitch`, `errors`, `setErrors`,
`checked`, `handleCheckboxChange`, and `isProgressives`. */
import React from 'react';
import * as material from '@mui/material';
import * as styled from 'assets/styles';
import { cylOptions, sphOptions, powerOptions } from 'data';
import './UserPrescriptionTable.css';
import { usePrescriptionContext } from 'context';
import { CheckBoxCustom } from 'components/commonComponents';

const UserPrescriptionTable = (props) => {
  const { isProgressives } = usePrescriptionContext();

  return (
    <>
      <material.Paper
        sx={{
          width: '100%',
          overflow: 'hidden',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        <styled.TableWrapper>
          <material.Table aria-label="simple table">
            <material.TableHead>
              <material.TableRow>
                <material.TableCell style={{ width: '20%' }} align="center">
                  Eye
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  SPH
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  CYL
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  AXIS
                </material.TableCell>
                {isProgressives && (
                  <material.TableCell style={{ width: '20%' }} align="center">
                    ADD
                  </material.TableCell>
                )}
              </material.TableRow>
            </material.TableHead>
            <material.TableBody>
              <material.TableRow
                sx={{
                  border_bottom: 'none',
                }}
              >
                <material.TableCell
                  style={{ width: '20%' }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  OD
                  <p className="text-xs text-[#6f6f6f]">(Right Eye)</p>
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  <styled.SelectWrapper
                    className="text-center"
                    value={props.rightEyeAttributes.sph}
                    onChange={(e) => {
                      if (e.target.value !== 0.0)
                        props.setRightEyeAxisSwitch(false);
                      props.setRightEyeAttributes((prev) => {
                        return { ...prev, sph: e.target.value };
                      });
                    }}
                    borderColor={props.errors?.right?.sph ? 'red' : null}
                    color={props.errors?.right?.sph ? 'red' : null}
                  >
                    {sphOptions[1]?.map((option) => (
                      <option
                        key={option}
                        selected={option === 0.0}
                        value={option}
                      >
                        {typeof option === 'number'
                          ? option > 0
                            ? '+' + option.toFixed(2)
                            : option.toFixed(2)
                          : option}
                      </option>
                    ))}
                  </styled.SelectWrapper>
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  <styled.SelectWrapper
                    className="text-center"
                    value={props.rightEyeAttributes.cyl}
                    onChange={(e) => {
                      if (e.target.value !== 0.0 && e.target.value !== '0') {
                        props.setRightEyeAxisSwitch(false);
                      }
                      props.setRightEyeAttributes((prev) => {
                        return { ...prev, cyl: e.target.value };
                      });
                    }}
                    borderColor={props.errors?.right?.cyl ? 'red' : null}
                    color={props.errors?.right?.cyl ? 'red' : null}
                  >
                    {cylOptions[1]?.map((option) => (
                      <option
                        selected={option === 0.0}
                        key={option}
                        value={option}
                      >
                        {typeof option === 'number'
                          ? option > 0
                            ? '+' + option.toFixed(2)
                            : option.toFixed(2)
                          : option}
                      </option>
                    ))}
                  </styled.SelectWrapper>
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  <input
                    type="text"
                    className={`text-center font-extrabold py-4 px-2 w-full h-full rounded-[10px] border  ${props.errors?.right?.axis ? 'border-[red]' : null
                      }`}
                    placeholder="--"
                    value={props.rightEyeAttributes.axis}
                    disabled={props.rightEyeAxisSwitch}
                    onChange={(e) => {
                      if (
                        props.rightEyeAttributes.axis &&
                        props.rightEyeAttributes.axis !== '0' &&
                        (props.rightEyeAttributes.axis > 180 ||
                          props.rightEyeAttributes.axis < 1)
                      ) {
                        props.setErrors((pre) => {
                          pre.right.axis =
                            'Please check your prescription. This value should not be less the 1 or exceed 180';
                          pre.isValid = false;
                          return { ...pre };
                        });
                      } else {
                        props.setErrors((pre) => {
                          pre.left.axis = '';
                          pre.isValid = true;
                          return { ...pre };
                        });
                      }
                      if (
                        Number(e.target.value) > 180 ||
                        Number(e.target.value) < 1
                      ) {
                        props.setErrors((pre) => {
                          pre.right.axis =
                            'Please check your prescription. This value should not be less the 1 or exceed 180';
                          pre.isValid = false;
                          return { ...pre };
                        });
                      } else {
                        props.setErrors((pre) => {
                          pre.right.axis = '';
                          pre.isValid = true;
                          return { ...pre };
                        });
                      }
                      // if (
                      //   props.rightEyeAttributes.cyl !== 0 &&
                      //   props.rightEyeAttributes.axis !== 0
                      // ) {
                      //   props.setErrors((pre) => {
                      //     pre.right.axis =
                      //       'If you enter a CYL value then you should add an Axis';
                      //     pre.isValid = false;
                      //     return { ...pre };
                      //   });
                      // } else {
                      //   props.setErrors((pre) => {
                      //     pre.right.axis = '';
                      //     pre.isValid = true;
                      //     return { ...pre };
                      //   });
                      // }
                      props.setRightEyeAttributes((prev) => {
                        return {
                          ...prev,
                          axis: !isNaN(e.target.value) ? e.target.value : '',
                        };
                      });
                    }}
                  />
                </material.TableCell>
                {isProgressives && (
                  <material.TableCell style={{ width: '20%' }} align="center">
                    <styled.SelectWrapper
                      className="text-center"
                      value={props.rightEyeAttributes.power}
                      onChange={(e) => {
                        props.setRightEyeAttributes((prev) => {
                          if (e.target.value !== 0.0)
                            props.setRightEyeAxisSwitch(false);
                          return { ...prev, power: e.target.value };
                        });
                      }}
                      defaultValue={''}
                      borderColor={
                        props?.rightEyeAttributes?.power === '' ? 'red' : null
                      }
                      color={
                        props?.rightEyeAttributes?.power === '' ? 'red' : null
                      }
                    >
                      <option value={''} disabled>
                        n/a
                      </option>
                      {powerOptions?.map((option) => (
                        <option
                          selected={option === 0.0}
                          key={option}
                          value={option}
                        >
                          {typeof option === 'number'
                            ? option > 0
                              ? '+' + option.toFixed(2)
                              : option.toFixed(2)
                            : option}
                        </option>
                      ))}
                    </styled.SelectWrapper>
                  </material.TableCell>
                )}
              </material.TableRow>
              <material.TableRow
                sx={{
                  border_bottom: 'none',
                }}
              >
                <material.TableCell
                  style={{ width: '20%' }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  OS
                  <p className="text-xs text-[#6f6f6f]">(Left Eye)</p>
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  <styled.SelectWrapper
                    className="text-center"
                    value={props.leftEyeAttributes.sph}
                    onChange={(e) => {
                      props.setLeftEyeAttributes((prev) => {
                        if (e.target.value !== 0.0)
                          props.setLeftEyeAxisSwitch(false);
                        return { ...prev, sph: e.target.value };
                      });
                    }}
                    borderColor={props.errors?.left?.sph ? 'red' : null}
                    color={props.errors?.left?.sph ? 'red' : null}
                  >
                    {sphOptions[0]?.map((option) => (
                      <option
                        selected={option === 0.0}
                        key={option}
                        value={option}
                      >
                        {typeof option === 'number'
                          ? option > 0
                            ? '+' + option.toFixed(2)
                            : option.toFixed(2)
                          : option}
                      </option>
                    ))}
                  </styled.SelectWrapper>
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  <styled.SelectWrapper
                    className="text-center"
                    value={props.leftEyeAttributes.cyl}
                    onChange={(e) => {
                      props.setLeftEyeAttributes((prev) => {
                        if (e.target.value !== 0.0)
                          props.setLeftEyeAxisSwitch(false);
                        return { ...prev, cyl: e.target.value };
                      });
                    }}
                    borderColor={props.errors?.left?.cyl ? 'red' : null}
                    color={props.errors?.left?.cyl ? 'red' : null}
                  >
                    {cylOptions[0]?.map((option) => (
                      <option
                        selected={option === 0.0}
                        key={option}
                        value={option}
                      >
                        {typeof option === 'number'
                          ? option > 0
                            ? '+' + option.toFixed(2)
                            : option.toFixed(2)
                          : option}
                      </option>
                    ))}
                  </styled.SelectWrapper>
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  <input
                    type="text"
                    className={`text-center font-extrabold py-4 px-2 w-full h-full rounded-[10px] border ${props.errors?.left?.axis ? 'border-[red]' : null
                      }`}
                    placeholder="--"
                    disabled={props.leftEyeAxisSwitch}
                    value={props.leftEyeAttributes.axis}
                    onChange={(e) => {
                      if (
                        props.leftEyeAttributes.axis &&
                        props.leftEyeAttributes.axis !== '0' &&
                        (props.leftEyeAttributes.axis > 180 ||
                          props.leftEyeAttributes.axis < 1)
                      ) {
                        props.setErrors((pre) => {
                          pre.left.axis =
                            'Please check your prescription. This value should not be less the 1 or exceed 180';
                          pre.isValid = false;
                          return { ...pre };
                        });
                      } else {
                        props.setErrors((pre) => {
                          pre.right.axis = '';
                          pre.isValid = true;
                          return { ...pre };
                        });
                      }
                      if (
                        Number(e.target.value) > 180 ||
                        Number(e.target.value) < 1
                      ) {
                        props.setErrors((pre) => {
                          pre.left.axis =
                            'Please check your prescription. This value should not be less the 1 or exceed 180';
                          pre.isValid = false;
                          return { ...pre };
                        });
                      } else {
                        props.setErrors((pre) => {
                          pre.left.axis = '';
                          pre.isValid = true;
                          return { ...pre };
                        });
                      }
                      props.setLeftEyeAttributes((prev) => {
                        return {
                          ...prev,
                          axis: !isNaN(e.target.value) ? e.target.value : '',
                        };
                      });
                    }}
                  />
                </material.TableCell>
                {isProgressives && (
                  <material.TableCell style={{ width: '20%' }} align="center">
                    <styled.SelectWrapper
                      className="text-center"
                      value={props.leftEyeAttributes.power}
                      onChange={(e) => {
                        props.setLeftEyeAttributes((prev) => {
                          if (e.target.value !== 0.0)
                            props.setLeftEyeAxisSwitch(false);
                          return { ...prev, power: e.target.value };
                        });
                      }}
                      defaultValue={''}
                      borderColor={
                        props?.leftEyeAttributes?.power === '' ? 'red' : null
                      }
                      color={
                        props?.leftEyeAttributes?.power === '' ? 'red' : null
                      }
                    >
                      <option value={''} disabled>
                        {' '}
                        n/a{' '}
                      </option>
                      {powerOptions?.map((option) => (
                        <option
                          selected={option === 0.0}
                          key={option}
                          value={option}
                        >
                          {typeof option === 'number'
                            ? option > 0
                              ? '+' + option.toFixed(2)
                              : option.toFixed(2)
                            : option}
                        </option>
                      ))}
                    </styled.SelectWrapper>
                  </material.TableCell>
                )}
              </material.TableRow>
              <material.TableRow
                sx={{
                  border_bottom: 'none',
                }}
              >
                <material.TableCell
                  style={{ width: '20%' }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  PD
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  <styled.SelectWrapper
                    className="text-center"
                    value={props.rightEyeAttributes.pd}
                    onChange={(e) => {
                      props.setRightEyeAttributes((prev) => {
                        return { ...prev, pd: e.target.value };
                      });
                    }}
                  >
                    {props.checked ? (
                      <>
                        <option value="">Right PD</option>
                        {sphOptions[3]?.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {sphOptions[2]?.map((option) => (
                          <option
                            selected={option === 63}
                            key={option}
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </>
                    )}
                  </styled.SelectWrapper>
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  {props.checked && (
                    <styled.SelectWrapper
                      className="text-center"
                      value={props.leftEyeAttributes.pd}
                      onChange={(e) => {
                        props.setLeftEyeAttributes((prev) => {
                          return { ...prev, pd: e.target.value };
                        });
                      }}
                    >
                      <option value="">Left PD</option>
                      {cylOptions[2]?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </styled.SelectWrapper>
                  )}
                </material.TableCell>
                <material.TableCell align="center"></material.TableCell>
                {isProgressives && (
                  <material.TableCell align="center"></material.TableCell>
                )}
              </material.TableRow>
            </material.TableBody>
          </material.Table>
        </styled.TableWrapper>
      </material.Paper>

      <div className="lense_prescription_manually_text_add_inner_div">
        <label className="cursor-pointer flex">
          <CheckBoxCustom
            id="agreeTwoPDNumber"
            name="agreeTwoPDNumber"
            checked={props.checked}
            onChange={props.handleCheckboxChange}
          />
          <styled.Para18Tag className="ml-[10px]" $weight={600}>
            {'2 PD number (Select this option only if your prescription includes two PD Values)'}
          </styled.Para18Tag>
        </label>
      </div>
    </>
  );
};

export default UserPrescriptionTable;
