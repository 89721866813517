/* This code is defining a React component called `EmptyCard`. */
import React from 'react';
import * as styles from 'assets/styles';
import { routesConfig } from 'helper/config';

const EmptyCard = ({ emptyImg, emptyText, continueShopping }) => {
  return (
    <div className="flex items-center flex-col justify-center">
      <img loading="lazy" src={emptyImg} alt="img" />
      <styles.Head32Tag className="py-9" color={'#0e1213'} $weight={600}>
        {emptyText}
      </styles.Head32Tag>
      {continueShopping ? (
        <styles.LinkWrapper to={routesConfig?.productList}>
          Continue Shopping
        </styles.LinkWrapper>
      ) : null}
    </div>
  );
};

export default EmptyCard;
