import { Auth } from 'helper/Auth';
import { useQuery } from 'react-query';
import { API } from 'utils/axios';

const useQueryFetch = (key, apiEndpoint, staleTime = 3000) => {
  const user_id = Auth?.getUserId();

  const { data, isLoading, refetch } = useQuery(
    key,
    () => API.get(apiEndpoint),
    {
      staleTime: staleTime,
      cacheTime: 30000,
      enabled: !!user_id,
    }
  );

  const fetchData = () => {
    // Manually trigger the API call when needed
    refetch();
  };

  return { data: data?.data, isLoading, fetchData };
};

export default useQueryFetch;
