import React from 'react';
import './lenseUploadPrescription.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as styled from 'assets/styles';
import { addItemToCart } from 'redux/actions';
import { message, routesConfig } from 'helper/config';
import { BtnRightArrow } from 'components/typography';
import { ApiPostDocsPrescription } from 'helper/API';
import { usePrescriptionContext, useSnackbar } from 'context';
import { Auth } from 'helper/Auth';
import UserUploadImg from '../userPrescription/userUploadPrescription/UserUploadImg';
import { STORAGE_KEYS } from 'helper/APP';
import { IoClose } from 'react-icons/io5';
import { CheckBoxCustom } from 'components/commonComponents';
import { sessionStorageHandler } from 'utils';

const LenseUploadPrescription = ({ handleBackButtonClick, closeButton }) => {
  const [uploadChecked, setUploadChecked] = React.useState(false);

  const {
    camOn,
    setCamOn,
    prescriptionImages,
    setPrescriptionImages,
    reset,
    camera,
    handleImageChange,
    lensSelectionFlowData,
  } = usePrescriptionContext();

  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userID = Auth.getUserId();
  const products = useSelector((store) => store.products);
  const { frameSize } = useSelector((store) => store.frameSize);
  const {
    selectedProduct: {
      product_frame: { _id: productID, price: productPrice },
    },
  } = products;
  const lensSelection = useSelector((store) => store.lensSelection);
  const { selectedLensData, item_price } = lensSelection;

  const allPrices = [productPrice];

  /* The code is iterating over the values of the `lensSelectionFlowData` object and checking if each value has a valid `price` property. If the value has a valid `price` property (not NaN), it is added to the `allPrices` array using the `push()` method. */
  Object.values(lensSelectionFlowData).forEach((x) => {
    !isNaN(x?.price) && allPrices.push(x?.price);
  });

  /* The `totalPrice` variable is calculated using the `reduce()` method on the `allPrices` array. */
  const totalPrice = allPrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const handleSubmit = async () => {
    if (uploadChecked) {
      try {
        if (Auth.isLogin()) {
          const { status } = await ApiPostDocsPrescription(prescriptionImages);

          if (status === 200) {
            showSnackbar(
              message?.PRESCRIPTION_SUBMITTED_SUCCESS,
              'soft',
              'success'
            );
          }

          const cartRequestBody = {
            user_id: userID,
            items: [
              {
                prescription_data: {
                  is_image_prescription: true,
                  image_prescription: prescriptionImages,
                },
                product_frame_detail_id: frameSize?.frame_id,
                product_frame_id: productID,
                lenses: selectedLensData,
                quantity: 1,
                item_price: totalPrice,
                frame_size_id: frameSize?.sizeId,
              },
            ],
            final_amount: totalPrice,
          };
          dispatch(addItemToCart(cartRequestBody, navigate, 'soft', 'success'));
          reset();
        } else {
          sessionStorageHandler.setItem(
            STORAGE_KEYS.prescriptionData,
            JSON.stringify({
              prescriptionImages,
            })
          );
          const cartList = JSON.parse(
            sessionStorageHandler.getItem(STORAGE_KEYS.cartList) || '[]'
          );
          cartList?.push({
            product_frame_detail_id: products.selectedProduct?.frames?.filter(
              (frame) => frame?._id === frameSize?.frame_id
            )?.[0],
            product_frame_id: products.selectedProduct,
            lenses: selectedLensData,
            _id: productID,
            quantity: 1,
            item_price: totalPrice,
            prescription_data: {
              is_image_prescription: true,
              image_prescription: prescriptionImages,
            },
            frame_size_id: frameSize?.sizeId,
          });
          sessionStorageHandler.setItem(
            STORAGE_KEYS.cartList,
            JSON.stringify(cartList)
          );
          const cartData = JSON.parse(
            sessionStorageHandler.getItem(STORAGE_KEYS.cartData) ||
              JSON.stringify({
                items: [],
                final_amount: 0,
              })
          );
          cartData.items.push({
            product_frame_detail_id: frameSize?.frame_id,
            product_frame_id: productID,
            lenses: selectedLensData,
            quantity: 1,
            item_price: totalPrice,
            prescription_data: {
              is_image_prescription: true,
              image_prescription: prescriptionImages,
            },
            frame_size_id: frameSize?.sizeId,
          });
          const final = cartData.items.reduce((total, data) => {
            return total + (data?.item_price || 0);
          }, 0);
          cartData.final_amount = final;
          sessionStorageHandler.setItem(
            STORAGE_KEYS.cartData,
            JSON.stringify(cartData)
          );
          navigate(routesConfig.shippingCart);
          reset();
        }
      } catch (error) {
        showSnackbar(error, 'soft', 'danger');
      }
    } else {
      showSnackbar(
        'Check the box before proceeding to the next page.',
        'soft',
        'danger'
      );
    }
  };

  return (
    <>
      <div>
        <div className="hidden screen1280:block">
          <div className="flex items-center gap-3 pb-[14px] mb-[14px] border-b-4">
            <button onClick={closeButton}>
              <IoClose size={20} color="#868A8B" />
            </button>
            <styled.Para16Tag>Upload Prescription</styled.Para16Tag>
          </div>
        </div>
        <div>
          <styled.Head32Tag
            className="screen1280:hidden"
            color={'#2E3233'}
            $weight={600}
          >
            Upload Prescription
          </styled.Head32Tag>
          <styled.Para16Tag className="py-4 screen1280:pt-0" $weight={600}>
            Have a Saved Prescription?{' '}
            <span className="text-[#FB8500]">
              {' '}
              <Link to={routesConfig?.addNewPrescription}>
                Go to Add Prescription
              </Link>{' '}
            </span>
          </styled.Para16Tag>
        </div>
        <UserUploadImg
          camOn={camOn}
          setCamOn={setCamOn}
          prescriptionImages={prescriptionImages}
          setPrescriptionImages={setPrescriptionImages}
          camera={camera}
          takePhoto={camera?.current?.takePhoto}
          handleImageChange={handleImageChange}
        />

        {/* <img src={prescriptionImages} alt="" /> */}

        <label className="cursor-pointer flex items-baseline gap-[10px] py-4">
          <CheckBoxCustom
            id="uploadChecked"
            name="uploadChecked"
            checked={uploadChecked}
            onChange={() => setUploadChecked(!uploadChecked)}
          />
          <styled.Para16Tag className="italic !font-bold">
            By selecting this box, the customer affirms that they are 18 years
            and older and that their eye prescription is correct, was obtained
            from a licensed optometrist/ophthalmologist and valid under state
            law. Optics Haven assumes no liability for any inaccuracies in the
            prescription provided on this site.
          </styled.Para16Tag>
        </label>

        <styled.Para16Tag color="red">
          Note: To avoid delay in processing your orders, Residents of
          California and Nevada should upload their prescription as we cannot
          ship self reported prescriptions to their state
        </styled.Para16Tag>

        <div className="flex items-center mt-10 justify-center screen1280:hidden">
          <styled.ButtonWrapper onClick={handleSubmit}>
            Submit Prescription
            <BtnRightArrow />
          </styled.ButtonWrapper>
        </div>
      </div>

      <div className="hidden screen1280:block py-4">
        <div className="flex justify-between gap-4">
          <styled.ButtonWrapper
            onClick={handleBackButtonClick}
            $bg_color={'#FFF'}
            $bColor={'#DEE2E4'}
            color={'#5F6364'}
            $hover_color={'#2E3233'}
            $hover_bg={'#FFF'}
            $hover_border={'#DEE2E4'}
            width={'100%'}
            $border_color={'#DEE2E4'}
          >
            Back
          </styled.ButtonWrapper>
          <styled.ButtonWrapper onClick={handleSubmit} width={'100%'}>
            Submit Prescription
          </styled.ButtonWrapper>
        </div>
      </div>
    </>
  );
};

export default LenseUploadPrescription;
