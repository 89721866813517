/**
 * The `useAuthentication` function is a custom hook that handles user authentication logic in a React
 * application.
 * @returns The `useAuthentication` hook returns an object with the following properties:
 */
import React from 'react';
import { useApp, useSnackbar } from 'context';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSingleUserAction } from 'redux/actions';
import { Auth } from 'helper/Auth';
import { message, routesConfig } from 'helper/config';
import { googleLogout } from '@react-oauth/google';
import { resetUserData } from 'redux/actions/userAction';

const useAuthentication = () => {
  const { authenticated, setAuthenticated } = useApp();

  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();

  const { user, loading } = useSelector((state) => state.userAuth);

  /**
   * The function `handleUserLogout` logs the user out, sets the authentication status to false,
   * navigates to the home page, and displays a success toast message.
   */
  const handleUserLogout = () => {
    setAuthenticated(false);
    Auth?.userLogout();
    dispatch(resetUserData());
    showSnackbar(message.LOGGED_OUT_SUCCESS, 'soft', 'success');
    googleLogout();
  };

  /* The `handleGetUser` variable is a memoized function that is created using the `React.useMemo` hook.
    It is responsible for fetching the user data by dispatching the `getSingleUserAction` action. */
  const handleGetUser = React.useMemo(
    () => async () => {
      try {
        await dispatch(getSingleUserAction(showSnackbar, handleUserLogout));
      } catch (error) {
        throw new Error();
      }
    },
    [dispatch, Auth.getToken()]
  );

  /* The `React.useEffect` hook is used to perform side effects in a functional component. In this case,
    it is used to fetch the user data when the `authenticated` state changes. */
  React.useEffect(() => {
    if (authenticated) handleGetUser();
  }, [authenticated, handleGetUser]);

  return {
    user,
    loading,
    authenticated,
    handleUserLogout,
  };
};

export default useAuthentication;
