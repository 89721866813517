/**
 * The above code is a React component that renders a radio button with a label.
 * @returns The RadioButton component is being returned.
 */
import React from 'react';
import { RadioButtonContainer, RadioInput, RadioLabel } from 'assets/styles';

const RadioButton = ({
  onChange,
  radio_id,
  isSelected,
  radio_name,
  label,
  value,
}) => {
  return (
    <RadioButtonContainer>
      <RadioInput
        id={radio_id}
        name={radio_name}
        onChange={onChange}
        value={value}
        checked={isSelected}
      />
      <RadioLabel htmlFor={radio_id}>{label}</RadioLabel>
    </RadioButtonContainer>
  );
};

export default RadioButton;
