import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getAllProducts } from 'redux/actions'; // Import your API functions

const useProduct = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const dispatch = useDispatch();

  const { categoryList } = useSelector((store) => store.category);
  const { productList, loading } = useSelector((store) => store.products);

  const category = searchParams.getAll('categoryId');
  const [categoryId, setCategoryId] = useState(
    category?.length ? category : []
  );
  const frameTypes = searchParams.getAll('frameType');
  const frameShapes = searchParams.getAll('frameShape');
  const frameColors = searchParams.getAll('frameColor');
  const frameSizes = searchParams.getAll('frameSize');
  const frameMaterial = searchParams.getAll('frameMaterial');
  const genders = searchParams.getAll('gender');
  const productSortBy = searchParams.getAll('sortBy');
  const [sortBy, setSortBy] = useState(
    productSortBy?.length ? productSortBy : ['new_arrival']
  );

  const memoizedGetAllProducts = useMemo(() => {
    setSelectedCategory(categoryList?.find((x) => x.id === category?.[0]));
    return (
      category,
      frameTypes,
      frameShapes,
      frameColors,
      frameSizes,
      frameMaterial,
      genders,
      sortBy
    ) => {
      dispatch(
        getAllProducts(
          category,
          frameTypes,
          frameShapes,
          frameColors,
          frameSizes,
          frameMaterial,
          genders,
          sortBy
        )
      );
    };
  }, [dispatch, searchParams, categoryList]);

  useEffect(() => {
    memoizedGetAllProducts(
      category,
      frameTypes,
      frameShapes,
      frameColors,
      frameSizes,
      frameMaterial,
      genders,
      sortBy
    );
  }, [memoizedGetAllProducts]);

  return {
    selectedCategory,
    categoryList,
    productList,
    loading,
    categoryId,
    frameTypes,
    frameShapes,
    frameColors,
    frameSizes,
    frameMaterial,
    genders,
    sortBy,
    setCategoryId,
    setSortBy,
    searchParams,
    setSearchParams,
  };
};

export default useProduct;
