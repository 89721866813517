import React from 'react';
import './reviewTab.css';
import * as joy from '@mui/joy';
import * as styled from 'assets/styles';
import * as typography from 'components/typography';
import * as formik from 'formik';
import * as actions from 'redux/actions';
import * as commonComponents from 'components/commonComponents';
import { productReviewSchema } from 'helper';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'helper/config';
import { Auth } from 'helper/Auth';
import { useAuthentication, useNavigateWithState } from 'hooks';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'context';

const ProductReview = ({ product }) => {
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [averageReview, setAverageReview] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rating, setRating] = React.useState(0);

  const { showSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const location = useLocation();

  const { authenticated } = useAuthentication();
  const navigateWithState = useNavigateWithState();
  const { reviewList, avg } = useSelector((state) => state?.reviews);

  /* The above code is using the `useEffect` hook from the React library. It is being used to perform
  some actions when the component is mounted or when the `dispatch` value changes. */
  React.useEffect(() => {
    /**
     * The function `getAllReviews` is an asynchronous function that retrieves all reviews for a specific
     * product and sets the data in the state.
     */
    const getAllReviews = async () => {
      let body = { product_frame_id: product?._id };
      setIsLoading(true);

      try {
        // API
        await dispatch(actions.listReviews(body));

        /* The below code is checking if the value of `data.avg` is NaN (Not a Number).
      If it is NaN, it returns 0. Otherwise, it returns the value of `data.avg`. */
        setAverageReview(isNaN(avg) ? 0 : avg);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    getAllReviews();
  }, [dispatch, open, avg, product?._id]);

  let initialValues = {
    comment: '',
  };

  const handleRatingChange = (newRating) => setRating(newRating);

  const itemsPerPage = 6; // Number of items to display per page*

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleNextClick = () => setCurrentPage((prevPage) => prevPage + 1);
  const handlePrevClick = () => setCurrentPage((prevPage) => prevPage - 1);

  // Calculate the index of the first and last items on the current page*
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items to display*
  const currentItems = reviewList?.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages*
  const totalPages = Math.ceil(reviewList?.length / itemsPerPage);

  /**
   * The handleOpen function sets the state variable "open" to true.
   */
  const handleOpen = () => {
    if (authenticated) {
      setOpen(true);
    } else {
      navigateWithState(location.pathname, {
        redirect: location.pathname,
      });
    }
  };

  /**
   * The handleClose function sets the value of the "open" variable to false.
   */
  const handleClose = () => setOpen(false);

  /**
   * The `handleSubmit` function is an asynchronous function that handles the submission of a product
   * review by making an API call using Axios.
   * @param values - The `values` parameter is an object that contains the form values submitted by the
   * user. It likely includes properties such as `rating` and `review` which are used to create the
   * `body` object for the API request.
   */
  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);

    // Axios Body
    let body = {
      user_id: Auth.getUserId(),
      product_frame_id: product?._id,
      rating: rating,
      comment: values?.comment,
    };

    try {
      if (rating > 0) {
        // API
        await dispatch(actions.createReview(body, showSnackbar));

        resetForm();
        handleClose();
        setIsLoading(false);
      } else {
        showSnackbar(message?.RATING_STAR, 'soft', 'danger');
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <styled.MainPadding $p_top={40} $p_bottom={40}>
        {isLoading ? (
          <commonComponents.Loader />
        ) : (
          <>
            <div className="review_tab_inner_outer_frame_inner mb-4">
              {averageReview && reviewList?.length ? (
                <div className="review_tab_group">
                  <h3 className="review_tab_main_rating_top_add">
                    {averageReview}
                  </h3>
                  <div className="review_tab_frame-parent32">
                    <div className="review_tab_num_add_inner">
                      <commonComponents.ReviewRating
                        name="read-only"
                        value={averageReview}
                        readOnly
                        max={5}
                        size={24}
                        isHalf={true}
                      />
                    </div>
                    <styled.Para16Tag $weight={500} color={'#2E3233'}>
                      {`${reviewList?.length} reviews`}
                    </styled.Para16Tag>
                  </div>
                </div>
              ) : null}
            </div>

            {currentItems?.length ? (
              <div className="grid grid-cols-2 mobile:grid-cols-1 gap-4">
                {currentItems?.map((review, index) => {
                  return (
                    <commonComponents.ReviewCard key={index} review={review} />
                  );
                })}
              </div>
            ) : null}

            <div
              className={`text-center mt-4 ${
                averageReview && reviewList?.length ? null : 'm-auto'
              }`}
            >
              <styled.Para18Tag className="mb-3">
                Review this product
              </styled.Para18Tag>
              <styled.Para16Tag className="mb-3">
                Share your thoughts with other customers
              </styled.Para16Tag>
              <styled.ButtonWrapper
                onClick={handleOpen}
                $bg_color={'#FFF'}
                $border_color={'#DEE2E4'}
                color={'#5F6364'}
                $hover_color={'#2E3233'}
                $hover_bg={'#FFF'}
                $hover_border={'#DEE2E4'}
                className="m-auto"
              >
                Write a customer review
              </styled.ButtonWrapper>
            </div>
          </>
        )}
      </styled.MainPadding>

      {reviewList.length > itemsPerPage && (
        <div className="review_tab_num_add_inner_main gap-1">
          <button
            onClick={handlePrevClick}
            disabled={currentPage === 1}
            className="review_tab_bottom_left_pagination"
          >
            <typography.PaginationLeftArrow />
          </button>

          {Array.from({ length: totalPages }, (_, index) => (
            <button
              className={`review_tab_div29 ${
                currentPage === index + 1 ? 'active' : ''
              }`}
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={handleNextClick}
            disabled={currentPage === totalPages}
            className="review_tab_bottom_left_pagination"
          >
            <typography.PaginationRightArrow />
          </button>
        </div>
      )}

      {/* review_model */}
      <joy.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <joy.Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <div>
            <div onClick={handleClose}>
              <commonComponents.ModalCloseButton />
            </div>
            <div className="review_model_inner_head_main">
              <styled.Head32Tag color="#2E3233" $weight="600">
                Write A Review
              </styled.Head32Tag>
            </div>
            <styled.Para18Tag $weight={600}>
              How would you rate the Product ?
            </styled.Para18Tag>
            <div className="rating_main_div">
              <commonComponents.AddReviewRating
                value={rating}
                onChange={handleRatingChange}
              />
            </div>

            {/* Formik Form */}
            <formik.Formik
              initialValues={initialValues}
              validationSchema={productReviewSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <formik.Form className="flex flex-col gap-4">
                  <div>
                    <styled.FormInputLabel>Review</styled.FormInputLabel>
                    <formik.Field
                      as="textarea"
                      placeholder="Enter your review message"
                      name="comment"
                      id="comment"
                    />
                    {touched.comment && errors.comment && (
                      <commonComponents.FormErrorMessage
                        errors={errors.comment}
                      />
                    )}
                  </div>
                  <styled.ButtonWrapper margin={'auto'} type="submit">
                    {isLoading ? message?.IS_LOADING : 'Post Review'}
                  </styled.ButtonWrapper>
                </formik.Form>
              )}
            </formik.Formik>
          </div>
        </joy.Sheet>
      </joy.Modal>
    </>
  );
};

export default ProductReview;
