import CustomSnackbar from 'components/commonComponents/CustomSnackbar';
import React, { createContext, useContext, useState } from 'react';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    variant: 'soft',
    color: 'success',
  });

  const showSnackbar = (message, variant = 'soft', color = 'success') => {
    setSnackbarConfig({ open: true, message, variant, color });
  };

  const closeSnackbar = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <CustomSnackbar
        open={snackbarConfig.open}
        message={snackbarConfig.message}
        onClose={closeSnackbar}
        variant={snackbarConfig.variant}
        color={snackbarConfig.color}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
