import { useEffect, useState } from 'react';
import useAuthentication from './useAuthentication';
import { updateUserAction } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'context';

const useUserProfile = (initialUserData = {}) => {
  const [userData, setUserData] = useState({
    userName: '',
    firstname: '',
    lastname: '',
    email: '',
    is_google_login: false,
    is_apple_login: false,
    ...initialUserData,
  });
  const [gender, setGender] = useState('');
  const [sendProfileImg, setSendProfileImg] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [changePasswordPopup, setChangePasswordPopup] = useState(false);

  const { showSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { user, loading } = useAuthentication();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    profileImage === null && setProfileImage(null);
    setSendProfileImg(null);
  };

  const handleChangeUserData = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleChangeImage = (event) => {
    if (event.target.files?.[0]?.size > 1000000) {
      setProfileImage(null);
      setSendProfileImg(null);
    }

    if (event?.target?.files) {
      setProfileImage(URL.createObjectURL(event.target.files?.[0]));
      setSendProfileImg(event.target.files?.[0]);
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      let userId = user?._id;

      const updateUser = new FormData();
      updateUser.append('username', userData?.userName);
      updateUser.append('firstname', userData?.firstname);
      updateUser.append('lastname', userData?.lastname);
      updateUser.append('gender', gender);
      sendProfileImg && updateUser.append('profile_picture', sendProfileImg);

      await dispatch(
        updateUserAction(
          updateUser,
          userId,
          setProfileImage,
          handleClose,
          showSnackbar
        )
      );
    } catch (error) {
      throw new Error();
    }
  };

  useEffect(() => {
    const handleGetUserLoading = () => {
      return new Promise((resolve, reject) => {
        try {
          setUserData({
            userName: user?.username,
            firstname: user?.firstname || '',
            lastname: user?.lastname,
            email: user?.email,
            is_google_login: user?.is_google_login,
            is_apple_login: user?.is_apple_login,
          });
          if (user?.gender === 'male') {
            setGender('male');
          } else if (user?.gender === 'female') {
            setGender('female');
          } else setGender('');

          setProfileImage(user?.profile_picture);

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };

    handleGetUserLoading();
  }, [user]);

  return {
    userData,
    gender,
    setGender,
    profileImage,
    open,
    handleOpen,
    handleClose,
    handleChangeUserData,
    handleChangeImage,
    changePasswordPopup,
    setChangePasswordPopup,
    loading,
    handleUpdateUser,
  };
};

export default useUserProfile;
