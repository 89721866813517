/* The code you provided is a Redux reducer function for managing the state related to products. */
import {
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_REQUEST,
  LOADING_FILTER_DATA,
  SET_FILTER_DATA,
  SET_SELECTED_PRODUCT,
} from 'redux/constants';

const initialState = {
  productList: [],
  loading: false,
  selectedProduct: null,
  loadingFilterData: false,
  filterData: {
    type: [],
    shape: [],
    color: [],
    size: [],
  },
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        productList: action.payload,
        loading: false,
      };

    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };

    case LOADING_FILTER_DATA:
      return {
        ...state,
        loadingFilterData: true,
      };

    case SET_FILTER_DATA:
      return {
        ...state,
        loadingFilterData: false,
        filterData: action.payload,
      };

    default:
      return state;
  }
};
