/**
 * The `ProductCard` component is a reusable component that displays information about a product,
 * including its image, price, rating, and other details.
 * @returns The ProductCard component is returning a JSX element.
 */
import * as styles from 'assets/styles';
import { useApp } from 'context';
import { addRecentlyViewedProduct } from 'helper/API/API.request';
import { Auth } from 'helper/Auth';
import { device, routesConfig } from 'helper/config';
import { useWishList } from 'hooks';
import React, { useState } from 'react';
import { AiOutlineHeart } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SET_SELECTED_PRODUCT } from 'redux/constants';
import { styled } from 'styled-components';
import { ReviewRating } from '..';
import './productCard.css';

const ProductCard = ({ product }) => {
  // Display the product card
  const [hoveredImage, setHoveredImage] = React.useState(null);
  // Create a state variable to store the active color id
  const [activeColor, setActiveColor] = React.useState(null);
  const [selectedFramesId, setSelectedFramesId] = useState('');

  const { authenticated } = useApp();
  const userId = Auth?.getUserId();
  const dispatch = useDispatch();

  const sizes = [];
  const colors = [];

  const handleImageHover = (imageSrc, colorId) => {
    setHoveredImage(imageSrc);
    setActiveColor(colorId);
  };

  const { handleWishList } = useWishList();

  const createRecentlyViewedProduct = async (
    user_id,
    product_frame_detail_id
  ) => {
    try {
      await addRecentlyViewedProduct({
        user_id,
        product_frame_detail_id,
      });
    } catch (error) {
      throw new Error();
    }
  };

  /* The code snippet is iterating over the `frames` array of the `product` object and for each
  `frame`, it is iterating over the `frame_sizes` array. It checks if the `frame_size_id` of each
  `frame_size` is not already included in the `sizes` array. If it is not included, it adds the
  `frame_size_id`'s `frame_size` value to the `sizes` array. This is done to collect all unique
  frame sizes from the `frames` array. */
  product?.frames?.map((item) => {
    return item?.frame_sizes?.forEach((item) => {
      if (!sizes.includes(item?.frame_size_id?.frame_size)) {
        sizes.push(item?.frame_size_id?.frame_size);
      }
    });
  });

  /* The code snippet is iterating over the `frames` array of the `product` object. For each `frame`
  object, it is extracting the `colorName`, `colorImage`, and `colorThumbnailImage` properties from
  the `color_id` and `thumbnail_picture` properties respectively. */
  product?.frames?.map((item) => {
    const colorName = item?.color_id?.color_name;
    if (colorName && !colors.some((color) => color.name === colorName)) {
      colors.push({
        id: item?.color_id?._id,
        name: colorName,
        colorImage: item?.color_id?.image,
        thumbnailImage: item?.thumbnail_picture,
        images: item?.images,
        tryOnPicture: item?.try_on_picture,
        framesId: item?._id,
      });
    }
  });

  // const allSizes = sizes.join(', ');
  const allSizes = sizes?.[0];

  React?.useEffect(() => {
    setHoveredImage(colors?.[0]?.thumbnailImage);
    setActiveColor(colors?.[0]?.id);
  }, [product]);

  return (
    <>
      <div className="latest_pro_inner_main_div_glass">
        <Link
          to={`${routesConfig?.productDetails}/${product?.product_frame?._id}`}
          state={{
            avgRating: product?.product_frame?.avgRating,
            totalReview: product?.product_frame?.totalReview,
            selectedColor: activeColor,
          }}
          onClick={() => {
            dispatch({ type: SET_SELECTED_PRODUCT, payload: product });
            const findActiveColorIndex = product?.frames?.findIndex(
              (x) => x?.color_id?._id === activeColor
            );
            authenticated &&
              createRecentlyViewedProduct(
                userId,
                product?.frames?.[findActiveColorIndex]?._id
              );
          }}
        >
          <ProductImg
            loading="lazy"
            src={hoveredImage || product?.frames?.[0]?.thumbnail_picture}
            alt="product"
          />
        </Link>
        <div className="latest_product_add_review_list">
          <div className="flex items-center justify-between mb-3">
            <div
              className={`latest_inner_product_add_review ${
                product?.product_frame?.avgRating &&
                product?.product_frame?.totalReview > 0
                  ? 'visible'
                  : 'invisible'
              }`}
            >
              <ReviewRating
                name="read-only"
                value={1}
                readOnly
                max={1}
                size={20}
                count={1}
                isHalf={true}
              />
              <div className="latest_product_all_rating_view_add_depend_product_submit_view">
                <styles.Para16Tag $weight="600">
                  {product?.product_frame?.avgRating}
                </styles.Para16Tag>
                <styles.Para16Tag
                  color={'#2E3233'}
                >{`(${product?.product_frame?.totalReview} Review)`}</styles.Para16Tag>
              </div>
            </div>
            {/* <button className="h-8 w-8 bg-white rounded-lg backdrop-blur-md flex items-center justify-center border border-gray-400">
              <PiUserFocus size={20} />
            </button> */}
          </div>
          <div className="flex">
            <div>
              <Link
                to={`${routesConfig?.productDetails}/${product?.product_frame?._id}`}
                state={{
                  avgRating: product?.product_frame?.avgRating,
                  totalReview: product?.product_frame?.totalReview,
                }}
                onClick={() => {
                  dispatch({ type: SET_SELECTED_PRODUCT, payload: product });
                  const findActiveColorIndex = product?.frames?.findIndex(
                    (x) => x?.color_id?._id === activeColor
                  );
                  authenticated &&
                    createRecentlyViewedProduct(
                      userId,
                      product?.frames?.[findActiveColorIndex]?._id
                    );
                }}
              >
                <styles.Para18Tag className="mb-1">
                  {product?.product_frame?.product_name}
                </styles.Para18Tag>
              </Link>
              <div className="flex">
                <styles.Para16Tag color="#2E3233">{`Size : ${allSizes}`}</styles.Para16Tag>
                <div className="latest_product_size_add">
                  <styles.SpanDots $bg_color={'#219ebc'} />
                  <styles.Para16Tag color="#2E3233">
                    {process.env.REACT_APP_PUBLIC_NAME}
                  </styles.Para16Tag>
                </div>
              </div>
            </div>
            <button
              onClick={() =>
                handleWishList(selectedFramesId || product?.frames[0]?._id)
              }
              className="ml-auto"
            >
              <AiOutlineHeart size={24} color={'#FF8787'} />
            </button>
          </div>
        </div>
        <div className="latest_product_price_color_tax_info">
          <div className="latest_product_inner_price_text_outer_color_add_num">
            <div>
              <styles.Para16Tag className="mb-1" color="#2E3233">
                Price
              </styles.Para16Tag>
              <div className="latest_product_price_dollar_num_plush_text">
                <styles.Para20Tag color="#2e3233" $weight="600">
                  {' '}
                  {`$${product?.product_frame?.price}`}
                </styles.Para20Tag>
                <styles.Para12Tag className="ml-1">(+Tax)</styles.Para12Tag>
              </div>
            </div>
            <div className="latest_product_num_add_color">
              <div className="flex gap-2">
                {colors?.map((color) => {
                  return (
                    <styles.ColorWrapper
                      key={color.id}
                      className={color.id === activeColor ? 'active' : ''}
                      width={22}
                      height={22}
                      $color_img={color?.colorImage}
                      onClick={() => {
                        handleImageHover(color?.thumbnailImage, color?.id);
                        setSelectedFramesId(color.framesId);
                      }}
                      title={color?.name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ProductImg = styled.img`
  object-fit: cover;
  width: ${({ $img_width }) => ($img_width ? `${$img_width}px` : '528px')};
  // width: 100%;
  border-radius: 18px 18px 0 0;
  margin: auto;
  height: 269px;

  ${device.maxW_1280} {
    width: 482px;
    // height: 334px;
  }

  ${device.maxW_800} {
    height: 200px;
  }

  ${device.maxW_720} {
    width: 335px;
  }

  // ${device.maxW_576} {
  //   width: 232px;
  // }
`;

export default ProductCard;
