/* The code snippet is defining an object named `message` that contains various string constants. These
constants are used for displaying messages in different scenarios in a JavaScript application. */

const YUP_REQUIRED_MESSAGE = 'Required!';

/* The code snippet is defining an object named `message` that contains various string constants. These
constants are used for displaying messages in different scenarios in a JavaScript application. Each
constant represents a specific message that can be used for error handling, validation, or user
interface feedback. The `message` object is exported using the `export` keyword, which allows other
modules to import and use these message constants. */
export const message = {
  // ERROR messages
  NETWORK_ERROR:
    'No internet connection Cannot connect to the server, Check your internet network',
  ERROR_TRY_AGAIN: 'Something went wrong! Try again in a few minutes',
  LOGIN_USER_INVALID_EMAIL: 'Invalid email Please check your credentials.',
  LOGIN_USER_INVALID_EMAIL_OR_PASSWORD:
    'Invalid email or password Please check your credentials.',

  // validation Messages
  YUP_REQUIRED: YUP_REQUIRED_MESSAGE,
  YUP_EMAIL: 'Invalid email',
  YUP_TOO_SHORT: 'Too Short!',
  YUP_TOO_LONG: 'Too Long!',
  YUP_INVALID_NUMBER: 'Mobile number is not valid',
  YUP_PASSWORD_MIN: 'Password must be at least 8 characters',
  YUP_PASSWORD_MAX: 'Password can be at most 50 characters',
  YUP_PASSWORD_NOT_MATCH: 'Password does not match',
  YUP_MUST_AGREE_TO_PRIVACY_POLICY: 'You must agree to the Privacy Policy',

  // SIgn In
  EMAIL_REQUIRED: `Email must be provided!`,

  // Address
  ADD_SUCCESS_REVIEW: 'Your review is added successfully',
  ADD_SUCCESS_SHIPPING_ADDRESS: 'Shipping address added successfully.',
  UPDATE_SUCCESS_SHIPPING_ADDRESS: 'Shipping address updated successfully.',
  DELETE_SUCCESS_SHIPPING_ADDRESS: 'Shipping address deleted successfully.',

  // Review
  RATING_STAR: 'Please! select rating star',
  REVIEW_COMMENT: `Review message is ${YUP_REQUIRED_MESSAGE}`,

  // shipping address
  ADDRESS_REQUIRED: `Address is ${YUP_REQUIRED_MESSAGE}`,
  SELECT_STATE: 'Please! Select a state',
  SELECT_CITY: 'Please! Select a city',
  ZIP_CODE: `Zip Code is ${YUP_REQUIRED_MESSAGE}`,
  SHIPPING_ADDRESS_REMOVE: 'Failed to delete shipping address!',
  SHIPPING_ADDRESS_FAILURE_UPDATE: 'Failed to update address!',

  // Prescription
  ADD_PRESCRIPTION_SUCCESS: 'Your prescription has been added successfully.',
  UPDATE_PRESCRIPTION_SUCCESS:
    'Your prescription has been updated successfully.',
  DELETE_PRESCRIPTION_SUCCESS:
    'Your prescription has been deleted successfully.',
  PRESCRIPTION_FAILURE_ADD: 'Failed to add prescription!',
  PRESCRIPTION_FAILURE_UPDATE: 'Failed to update prescription!',
  PRESCRIPTION_FAILURE_DELETE: 'Failed to delete prescription!',

  // RIGHT EYE
  RIGHT_EYE_AXIS: `Right eye axis is ${YUP_REQUIRED_MESSAGE}`,
  RIGHT_EYE_CYL: `Right eye Cylindrical Power is ${YUP_REQUIRED_MESSAGE}`,
  RIGHT_EYE_PD: `Right eye PD is ${YUP_REQUIRED_MESSAGE}`,
  RIGHT_EYE_SPH: `Right eye Spherical Power is ${YUP_REQUIRED_MESSAGE}`,

  // LEFT EYE
  LEFT_EYE_AXIS: `Left eye axis is ${YUP_REQUIRED_MESSAGE}`,
  LEFT_EYE_CYL: `Left eye Cylindrical Power is ${YUP_REQUIRED_MESSAGE}`,
  LEFT_EYE_PD: `Left eye PD is ${YUP_REQUIRED_MESSAGE}`,
  LEFT_EYE_SPH: `Left eye Spherical Power is ${YUP_REQUIRED_MESSAGE}`,

  // User
  FIRST_NAME: `First Name is ${YUP_REQUIRED_MESSAGE}`,
  LAST_NAME: `Last Name is ${YUP_REQUIRED_MESSAGE}`,

  // Use My Frame
  UPLOAD_ONLY_THREE_IMAGE: 'Limit: Select Up to Three Images',

  // SUCCESS
  PRESCRIPTION_SUBMITTED_SUCCESS: 'Prescription Submitted successfully!',
  RESEND_OTP_SUCCESS: 'Verified code is resent successfully!',
  PROFILE_UPDATE_SUCCESS: 'User profile is updated successfully!',
  LOGIN_SUCCESS: 'You are logged in successfully!',
  LOGGED_OUT_SUCCESS: 'You have successfully logged out of the optics haven',
  COPYCLIPBOARD: 'Referral code copied to clipboard.',
  CART_ADD_SUCCESS: 'Item successfully added to cart',

  IS_LOADING: 'Loading...',
};
