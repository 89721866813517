import React, { useState } from 'react';
import { usePrescriptionContext } from 'context';
import * as commonComponents from 'components/commonComponents';

const useLensePrescriptionSteps = (setFinished, closeButton) => {
  const [selectedId, setSelectedId] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(null);

  const { reset } = usePrescriptionContext();

  const handelClick = (itemId) => {
    setSelectedId(itemId);
    const componentMap = {
      1: 'manually',
      2: 'uploadPrescription',
      3: 'review',
    };
    setCurrentComponent(componentMap[itemId]);
  };

  const handleBackButtonClick = () => {
    if (currentComponent === 'review') {
      handelClick(1);
    } else {
      if (!currentComponent) {
        if (currentComponent === 'manually') {
          reset?.();
        }
        setFinished(() => {
          return false;
        });
      }
      setCurrentComponent(null);
    }
  };

  const selectedComponent = (() => {
    switch (currentComponent) {
      case 'manually':
        return (
          <commonComponents.LensePrescriptionManually
            handelClick={handelClick}
            handleBackButtonClick={handleBackButtonClick}
            closeButton={closeButton}
          />
        );
      case 'uploadPrescription':
        return (
          <commonComponents.LenseUploadPrescription
            handleBackButtonClick={handleBackButtonClick}
            closeButton={closeButton}
          />
        );
      case 'review':
        return (
          <commonComponents.ReviewSelections
            handleBackButtonClick={handleBackButtonClick}
            closeButton={closeButton}
          />
        );
      default:
        return (
          <commonComponents.UserPrescriptionOptions
            selectedId={selectedId}
            handelClick={handelClick}
            handleBackButtonClick={handleBackButtonClick}
            closeButton={closeButton}
          />
        );
    }
  })();

  return {
    handleBackButtonClick,
    selectedComponent,
  };
};

export default useLensePrescriptionSteps;
