import { useEffect, useState } from 'react';
import { Auth } from 'helper/Auth';
import { getHeaders, ManageNotificationAPI } from 'helper/API';
import { useSnackbar } from 'context';
import { message } from 'helper/config';

const useManageNotification = () => {
  const [email, setEmail] = useState(false);
  const [push, setPush] = useState(false);
  const [sms, setSms] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);

  const userId = Auth.getUserId();

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const getNotificationsStates = async (userId) => {
      try {
        const headers = await getHeaders();

        const response = await ManageNotificationAPI.get(`/user/${userId}`, {
          headers,
        });

        if (response?.data?._id) {
          setEmail(response?.data?.is_enable_email);
          setPush(response?.data?.is_enable_push);
          setSms(response?.data?.is_enable_sms);
          setWhatsapp(response?.data?.is_enable_whatsapp);
        }
      } catch (error) {
        if (error) showSnackbar(message?.ERROR_TRY_AGAIN, 'soft', 'danger');
        else if (error?.message === 'Network Error')
          showSnackbar(message?.NETWORK_ERROR, 'soft', 'danger');
        else throw error;
      }
    };

    getNotificationsStates(userId);
  }, [userId]);

  const updateNotification = async (body) => {
    try {
      const headers = await getHeaders();

      // Axios Body
      const axiosBody = {
        user_id: userId,
        ...body,
      };

      const response = await ManageNotificationAPI.post('/', axiosBody, {
        headers,
      });

      if (response?.data?.message?.status == 200) {
        setEmail(response?.data?.settings?.is_enable_email);
        setPush(response?.data?.settings?.is_enable_push);
        setSms(response?.data?.settings?.is_enable_sms);
        setWhatsapp(response?.data?.settings?.is_enable_whatsapp);
      } else {
        showSnackbar(
          'Error while updating notification! Please try again',
          'soft',
          'danger'
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleChange = async (type, isChecked) => {
    const body = {
      user_id: userId,
      is_enable_whatsapp: whatsapp,
      is_enable_sms: sms,
      is_enable_push: push,
      is_enable_email: email,
    };

    switch (type) {
      case 'email':
        setEmail(isChecked);
        body.is_enable_email = isChecked;
        await updateNotification(body);
        break;
      case 'push':
        setPush(isChecked);
        body.is_enable_push = isChecked;
        await updateNotification(body);
        break;
      case 'sms':
        setSms(isChecked);
        body.is_enable_sms = isChecked;
        await updateNotification(body);
        break;
      case 'whatsapp':
        setWhatsapp(isChecked);
        body.is_enable_whatsapp = isChecked;
        await updateNotification(body);
        break;

      default:
        break;
    }
  };

  return {
    handleChange,
    email,
    push,
    sms,
    whatsapp,
    setEmail,
    setPush,
    setSms,
    setWhatsapp,
  };
};

export default useManageNotification;
