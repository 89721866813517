import React from 'react';
import * as styled from 'assets/styles';
import { message } from 'helper/config';
import { useQuery } from 'react-query';
import { API } from 'utils/axios';
import { IoWalletOutline } from 'react-icons/io5';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { CopyClipboardIcon } from 'components/typography';
import { useSnackbar } from 'context';

const UserReferralProgram = () => {
  const [open, setOpen] = React.useState(false);

  const { showSnackbar } = useSnackbar();

  const { data } = useQuery('refers', () => API.get('/users/refer-code'), {
    staleTime: 30000,
  });

  const handleCopyCode = () => {
    if (data?.data?.referral_code) {
      /* The `navigator.clipboard.writeText(data?.data?.referral_code)` line of code is using the Clipboard API
    to write the `data?.data?.referral_code` value to the user's clipboard. */
      navigator.clipboard.writeText(data?.data?.referral_code);
      showSnackbar(message.COPYCLIPBOARD, 'soft', 'success');
    }
  };

  return (
    <>
      <styled.DashboardDetailsBorder padding={'24px'}>
        <div className="text-center">
          <styled.Head24Tag className="mb-3">
            Share your Referral Code
          </styled.Head24Tag>
          <styled.Para16Tag className="mb-4" color="#2E3233">
            Copy the code below and send it to your friends!
          </styled.Para16Tag>

          <styled.Para20Tag className="underline">
            {data?.data?.referral_code}
          </styled.Para20Tag>

          <div className="flex justify-center flex-wrap gap-4 mt-6">
            <styled.ButtonWrapper onClick={handleCopyCode}>
              <CopyClipboardIcon />
              Copy to clipBoard
            </styled.ButtonWrapper>
            <styled.ButtonWrapper onClick={() => setOpen(true)}>
              <IoWalletOutline size={20} />
              <span className="ml-2">Check your Wallet</span>
            </styled.ButtonWrapper>
          </div>
        </div>
      </styled.DashboardDetailsBorder>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Optics Haven Refer & End Rewards
          </Typography>
          <Typography
            component="h2"
            id="modal-title"
            level="h2"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            {`$${data?.data?.refer_discount}`}{' '}
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary">
            Clearly presents the current balance in the user&apos;s wallet,
            providing an instant overview of available funds.
          </Typography>
        </Sheet>
      </Modal>
    </>
  );
};

export default UserReferralProgram;
