import React from 'react';
import * as styled from 'assets/styles';
import * as formik from 'formik';
import * as countryStateCity from 'country-state-city';
import * as config from 'helper/config';
import { shippingAddressSchema } from 'helper';
import { useEditAddressForm } from 'hooks';
import { CheckBox, FormErrorMessage, RadioButton } from '.';

const EditShippingForm = () => {
  const { isLoading, formValues, handleSubmit } = useEditAddressForm();

  return (
    <>
      <formik.Formik
        initialValues={formValues}
        validationSchema={shippingAddressSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <formik.Form>
            <styled.FormGrid grid_cols={2} gap={2}>
              <div>
                <styled.FormInputLabel>First Name</styled.FormInputLabel>
                <styled.FormInputOuter>
                  <formik.Field
                    type="text"
                    placeholder="Enter your first name"
                    name="firstname"
                  />
                </styled.FormInputOuter>
                {touched.firstname && errors.firstname && (
                  <FormErrorMessage errors={errors.firstname} />
                )}
              </div>
              <div>
                <styled.FormInputLabel>Last Name</styled.FormInputLabel>
                <styled.FormInputOuter>
                  <formik.Field
                    type="text"
                    placeholder="Enter your last name"
                    name="lastname"
                  />
                </styled.FormInputOuter>
                {touched.lastname && errors.lastname && (
                  <FormErrorMessage errors={errors.lastname} />
                )}
              </div>
              <div>
                <styled.FormInputLabel>Mobile Number</styled.FormInputLabel>
                <styled.FormInputOuter>
                  <formik.Field
                    type="text"
                    placeholder="Enter your mobile number"
                    name="mobile_no"
                  />
                </styled.FormInputOuter>
                {touched.mobile_no && errors.mobile_no && (
                  <FormErrorMessage errors={errors.mobile_no} />
                )}
              </div>
              <div>
                <styled.FormInputLabel>Email Address</styled.FormInputLabel>
                <styled.FormInputOuter>
                  <formik.Field
                    type="email"
                    placeholder="Enter your email address"
                    name="email"
                  />
                </styled.FormInputOuter>
                {touched.email && errors.email && (
                  <FormErrorMessage errors={errors.email} />
                )}
              </div>
            </styled.FormGrid>
            <styled.BorderDivider $m_bottom={20} m_top={20} />
            <styled.FormGrid grid_cols={2} gap={2}>
              <div>
                <styled.FormInputLabel>Address </styled.FormInputLabel>
                <styled.FormInputOuter>
                  <formik.Field
                    type="text"
                    placeholder="Enter your address one"
                    name="address"
                  />
                </styled.FormInputOuter>
                {touched.address && errors.address && (
                  <FormErrorMessage errors={errors.address} />
                )}
              </div>
              <div>
                <styled.FormInputLabel>Zip Code</styled.FormInputLabel>
                <styled.FormInputOuter>
                  <formik.Field
                    type="text"
                    placeholder="Enter your zip code"
                    name="zipcode"
                  />
                </styled.FormInputOuter>
                {touched.zipcode && errors.zipcode && (
                  <FormErrorMessage errors={errors.zipcode} />
                )}
              </div>

              <div>
                <styled.FormInputLabel>State</styled.FormInputLabel>
                <div className="form_input_main_inner">
                  <styled.SelectWrapperField
                    as="select"
                    name="state"
                    value={values.state}
                    onChange={(e) => {
                      setFieldValue('state', e.target.value);
                      setFieldValue('city', ''); // Reset city when state changes
                    }}
                  >
                    <option value="">Select a State</option>
                    {/* Map your state options here based on country */}
                    {countryStateCity.State?.getStatesOfCountry('US').map(
                      (state) => (
                        <option key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </option>
                      )
                    )}
                  </styled.SelectWrapperField>
                </div>
                {touched.state && errors.state && (
                  <FormErrorMessage errors={errors.state} />
                )}
              </div>

              <div>
                <styled.FormInputLabel>City</styled.FormInputLabel>
                <div className="form_input_main_inner">
                  <styled.SelectWrapperField
                    as="select"
                    name="city"
                    value={values.city}
                    onChange={(e) => setFieldValue('city', e.target.value)}
                  >
                    <option value="">Select a City</option>
                    {/* Map your city options here based on state */}
                    {values.state &&
                      countryStateCity.City.getCitiesOfState(
                        'US', // Pass country instead of state
                        values.state
                      ).map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                  </styled.SelectWrapperField>
                </div>
                {touched.city && errors.city && (
                  <FormErrorMessage errors={errors.city} />
                )}
              </div>
              <div>
                <styled.FormInputLabel>Address Type</styled.FormInputLabel>
                <div className="flex items-center gap-3">
                  <label className="flex items-center cursor-pointer">
                    <RadioButton
                      radio_id="home_radio2"
                      isSelected={'home'}
                      radio_name="address_type"
                      value="home"
                      onChange={(e) =>
                        setFieldValue('address_type', e.target.value)
                      }
                    />
                    <styled.Para16Tag color={'#2E3233'}>
                      {'Home'}
                    </styled.Para16Tag>
                  </label>
                  <label className="flex items-center cursor-pointer">
                    <RadioButton
                      radio_id="work_radio"
                      isSelected={values?.address_type === 'work'}
                      radio_name="address_type"
                      value="work"
                      onChange={(e) =>
                        setFieldValue('address_type', e.target.value)
                      }
                    />
                    <styled.Para16Tag color={'#2E3233'}>
                      {'Work'}
                    </styled.Para16Tag>
                  </label>
                  {touched.address_type && errors.address_type && (
                    <FormErrorMessage errors={errors.address_type} />
                  )}
                </div>
              </div>
              <label className="w-fit cursor-pointer flex items-start flex-col">
                <div className="cursor-pointer flex">
                  <div className="cursor-pointer flex mr-m10">
                    <CheckBox
                      id="is_default_address"
                      name="is_default_address"
                    />
                  </div>
                  <styled.Para16Tag color={'#A7ABAC'}>
                    {'Default Address '}
                  </styled.Para16Tag>
                </div>
                {touched.is_default_address && errors.is_default_address && (
                  <FormErrorMessage errors={errors.is_default_address} />
                )}
              </label>
            </styled.FormGrid>
            <div className="flex items-center justify-end mt-4">
              <styled.LinkWrapper
                to={config.routesConfig?.shippingAddress}
                className="mr-6 screen1280:!hidden"
                $bg_color={'#FFF'}
                $bColor={'#DEE2E4'}
                color={'#5F6364'}
                $hover_color={'#2E3233'}
                $hover_bg={'#FFF'}
                $hover_border={'#DEE2E4'}
              >
                Cancel
              </styled.LinkWrapper>
              <styled.ButtonWrapper className="screen1280:w-full" type="submit">
                {isLoading ? config.message?.IS_LOADING : 'Save & Proceed'}
              </styled.ButtonWrapper>
            </div>
          </formik.Form>
        )}
      </formik.Formik>
    </>
  );
};

export default EditShippingForm;
