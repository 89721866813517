/* The above code is importing various images and icons from the assets folder and components folder.
It is also importing the routesConfig from the config folder. The code is written in JavaScript and
is likely part of a React application. */
import React from 'react';
import { BsTelephone } from 'react-icons/bs';
import * as fa from 'react-icons/fa';
import * as images from 'assets/images';
import * as typography from 'components/typography';
import { routesConfig } from 'helper/config';
import * as pdf from 'assets/pdf';
import { BorderDivider } from 'assets/styles';

/* ------------------------- */
// 💡💡 Common data 💡💡
/* ------------------------- */

// socialMediaIcons
export const socialMediaIconsData = [
  {
    icon: <fa.FaFacebookF />,
    link: 'https://www.facebook.com/profile.php?id=61557482311838',
  },
  { icon: <typography.XIcon />, link: 'https://x.com/opticshaven' },
  { icon: <fa.FaTiktok />, link: 'https://www.tiktok.com/@opticshaven' },
  {
    icon: <fa.FaLinkedinIn />,
    link: 'https://www.linkedin.com/company/opticshaven',
  },
  { icon: <fa.FaInstagram />, link: 'https://www.instagram.com/opticshaven' },
];

// sortByFilterData
export const sortByFilterData = [
  { value: 'low_to_high_price', label: 'Price (Low to High)' },
  { value: 'high_to_low_price', label: 'Price (High to Low)' },
  { value: 'highest_avg_rating', label: 'Avg. Customer Review' },
  { value: 'new_arrival', label: 'Newest Arrivals' },
];

// framesGender Data
export const framesGenderData = [
  {
    gender: 'Male',
    value: 'male',
  },
  {
    gender: 'Female',
    value: 'female',
  },
  {
    gender: 'Boy',
    value: 'boys',
  },
  {
    gender: 'Girl',
    value: 'girls',
  },
  {
    gender: 'Other',
    value: 'other',
  },
];

/* ------------------------- */
// 💡💡 DeskTop data 💡💡
/* ------------------------- */

// ============================ //
// Desktop view Data
// ============================ //

// Footer Links
export const footerLinks = [
  {
    label: 'New Arrivals',
    to: routesConfig?.productList + '?sortBy=new_arrival',
  },
  { label: 'Use Your Frame', to: routesConfig?.useMyFrame },
];

// frameShapeData
export const frameShapeData = [
  {
    id: 1,
    images: images.frame_shape1,
    title: 'Rectangle',
    frame: 'product_sidebar_main_frame_brand_glass',
  },
  {
    id: 2,
    images: images.frame_type2,
    title: 'Square',
    frame: 'product_sidebar_main_frame_brand_glass',
  },
  {
    id: 3,
    images: images.frame_type3,
    title: 'Round',
    frame: 'product_sidebar_main_frame_brand_glass_inner',
  },
  {
    id: 4,
    images: images.frame_shape4,
    title: 'Cat Eye',
    frame: 'product_sidebar_main_frame_brand_glass',
  },
  {
    id: 5,
    images: images.frame_shape5,
    title: 'Geometric',
    frame: 'product_sidebar_main_frame_brand_glass',
  },
  {
    id: 6,
    images: images.frame_shape6,
    title: 'Aviator',
    frame: 'product_sidebar_main_frame_brand_glass_inner',
  },
  {
    id: 7,
    images: images.frame_shape7,
    title: 'Wayfarer',
    frame: 'product_sidebar_main_frame_brand_glass',
  },
  {
    id: 8,
    images: images.frame_shape8,
    title: 'Hexagonal',
    frame: 'product_sidebar_main_frame_brand_glass_inner',
  },
];

// product Data
export const productData = [
  {
    id: 1,
    images: images.latest_product1,
    review: '4.6',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Medium',
    frameName: 'John Jacobs',
    price: 59,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: true,
  },
  {
    id: 2,
    images: images.latest_product2,
    review: '4.3',
    title: 'Transparent Full Rim Wayfarer Eyeglasses',
    size: 'Wide',
    frameName: 'Sleek Steel',
    price: 59,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 3,
    images: images.latest_product3,
    review: '4.1 ',
    reviewNum: '205',
    title: 'Amber Orange Full Rim Hustlr Eyeglasses',
    size: 'Extra Wide',
    frameName: 'John Jacobs',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 4,
    images: images.latest_product4,
    review: '4.6',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Medium',
    frameName: 'Sleek Steel',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 5,
    images: images.latest_product5,
    review: '4.9',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Wide',
    frameName: 'John Jacobs',
    price: 60,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 6,
    images: images.latest_product6,
    review: '3.9',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Wide',
    frameName: 'Sleek Steel',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 7,
    images: images.latest_product1,
    review: '4.6',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Medium',
    frameName: 'John Jacobs',
    price: 59,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 8,
    images: images.latest_product2,
    review: '4.3',
    title: 'Transparent Full Rim Wayfarer Eyeglasses',
    size: 'Wide',
    frameName: 'Sleek Steel',
    price: 59,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 9,
    images: images.latest_product3,
    review: '4.1 ',
    reviewNum: '205',
    title: 'Amber Orange Full Rim Hustlr Eyeglasses',
    size: 'Extra Wide',
    frameName: 'John Jacobs',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 10,
    images: images.latest_product4,
    review: '4.6',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Medium',
    frameName: 'Sleek Steel',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 11,
    images: images.latest_product5,
    review: '4.9',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Wide',
    frameName: 'John Jacobs',
    price: 60,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 12,
    images: images.latest_product6,
    review: '3.9',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Wide',
    frameName: 'Sleek Steel',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 13,
    images: images.latest_product1,
    review: '4.6',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Medium',
    frameName: 'John Jacobs',
    price: 59,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 14,
    images: images.latest_product2,
    review: '4.3',
    title: 'Transparent Full Rim Wayfarer Eyeglasses',
    size: 'Wide',
    frameName: 'Sleek Steel',
    price: 59,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 15,
    images: images.latest_product3,
    review: '4.1 ',
    reviewNum: '205',
    title: 'Amber Orange Full Rim Hustlr Eyeglasses',
    size: 'Extra Wide',
    frameName: 'John Jacobs',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 16,
    images: images.latest_product4,
    review: '4.6',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Medium',
    frameName: 'Sleek Steel',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 17,
    images: images.latest_product5,
    review: '4.9',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Wide',
    frameName: 'John Jacobs',
    price: 60,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 18,
    images: images.latest_product6,
    review: '3.9',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Wide',
    frameName: 'Sleek Steel',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 19,
    images: images.latest_product1,
    review: '4.6',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Medium',
    frameName: 'John Jacobs',
    price: 59,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 20,
    images: images.latest_product2,
    review: '4.3',
    title: 'Transparent Full Rim Wayfarer Eyeglasses',
    size: 'Wide',
    frameName: 'Sleek Steel',
    price: 59,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 21,
    images: images.latest_product3,
    review: '4.1 ',
    reviewNum: '205',
    title: 'Amber Orange Full Rim Hustlr Eyeglasses',
    size: 'Extra Wide',
    frameName: 'John Jacobs',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 22,
    images: images.latest_product4,
    review: '4.6',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Medium',
    frameName: 'Sleek Steel',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 23,
    images: images.latest_product5,
    review: '4.9',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Wide',
    frameName: 'John Jacobs',
    price: 60,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
  {
    id: 24,
    images: images.latest_product6,
    review: '3.9',
    title: 'Maroon Black Full Rim Cat Eyeglasses',
    size: 'Wide',
    frameName: 'Sleek Steel',
    price: 58,
    allReview: 205,
    colors: ['#FFB703', '#8ECAE6', '#82191D', '#1C7ED6'],
    colorActive: false,
  },
];

// Order Data
export const orderData = [
  {
    id: 1,
    orderID: 125656898,
    image: images.order1,
    orderDate: '22 May 2023',
    orderStatus: 'Placed Order',
    title: 'Black Rectangle Rimmed Eyeglasses',
    details: '+ Hydrophobic Anti-Glare',
    seller: 'Sold by Optics Haven',
    amount: 420,
  },
  {
    id: 2,
    orderID: 125656898,
    image: images.order1,
    orderDate: '22 May 2023',
    orderStatus: 'Return Order',
    title: 'Black Rectangle Rimmed Eyeglasses',
    details: '+ Hydrophobic Anti-Glare',
    seller: 'Sold by Optics Haven',
    amount: 420,
  },
  {
    id: 3,
    orderID: 125656898,
    image: images.order1,
    orderDate: '22 May 2023',
    orderStatus: 'Cancelled Order',
    title: 'Black Rectangle Rimmed Eyeglasses',
    details: '+ Hydrophobic Anti-Glare',
    seller: 'Sold by Optics Haven',
    amount: 420,
  },
  {
    id: 4,
    orderID: 125656898,
    image: images.order1,
    orderDate: '22 May 2023',
    orderStatus: 'Buy Again',
    title: 'Black Rectangle Rimmed Eyeglasses',
    details: '+ Hydrophobic Anti-Glare',
    seller: 'Sold by Optics Haven',
    amount: 420,
  },
  {
    id: 5,
    orderID: 125656898,
    image: images.order1,
    orderDate: '22 May 2023',
    orderStatus: 'Not Shipped Yet',
    title: 'Black Rectangle Rimmed Eyeglasses',
    details: '+ Hydrophobic Anti-Glare',
    seller: 'Sold by Optics Haven',
    amount: 420,
  },
];

// Shipping Cart
export const shippingCart = [
  {
    id: 1,
    image: images.cart1,
    title: 'Black Rectangle Rimmed Eyeglasses',
    details: 'Hydrophobic Anti-Glare',
    price: 3400,
    subPrice: 20,
    color: 'Black',
    size: 'Large',
    quantity: 1,
    mainPrice: 180,
  },
  {
    id: 2,
    image: images.cart1,
    title: 'Black Rectangle Rimmed Eyeglasses',
    details: 'Hydrophobic Anti-Glare',
    price: 3400,
    subPrice: 20,
    color: 'Yellow',
    size: 'Large',
    quantity: 1,
    mainPrice: 140,
  },
  {
    id: 3,
    image: images.cart1,
    title: 'Black Rectangle Rimmed Eyeglasses',
    details: 'Hydrophobic Anti-Glare',
    price: 3400,
    subPrice: 20,
    color: 'Blue',
    size: 'Large',
    quantity: 2,
    mainPrice: 150,
  },
];

// Faqs page
// Eye wear Accordion
// Type Lenses Data
export const typeLensesData = [
  {
    id: 1,
    images: images.eye_wear_lenses1,
    title: 'Anti-Glare or Anti-Reflactive',
    description: [
      'Also known as AR coating',
      'Eliminates reflection & glare',
      'Improves vision',
    ],
  },
  {
    id: 2,
    images: images.eye_wear_lenses2,
    title: 'UV Protection',
    description: [
      'Also known as AR coating',
      'Eliminates reflection & glare',
      'Improves vision',
    ],
  },
  {
    id: 3,
    images: images.eye_wear_lenses3,
    title: 'Hydrophobic Anti-Glare Lenses',
    description: [
      'Also known as AR coating',
      'Eliminates reflection & glare',
      'Improves vision',
    ],
  },
];

// prescriptionData Data
export const prescriptionData = [
  {
    id: 1,
    image: images.eye_wear_prescription1,
    title: 'Single vision',
    fieldVision: 'One',
    visionRange: 'Near or Distant',
    visibleDistinction: 'No Visible Distanction',
    correction: 'Corrects Myopia, hypermetropia & Astigmatism',
  },
  {
    id: 2,
    image: images.eye_wear_prescription2,
    title: 'Bifocal',
    fieldVision: 'One',
    visionRange: 'Near or Distant',
    visibleDistinction: 'No Visible Distanction',
    correction: 'Corrects Myopia, hypermetropia & Astigmatism',
  },
  {
    id: 3,
    image: images.eye_wear_prescription3,
    title: 'Progressive',
    fieldVision: 'One',
    visionRange: 'Near or Distant',
    visibleDistinction: 'No Visible Distanction',
    correction: 'Corrects Myopia, hypermetropia & Astigmatism',
  },
];

// Frames faqs Details
export const FramesFaqsData = [
  {
    id: 1,
    title: 'Trying On Frames',
    description:
      'You can see how the frames look on you by using our Virtual AR Technology . Simply follow the instructions to get a 180° view of yourself in your favourite frames. Additionally, you can visit any store or experience Opticshaven@Home—where you can try & buy from 150+ frames, all from the comfort of your home.',
  },
  {
    id: 2,
    title: 'Finding Out Your Power',
    description:
      'In order to find out your power, you can opt for an eye test at optics Haven. You can either visit your nearest store for a free eye check-up or book a home eye test for only ₹99! The safe & thorough 12-step eye test will be done by a certified optometrist with the latest equipment, and you’ll be provided the prescription immediately after the check-up.',
  },
  {
    id: 3,
    title: 'Quality Assurance',
    description:
      'Quality is at the core of all Optics Haven products. All our products are thoughtfully designed using high-quality materials like acetate, stainless steel, titanium & TR90. This ensures maximum comfort, protection, flexibility and breakage-resistance. We also offer a 1-year warranty on all our glasses.',
  },
];

// Accordion Data
// Faqs PoliciesAccordionData
export const FaqsPoliciesAccordionData = [
  {
    id: 1,
    title: 'Delivery',
  },
  {
    id: 2,
    title: 'Refund & Exchange',
  },
  {
    id: 3,
    title: 'Insurance For Your Eyewear',
  },
  {
    id: 4,
    title: 'Store-related Information',
  },
  {
    id: 5,
    title: 'Warranty Policy',
  },
  {
    id: 6,
    title: 'Privacy Policy',
  },
];

// Faqs accordion Data
export const FaqsAccordionData = [
  {
    id: 1,
    title: 'How do I order prescription glasses from Optics Haven?',
    des: `<p class="mb-4">To order your prescription glasses from Optics Haven, you will need your current lens prescription, including your PD measurement (pupillary distance).</p>

    <p class="mb-4">You can either buy an eyeglass frame from our collection or send in your already-owned eyeglasses for us to fix the lenses using our <i class="font-black underline "> Use Your Frame feature. </i> </p>

    <p>Then, follow the steps outlined on the home page. Choose your frame, choose your lens options, and select "Add your prescription." Enter your prescription details, add to the cart, and checkout.</p>
    `,
  },
  {
    id: 2,
    title: 'How to measure your pupillary distance (PD).',
    des: `<p>Pupillary distance measures the distance between your pupils. If not included in your prescription, you can visit <a class="text-[#219EBC]" href="https://www.accupd.com/#/" target="_blank"> AccuPD </a>, a free tool that measures your PD.</p>`,
  },
  {
    id: 3,
    title: 'How long does it take to receive my Optics Haven order?',
    des: `<p class="mb-4">Once you've placed your prescription order, receiving it can take 7-14 days. We ship and deliver as quickly as possible upon completion and quality inspection. When your order leaves our fulfillment center, you will receive a shipping confirmation email.</p>
    
    <p>Keep track of your shipment through the “Track My Order” link in your shipping confirmation email or logging into your Account.</p>`,
  },
  {
    id: 4,
    title: 'Can I reorder a pair I bought previously?',
    des: `<p>Yes! Log into your account and look up the previous order in the purchase history. You will see the word Reorder in the order details if the frame is currently available. Click on Reorder, and the same pair of glasses will be in your Shopping Cart.</p>`,
  },
  {
    id: 5,
    title: 'Can I email or fax my prescription later?',
    des: `<p>No. All Optics Haven prescription orders must include a valid prescription submitted with the order. </p>`,
  },
  {
    id: 6,
    title: 'What if I need to change or cancel my Optics Haven Rx order?',
    des: `<p>You can cancel your prescription up to 6 hours after placing the order. This is because production starts immediately when orders are sent in. Please review our Returns policy for more information.
</p>`,
  },
  {
    id: 7,
    title: 'What is Optics Haven Rx warranty policy?',
    des: `<p class="mb-4">Optics Haven is committed to quality, and we've built our reputation on long-lasting, high-performing products.</p>
     <p class="mb-4">All Optics Haven prescriptions and products come with a two-year guarantee from defects in workmanship and manufacturer materials. This limited warranty is only valid to the original purchaser with proof of purchase from ww.opticsHaven.com. We will replace the defective product that meets the above criteria at no charge.</p>
     <p class="mb-4">Please be advised that the Optics Haven warranty applies only to authentic Optics Haven products, including authentic Optics Haven lenses, frames, and parts that have not been modified. Any alteration to lenses, frame parts, or nose pads voids all warranties.</p>
     <p class="mb-4">How it Works</p>
     <p class="mb-4">The warranty process usually takes 1-2 weeks. Head over to the Optics Haven Warranty Claim Form to start a warranty claim. To help make the warranty process go smoothly, you'll need the following:
</p>

<ul class="list-decimal list-inside mb-4">
                <li class="mb-1">
                    Product name and model
                </li>
                <li class="mb-1">Photos that clearly show the problem with your product. Check out Warranty Claim Photos for examples.
</li>
<li>Proof of Purchase is required for all Rx products.</li>
              </ul>

    <p class="mb-4">Limitation on Damages
</p>
    
    <p class="mb-4">For breach of any written or implied warranty on this product, the consumer is limited to damages of repair of the product or replacement with an identical or equivalent style at the election of Optics Haven. Optics Haven is NOT liable for any other damages, such as special, incidental, or consequential damages, resulting from using this product.</p>

    <p class="mb-4">Note: Some states do not allow the exclusion or limitation of special, incidental, or consequential damages, so the above limitations or exclusion may not apply to you. This warranty gives you specific legal rights; you may have other rights varying from state to state. This warranty extends to residents of the United States only.</p>
     `,
  },
  {
    id: 8,
    title: 'What is the Optics Haven product return policy?',
    des: `<p>Our return policy is the same as our standard. We want you to be happy with your purchase from Optics Haven; we accept returns of products purchased for our website up to 14 days of delivery. Learn more about our Return Process <a class="text-[#219EBC]" href="https://support.smithoptics.com/hc/en-us/articles/4403962484503" target="_blank"> here</a>. 
</p>`,
  },
  {
    id: 9,
    title: `What do I do if I'm experiencing issues with my Optics Haven prescription glasses?`,
    des: `<p>Sometimes, it takes a few days to adjust to a new prescription. If you still have issues after a few days, please call the customer care line on 888 279 9806. so we can help troubleshoot the problem.
</p>`,
  },
  {
    id: 10,
    title:
      'I need help placing my Optics Haven prescription order online. What should I do?',
    des: `<p>If you need help placing your order, contact call the customer care line at 888 279 9806.</p>`,
  },

  {
    id: 11,
    title:
      'Can I purchase Optics Haven prescription glasses for someone as a gift?',
    des: `<p>Yes, you can order prescription glasses as a gift, but you will need the recipient's current prescription details, including their PD measurement (pupillary distance).</p>`,
  },
  {
    id: 12,
    title: 'How do I get my prescription?',
    des: `<p>We recommend that you get an eye exam at your local vision center for the most current prescription possible. If your current prescription is still valid, contact your doctor for a copy. Be sure to include your PD (pupillary distance) measurement.
</p>`,
  },
  {
    id: 13,
    title: 'Can you fill my glasses prescription?',
    des: `<p>Yes, we can fill most eyeglasses prescriptions. However, we encourage high-power lenses to choose the 1.65 high index lens for reduced weight and thinner lenses.</p>`,
  },
  {
    id: 14,
    title: 'Can I send my current glasses to get the prescription filled?',
    des: `<p>You can send in your old glasses for us to fit in a new lens. Please follow the steps in the <i class="font-black underline "> Use Your Frame Feature. </i>
</p>`,
  },
  {
    id: 15,
    title: 'Can you use my contact lens prescription to make my glasses?',
    des: `<p>No, we cannot create eyeglasses using a contact lens prescription. Contact lens prescriptions are specific to contact lenses. We recommend getting a new eye exam before ordering the most up-to-date prescription possible.
</p>`,
  },
  {
    id: 16,
    title: 'What is "PD," and how do I get mine?',
    des: `<p>PD (pupillary distance), is the distance in millimeters between the center of one pupil to the other measured in millimeters. Your PD is usually included in your prescription. If not, you can <a class="text-[#219EBC]" href="https://yug-new.d1zbv93kn0yndl.amplifyapp.com/measure-my-pd" target="_blank">measure your PD</a> yourself or contact your eye doctor to measure it for you.
</p>`,
  },
  {
    id: 17,
    title: 'How do I measure my frames?',
    des: `<p class="mb-4">Knowing the correct measurements for your frames is important when shopping for glasses online. Check the inside of the temple arm; some numbers should be there. These numbers are your frame measurements. 
</p> <p>You can always try measuring by hand if you can no longer read the numbers inside your frame. Just remember, when measuring frame width, you need to measure from temple screw to temple screw. For temple length, you need to measure from the temple screw to the temple tip.</p>
`,
  },
  {
    id: 18,
    title: `Do you offer children's frames?`,
    des: `<p>Yes, we do. Check out our <a class="text-[#219EBC]" href="" target="_blank">Kids' Eyeglasses</a> collection.</p>`,
  },
  {
    id: 19,
    title: 'Do you fill contact lens prescriptions?',
    des: `<p>We do not currently fill contact lens prescriptions.</p>`,
  },
  {
    id: 20,
    title:
      'What is the difference between single-vision and progressive lenses?',
    des: `<p class="mb-4">Single-vision lenses offer one correction value throughout the lens, typically for distance or Near.</p> <p>Progressive lenses offer multiple correction values in one lens so that you can have distance and near values in one pair of glasses.
</p>`,
  },
  {
    id: 21,
    title:
      'What is the difference between progressive lenses and transition lenses?',
    des: `<p class="mb-4">Progressive lenses offer multiple correction values in one lens. E.g. distance and Near values in one pair of glasses.</p> 
    <p>Transition lenses are light-sensitive/Light-responsive or photochromic lenses that change from clear to tinted when exposed to sunlight (UV light).
</p>`,
  },
  {
    id: 22,
    title: `Does Optics Haven offer light-responsive (photochromic) lenses?`,
    des: `<p>Yes, Optics Haven offers light-responsive or photochromic lenses of various shades that automatically adjust from clear to tinted when exposed to sunlight (UV light).
</p>`,
  },
  {
    id: 23,
    title: 'Does Optics Haven offer Bifocal lens?',
    des: `<p>We do not offer prescription bifocal lenses. We do, however, offer a few frame models with non-prescription readers.
</p>`,
  },
  {
    id: 24,
    title: 'Does Optics Haven offer lenses with a prism correction?',
    des: `<p>At the moment, we offer on a case-by-case basis. Call our customer care line at 888 279 9806 for more information. 
</p>`,
  },

  {
    id: 25,
    title:
      'Do Optics Haven prescription glasses offer protection from blue light?',
    des: `<p>Yes, we offer blue-light filtering lenses as an add-on option when selecting your lens type.
</p>`,
  },
  {
    id: 26,
    title: 'Do Optics Haven lenses protect from UV Sunlight?',
    des: `<p>Yes. Our clear lenses and tinted sunglass lenses filter out harmful UV rays.
</p>`,
  },
  {
    id: 27,
    title: 'Can I purchase lenses by themselves?',
    des: `<p>Unfortunately, we are unable to sell lenses independent of their frame. But we can fix lenses in your already-owned frame. Utilize the Use Your Frame feature. </p>`,
  },
  {
    id: 28,
    title: 'What payment methods do you accept?',
    des: `<p>We accept credit/debit cards, and gift cards.
</p>`,
  },
  {
    id: 29,
    title: 'How are your payments secured?',
    des: `<p>All transactions are also processed and protected through Stripe.
</p>`,
  },
  {
    id: 30,
    title: 'How can I obtain a sales invoice?',
    des: `<p>You can download a detailed sales invoice by accessing the <a class="text-[#219EBC]" href="" target="_blank">Purchase History  </a>section of your account page.
</p>`,
  },
  {
    id: 31,
    title:
      'How do I order multiple pairs of glasses with different prescriptions?',
    des: `<p>If you need multiple pairs of glasses with different prescriptions — like a pair for you and a pair for your child or a pair for reading and another for Driving — you can simply add another prescription to a second pair of glasses in the same order. Contact customer support on <a class="text-[#219EBC]" href="tel:+18882799806" target="_blank">8882799806 </a> if you need any assistance.</p>`,
  },
  {
    id: 31,
    title: 'What is computer vision Syndrome (CVS)?',
    des: `<p class="mb-4">Optics Haven is committed to quality, and we've built our reputation on long-lasting, high-performing products.</p>
        <ul class="list-decimal list-inside mb-4">
          <li class="mb-1">Eye irritation (Dry eyes, itchy eyes, red eyes)</li>
          <li class="mb-1">Blurred vision</li>
          <li class="mb-1">Headaches</li>
          <li class="mb-1">Backaches</li>
          <li class="mb-1">Neck aches</li>
          <li>Muscle fatigue</li>
        </ul>`,
  },
  {
    id: 32,
    title: 'How is Computer Vision Syndrome treated?',
    des: `<p class="mb-4">Reducing glare and harsh reflections on the computer screen by modifying the lighting in the room, closing window shades, changing the contrast or brightness of the screen, or attaching a filter or hood to the monitor. This will not only help eyes focus better but may also eliminate the need to squint while looking at the screen. It is also beneficial for you to get special lens designs, lens powers, lens tints, or coatings that help maximize visual abilities and comfort. Optics Haven provides a wide variety of lenses with anti-reflective coating,  glare-reducing, light responsiveness, blue light filtering, and anti-fatigue lenses. These lenses help reduce the strains of Computer vision syndrome (CVS).  
    </p>`,
  },
];

// popularBlogData
export const popularBlogData = [
  {
    id: 1,
    image: images.popularblog1,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 2,
    image: images.popularblog2,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 3,
    image: images.popularblog3,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 4,
    image: images.popularblog4,
    heading: 'Make up tips for glasses wearers',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 5,
    image: images.popularblog5,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 6,
    image: images.popularblog6,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 7,
    image: images.popularblog7,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 8,
    image: images.popularblog8,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
];

// mostPopularBlogData
export const mostPopularBlogData = [
  {
    id: 1,
    image: images.mostpopularblog1,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 2,
    image: images.mostpopularblog2,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 3,
    image: images.mostpopularblog3,
    heading: '5 Reasons Why You Need Anti Glare Glasses',
    date: 'October 3, 2022',
    time: '10 min',
    images: images.popular_blog_img1,
    title: 'Jane Cooper',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
];

// recentBlog
export const recentBlog = [
  {
    id: 1,
    image: images.Sunglass_outfit1,
    Title: 'Sunglasses',
    heading: '5 Retro Sunglasses to matchStylist Outfit',
    date: 'October 3, 2022',
    time: '10 min',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 2,
    image: images.Sunglass_outfit2,
    Title: 'Sunglasses',
    heading: '5 Retro Sunglasses to matchStylist Outfit',
    date: 'October 3, 2022',
    time: '10 min',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 3,
    image: images.Sunglass_outfit3,
    Title: 'Sunglasses',
    heading: '5 Retro Sunglasses to matchStylist Outfit',
    date: 'October 3, 2022',
    time: '10 min',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
  {
    id: 4,
    image: images.Sunglass_outfit3,
    Title: 'Sunglasses',
    heading: '5 Retro Sunglasses to matchStylist Outfit',
    date: 'October 3, 2022',
    time: '10 min',
    Link: 'Read More',
    path: routesConfig?.blogDetail,
  },
];

// Coupons Page
// eyeglass frame collection Data
export const eyeGlassesFrameCollectionData = [
  {
    id: 1,
    images: images.eye_glasses_frame_collection1,
    discount: 'Get 10% of your Men Eyeglasses.',
    // frameName: 'Men Eyeglasses',
    linkText: 'Shop Now',
    path: '/frames?gender=male',
    description:
      'Use this code xxxxx at checkout and get 10% off your Frame order.',
  },
  {
    id: 2,
    images: images.eye_glasses_frame_collection2,
    discount: 'Get 10% of your Women Eyeglasses.',
    // frameName: 'Women Eyeglasses',
    // code: 'Code : OH344',
    linkText: 'Shop Now',
    path: '/frames?gender=female',
    description:
      'Use this code xxxxx at checkout and get 10% off your Frame order.',
  },
  {
    id: 3,
    images: images.eye_glasses_frame_collection3,
    discount: 'Get 10% of your Kids Eyeglasses.',
    // frameName: 'Kids Eyeglasses',
    linkText: 'Shop Now',
    path: '/frames?gender=girls&gender=male',
    description:
      'Use this code xxxxx at checkout and get 10% off your Frame order.',
  },
  {
    id: 4,
    images: images.eye_glasses_frame_collection4,
    discount: 'Get 10% of your Use your Frame Order.',
    // frameName: 'Frame Order',
    linkText: 'Shop Now',
    path: routesConfig.useMyFrame,
    description:
      'Use this code xxxxx at checkout and get 10% off your lens order.',
  },
];

// buy 1 get 1 brand frame Data
export const buyOneGetOneBrandFrameData = [
  {
    id: 1,
    images: images.bye_one_get_one_brands1,
    title: 'Eyeglasses',
    linkText: 'Shop Now',
    path: '/',
  },
  {
    id: 2,
    images: images.bye_one_get_one_brands2,
    title: 'Kids Eyeglasses',
    linkText: 'Shop Now',
    path: '/',
  },
  {
    id: 3,
    images: images.bye_one_get_one_brands3,
    title: 'Computer Glass',
    linkText: 'Shop Now',
    path: '/',
  },
];

// buy 1 get 1 brand frame name
export const buyOneGetOneBrandFrameNameData = [
  {
    id: 1,
    images: images.bye_one_get_one_brands_frame1,
    brandImg: images.buy_one_get_one_brands_name1,
    title: 'Marble',
  },
  {
    id: 2,
    images: images.bye_one_get_one_brands_frame2,
    brandImg: images.buy_one_get_one_brands_name2,
    title: "Gentlemen's Edit",
  },
  {
    id: 3,
    images: images.bye_one_get_one_brands_frame3,
    brandImg: images.buy_one_get_one_brands_name3,
    title: 'Pilot',
  },
  {
    id: 4,
    images: images.bye_one_get_one_brands_frame4,
    brandImg: images.buy_one_get_one_brands_name4,
    title: 'JJ Rhapsody',
  },
];

// coupan help data
export const couponsHelpData = [
  {
    id: 1,
    images: images.help1,
    title: 'Chat With Us',
    description: 'On WhatsApp',
    path: '/',
  },
  {
    id: 2,
    images: images.help2,
    title: 'Call Us On',
    description: '888-279-9806',
    path: 'tel:+1888-279-9806',
  },
  {
    id: 3,
    images: images.help3,
    title: 'Find Now',
    description: 'Store Near You',
    path: '/',
  },
];

// frame size data
export const checkPositionCardData = [
  {
    id: 1,
    images: images.check_card1,
    title: 'Size is Small',
    description: 'If card extends beyond the corner of the eye',
  },
  {
    id: 2,
    images: images.check_card2,
    title: 'Size is Medium',
    description: 'If card roughly touches the corner of the eye',
  },
  {
    id: 3,
    images: images.check_card3,
    title: 'Size is large',
    description: "If card doesn't reach the corner of the eye",
  },
];

// insurance Page
// insurance process Data
export const InsuranceProcessData = [
  {
    id: 1,
    images: images.insurance_process1,
    title: 'Fill out a claim',
    description:
      'Find your vision insurance provider below and download the out-of-network reimbursement form.',
  },
  {
    id: 2,
    images: images.insurance_process2,
    title: 'Attached Receipt',
    description:
      'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
  },
  {
    id: 3,
    images: images.insurance_process3,
    title: 'Submit the claim form and Receipt.',
    description:
      'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
  },
  {
    id: 4,
    images: images.insurance_process4,
    title: 'Get Money Back',
    description:
      'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
  },
];

// network insurance  Data
export const networkInsuranceData = [
  {
    id: 1,
    images: images.network_insurance1,
  },
  {
    id: 2,
    images: images.network_insurance2,
  },
  {
    id: 3,
    images: images.network_insurance3,
  },
  {
    id: 4,
    images: images.network_insurance4,
  },
  {
    id: 5,
    images: images.network_insurance5,
  },
  {
    id: 6,
    images: images.network_insurance6,
  },
  {
    id: 7,
    images: images.network_insurance7,
  },
  {
    id: 8,
    images: images.network_insurance8,
  },
  // {
  //   id: 9,
  //   images: images.network_insurance9,
  // },
  {
    id: 9,
    images: images.network_insurance10,
  },
  {
    id: 10,
    images: images.network_insurance11,
  },
  // {
  //   id: 12,
  //   images: images.network_insurance12,
  // },
  {
    id: 11,
    images: images.network_insurance13,
  },
  {
    id: 12,
    images: images.vbn_logo,
    class: 'h-[58px]',
  },
];

// network insurance Data Modal
export const networkInsuranceModalHead = {
  1: [
    {
      title: 'Aetna Vision Benefits Claim Form & Instruction',
      des: 'we are out of network with Aetna, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  2: [
    {
      title: 'Cigna Insurance Claim',
      des: 'we are out of network with Cigna, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  3: [
    {
      title: 'Anthem.. BlueCross BlueShield Insurance',
      des: 'we are out of network with Anthem, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  4: [
    {
      title: 'Humana Vision Insurance claim form',
      des: 'we are out of network with Humana, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  5: [
    {
      title: 'VSP Vision Insurance',
      des: 'we are out of network with VSP, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  6: [
    {
      title: 'NVA Insurance',
      des: 'we are out of network with NVA, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  7: [
    {
      title: 'Eye Med Vision insurance',
      des: 'we are out of network with Eye Med, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  8: [
    {
      title: 'Davis Vision Insurance',
      des: 'we are out of network with Davis Vision, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  9: [
    {
      title: 'Spectera vision insurance reimbursement form',
      des: 'we are out of network with Spectera, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  10: [
    {
      title: 'United Healthcare reimbursement form',
      des: 'we are out of network with United Healthcare, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  11: [
    {
      title: 'BlueCross BlueShield Insurance form',
      des: 'we are out of network with BlueCross BlueShield, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
  12: [
    {
      title: 'VBA',
      des: 'we are out of network with VBA, but you can still save 100 or more depending on your vision insurance out-of-network policy. Please follow the steps below.',
    },
  ],
};

// network insurance Data Modal
export const networkInsuranceModalData = {
  1: [
    {
      id: 1,
      title: 'Fill out claim form',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.aetna_Vision_Benefits_Claim_Formand_Instruction,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'Aetna Vision',
      attn: 'Attn: OON Claims',
      receiptBoxName: 'P.O. Box 8504',
      receiptAddressName: 'Mason, OH 45040-7111',
      receiptEmail: 'oonclaims@eyewearspecialoffers.com',
      receiptEmailLink: 'mailto:oonclaims@eyewearspecialoffers.com',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  2: [
    {
      id: 1,
      title: 'Fill out claim form',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.multi_language_interpreter_services,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'CignaVision',
      receiptBoxName: 'P.O. Box 385018',
      receiptAddressName: 'Birmingham, AL 35238-5018',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  3: [
    {
      id: 1,
      title: 'Fill out claim from data',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      linkText: 'Open to fill form.',
      linkURL:
        'https://www.eyemedonline.com/managed-vision-care/member-forms/out-of-network-claim/blue-view-vision#/',
      linkTrue: true,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'Blue View Vision',
      attn: 'Attn: OON Claims',
      receiptBoxName: 'P.O. Box 8504',
      receiptAddressName: 'Mason, OH 45040-7111',
      receiptEmail: 'oonclaims@eyewearspecialoffers.com',
      receiptEmailLink: 'mailto:oonclaims@eyewearspecialoffers.com',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  4: [
    {
      id: 1,
      title: 'Fill out claim form data',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.humana_NonNetworkVisionClaimForm,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'Humana Vision Care Plan',
      attn: 'Attn: OON Claims',
      receiptBoxName: 'P.O. Box 14311',
      receiptAddressName: 'Lexington, KY 40512-4311',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  5: [
    {
      id: 1,
      title: 'Fill out claim',
      description:
        'Log in to vsp.com to complete a claim form and submit it along with your itemized receipt to this address:',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.vsp_reimbursement,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'Vision Service Plan',
      attn: 'ATTN: Claims Department',
      receiptBoxName: 'P.O. Box 385018',
      receiptAddressName: 'Birmingham, AL 35238-5018',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
    },
    {
      id: 3,
      title: 'Submit your completed claim form',
      description:
        '(online or in the mail, depending on the instructions provided by your carrier) along with your itemized Optics Haven receipt. Need another copy of your receipt, physical or digital? Log in to your account to access past invoices, or give us a shout.',
    },
    {
      id: 4,
      title: 'Now the easiest part',
      description: `If you have an eligible out-of-network benefit, you'll typically be fully or partially reimbursed within 2–3 weeks of submitting your claim (but that can vary depending on your carrier).`,
    },
  ],
  6: [
    {
      id: 1,
      title: 'Fill out claim form data',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.nva_out_of_network_vision_care_claim_form,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'National Vision Administrators (NVA)',
      attn: 'Attn: Claims Department',
      receiptBoxName: 'P.O. Box 2187',
      receiptAddressName: 'Clifton, New Jersey, 07015',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  7: [
    {
      id: 1,
      title: 'Fill out claim form data',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.eyemed_reimbursement,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'VFirst American Administrators',
      attn: 'Attn: OON Claims Department',
      receiptBoxName: 'P.O. Box 8504',
      receiptAddressName: 'Mason, OH 45040-7111',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  8: [
    {
      id: 1,
      title: 'Fill out claim from data',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.davis_Vision_Insurance_Claim_form,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'Vision Care Processing Unit',
      receiptBoxName: 'P.O. Box 1525',
      receiptAddressName: 'Latham, NY 12110',
      className: 'network_insurance_inner_vision_processing_unit',
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  // 9: [
  //   {
  //     id: 1,
  //     title: 'Fill out claim from data',
  //     description: 'Find your vision insurance provider below and download the out-of-network reimbursement form.',
  //     pdfDownload: 'Download it here',
  //   },
  //   {
  //     id: 2,
  //     title: 'Attach Receipt',
  //     description:
  //       "Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.",
  //     receiptUnitName: 'Vision Care Processing Unit',
  //     receiptBoxName: 'P.O. Box 3545',
  //     receiptAddressName: 'Latham, NY 12401',
  //     className: 'network_insurance_inner_vision_processing_unit',
  //   },
  //   {
  //     id: 3,
  //     title: 'Submit claim form and receipt',
  //     description:
  //       "Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.",
  //   },
  //   {
  //     id: 4,
  //     title: 'Get Money Back',
  //     description:
  //       'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
  //   },
  // ],
  9: [
    {
      id: 1,
      title: 'Fill out claim form data',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.spectra_OON_form,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'Spectera Claims Department',
      receiptBoxName: 'P.O. Box 30978',
      receiptAddressName: 'Salt Lake City, UT 84130',
      fax: 'Fax: 248-733-6060',
      visionCustomerService: 'Customer Service: 800-638-3120',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
      fax_vision: true,
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  10: [
    {
      id: 1,
      title: 'Fill out claim form data',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.united_healthcare_uhc_vision_reimbursement,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'UnitedHealthcare Vision',
      attn: 'Attn: Claims Department',
      receiptBoxName: 'P.O. Box 30978',
      receiptAddressName: 'Salt Lake City, UT 84130',
      fax: 'Fax: (248) 733-6060',
      receipt: true,
      fax_vision: true,
      className: 'network_insurance_inner_vision_processing_unit',
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  // 12: [
  //   {
  //     id: 1,
  //     title: 'Fill out claim from data',
  //     description: 'Find your vision insurance provider below and download the out-of-network reimbursement form.',
  //     pdfDownload: 'Download it here',
  //   },
  //   {
  //     id: 2,
  //     title: 'Attach Receipt',
  //     description:
  //       "Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.",
  //     receiptUnitName: 'Vision Care Processing Unit',
  //     receiptBoxName: 'P.O. Box 3545',
  //     receiptAddressName: 'Latham, NY 12401',
  //     className: 'network_insurance_inner_vision_processing_unit',
  //   },
  //   {
  //     id: 3,
  //     title: 'Submit claim form and receipt',
  //     description:
  //       "Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.",
  //   },
  //   {
  //     id: 4,
  //     title: 'Get Money Back',
  //     description:
  //       'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
  //   },
  // ],
  11: [
    {
      id: 1,
      title: 'Fill out claim form data',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.FEP_BlueVision_Claim_Form,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'FEP Blue Vision',
      receiptBoxName: 'P.O. Box 2010',
      receiptAddressName: 'Latham, NY 12110-2010',
      fax: 'Fax: 314-849-4830 or 800-501-8432',
      visionCustomerService: 'Vision Customer Service: 866-425-2323',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
      fax_vision: true,
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
  12: [
    {
      id: 1,
      title: 'Fill out claim form data',
      description:
        'Find your vision insurance provider below and download the out-of-network reimbursement form.',
      pdfDownload: 'Download it here',
      pdfDownloadLink: pdf.vba_reimbursement,
    },
    {
      id: 2,
      title: 'Attach Receipt',
      description:
        'Attach the Itemised Optics Haven receipt/invoice to the out-of-network reimbursement form and submit it to the address of your vision insurance provider listed below.',
      receiptUnitName: 'Vision Benefits of America',
      receiptBoxName: '300 Weyman Plaza, Suite 400',
      receiptAddressName: 'Pittsburg, PA 15236-1588',
      className: 'network_insurance_inner_vision_processing_unit',
      receipt: true,
    },
    {
      id: 3,
      title: 'Submit claim form and receipt',
      description:
        'Send both the claim form and invoice to your vision insurance company. You can get the address of your vision insurance company by clicking the picture below.',
    },
    {
      id: 4,
      title: 'Get Money Back',
      description:
        'After you place your order, submit a claim and get reimbursed (typically takes 2–3 weeks, depending on your Vision insurance company). It’s that easy!',
    },
  ],
};

// Faqs insurance Data
export const FaqsInsuranceData = [
  {
    id: 1,
    title: 'Why do I need an eye exam?',
    description: `<p class="mb-4"> It is vital to have an annual eye examination because eye diseases are common and can go unnoticed for a long time. A comprehensive dilated eye examination by an Eye Doctor is necessary to find eye diseases early when treatment to prevent vision loss is most effective. </p>

      <p class="mb-2"> Early treatment of eye disease is critically important to prevent permanent vision loss or blindness: </p>
      <ul class="list-disc list-inside mb-4">
                <li class="mb-1">
                    Cataracts (clouding of the lens) are a leading cause of vision loss.
                </li>
                <li class="mb-1">
                Diabetic retinopathy (causes damage to blood vessels in the back of the eye) is the leading cause of blindness in American adults.
                </li>
                <li class="mb-1">Glaucoma (a group of diseases that damages the optic nerve)</li>
<li>Age-related macular degeneration (gradual breakdown of light-sensitive tissue in the eye)</li>
              </ul>

              <p class="mb-2"> It is also essential to check your eyes for refractive error, as your eyes might not have optimal vision (20/20). Common refractive errors include </p>
      <ul class="list-disc list-inside mb-4">
                <li class="mb-1">Myopia (Short sightedness)</li>
                <li class="mb-1">Hyperopia (Long Sightedness)</li>
                <li class="mb-1">Astigmatism (Irregular curvature of the Cornea or Lens)</li>
              </ul>

              <p class="mb-2"> Other eye conditions include </p>
      <ul class="list-disc list-inside mb-4">
                <li class="mb-1">Asthenopia (Eye stain, Headache, light sensitivity, or Eye fatigue) </li>
                <li class="mb-1">Strabismus (Hypertropia or crossed eye)</li>
                <li class="mb-1">Amblyopia (Lazy Eye) </li>
                <li class="mb-1">Color Blindness</li>
                <li class="mb-1"> Dry eyes</li>
                <li class="mb-1"> Floaters</li>
                <li class="mb-1"> Uveitis</li>
              </ul>
      `,
  },
  {
    id: 2,
    title: 'What is Vision Insurance?',
    description: `<p class="mb-4">Vision insurance is commonly used to describe health and wellness plans designed to reduce the costs of routine preventive eye care exams and prescription eyewear like eyeglasses and contact lenses. Unlike major medical insurance policies that may provide unlimited benefits after certain co-pays and deductibles are met, most vision insurance plans are discount or wellness benefit plans that provide specific benefits and discounts for an annual premium.</p>

      <p class="mb-4">These vision discount and wellness benefits plans offer significant savings to the recipient. As such, they can be used to cover much of the cost of basic eyewear, or they can be used to make premium eyewear products and enhancements — such as progressive lenses, anti-reflective coating and light-responsive lenses — significantly more affordable.</p>

      <p class="mb-4">When purchasing "vision insurance," be sure you fully understand the costs and benefits associated with the plan(s) you are considering. </p>`,
  },
  // {
  //   id: 3,
  //   title: 'How is a contact lens exam different?',
  //   description:
  //     'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  // },
  // {
  //   id: 4,
  //   title: 'How much does vision insurance cost?',
  //   description:
  //     'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  // },
  {
    id: 3,
    title: 'How does Vision Insurance work?',
    description: `<p class="mb-4"> A Vision insurance policy is different from a health insurance plan. Regular medical health insurance protects you from unexpected eye injury or disease costs. In contrast, Vision insurance provides an added wellness benefit for healthy eye exams, which includes routine eye care, prescription eyewear and contact lenses, and other vision services at a reduced cost.</p>

      <p class="mb-2"> Most Vision insurance plans include the following benefits: </p>
      <ul class="list-disc list-inside mb-4">
                <li class="mb-1">Annual vision exams</li>
                <li class="mb-1">Eyeglass frames</li>
                 <li class="mb-1">Eyeglass lenses</li>
                 <li class="mb-1">Contact lenses</li>
              </ul>
              
              <p class="mb-2"> When researching a vision insurance plan, determine if the coverage fits your vision needs.  </p>
              `,
  },
  // {
  //   id: 6,
  //   title: 'How do I pick a vision insurance plan?',
  //   description:
  //     'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  // },
];

// blog details Page
// make up tips Data
export const MakeUpTipsWearersData = [
  {
    id: 1,
    title: 'Make up tips for glasses Wearers',
    images: images.popular_blog_img1,
    manName: 'Jacob Jones',
    date: 'October 3, 2022',
    minutes: '10Min',
    descriptionOne:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    descriptionTwo:
      'Cras volutpat purus purus sapien elit. Pellentesque nibh quisque ornare diam. Faucibus tempus et magna in egestas id. Augue lorem nunc varius est tempus volutpat. Placerat mi at aliquam amet. Cursus pretium arcu amet pellentesque est integer egestas pellentesque mauris. Ac etiam donec hendrerit pellentesque feugiat nisl. Facilisis erat vitae montes aliquam egestas consectetur amet. Mi arcu purus id magna in suspendisse ac eget. Dignissim diam lacus adipiscing diam tempor vitae platea vitae. Mattis cras neque elit et ut tempus maecenas. Vitae pellentesque in pulvinar nibh tellus in.',
    descriptionThree:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    descriptionFour:
      'Cras volutpat purus purus sapien elit. Pellentesque nibh quisque ornare diam. Faucibus tempus et magna in egestas id. Augue lorem nunc varius est tempus volutpat. Placerat mi at aliquam amet. Cursus pretium arcu amet pellentesque est integer egestas pellentesque mauris. Ac etiam donec hendrerit pellentesque feugiat nisl. Facilisis erat vitae montes aliquam egestas consectetur amet. Mi arcu purus id magna in suspendisse ac eget. Dignissim diam lacus adipiscing diam tempor vitae platea vitae. Mattis cras neque elit et ut tempus maecenas. Vitae pellentesque in pulvinar nibh tellus in.',
  },
];

// eye lips stick make up  Data
export const EyeLipStickMakeUpData = [
  {
    id: 1,
    title: 'Eye make-up tips for glasses wearers',
    descriptionOne:
      'Cras volutpat purus purus sapien elit. Pellentesque nibh quisque ornare diam. Faucibus tempus et magna in egestas id. Augue lorem nunc varius est tempus volutpat. Placerat mi at aliquam amet. Cursus pretium arcu amet pellentesque est integer egestas pellentesque mauris. Ac etiam donec hendrerit pellentesque feugiat nisl. Facilisis erat vitae montes aliquam egestas consectetur amet. Mi arcu purus id magna in suspendisse ac eget. Dignissim diam lacus adipiscing diam tempor vitae platea vitae. Mattis cras neque elit et ut tempus maecenas. ',
    images: images.eye_make_up_img,
  },
  {
    id: 2,
    title: 'Lipstick looks for glasses wearers',
    descriptionOne:
      'Cras volutpat purus purus sapien elit. Pellentesque nibh quisque ornare diam. Faucibus tempus et magna in egestas id. Augue lorem nunc varius est tempus volutpat. Placerat mi at aliquam amet. Cursus pretium arcu amet pellentesque est integer egestas pellentesque mauris. Ac etiam donec hendrerit pellentesque feugiat nisl. Facilisis erat vitae montes aliquam egestas consectetur amet. Mi arcu purus id magna in suspendisse ac eget. Dignissim diam lacus adipiscing diam tempor vitae platea vitae. Mattis cras neque elit et ut tempus maecenas. ',
    images: images.lip_stick_glass,
    descriptionTwo:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    descriptionThree:
      'Cras volutpat purus purus sapien elit. Pellentesque nibh quisque ornare diam. Faucibus tempus et magna in egestas id. Augue lorem nunc varius est tempus volutpat. Placerat mi at aliquam amet. Cursus pretium arcu amet pellentesque est integer egestas pellentesque mauris. Ac etiam donec hendrerit pellentesque feugiat nisl. Facilisis erat vitae montes aliquam egestas consectetur amet. Mi arcu purus id magna in suspendisse ac eget. Dignissim diam lacus adipiscing diam tempor vitae platea vitae. Mattis cras neque elit et ut tempus maecenas. Vitae pellentesque in pulvinar nibh tellus in.',
    descriptionFour:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    descriptionFive:
      'Cras volutpat purus purus sapien elit. Pellentesque nibh quisque ornare diam. Faucibus tempus et magna in egestas id. Augue lorem nunc varius est tempus volutpat. Placerat mi at aliquam amet. Cursus pretium arcu amet pellentesque est integer egestas pellentesque mauris. Ac etiam donec hendrerit pellentesque feugiat nisl. Facilisis erat vitae montes aliquam egestas consectetur amet. Mi arcu purus id magna in suspendisse ac eget. Dignissim diam lacus adipiscing diam tempor vitae platea vitae. Mattis cras neque elit et ut tempus maecenas. Vitae pellentesque in pulvinar nibh tellus in.',
  },
];

// RefundProcess
export const RefundProcess = [
  {
    id: 1,
    images: images.returnPolicy1,
    title: 'Return is Filed',
    Description: '(Day 1)',
  },

  {
    id: 2,
    images: images.returnPolicy2,
    title: 'Product is Picked up',
    Description: '(Usually takes 1-2 days)',
  },
  {
    id: 3,
    images: images.returnPolicy3,
    title: 'QC Validation by Optics Haven',
    Description: '(Usually takes 2-3 days)',
  },
  {
    id: 4,
    images: images.returnPolicy4,
    title: 'Refund Initiation',
    Description: '(Usually takes 1-2 days)',
  },
  {
    id: 5,
    images: images.returnPolicy1,
    title: 'Credit Complete',
    Description: '(1-7 days depend on refund method)',
  },
];

// tableData
export const tableData = [
  {
    id: 1,
    title: 'Credit Card',
    Description: '10-15 Working Days',
  },
  {
    id: 2,
    title: 'Net Banking',
    Description: '10-15 Working Days',
  },
  {
    id: 3,
    title: 'Debit Card',
    Description: '10-15 Working Days',
  },
  {
    id: 4,
    title: 'COD',
    Description: '10-15 Working Days',
  },
  {
    id: 5,
    title: 'UPI',
    Description: 'More than 10-15 Working days',
  },
];

// lensAllInfo
export const lensAllInfo = [
  {
    id: 1,
    image: images.use_my_frame1,
    mainTitle: 'Frame Information',
    title:
      'Enter the name and Model of your eyeglasses frame. Upload a picture of your frame showing the front and temples.',
  },
  {
    id: 2,
    image: images.use_my_frame2,
    mainTitle: 'Select Lens',
    title:
      'Customize your select lens with your preferred lenses. We Have single-vision lenses, Progressive lenses, readers, and Non-Prescription. Select your lenses according to your visual needs.',
  },
  {
    id: 3,
    image: images.use_my_frame3,
    mainTitle: 'Terms and Condition',
    title: 'Read our teams and conditions and agree to all the stipulations.',
  },
  {
    id: 4,
    image: images.use_my_frame4,
    mainTitle: 'Return Address',
    title:
      'Enter your preferred return address. This is the address that the finished eyeglasses will be sent. Ensure you enter the correct address.',
  },
  {
    id: 5,
    image: images.use_my_frame5,
    mainTitle: 'Payment Method',
    title:
      'This process is easy; confirm that you have the right eyeglasses frame and lenses. Enter your payment method in our secured payment portal and checkout.',
  },
  {
    id: 6,
    image: images.use_my_frame6,
    mainTitle: 'Print shipping label',
    title:
      'Print your shipping label from our website and attach it to a box. Drop the box at the nearest FedEx office or schedule a pickup from FedEx.',
  },
];

// buying Guide page
// Guide Eyeglasses Data
export const GuideEyeglassesData = [
  {
    id: 1,
    title: 'Know Your Eyeglasses',
    descriptionOne:
      "It is essential to know the components of our eyeglasses so that if ever needed, we are empowered to explain our requirements to an eye doctor, optician, or friend. It is essential to know the components of the eyeglasses to enable us to buy the perfect fit when replacing our frames. Just like clothes, Eyeglasses have sizes, and we all need to know our sizes so we don't buy eyeglasses that do not fit. The main parts of an eyeglass frame are temples, lenses, and bridge. The size of the frame is mainly imprinted in the inner temple of the frame. The eyeglasses frame also has accessories such as hinges, screws, and nose pads. An eyeglass frame can be made from plastic, metal, acetate, or titanium.",
    descriptionTwo:
      'Fringilla non eget facilisis amet sit molestie. Integer mauris nulla quisque et quam dui.',
  },
];

// Guide FrameComponentData
export const GuideFrameComponentData = [
  {
    id: 1,
    title: 'Frame Component',
    descriptionOne:
      'There are three main categories of the eyeglasses frame—the main parts, accessories, and auxiliary materials. The main parts are the temples, lenses, and nose bridge. The accessories include hinges, screws, nose pads, etc. Supplemental materials include a tag, lens cloth, glass cases, and lens cleaning fluid.',
    descriptionTwo:
      'Fringilla non eget facilisis amet sit molestie. Integer mauris nulla quisque et quam dui.',
  },
];

// Guide FrameListData
export const GuideFrameListData = [
  {
    id: 1,
    images: images.frame_front1,
    title: 'Frame Front',
    description:
      'This is the front of the eyeglasses frame; it can be of various sizes and shapes, for example, round, rectangle, oval, or cat eyes. The front of the frame holds the optical lens in place and helps you see clearly. When choosing a frame, it is essential to select the style of the frame you like.',
  },
  {
    id: 1,
    images: images.frame_front2,
    title: 'Lenses',
    description:
      'This is a custom lens fabricated to correct a patient refractive error. The lens can have multiple powers (Strength). With modern advances in technology, lenses come in various types. There are single-vision lenses, Bifocal lenses, progressive lenses, blue light filtering lenses, photochromic lenses, and anti-reflective lenses.',
  },
  {
    id: 1,
    images: images.frame_front3,
    title: 'Temples',
    description:
      'The temples of an eyeglasses frame help hold the frame to the face. They are the arms connected to the eyeglasses that extend from the face to behind the ears. For glasses to fit correctly, the temples must be the right length. The size of the eyeglasses is usually written behind the temples.',
  },
];

// Guide FrameSize Data
export const GuideFrameSizeData = [
  {
    id: 1,
    title: 'Frame Size',
    descriptionOne: `One crucial factor that ensures you have the correct value from your frames is the size. An eyeglasses frame that is too tight will be uncomfortable, while an eyeglasses that is too big will fall off your face. The right size makes it possible for you to see from the optical center of the lenses. To get the correct measurement for your glasses, follow the following steps.<br/>
    <div class="ml-[2rem]"> 1. Measure an old pair that fits correctly.<br/> 
    2. Measure your face. <br/> 
    3. Visit your local eye doctor or optician and get your eyeglasses size. </div>
    The eyeglass's size is usually located at the inner temple; the size can be written like this: 47-19-140. The 1st number is the lens width, the 2nd number is the bridge width, and the 3rd number is the temple length.`,
    descriptionTwo:
      'Fringilla non eget facilisis amet sit molestie. Integer mauris nulla quisque et quam dui.',
  },
];

// Guide Frame Material Data
export const GuideFrameMaterialData = [
  {
    id: 1,
    images: images.frame_material1,
    title: 'Acetate',
    lightWeight: 5,
    flexibility: 4,
    durability: 3,
    styleQuotient: 2,
  },
  {
    id: 2,
    images: images.frame_material2,
    title: 'TR90',
    lightWeight: 5,
    flexibility: 4,
    durability: 3,
    styleQuotient: 2,
  },
  {
    id: 3,
    images: images.frame_material3,
    title: 'Stainless Steel',
    lightWeight: 5,
    flexibility: 4,
    durability: 3,
    styleQuotient: 2,
  },
  {
    id: 4,
    images: images.frame_material4,
    title: 'Titanium',
    lightWeight: 5,
    flexibility: 4,
    durability: 3,
    styleQuotient: 2,
  },
  {
    id: 5,
    images: images.frame_material5,
    title: 'Wood',
    lightWeight: 5,
    flexibility: 4,
    durability: 3,
    styleQuotient: 2,
  },
];

// Guide EyeglassesLensWidthData
export const GuideEyeglassesLensWidthData = [
  {
    id: 1,
    title: 'Small',
    description: 'Below 48 mm',
    path: '/',
    likeName: 'View Range',
  },
  {
    id: 1,
    title: 'Medium',
    description: '48mm to 55 mm',
    path: '/',
    likeName: 'View Range',
  },
  {
    id: 1,
    title: 'Large',
    description: 'Above 55 mm',
    path: '/',
    likeName: 'View Range',
  },
];

// Guide Frame & Face Shapes Data
export const GuideFrameFaceShapeData = [
  {
    id: 1,
    images: images.guide_frame_face_shape_oval,
    title: 'Oval',
    description: 'Symmetrical,defined cheekbones',
    shapeImagesOne: images.guide_frame_face_shape_oval1,
    shapeImagesTwo: images.guide_frame_face_shape_oval2,
    shapeImagesThree: images.guide_frame_face_shape_oval3,
    shapeImagesFour: images.guide_frame_face_shape_oval4,
    shapeImagesFive: images.guide_frame_face_shape_oval5,
    shapeImagesSix: images.guide_frame_face_shape_oval6,
    shapeTitleOne: 'Cat eye',
    shapeTitleOnePath: '/',
    shapeTitleTwo: 'Rectangle',
    shapeTitleTwoPath: '/',
    shapeTitleThree: 'Aviator',
    shapeTitleThreePath: '/',
    shapeTitleFour: 'Wayfarer',
    shapeTitleFourPath: '/',
    shapeTitleFive: 'Square',
    shapeTitleFivePath: '/',
    shapeTitleSix: 'Hexagonal',
    shapeTitleSixPath: '/',
  },
  {
    id: 2,
    images: images.guide_frame_face_shape_diamond,
    title: 'Diamond',
    description: 'Narrow forehead, pointed jawline',
    shapeImagesOne: images.guide_frame_face_shape_diamond1,
    shapeImagesTwo: images.guide_frame_face_shape_diamond2,
    shapeImagesThree: images.guide_frame_face_shape_diamond3,
    shapeImagesFour: images.guide_frame_face_shape_diamond4,
    shapeTitleOne: 'Round',
    shapeTitleTwo: 'Aviator',
    shapeTitleThree: 'Hexagonal',
    shapeTitleFour: 'Oval',
  },
  {
    id: 3,
    images: images.guide_frame_face_shape_round,
    title: 'Round',
    description: 'Round chin, wide cheeks',
    shapeImagesOne: images.guide_frame_face_shape_round1,
    shapeImagesTwo: images.guide_frame_face_shape_round2,
    shapeImagesThree: images.guide_frame_face_shape_round3,
    shapeTitleOne: 'Round',
    shapeTitleTwo: 'Aviator',
    shapeTitleThree: 'Square',
  },
  {
    id: 4,
    images: images.guide_frame_face_shape_rectangle,
    title: 'Rectangle',
    description: 'Wide cheeks,wide chin',
    shapeImagesOne: images.guide_frame_face_shape_rectangle1,
    shapeImagesTwo: images.guide_frame_face_shape_rectangle2,
    shapeImagesThree: images.guide_frame_face_shape_rectangle3,
    shapeImagesFour: images.guide_frame_face_shape_rectangle4,
    shapeImagesFive: images.guide_frame_face_shape_rectangle5,
    shapeTitleOne: 'Round',
    shapeTitleTwo: 'Cat eye',
    shapeTitleThree: 'Aviator',
    shapeTitleFour: 'Wayfarerval',
    shapeTitleFive: 'Oval',
  },
  {
    id: 5,
    images: images.guide_frame_face_shape_v_triangle,
    title: 'V-Triangle',
    description: 'Wide cheeks,tapered chin',
    shapeImagesOne: images.guide_frame_face_shape_v_triangle1,
    shapeImagesTwo: images.guide_frame_face_shape_v_triangle2,
    shapeImagesThree: images.guide_frame_face_shape_v_triangle3,
    shapeImagesFour: images.guide_frame_face_shape_v_triangle4,
    shapeImagesFive: images.guide_frame_face_shape_v_triangle5,
    shapeImagesSix: images.guide_frame_face_shape_v_triangle6,
    shapeTitleOne: 'Round',
    shapeTitleTwo: 'Cat eye',
    shapeTitleThree: 'Aviator',
    shapeTitleFour: 'Wayfarerval',
    shapeTitleFive: 'Square',
    shapeTitleSix: 'Hexagonal',
  },
  {
    id: 6,
    images: images.guide_frame_face_shape_heart,
    title: 'Heart',
    description: 'Elongated features,from forehead to chin',
    shapeImagesOne: images.guide_frame_face_shape_heart1,
    shapeImagesTwo: images.guide_frame_face_shape_heart2,
    shapeImagesThree: images.guide_frame_face_shape_heart3,
    shapeImagesFour: images.guide_frame_face_shape_heart4,
    shapeImagesFive: images.guide_frame_face_shape_heart5,
    shapeImagesSix: images.guide_frame_face_shape_heart6,
    shapeTitleOne: 'Round',
    shapeTitleTwo: 'Cat eye',
    shapeTitleThree: 'Aviator',
    shapeTitleFour: 'Wayfarer',
    shapeTitleFive: 'Hexagonal',
    shapeTitleSix: 'Oval',
  },
];

// GuideLensType Data
export const GuideLensTypeData = [
  {
    id: 1,
    images: images.guide_lens_type1,
    title: 'Anti-Glare or',
    titleInner: 'Anti-Reflective',
    description:
      'Also called AR CoatingEliminates reflections of lightImproves vision',
  },
  {
    id: 2,
    images: images.guide_lens_type2,
    title: 'UV',
    titleInner: 'Protection',
    description:
      'Filters out 100% of UVA and UVB lightBlocks all light rays up to 400 nm Provides optimum protection to eyes',
  },
  {
    id: 3,
    images: images.guide_lens_type3,
    title: 'Blue',
    titleInner: 'Cat',
    description:
      'Reflects blue lightReduces strain on the eyesImparts more clarity in vision',
  },
  {
    id: 4,
    images: images.guide_lens_type4,
    title: 'Hydrophobic',
    titleInner: 'Anti-Glare Lenses',
    description:
      'Additional coating to repel waterExhibits anti-glare propertiesEasier to clean',
  },
];

// Guide Eye Care Data
export const GuideEyeCareData = [
  {
    id: 1,
    images: images.gide_eye_care1,
    title: 'Daily Diet',
    description:
      'Auctor consectetur molestie arcu velit scelerisque posuere vulputate facilisi sem. Aliquet et justo aliquet libero varius dictum.',
  },
  {
    id: 2,
    images: images.gide_eye_care2,
    title: 'Take Breakes',
    description:
      'Auctor consectetur molestie arcu velit scelerisque posuere vulputate facilisi sem. Aliquet et justo aliquet libero varius dictum.',
  },
  {
    id: 3,
    images: images.gide_eye_care3,
    title: 'Avoid Smoking',
    description:
      'Auctor consectetur molestie arcu velit scelerisque posuere vulputate facilisi sem. Aliquet et justo aliquet libero varius dictum.',
  },
  {
    id: 4,
    images: images.gide_eye_care4,
    title: 'Protective Goggles',
    description:
      'Auctor consectetur molestie arcu velit scelerisque posuere vulputate facilisi sem. Aliquet et justo aliquet libero varius dictum.',
  },
];

// Guide EyeWear Care Data
export const GuideEyeWearCareData = [
  {
    id: 1,
    images: images.gide_eye_wear_care_1,
    title: 'Cleaning Care',
    description:
      'Auctor consectetur molestie arcu velit scelerisque posuere vulputate facilisi sem. Aliquet et justo aliquet libero varius dictum.',
  },
  {
    id: 2,
    images: images.gide_eye_wear_care_2,
    title: 'Do not use Chemicals',
    description:
      'Auctor consectetur molestie arcu velit scelerisque posuere vulputate facilisi sem. Aliquet et justo aliquet libero varius dictum.',
  },
  {
    id: 3,
    images: images.gide_eye_wear_care_3,
    title: 'Glasses in Hand case',
    description:
      'Auctor consectetur molestie arcu velit scelerisque posuere vulputate facilisi sem. Aliquet et justo aliquet libero varius dictum.',
  },
  {
    id: 4,
    images: images.gide_eye_wear_care_4,
    title: 'Avoid placing top of head',
    description:
      'Auctor consectetur molestie arcu velit scelerisque posuere vulputate facilisi sem. Aliquet et justo aliquet libero varius dictum.',
  },
];

// Guide Popular Collections Data
export const PopularCollectionsData = [
  {
    id: 1,
    images: images.guide_popular_collection1,
    title: 'Vagabond',
    description:
      'Full-rim eyeglasses Plethora of designs and styles Crafted with acetate, TR90, stainless steel material',
  },
  {
    id: 2,
    images: images.guide_popular_collection2,
    title: 'Size Zero',
    description:
      'Attractive temples Stainless steel frame Half-rim, rimless & full-rim eyeglasses',
  },
  {
    id: 2,
    images: images.guide_popular_collection3,
    title: 'Myriad',
    description:
      'Comes in different size options Full rim acetate frames Fitted with spring hinges',
  },
];

// steeper Stepper Delivery Address Data
export const StepperDeliveryAddressData = [
  {
    id: 1,
    personalInfo: 'Guy Hawkins',
    addersStatus: 'Home',
    phoneNumber: '(307) 555-0133',
    adders: '4517 Washington Ave. Manchester, Kentucky 39495',
  },
  {
    id: 2,
    personalInfo: 'Eleanor Pena',
    addersStatus: 'Home',
    phoneNumber: '(252) 555-0126',
    adders: '2464 Royal Ln. Mesa, New Jersey 45463',
  },
  {
    id: 3,
    personalInfo: 'Cody Fisher',
    addersStatus: 'Home',
    phoneNumber: '(307) 555-0133',
    adders: '4517 Washington Ave. Manchester, Kentucky 39495',
  },
  {
    id: 4,
    personalInfo: 'Cody Fisher',
    addersStatus: 'Home',
    phoneNumber: '(307) 555-0133',
    adders: '4517 Washington Ave. Manchester, Kentucky 39495',
  },
];

// useMyFrame Page Data
// frame Information card stepper Data
export const FrameInfoCardStepperData = [
  {
    id: 1,
    image: images.frame_info_ex_img1,
    title: 'Left Temple',
  },
  {
    id: 1,
    image: images.frame_info_ex_img2,
    title: 'Center',
  },
  {
    id: 1,
    image: images.frame_info_ex_img3,
    title: 'Right Temple',
  },
];

// frame Information card stepper Data
export const SelectLensCardStepperData = [
  {
    id: 1,
    companyLogo: images.select_lens_logo,
    warranty: '1 Year Warranty',
    lensePrice: 1499,
    lenseBlock: 'Blue Block & Anti-Glare',
    lensePower: '1.6 - 1.74 Index [Thinnest - for Powers Upto - 12]',
    lenseSide: 'Double - Side Anti-Glare',
    lenseResistant: 'Crack, Smudge & Scratch Resistant',
    lenseWater: 'Water & dust Repellent',
    lenseProtected: 'UV 420 - Protection',
  },
  {
    id: 2,
    companyLogo: images.select_lens_logo,
    warranty: '1 Year Warranty',
    lensePrice: 1499,
    lenseBlock: 'Blue Block & Anti-Glare',
    lensePower: '1.6 - 1.74 Index [Thinnest - for Powers Upto - 12]',
    lenseSide: 'Double - Side Anti-Glare',
    lenseResistant: 'Crack, Smudge & Scratch Resistant',
    lenseWater: 'Water & dust Repellent',
    lenseProtected: 'UV 420 - Protection',
  },
  {
    id: 3,
    companyLogo: images.select_lens_logo,
    warranty: '1 Year Warranty',
    lensePrice: 1499,
    lenseBlock: 'Blue Block & Anti-Glare',
    lensePower: '1.6 - 1.74 Index [Thinnest - for Powers Upto - 12]',
    lenseSide: 'Double - Side Anti-Glare',
    lenseResistant: 'Crack, Smudge & Scratch Resistant',
    lenseWater: 'Water & dust Repellent',
    lenseProtected: 'UV 420 - Protection',
  },
];

// Power of Frame Data
export const PowerFrameData = [
  {
    id: 1,
    images: <typography.PrescriptionPowerIcon />,
    title: 'Enter Prescription, after Checkout',
    description: 'You’ll get the option to enter your prescription details.',
  },
  {
    id: 2,
    images: <typography.UploadIcon />,
    title: 'Upload, after Checkout',
    description:
      'You may upload scanned copy of your prescription saved on your computer.',
  },
];

// Terms and Condition Data
export const TermsConditionData = {
  // termsConditionDescriptionOne:
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  termsConditionDescriptionTwo:
    "We are committed to providing you high quality products. In a rare event, if you aren't fully satisfied with our product and find it is not 'As described' on our website, or is received in a 'damaged' condition, you can request an Exchange or Refund within 14 days of receiving the order for eyeglasses and within 7 days of receiving the order for sunglasses for Lenskart in-house brands. We allow exchange/refund with the following exceptions:",
  contactLensesDetails:
    'These would be acceptable only if they are in unworn condition, with the sealed outer packaging intact within 14 days in exceptional cases through customer support centre. Contact Lenses can only be refunded, not exchanged.',
  eyeWearDetailsOne:
    '[Eyeglasses, Sunglasses, Reading Glasses] with the following are not accepted for exchange/refund:',
  eyeWearDetailsTwo:
    'Scratches on frame or lens that were not present at the time of delivery. We do a rigorous 3-step quality check to ensure that the product reaches you in perfect condition.',
  eyeWearDetailsThree:
    'Product damaged due to mishandling post delivery [product is de-shaped, with visible intentional damage to the product].',
  eyeWearDetailsFour:
    'Eyewear case or other standard packaging is damaged or torn or otherwise not in the same condition as it was received.',
  accessoriesDetails: 'Refund/ exchange is not allowed on accessories.',
  goldMemberShipDetails: 'Once purchased this Membership is non- refundable',
  partialReturnDetails:
    'Partial returns are entitled for exchange and store credits only if Buy One Get One offer is availed. To avail complete refund, all the products in the order need to be refund requested',
  ReturnRequestDescription:
    'Sit varius at venenatis facilisi in consectetur in. Velit nunc elementum arcu aliquam amet in facilisis.',
  ReturnRequestDetailsOne:
    'Sign In/Log In: Visit “My Orders” section & go to specific order to “Request Return”.',
  ReturnRequestDetailsTwo:
    'Call Optics Haven Customer Care at 05637281191 or send an email to support@opticshaven.com with order details.',
  contactLensesDescription:
    'These would be acceptable only if they are in unworn condition, with the sealed outer packaging intact within 14 days in exceptional cases through customer support centre. Contact Lenses can only be refunded, not exchanged.',
};

// lens
// Select Lens Details
export const selectLensTypeOfPrescription = [
  {
    id: 1,
    images: <typography.SelectVisionLenseIcons />,
    title: 'Single Vision Lenses',
    price: '$195',
    details:
      'This lens is generally used to correct one focal distance ( Far or Near) it is primary used to correct Myopia (Short sightedness) or Hyperopia (Long Sightedness).',
  },
  {
    id: 2,
    images: <typography.SelectProgressivesIcons />,
    title: 'Progressives',
    price: '$386',
    details: 'Distance & Near Vision in same lenses.',
  },
  {
    id: 3,
    images: <typography.SelectBifocalIcons />,
    title: 'Bifocal',
    price: '$100',
    details: 'For Distance or near Vision.(Thin, anti-glare, blue-cut option)',
  },
  {
    id: 4,
    images: <typography.SelectNonPrescriptionIcons />,
    title: 'Non-Prescription',
    price: '$179',
    details: 'Distance & Near Vision in same lenses.',
  },
  {
    id: 5,
    images: <typography.SelectReadersIcons />,
    title: 'Readers',
    price: '$185',
    details: 'For Distance or near Vision.(Thin, anti-glare, blue-cut option)',
  },
];

// single Vision Lens Details & Kids
// single Vision Standard
export const singleVisionStandardAndKidsData = [
  {
    id: 1,
    title: 'Standard',
    price: 'Free',
    details: 'For Distance or near Vision.(Thin, anti-glare, blue-cut option)',
  },
  {
    id: 2,
    title: 'Anti-fatigue',
    price: '+$100',
    details: 'Fashion or Protection frame Glare/Computer Screen etc.',
  },
];

// single Vision Classic - Type of Lens
export const classicTypeofLensData = [
  {
    id: 1,
    title: 'Classic',
    price: 'Free',
    details:
      'Scratch-resistant, anti-reflective lenses that block 100% of UV rays',
  },
  {
    id: 2,
    title: 'Blue-light filtering',
    price: '+$50',
    details:
      'Scratch-resistant, anti-reflective lenses that block 100% of UV rays',
  },
  {
    id: 3,
    title: 'Light responsive',
    price: '$100',
    details:
      'Scratch-resistant, anti-reflective lenses that block 100% of UV rays',
  },
];

// single Vision Polycarbonate - Type of Lens Material
export const polycarbonateTypeofLensMaterialData = [
  {
    id: 1,
    title: 'Polycarbonate',
    price: 'Free',
    details: 'High-quality and impact-resistant',
  },
  {
    id: 2,
    title: '1.67 high-index',
    price: '+$50',
    details:
      'Thinner lenses recommended for strong prescriptions (if total power is +/-4.0 or higher)',
  },
];

// Color - Type of Lens Color
export const colorTypeofLensColorData = [
  {
    id: 1,
    title: 'Grey',
    colorCode: 'linear-gradient(270deg, #5F605F 0%, #FEFFFE 100%)',
  },
  {
    id: 2,
    title: 'Brown',
    colorCode: 'linear-gradient(273deg, #8B715A 0%, #FFF 100%)',
  },
  {
    id: 3,
    title: 'Blue',
    colorCode: 'linear-gradient(270deg, #3B6096 0%, #FEFEFE 100%)',
  },
  {
    id: 4,
    title: 'Green',
    colorCode: 'linear-gradient(270deg, #56734A 0%, #FEFFFE 100%)',
  },
];

// Progressives Lens
// Progressives Classic - Type of Lens
export const progressivesClassicTypeofLensData = [
  {
    id: 1,
    title: 'Classic',
    price: '$100',
    details:
      'Scratch-resistant, anti-reflective lenses that block 100% of UV rays',
  },
  {
    id: 2,
    title: 'Blue-light filtering',
    price: '$100',
    details:
      'Scratch-resistant, anti-reflective lenses that block 100% of UV rays',
  },
  {
    id: 3,
    title: 'Light responsive',
    price: '$100',
    details:
      'Scratch-resistant, anti-reflective lenses that block 100% of UV rays',
  },
];

/* The above code is exporting an array called `authFrames` which contains objects representing
different frames for a login page. Each object has properties such as `id`, `images`, `title`, and
`description` which provide information about the frame. These frames can be used to display
different login page designs with corresponding titles and descriptions. */
export const authFrames = [
  {
    id: 1,
    images: images.login_1,
    title: 'Seeing the world through stylish frames',
    description:
      'Discovering the beauty of the world with the perfect blend of fashion and vision, thanks to these trendy eyeglasses.',
  },
  {
    id: 2,
    images: images.login_2,
    title: 'Seeing clearly, looking fabulous',
    description:
      'Experience crystal-clear vision while exuding effortless style and confidence.',
  },
  {
    id: 3,
    images: images.login_3,
    title: 'Elevate your sight, elevate your style',
    description:
      'Enhance your vision and elevate your fashion game to new heights.',
  },
  {
    id: 4,
    images: images.login_4,
    title: 'A clearer view, a confident you',
    description: 'See the world with clarity and embrace your confident self.',
  },
  {
    id: 5,
    images: images.login_5,
    title: 'A clearer view, a confident you',
    description: 'See the world with clarity and embrace your confident self.',
  },
];

/* The above code is exporting an array called `contactData` which contains objects with contact
information. Each object represents a different piece of contact information, such as company name,
address, email address, country, and contact number. Each object has properties such as `id`,
`icons`, `userInfo`, and `userDetails` which store the corresponding information. The `icons`
property contains JSX elements representing typography. */
export const contactData = [
  {
    id: 1,
    icons: <typography.CompanyIcon />,
    userInfo: 'Company name:',
    userDetails: 'Optics Haven',
    path: '/',
  },
  {
    id: 2,
    icons: <typography.LocationIcon stroke={'#000'} />,
    userInfo: 'Address:',
    userDetails: '1305 West 7th St Suite 1 -1063 Frederick, MD 21702',
    path: '/',
  },
  {
    id: 3,
    icons: <typography.EmailIcon margin_right={'0'} stroke={'#000'} />,
    userInfo: 'Email Address:',
    userDetails: 'contact@opticshaven.com',
    path: `mailto:${process.env.REACT_APP_PUBLIC_CONTACT_MAIL}`,
  },
  {
    id: 4,
    icons: <typography.CountryIcon />,
    userInfo: 'Country:',
    userDetails: 'USA',
    path: '/',
  },
  {
    id: 5,
    icons: <BsTelephone />,
    userInfo: 'Contact number:',
    userDetails: '888-279-9806',
    path: `tel:${process.env.REACT_APP_PUBLIC_CONTACT_TEL_URL}`,
  },
];

/* The above code is exporting an array called `contactUsPolicyData`. This array contains objects with
information about contact us policies. Each object has properties such as `id`, `images`, `title`,
and `description`. These properties store information about the policy, such as the policy's ID, an
image associated with the policy, the policy's title, and a description of the policy. */
export const contactUsPolicyData = [
  {
    id: 1,
    images: images.about_us_contact1,
    title: '14 Day Refund',
    description:
      'Nunc non diam fusce consectetur. Tellus pharetra in porta urna sed tellus velit eu. Ut in diam lorem aliquam vivamus. Condimentum enim.',
  },
  {
    id: 2,
    images: images.about_us_contact2,
    title: '1 year Warranty',
    description:
      'Nunc non diam fusce consectetur. Tellus pharetra in porta urna sed tellus velit eu. Ut in diam lorem aliquam vivamus. Condimentum enim in habitant elementum .',
  },
  {
    id: 3,
    images: images.about_us_contact3,
    title: 'Authenticity card',
    description:
      'Nunc non diam fusce consectetur. Tellus pharetra in porta urna sed tellus velit eu. Ut in diam lorem aliquam vivamus. Condimentum enim in habitant elementum .',
  },
];

/* The above code is exporting an array called `varietyData` which contains objects with information
about different varieties of eyewear. Each object has properties such as `id`, `images`, `title`,
and `description` which provide details about the variety. The `images` property is referencing
images related to each variety. */
export const varietyData = [
  {
    id: 1,
    images: images.about_us_variety1,
    title: 'We let the numbers talk.',
    description:
      'AEgestas quam tortor et nunc at nunc condimentum. Proin et rhoncus fermentum vitae at. Eu eget urna enim at fames.',
  },
  {
    id: 2,
    images: images.about_us_variety2,
    title: 'An eye for an eye',
    description:
      'From sunglasses to reading glasses to contact lenses, we make everything that can help you see this beautiful world with more clarity.',
  },
  {
    id: 3,
    images: images.about_us_variety3,
    title: 'We got the whole world',
    description:
      'es it is true make eyewear for men, women, kids, for all humans of the world.',
  },
  {
    id: 4,
    images: images.about_us_variety4,
    title: 'All covered',
    description:
      'We have it all. everyday basics. Everyday fashion. Evening wear. Not just that, we update our styles every year to keep up with fashion trends around the globe, taking inspiration from fashion capitals and the best designers of the world.',
  },
];

/* The above code is exporting an array called `lensePresentationData`. This array contains objects
with properties such as `id`, `images`, `title`, and `description`. Each object represents a
different option for entering or submitting a prescription for lenses. */
export const lensePresentationData = [
  {
    id: 1,
    images: images.lense_prescription_img_1,
    title: 'Enter Prescription Manually',
    description: 'Enter your both eye details as a prescription mannually',
  },
  {
    id: 2,
    images: images.lense_prescription_img_2,
    title: 'Upload Prescription',
    description: 'This is the quickest option.',
  },
];

// Mega Menu Data
// subCategory our Top Picks list
export const ourTopPicks = [
  {
    id: 1,
    name: 'New Arrivals',
    url: routesConfig?.productList + '?sortBy=new_arrival',
  },
];

// subCategory frame Type list
export const frameType = [
  {
    id: 1,
    name: 'Rectangle Frames',
    url: '/',
  },
  {
    id: 2,
    name: 'Wayfarer Frames',
    url: '/',
  },
  {
    id: 3,
    name: 'Round Frames',
    url: '/',
  },
  {
    id: 4,
    name: 'Aviator Frames',
    url: '/',
  },
  {
    id: 5,
    name: 'Cat Eye Frames',
    url: '/',
  },
  {
    id: 6,
    name: 'Rimless Frames',
    url: '/',
  },
  {
    id: 7,
    name: 'Half Rim Frames',
    url: '/',
  },
  {
    id: 8,
    name: 'Geometric Frames',
    url: '/',
  },
];

// subCategory collection list
export const collection = [
  {
    id: 1,
    name: 'Brow Bars',
    url: '/',
  },
  {
    id: 2,
    name: 'Blend Edit',
    url: '/',
  },
  {
    id: 3,
    name: 'Sleek Steel',
    url: '/',
  },
  {
    id: 4,
    name: 'Air Flex',
    url: '/',
  },
  {
    id: 5,
    name: 'Air Wrap',
    url: '/',
  },
  {
    id: 6,
    name: 'Hip Hop',
    url: '/',
  },
  {
    id: 7,
    name: 'Classic Acetate',
    url: '/',
  },
  {
    id: 8,
    name: 'Indian Accent',
    url: '/',
  },
  {
    id: 9,
    name: 'Float Pop',
    url: '/',
  },
];

// subCategory brands list
export const brands = [
  {
    id: 1,
    name: 'Vincent Chase',
    url: '/',
  },
  {
    id: 2,
    name: 'John Jacobs',
    url: '/',
  },
  {
    id: 3,
    name: 'Optics Haven Air',
    url: '/',
  },
  {
    id: 4,
    name: 'OJOS',
    url: '/',
  },
  {
    id: 5,
    name: 'New Balance',
    url: '/',
  },
  {
    id: 6,
    name: 'Carrera',
    url: '/',
  },
  {
    id: 7,
    name: 'Fossil',
    url: '/',
  },
  {
    id: 8,
    name: 'Optics Haven Studio',
    url: '/',
  },
];

// our process work
export const workDetails = [
  {
    id: 1,
    images: <typography.Frame />,
    grid_span: 'w-[31%]',
    title: 'Choose Your Frame',
    workDetails: `Please browse our collection of high-quality frames and select based on your size, style, and budget. If you already have a frame in mind, stype it in the search bar and find the color you want. If you don't have a frame in mind, choosing your frames is easier when you keep the following things in mind.`,
  },
  {
    id: 2,
    images: <typography.Lenses />,
    grid_span: 'w-[31%]',
    title: 'Select your Lenses',
    workDetails:
      'Customize your selected frame with your preferred lenses. We Have single-vision lenses, Progressive lenses, readers, and Non-Prescription. Select your lenses according to your visual needs.',
  },
  {
    id: 3,
    images: <typography.Prescription />,
    grid_span: 'w-[31%]',
    title: 'Upload your Prescription',
    workDetails:
      'Once you have selected the frames and lenses you want in your Eyeglasses, the next step is entering your prescription. We have four options for you to enter your lens prescription.',
  },
  {
    id: 4,
    images: <typography.Checkout />,
    grid_span: 'w-[48.2%]',
    title: 'Checkout',
    workDetails:
      'This process is easy; confirm that you have the right frame and lenses. Please enter your payment method in our secured payment portal and checkout.',
  },
  {
    id: 5,
    images: <typography.DeliverHome />,
    grid_span: 'w-[48.2%]',
    title: 'Delivers to Home',
    workDetails:
      'Optics Haven produces your Eyeglasses and delivers them to your address using our shipping Partner in 7 to 14 business days.',
  },
];

// Manually measure your PD
export const manuallyMeasureYourPDData = [
  {
    id: 1,
    image: images.manually_measure_PD_1,
    title: 'Position yourself',
    description:
      'Stand 8 inches away from the mirror or have a friend read your PD. If a friend is reading, look above their head at something 10-20 ft away.',
  },
  {
    id: 2,
    image: images.manually_measure_PD_2,
    title: 'Grab a ruler',
    description:
      'With your face straight, hold a ruler with millimeters against your brow. If needed, you can download our PD ruler.',
  },
  {
    id: 3,
    image: images.manually_measure_PD_3,
    title: 'Find your PD',
    description:
      'Close your right eye and align the ruler’s zero to the center of your left pupil. Then close your left eye and read the millimeter line that lines up with the center of the right pupil.',
  },
];

// Start with Frame Width
export const startWithFrameWidthData = [
  {
    id: 1,
    image: images.frame_width1,
    title: 'Measure your current pair',
    description:
      'Measure horizontally across the front of the frame from hinge to hinge.',
  },
  {
    id: 2,
    image: images.frame_width2,
    title: 'Use frame cutouts',
    description:
      'Print and cut out different frame sizes for adults and kids to determine your size range.',
  },
];

// frame Size
export const frameSizeData = [
  {
    id: 1,
    image: images.frame_size1,
    title: 'Adult Extra-Small',
    mm: '110-118 mm',
    in: '4.5-4.6 In',
  },
  {
    id: 2,
    image: images.frame_size2,
    title: 'Adult Small',
    mm: '119-125 mm',
    in: '4.6-4.9 In',
  },
  {
    id: 3,
    image: images.frame_size3,
    title: 'Adult Medium',
    mm: '126-132 mm',
    in: '4.9-5.2 In',
  },
  {
    id: 4,
    image: images.frame_size4,
    title: 'Adult Large',
    mm: '133-140 mm',
    in: '5.2-5.5 In',
  },
  {
    id: 5,
    image: images.frame_size5,
    title: 'Adult Extra-Large',
    mm: '141+ mm',
    in: '5.5+ In',
  },
];

// Kids Glasses
export const kidsGlasses = [
  {
    id: 1,
    image: images.kids_glasses1,
    title: 'Kids Extra-Small',
    mm: '90-106 mm',
  },
  {
    id: 2,
    image: images.kids_glasses2,
    title: 'Kids Small',
    mm: '107-112 mm',
  },
  {
    id: 3,
    image: images.kids_glasses3,
    title: 'Kids Medium',
    mm: '113-118 mm',
  },
  {
    id: 4,
    image: images.kids_glasses4,
    title: 'Kids Large',
    mm: '119-150 mm',
  },
];

/* The above code is exporting an object named `sphOptions` which contains four arrays. Each array
represents a set of options for a specific category. The arrays contain a range of numbers and a
string value "Plano". These options can be used in a dropdown or select input to provide choices for
the user. */
export const sphOptions = {
  0: [
    -16.0,
    -15.75,
    -15.5,
    -15.25,
    -15.0,
    -14.75,
    -14.5,
    -14.25,
    -14.0,
    -13.75,
    -13.5,
    -13.25,
    -13.0,
    -12.75,
    -12.5,
    -12.25,
    -12.0,
    -11.75,
    -11.5,
    -11.25,
    -11.0,
    -10.75,
    -10.5,
    -10.25,
    -10.0,
    -9.75,
    -9.5,
    -9.25,
    -9.0,
    -8.75,
    -8.5,
    -8.25,
    -8.0,
    -7.75,
    -7.5,
    -7.25,
    -7.0,
    -6.75,
    -6.5,
    -6.25,
    -6.0,
    -5.75,
    -5.5,
    -5.25,
    -5.0,
    -4.75,
    -4.5,
    -4.25,
    -4.0,
    -3.75,
    -3.5,
    -3.25,
    -3.0,
    -2.75,
    -2.5,
    -2.25,
    -2.0,
    -1.75,
    -1.5,
    -1.25,
    -1.0,
    -0.75,
    -0.5,
    -0.25,
    0.0,
    'Plano',
    +0.25,
    +0.5,
    +0.75,
    +1.0,
    +1.25,
    +1.5,
    +1.75,
    +2.0,
    +2.25,
    +2.5,
    +2.75,
    +3.0,
    +3.25,
    +3.5,
    +3.75,
    +4.0,
    +4.25,
    +4.5,
    +4.75,
    +5.0,
    +5.25,
    +5.5,
    +5.75,
    +6.0,
    +6.25,
    +6.5,
    +6.75,
    +7.0,
    +7.25,
    +7.5,
    +7.75,
    +8.0,
    +8.25,
    +8.5,
    +8.75,
    +9.0,
    +9.25,
    +9.5,
    +9.75,
    +10.0,
    +10.25,
    +10.5,
    +10.75,
    +11.0,
    +11.25,
    +11.5,
    +11.75,
    +12.0,
  ],
  1: [
    -16.0,
    -15.75,
    -15.5,
    -15.25,
    -15.0,
    -14.75,
    -14.5,
    -14.25,
    -14.0,
    -13.75,
    -13.5,
    -13.25,
    -13.0,
    -12.75,
    -12.5,
    -12.25,
    -12.0,
    -11.75,
    -11.5,
    -11.25,
    -11.0,
    -10.75,
    -10.5,
    -10.25,
    -10.0,
    -9.75,
    -9.5,
    -9.25,
    -9.0,
    -8.75,
    -8.5,
    -8.25,
    -8.0,
    -7.75,
    -7.5,
    -7.25,
    -7.0,
    -6.75,
    -6.5,
    -6.25,
    -6.0,
    -5.75,
    -5.5,
    -5.25,
    -5.0,
    -4.75,
    -4.5,
    -4.25,
    -4.0,
    -3.75,
    -3.5,
    -3.25,
    -3.0,
    -2.75,
    -2.5,
    -2.25,
    -2.0,
    -1.75,
    -1.5,
    -1.25,
    -1.0,
    -0.75,
    -0.5,
    -0.25,
    0.0,
    'Plano',
    +0.25,
    +0.5,
    +0.75,
    +1.0,
    +1.25,
    +1.5,
    +1.75,
    +2.0,
    +2.25,
    +2.5,
    +2.75,
    +3.0,
    +3.25,
    +3.5,
    +3.75,
    +4.0,
    +4.25,
    +4.5,
    +4.75,
    +5.0,
    +5.25,
    +5.5,
    +5.75,
    +6.0,
    +6.25,
    +6.5,
    +6.75,
    +7.0,
    +7.25,
    +7.5,
    +7.75,
    +8.0,
    +8.25,
    +8.5,
    +8.75,
    +9.0,
    +9.25,
    +9.5,
    +9.75,
    +10.0,
    +10.25,
    +10.5,
    +10.75,
    +11.0,
    +11.25,
    +11.5,
    +11.75,
    +12.0,
  ],
  2: [
    46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64,
    65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 78, 79, 80,
  ],
  3: [23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
};

/* The above code is exporting an object named `cylOptions` in JavaScript. This object contains three
arrays, each representing options for a different category. The first two arrays (with keys 0 and 1)
contain a range of numbers and a string value 'PH/DS'. The third array (with key 2) contains a range
of numbers from 25 to 40. These arrays likely represent options for selecting cylinder values in an
application or form. */
export const cylOptions = {
  0: [
    -6.0,
    -5.75,
    -5.5,
    -5.25,
    -5.0,
    -4.75,
    -4.5,
    -4.25,
    -4.0,
    -3.75,
    -3.5,
    -3.25,
    -3.0,
    -2.75,
    -2.5,
    -2.25,
    -2.0,
    -1.75,
    -1.5,
    -1.25,
    -1.0,
    -0.75,
    -0.5,
    -0.25,
    0.0,
    'PH/DS',
    +0.25,
    +0.5,
    +0.75,
    +1.0,
    +1.25,
    +1.5,
    +1.75,
    +2.0,
    +2.25,
    +2.5,
    +2.75,
    +3.0,
    +3.25,
    +3.5,
    +3.75,
    +4.0,
    +4.25,
    +4.5,
    +4.75,
    +5.0,
    +5.25,
    +5.5,
    +5.75,
    +6.0,
  ],
  1: [
    -6.0,
    -5.75,
    -5.5,
    -5.25,
    -5.0,
    -4.75,
    -4.5,
    -4.25,
    -4.0,
    -3.75,
    -3.5,
    -3.25,
    -3.0,
    -2.75,
    -2.5,
    -2.25,
    -2.0,
    -1.75,
    -1.5,
    -1.25,
    -1.0,
    -0.75,
    -0.5,
    -0.25,
    0.0,
    'PH/DS',
    +0.25,
    +0.5,
    +0.75,
    +1.0,
    +1.25,
    +1.5,
    +1.75,
    +2.0,
    +2.25,
    +2.5,
    +2.75,
    +3.0,
    +3.25,
    +3.5,
    +3.75,
    +4.0,
    +4.25,
    +4.5,
    +4.75,
    +5.0,
    +5.25,
    +5.5,
    +5.75,
    +6.0,
  ],
  2: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
};

export const powerOptions = [
  '+0.75',
  '+1.00',
  '+1.25',
  '+1.50',
  '+1.75',
  '+2.00',
  '+2.25',
  '+2.50',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
  '+3.75',
];

// Shipping and Return policy
export const shippingAndReturnData = {
  shipping: [
    'Non-prescription eyeglass orders are shipped within one to two business days of ordering the frame. Orders placed after the shipping cut-off time will ship out on the following business day. During major sales or holidays, additional processing time may be required.',
    'A shipping confirmation email containing tracking information (if applicable) will be sent for all orders as soon as they have been shipped.',
    'Shipping times do not include processing times, which are 1-2 business days for non-prescription eyewear and 5-9 business days for prescription eyewear orders. Specialty lenses, toric, and made-to-order products may take longer to process.',
    'In most cases, orders placed with a verified prescription, Monday through Friday before the shipping cut-off time, will be shipped the same day. Orders placed after the cut-off time on Friday will be shipped out the following Monday, while orders placed on weekends or holidays will be processed and shipped the following business day when possible.',
  ],
  expedited: [
    'Non-prescription eyeglasses can be sent out the same day if you choose to expedite shipping. For eyeglasses with prescription lenses, the shipping will be expedited after the lenses are made. Note that lens production depends on lens power and is subject to availability.',
    'If you have ordered multiple eyeglasses in one order, they may be shipped separately, or the order may be held until both sets of eyewear are ready for shipment. This decision is at the discretion of our shipment staff.',
    `*${process.env.REACT_APP_PUBLIC_NAME} ensures that all orders are sent insured against loss in transit`,
  ],
  taxes: [
    'The prices displayed on our website are not inclusive of taxes and processing fees, which will be calculated when you proceed to purchase your order on the checkout page of our website.',
    'A processing fee may be included in the total price of your order and will be specified when you proceed to purchase your order. The processing fee includes taxes, insurance, and costs associated with the handling of your order (such as third-party transaction fees). Processing fees do not include any charges for the cost of the product itself.',
  ],
  refused: [
    'We do our best to ensure that your orders arrive to you in perfect condition.',
    `In the event that your items arrive damaged, please accept the shipment and contact our Customer Service Team by email at <a href="mailto:contact@opticshaven.com" class="text-[#2E3233] font-semibold">contact@opticshaven.com</a> or by phone at <a href="tel:+18882799806" class="text-[#2E3233] font-semibold">888-279-9806</a>.`,
    'You can choose a product for exchange or take a refund as per policy once the returned item reaches us.',
  ],
  returns: [
    'To get the full refund or store credit for the 14-day return, the Glasses returned must be in the original state and have the original packaging for a full refund (the item is unused and unscratched as well as in its original condition and packaging with all added accessories including the lens fluid, cases and cleaning clothes).',
    `For children's eyeglasses, we offer a one-year (365 days) free exchange for damaged eyeglasses.`,
    `To get the one-year warranty, the Kid's eyeglasses must be purchased from the kid's session on our website.`,
    `Store credits do not expire, Children warranty does not include free return shipping as the sender will have to pay the cost for the shipping of damaged children's eyeglasses.`,
  ],
};

// Refer & Earn Term & Condition Data
export const referEarnTermData = [
  {
    referral: {
      title: 'Referral Process:',
      des: [
        'Customers can refer friends by sharing a unique referral link generated by our system.',
        'The referral link can be shared via email, social media, or other communication channels',
        'Referrals must be new customers who have not previously made a purchase on our website.',
      ],
    },
  },
  {
    rewards: {
      title: 'Rewards:',
      des: [
        'For each successful referral (when the referred customer makes a purchase), the referring customer will receive a 15% discount added to their account.',
        'Optics Haven LLC will communicate the 15% discount to the recipients.',
        `Rewards will be credited to the referring customer's account after the referred customer's order is successfully processed and fulfilled.`,
      ],
    },
  },
  {
    referralTracking: {
      title: 'Referral Tracking:',
      des: [
        'Referral tracking will be automated through the unique referral link provided to each customer.',
        'The referring customer can track the status of their referrals through their account dashboard.',
        'The Optics Haven reserves the right to investigate and disqualify any referrals that violate the terms of the referral program.',
      ],
    },
  },
  {
    redeemingRewards: {
      title: 'Redeeming Rewards:',
      des: [
        'Rewards earned through the referral program can be used towards future purchases on our website www.opticshaven.com.',
        'Rewards are not transferable and have no cash value.',
      ],
    },
  },
  {
    programChanges: {
      title: 'Program Changes:',
      des: [
        'The company reserves the right to modify, suspend, or terminate the referral program at any time without prior notice.',
        'Changes to the referral program will be communicated to customers through email or on our website.',
      ],
    },
  },
  {
    fraudulentActivity: {
      title: 'Fraudulent Activity:',
      des: [
        'Any attempt to manipulate or abuse the referral program, including but not limited to creating fake accounts, will result in the disqualification of the referring customer and the referred customer.',
        'The company reserves the right to take legal action against any fraudulent activity.',
      ],
    },
  },
  {
    limitations: {
      title: 'Limitations:',
      des: [
        'The referral program is subject to limitations based on local laws and regulations.',
        'The company reserves the right to limit or restrict the number of referrals a customer can make.',
      ],
    },
  },
];

/* ------------------------- */
// 💡💡 Tablet data 💡💡
/* ------------------------- */

/* The above code is exporting an array called `eyewearData` that contains references to three images
of eyewear. The images are referenced using the variables `images.tablet_eyewear1`,
`images.tablet_eyewear2`, and `images.tablet_eyewear3`. */
export const eyewearData = [
  images.tablet_eyewear1,
  images.tablet_eyewear2,
  images.tablet_eyewear3,
];

/* The above code is exporting an array called `standoutData` which contains references to three
images: `standout1`, `standout2`, and `standout3`. */
export const standoutData = [
  images.standout1,
  images.standout2,
  images.standout3,
];

/* The above code is exporting an array called `brandData` that contains references to three images.
These images are likely related to tablet brands, as indicated by the variable names
`tablet_brand1`, `tablet_brand2`, and `tablet_brand3`. */
export const brandData = [
  images.tablet_brand1,
  images.tablet_brand2,
  images.tablet_brand3,
];

/* The above code is exporting an array of objects called `chatWithUsListData`. Each object in the
array represents a chat option and contains properties such as `id`, `img`, `name`, and `path`.
These properties provide information about the chat option, such as its image, name, and path. */
export const chatWithUsListData = [
  {
    id: 1,
    img: images.chatWithUsList1,
    name: 'Chat With Us',
    path: '',
  },
  {
    id: 2,
    img: images.chatWithUsList2,
    name: 'Frame Size',
    path: '',
  },
  {
    id: 3,
    img: images.chatWithUsList3,
    name: '3D Try On',
    path: '',
  },
  {
    id: 4,
    img: images.chatWithUsList4,
    name: 'Delivery Time',
    path: '',
  },
  {
    id: 5,
    img: images.chatWithUsList5,
    name: 'Computer Glasses',
    path: '',
  },
];

/* The above code is exporting an array of objects called `categoryGlassData`. Each object represents a
category of glasses and contains properties such as `id`, `img`, `name`, and `path`. The `id`
property represents the unique identifier for each category. The `img` property represents the image
associated with the category. The `name` property represents the name of the category. The `path`
property represents the path or URL associated with the category. */
export const categoryGlassData = [
  {
    id: 1,
    img: images.glass1,
    name: 'Eye Glasses',
    path: '',
  },
  {
    id: 2,
    img: images.glass2,
    name: 'Computer Glasses',
    path: '',
  },
  {
    id: 3,
    img: images.glass3,
    name: 'Reading Glasses',
    path: '',
  },
  {
    id: 4,
    img: images.glass4,
    name: 'Accessories',
    path: routesConfig?.accessories,
  },
  {
    id: 5,
    img: images.glass5,
    name: '3D Try On',
    path: '',
  },
  {
    id: 6,
    img: images.glass6,
    name: 'Kids Glasses',
    path: '',
  },
];

/* The above code is exporting an array called `buyOnWhatsappData`. This array contains objects with
properties such as `id`, `img`, `name`, `linkText`, and `path`. These objects represent different
options for buying products on WhatsApp. Each object represents a different option, such as chatting
on WhatsApp, calling a phone number, finding a store near you, or booking a home try-on. The `img`
property represents an image associated with each option, and the `linkText` property represents the
text to display for the link/button associated with each option. The `path` */
export const buyOnWhatsappData = [
  {
    id: 1,
    img: images.buyonWhatsapp1,
    name: 'Buy On Whatsapp',
    linkText: 'Chat Now',
    path: '',
  },
  {
    id: 2,
    img: images.buyonWhatsapp2,
    name: 'Call 1800 245 1245',
    linkText: 'Place Order',
    path: '',
  },
  {
    id: 3,
    img: images.buyonWhatsapp3,
    name: 'Store Near You',
    linkText: 'Chat Now',
    path: '',
  },
  {
    id: 4,
    img: images.buyonWhatsapp4,
    name: 'Book Home try On',
    linkText: 'Place Order',
    path: '',
  },
];

/* The above code is exporting an array called `knowYourGlassesData` which contains objects with
information about different types of glasses. Each object has properties such as `id`, `image`,
`title`, `linkText`, and `path`. These properties provide details about the glasses, such as the
image, title, and a link text. The `path` property is currently empty and may be used to specify a
path for the link. */
export const knowYourGlassesData = [
  {
    id: 1,
    image: images.knowYourGlasses1,
    title: 'BLU Glasses',
    linkText: 'Watch Now',
    path: '',
  },
  {
    id: 2,
    image: images.knowYourGlasses2,
    title: 'Prescription Lenses',
    linkText: 'Watch Now',
    path: '',
  },
  {
    id: 3,
    image: images.knowYourGlasses2,
    title: 'Prescription Lenses',
    linkText: 'Watch Now',
    path: '',
  },
];

/* The above code is exporting an array called `assuranceData` which contains objects representing
different types of assurances related to lenses and frames. Each object has properties such as `id`,
`image`, `title`, `linkText`, and `path`. These properties provide information about the assurance,
such as its image, title, and a link text for chatting. The `images` variable is assumed to be
defined elsewhere in the code and contains references to the images used for each assurance. */
export const assuranceData = [
  {
    id: 1,
    image: images.assurance1,
    title: 'Lenses made by Robots',
    linkText: 'Chat Now',
    path: '',
  },
  {
    id: 2,
    image: images.assurance2,
    title: 'Frames Made With Precision',
    linkText: 'Chat Now',
    path: '',
  },
  {
    id: 3,
    image: images.assurance2,
    title: 'Frames Made With Precision',
    linkText: 'Chat Now',
    path: '',
  },
];

/* The above code is exporting an array called `tabletFooterLinksData` which contains objects representing
links for a tablet footer. Each object has properties such as `id`, `text`, and `path` which
represent the unique identifier, text content, and path of the link respectively. */
export const tabletFooterLinksData = [
  {
    id: 1,
    text: 'Buying Guide',
    path: routesConfig.buyingGuide,
  },
  {
    id: 2,
    text: 'Refer & Earn',
    path: routesConfig.referEarn,
  },
  {
    id: 3,
    text: 'Frame Size',
    path: routesConfig.frameSize,
  },
  {
    id: 4,
    text: 'Blogs',
    path: routesConfig.blog,
  },
  {
    id: 5,
    text: 'Optics Haven Coupons',
    path: routesConfig.coupons,
  },
  {
    id: 6,
    text: 'About Us',
    path: routesConfig.aboutUs,
  },
  {
    id: 7,
    text: 'Vision Insurance',
    path: routesConfig.visionInsurance,
  },
  {
    id: 8,
    text: 'FAQ’s',
    path: routesConfig.faqs,
  },
];

export const tabMobAuthSlider = [images.auth1, images.auth2, images.auth3];

export const tabMobDrawerLinksData = [
  {
    id: 1,
    icon: <typography.AccInfoIcon />,
    title: 'Account Information',
    path: routesConfig.account,
  },
  {
    id: 2,
    path: routesConfig?.order,
    title: 'My Order',
    icon: <typography.OrderIcon />,
    border: <BorderDivider $m_bottom={16} m_top={16} />,
  },
  {
    id: 3,
    title: 'Address book',
    path: routesConfig?.shippingAddress,
    icon: <typography.BlackLocationIcon />,
    border: <BorderDivider $m_bottom={16} m_top={16} />,
  },
  {
    id: 4,
    title: 'My Prescription',
    path: routesConfig?.prescription,
    icon: <typography.PrescriptionIcon />,
    border: <BorderDivider $m_bottom={16} m_top={16} />,
  },
  {
    id: 5,
    title: 'Manage Notification',
    path: routesConfig?.manageNotification,
    icon: <typography.NotificationIcon />,
    border: <BorderDivider $m_bottom={16} m_top={16} />,
  },
  {
    id: 6,
    title: 'Refer and Earn',
    path: routesConfig?.referralProgram,
    icon: <typography.VoucherIcons />,
    border: <BorderDivider $m_bottom={16} m_top={16} />,
  },
];
