import { useSnackbar } from 'context';
import { ApiGetByIdAuthorization, ApiPut } from 'helper/API';
import { message, routesConfig } from 'helper/config';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useEditAddressForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    address: '',
    state: '',
    city: '',
    zipcode: '',
    is_default_address: false,
    email: '',
    mobile_no: '',
    lastname: '',
    firstname: '',
    address_type: '',
  });
  const { showSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();

  const addressId = location?.state?.id;

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);

    const body = {
      address: values?.address,
      state: values?.state,
      city: values?.city,
      zipcode: values?.zipcode,
      is_default_address: values?.is_default_address,
      email: values?.email,
      mobile_no: values?.mobile_no,
      lastname: values?.lastname,
      firstname: values?.firstname,
      address_type: values?.address_type,
    };

    try {
      const response = await ApiPut('/user-billing-details', addressId, body);
      if (response?.data?.message?.status === 200) {
        showSnackbar(
          message?.UPDATE_SUCCESS_SHIPPING_ADDRESS,
          'soft',
          'success'
        );
        navigate(routesConfig?.shippingAddress);
      } else {
        showSnackbar(
          message?.SHIPPING_ADDRESS_FAILURE_UPDATE,
          'soft',
          'danger'
        );
      }
      resetForm();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getSingleAddress = async (addressId) => {
    try {
      setIsLoading(true);
      const response = await ApiGetByIdAuthorization(
        '/user-billing-details',
        addressId
      );

      const data = response?.data[0];

      setFormValues({
        address: data?.address,
        state: data?.state,
        city: data?.city,
        zipcode: data?.zipcode,
        is_default_address: data?.is_default_address,
        email: data?.email,
        mobile_no: data?.mobile_no,
        lastname: data?.lastname,
        firstname: data?.firstname,
        address_type: data?.address_type,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSingleAddress(addressId);
  }, [location, addressId]);

  return {
    isLoading,
    formValues,
    setFormValues,
    handleSubmit,
  };
};

export default useEditAddressForm;
