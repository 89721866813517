import React from 'react';
import * as styles from 'assets/styles';
import * as typography from 'components/typography';
import { usePrescriptionContext, useSnackbar } from 'context';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'helper/Auth';
import { message, routesConfig } from 'helper/config';
import { addItemToCart } from 'redux/actions';
import { useNavigate } from 'react-router-dom';
import { ReviewRating } from 'components/commonComponents';
import { STORAGE_KEYS } from 'helper/APP';
import { IoClose } from 'react-icons/io5';
import { sessionStorageHandler } from 'utils';

const ReviewFrameDetails = ({ product, selectedItem, closeButton }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const {
    lensSelectionFlowData,
    setSelectedLensType,
    setFinishLensSelection,
    setLensSelectionFlowData,
    setSelectedPrescriptionType,
    reset,
  } = usePrescriptionContext();

  const { selectedLensData } = useSelector((store) => store.lensSelection);
  const { frameSize } = useSelector((store) => store.frameSize);
  const products = useSelector((store) => store?.products);
  const {
    selectedProduct: {
      product_frame: { _id: productID, price: productPrice },
    },
    // frames,
  } = products;

  const allPrices = [productPrice];
  /* The code is iterating over the values of the `lensSelectionFlowData` object and checking if each value has a valid `price` property. If the value has a valid `price` property (not NaN), it is added to the `allPrices` array using the `push()` method. */
  Object.values(lensSelectionFlowData).forEach((x) => {
    !isNaN(x?.price) && allPrices.push(x.price);
  });
  /* The `totalPrice` variable is calculated using the `reduce()` method on the `allPrices` array. */
  const totalPrice = allPrices.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const onContinue = async () => {
    if (Auth.isLogin()) {
      const cartRequestBody = {
        user_id: Auth.getUserId(),
        items: [
          {
            product_frame_detail_id: frameSize?.frame_id,
            product_frame_id: productID,
            frame_size_id: frameSize?.sizeId,
            lenses: selectedLensData,
            quantity: 1,
            item_price: totalPrice,
          },
        ],
        final_amount: totalPrice,
      };
      dispatch(addItemToCart(cartRequestBody, navigate, showSnackbar));
      reset();
      showSnackbar(message?.PRESCRIPTION_SUBMITTED_SUCCESS, 'soft', 'success');
    } else {
      const cartList = JSON.parse(
        sessionStorageHandler.getItem(STORAGE_KEYS.cartList) || '[]'
      );
      cartList?.push({
        product_frame_detail_id: products.selectedProduct?.frames?.filter(
          (frame) => frame?._id === frameSize?.frame_id
        )?.[0],
        product_frame_id: products.selectedProduct,
        frame_size_id: frameSize?.sizeId,
        lenses: selectedLensData,
        _id: productID,
        quantity: 1,
        item_price: totalPrice,
      });
      sessionStorageHandler.setItem(
        STORAGE_KEYS.cartList,
        JSON.stringify(cartList)
      );
      const cartData = JSON.parse(
        sessionStorageHandler.getItem(STORAGE_KEYS.cartData) ||
          JSON.stringify({
            items: [],
            final_amount: 0,
          })
      );
      cartData.items.push({
        product_frame_detail_id: frameSize?.frame_id,
        product_frame_id: productID,
        frame_size_id: frameSize?.sizeId,
        lenses: selectedLensData,
        quantity: 1,
        item_price: totalPrice,
      });
      const final = cartData.items.reduce((total, data) => {
        return total + (data?.item_price || 0);
      }, 0);
      cartData.final_amount = final;
      sessionStorageHandler.setItem(
        STORAGE_KEYS.cartData,
        JSON.stringify(cartData)
      );
      navigate(routesConfig.shippingCart);
      reset();
    }
    setSelectedLensType('');
    setFinishLensSelection(false);
    setLensSelectionFlowData({});
    setSelectedPrescriptionType('');
  };

  return (
    <>
      <styles.ContainerWrapper className="h-[94%] py-[14px]">
        <div className="hidden screen1280:block">
          <div className="flex items-center gap-3 pb-[14px] mb-[14px] border-b-4">
            <button onClick={closeButton}>
              <IoClose size={20} color="#868A8B" />
            </button>
            <styles.Para16Tag>Review your selections</styles.Para16Tag>
          </div>
        </div>
        <div className="h-full flex items-center justify-center flex-col screen1280:justify-between">
          <div className="mx-auto w-fit screen1280:w-full screen1280min:p-8 ">
            <styles.Head32Tag
              className="pb-7 text-center screen1280:hidden"
              color={'#2E3233'}
              $weight={600}
            >
              Review your selections
            </styles.Head32Tag>
            <div>
              <h6 className="lens_slider_card_head_main_title">
                {process.env.REACT_APP_PUBLIC_NAME}
              </h6>
              <div className="lens_slider_card_main_head_black_title flex justify-between items-center">
                <styles.Head24Tag>
                  {product?.product_frame?.product_name}
                </styles.Head24Tag>
                <styles.Head24Tag>
                  ${product?.product_frame?.price}
                </styles.Head24Tag>
              </div>
              {product?.avgRating && product?.totalReview > 0 ? (
                <div className="lens_slider_card_star_review_add">
                  <div className="lens_slider_card_star_review_add_inner">
                    <div className="lens_slider_card_rating">
                      <ReviewRating
                        name="read-only"
                        value={product?.product_frame?.avgRating}
                        readOnly
                        max={5}
                        size={20}
                        isHalf={true}
                      />
                    </div>
                    <div className="lens_slider_card_review_add_main">
                      {product?.product_frame?.avgRating}{' '}
                      <span className="lens_slider_card_total_review_add_inner">
                        ( {product?.product_frame?.totalReview} Review )
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
              <p className="lens_slider_card_para_size_frame flex items-center mt-4">
                Size : {selectedItem?.size}
                <span className="info_icon_span">
                  <typography.ProductDetailsExcIcons />
                </span>
              </p>
              <p className="lens_slider_card_para_size_frame flex items-center mt-2">
                Color :{' '}
                <styles.ColorWrapper
                  className="ml-2"
                  $color_img={selectedItem?.color}
                />
              </p>
              {/* The code is mapping over the values of the `lensSelectionFlowData` object and
          rendering a paragraph element (`<p>`) for each value. */}
              {Object.values(lensSelectionFlowData).map((step, index) => {
                return (
                  <p
                    key={index}
                    className="lens_slider_card_para_size_frame flex items-center  mt-2"
                  >
                    {`${step?.title} ${
                      step?.price === 0
                        ? '(free)'
                        : step?.price
                          ? `($${step?.price})`
                          : ''
                    }`}
                  </p>
                );
              })}
            </div>
            <div className="flex items-center justify-between mb-11 mt-5">
              <styles.Para22Tag>Total</styles.Para22Tag>
              <styles.Head32Tag $weight={600}>${totalPrice}</styles.Head32Tag>
            </div>
          </div>
          <div className="flex justify-between gap-4 screen1280:w-full">
            <styles.ButtonWrapper
              // onClick={handleBackButtonClick}
              $bg_color={'#FFF'}
              $bColor={'#DEE2E4'}
              color={'#5F6364'}
              $hover_color={'#2E3233'}
              $hover_bg={'#FFF'}
              $hover_border={'#DEE2E4'}
              width={'100%'}
              $border_color={'#DEE2E4'}
              className="!hidden screen1280:!block"
            >
              Back
            </styles.ButtonWrapper>
            <styles.ButtonWrapper onClick={() => onContinue()} width={'100%'}>
              Confirm & Add to cart
            </styles.ButtonWrapper>
          </div>
        </div>
      </styles.ContainerWrapper>
    </>
  );
};

export default ReviewFrameDetails;
