/**
 * The `UserPrescriptionReviewTable` component is a React component that renders a table with
 * prescription review data.
 * @returns The UserPrescriptionReviewTable component is being returned. It renders a table using the
 * Material-UI library. The table has a header with columns for Eye, SPH, CYL, and AXIS. The table body
 * is populated with data from the `rows` prop, which is an array of objects. Each object represents a
 * row in the table and contains properties for eye, sph, cyl,
 */
import React from 'react';
import * as material from '@mui/material';
import { TableWrapper } from 'assets/styles';
import { usePrescriptionContext } from 'context';

const UserPrescriptionReviewTable = ({ rows }) => {
  const { selectedLensType } = usePrescriptionContext();
  const isProgressives = selectedLensType === 'Progressives';

  return (
    <>
      <material.Paper
        sx={{
          width: '100%',
          overflow: 'hidden',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        <TableWrapper>
          <material.Table aria-label="simple table">
            <material.TableHead>
              <material.TableRow>
                <material.TableCell style={{ width: '20%' }} align="center">
                  Eye
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  SPH
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  CYL
                </material.TableCell>
                <material.TableCell style={{ width: '20%' }} align="center">
                  AXIS
                </material.TableCell>
                {isProgressives && (
                  <material.TableCell style={{ width: '20%' }} align="center">
                    ADD
                  </material.TableCell>
                )}
              </material.TableRow>
            </material.TableHead>

            <material.TableBody>
              {rows.map((row) => (
                <material.TableRow
                  key={row.eye}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <material.TableCell
                    style={{ width: '20%' }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    {row.eye === 'OD' ? (
                      <>
                        <span>OD</span>
                        <p className="text-xs text-[#6f6f6f]">(Right Eye)</p>
                      </>
                    ) : row.eye === 'OS' ? (
                      <>
                        <span>OS</span>
                        <p className="text-xs text-[#6f6f6f]">(Left Eye)</p>
                      </>
                    ) : (
                      <span>PD</span>
                    )}
                  </material.TableCell>
                  <material.TableCell style={{ width: '20%' }} align="center">
                    {/* <p className='text-[16px] font-[500]'>{row.sph ? row.sph > 0 ? '+' + row.sph : row.sph : '-'}</p> */}
                    {row.sph !== undefined && row.sph !== null && row.eye !== 'PD'
                      ? <p className='text-[16px] font-[500]'>{(Number(row.sph) > 0 ? `+${Number(row.sph).toFixed(2)}` : Number(row.sph).toFixed(2))}</p>
                      : row.eye == 'PD' ? <p className='text-[16px] font-[500]'>{row.sph}</p> : '-'}

                  </material.TableCell>
                  <material.TableCell style={{ width: '20%' }} align="center">
                    {/* <p className='text-[16px] font-[500]'>{row.cyl ? row.cyl > 0 ? '+' + row.cyl : row.cyl : '-'}</p> */}
                    {row.cyl !== undefined && row.cyl !== null && row.eye !== 'PD'
                      ? <p className='text-[16px] font-[500]'>{(Number(row.cyl) > 0 ? `+${Number(row.cyl).toFixed(2)}` : Number(row.cyl).toFixed(2))}</p>
                      : row.eye == 'PD' ? <p className='text-[16px] font-[500]'>{row.cyl ? row.cyl : '-'}</p> : '-'}

                    {/* {row.cyl ? row.cyl : '-'} */}
                  </material.TableCell>
                  <material.TableCell style={{ width: '20%' }} align="center">
                    {row.axis ? row.axis : '-'}
                  </material.TableCell>
                  {isProgressives && (
                    <material.TableCell style={{ width: '20%' }} align="center">
                      {row.pd ? row.pd : '-'}
                      {console.log("row-pd:", row.pd)}
                    </material.TableCell>
                  )}
                </material.TableRow>
              ))}
              {console.log("🚀 ~ file: UserPrescriptionReviewTable.jsx:102 ~ rows:", rows)}
            </material.TableBody>
          </material.Table>
        </TableWrapper>
      </material.Paper>
    </>
  );
};

export default UserPrescriptionReviewTable;
